<template>
  <div>
    <div>
      <div v-if="hasPermission(Admin.companyDeployWrite)">
        <button v-if="!showAdd" @click="open" class="floating_btn">
          <i class="fa fa-plus my-float"></i>
        </button>
        <button v-else @click="open" class="floating_btn">
          <i class="fa fa-arrow-left my-float"></i>
        </button>
      </div>
      <div v-else>
        <button v-if="showAdd" @click="open" class="floating_btn">
          <i class="fa fa-arrow-left my-float"></i>
        </button>
      </div>

      <CompanyList v-if="showList" v-model="showAdd" @listView="listView" />
      <router-view @open="open"></router-view>
    </div>
  </div>
</template>

<script>
import { useRoute, useRouter } from "vue-router";
import web from "@/urls/web";
import { ref, watch, watchEffect } from "vue";
import permission from "@/modules/permission";
import roles from "@/modules/roles";
import CompanyList from "@/views/Admin/CompanyDeploy/CompanyList";
import store from "@/store";

const { hasPermission } = permission();
const { Admin } = roles();

export default {
  name: "AdminCompanyDeploy",
  components: { CompanyList },
  setup() {
    const showAdd = ref(false);
    const showList = ref(true);
    const route = useRoute();
    const router = useRouter();
    const listUrl = web.Admin.COMPANY_DEPLOY_URL.name;

    watchEffect(() => {
      if (route.name === listUrl) {
        showAdd.value = false;
        showList.value = true;
      } else {
        showList.value = false;
        showAdd.value = true;
      }
    });
    watch(
      () => store.getters.userRoles,
      (value) => {
        if (!value?.includes(Admin.companyDeployRead)) {
          router
            .push(web.Default.LOGIN_URL.path)
            .catch((error) => console.log(error));
        }
      }
    );
    const open = () => {
      if (!showAdd.value) {
        showAdd.value = true;
      } else {
        router.go(-1);
      }
    };
    const listView = () => {
      if (showList.value) {
        showList.value = false;
      }
    };

    return {
      showAdd,
      Admin,
      showList,
      hasPermission,
      open,
      listView,
    };
  },
};
</script>

<style scoped>
</style>