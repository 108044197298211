<template>
  <div v-if="hasPermission(Owner.ownerReports)">
    <div>
      <div v-if="reportTabs">
        <ul
            class="nav nav-tabs justify-content-center nav-style"
            id="myTab"
            role="tablist"
        >
          <li
              v-for="(tab, index) in reportTabs"
              :key="index"
              class="nav-item"
              role="presentation"
          >
            <button
                class="nav-link"
                :id="tab.name + '-tab'"
                data-bs-toggle="tab"
                v-bind:class="
                tab.name === activeTab
                  ? 'active active-button'
                  : 'inactive-button'
              "
                type="button"
                @click="setActive(tab.name)"
                role="tab"
                :aria-controls="tab.path"
                aria-selected="true"
            >
              {{ t(tab.title, {}, {locale: $store.getters.getLang}) }}
            </button>
          </li>
        </ul>
        <div class="tab-content" id="myTabContent">
          <div
              v-for="(tab, index) in reportTabs"
              :key="index"
              class="tab-pane fade"
              v-bind:class="tab.name === activeTab ? 'show active' : ''"
              :id="tab.path"
              role="tabpanel"
              :aria-labelledby="tab.name + '-tab'"
          ></div>
          <!--          <ChalanTripList-->
          <!--            v-if="activeTab === reportTabs.CHALAN_REPORT_URL.name"-->
          <!--          />-->
          <CoachReport v-if="activeTab === reportTabs.COACH_REPORT_URL.name"/>
          <DigitalTicketReport v-if="activeTab === reportTabs.DIGITAL_TICKET_REPORT_URL.name"/>
          <!--          <CounterReport-->
          <!--            v-if="activeTab === reportTabs.COUNTER_REPORT_URL.name"-->
          <!--          />-->
          <!--          <PrintCountReport-->
          <!--            v-if="activeTab === reportTabs.PRINT_COUNT_REPORT_URL.name"-->
          <!--          />-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import permission from "@/modules/permission";
import roles from "@/modules/roles";
import {onBeforeMount, onBeforeUnmount, ref, watch} from "vue";
import web from "@/urls/web";
import store from "@/store";
// import ChalanTripList from "./Children/Chalan/ChalanTripList.vue";
import DigitalTicketReport from "@/views/Owner/Report/Children/DigitalTicket/DigitalTicketReport.vue";
// import CounterReport from "@/views/Owner/Report/Children/Counter/CounterReport.vue";
import CoachReport from "@/views/Owner/Report/Children/Coach/CoachReport.vue";
// import PrintCountReport from "@/views/Owner/Report/Children/PrintCount/PrintCountReport.vue";
import router from "@/router";
import {useI18n} from "vue-i18n";

const {hasPermission} = permission();
const {Owner} = roles();
export default {
  name: "AdminReport",
  components: {
    // ChalanTripList,
    // CounterReport,
    CoachReport,
    // PrintCountReport,
    DigitalTicketReport,
  },
  setup() {
    let activeTab = ref("");
    const reportTabs = web.Owner.REPORT_URL.children;
    const {t, locale} = useI18n();
    watch(
        () => store.getters.userRoles,
        (value) => {
          if (!value?.includes(Owner.ownerReports)) {
            router
                .push(web.Default.LOGIN_URL.path)
                .catch((error) => console.log(error));
          }
        }
    );
    const setActive = (index) => {
      activeTab.value = index;
      store.commit("setActiveTab", activeTab.value);
    };

    onBeforeMount(() => {
      activeTab.value = store.getters.getActiveTab
          ? store.getters.getActiveTab
          : reportTabs.COACH_REPORT_URL.name;
      // : reportTabs.CHALAN_REPORT_URL.name;
    });
    onBeforeUnmount(() => {
      if (store.getters.isLoggedIn) {
        store.commit("setActiveTab", "");
      }
    });
    return {
      t,
      locale,
      Owner,
      activeTab,
      reportTabs,
      setActive,
      hasPermission,
    };
  },
};
</script>

<style scoped>
.nav-style {
  background: #3b3b3b;
  border-top: 1px;
}

.active-button {
  background: #242424 !important;
  border: 1px solid #242424 !important;
  color: #fff !important;
}

.inactive-button {
  color: #fff !important;
}
</style>
