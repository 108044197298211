import permission from "@/modules/permission";
import router from "@/router";
const {permittedRoute,permissionGranted} = permission();

export default function hasAccess({to, next}) {
    if (!permissionGranted(to.name)) {
        if(to.name == 'counterman-dashboard') {
            router.push('/counterman/verify').catch(error => (console.log(error)))
        } else {
            return next({
                name: permittedRoute()
            });
        }
    }
    return next();
}