<template>
  <div>
    <FilterTab>
      <template v-slot:body>
        <div class="col-sm-6 col-md-2">
          <DropdownFilter
              v-bind:id="'reporttype'"
              v-bind:label="'Report Type'"
              v-bind:defaultOption="'--select report type--'"
              v-model="reportingType"
              v-bind:defaultValue="''"
              v-bind:dropdown="dropdownVariables.reportType"
          />
        </div>
        <div
            v-if="showDatePicker === 'dateTimeRange'"
            class="col-sm-6 col-md-4"
        >
          <DateTimeRangePicker v-model="date.range"/>
        </div>
        <div
            v-if="showDatePicker === 'dateRange'"
            class="col-sm-6 col-md-2 text-white"
        >
          <DatePickerFilter
              v-model="printCountReport.summaryDate"
              v-bind:id="'countdate'"
              v-bind:label="'Report Count Date'"
          >
          </DatePickerFilter>
        </div>
      </template>
      <template v-slot:button2>
        <button class="edit_table_data font_detail" @click="filterCountReport">
          <i class="fas fa-filter"></i><span class="mx-1 bold">Filter</span>
        </button>
      </template>
      <template v-slot:button1>
        <button
            v-if="
            ($store.getters.getPrintTicketCountList.length > 0 ||
              $store.getters.getPrintDailySummaryWiseCountList.length > 0 ||
              $store.getters.getPrintTripWiseCountList.length > 0)
          "
            class="edit_table_data font_detail"
            @click="exportExcel('xlsx')"
        >
          <i class="fas fa-download"></i><span class="mx-1 bold">Export</span>
        </button>
      </template>
    </FilterTab>
    <div
        class="alert alert-warning"
        role="alert"
        v-if="
        reportingType === '' &&
        filterActive"
    >
      Please,Select
      <a href="#" class="alert-link">report type</a> from dropdown
    </div>

    <TicketPrintCountDetails
        v-if="printCountReport.reportType === 'ticket' && loading"
    />
    <TripWisePrintCountReportDetails
        v-if="printCountReport.reportType === 'tripWiseReport' && loading"
    />
    <DailySummaryReportDetails
        v-if="printCountReport.reportType === 'dailySummaryReport' && loading"
    />
  </div>

  <router-view></router-view>
</template>

<script>
import DateTimeRangePicker from "@/components/layouts/Filter/Inputs/DateTimeRangePicker";
import DropdownFilter from "@/components/layouts/Filter/Inputs/Dropdown";
import TicketPrintCountDetails from "./TicketPrintCountDetails.vue";
import TripWisePrintCountReportDetails from "./TripWisePrintCountReportDetails.vue";
import DailySummaryReportDetails from "./DailySummaryReportDetails.vue";
import dropdownVariables from "@/modules/dropdownVariables";
import {reactive} from "@vue/reactivity";
import moment from "moment";
import {computed, onBeforeMount, watch, ref, inject} from "vue";
import store from "@/store";
import XLSX from "xlsx";

export default {
  name: "PrintCountReport",
  components: {
    DateTimeRangePicker,
    DropdownFilter,
    TicketPrintCountDetails,
    TripWisePrintCountReportDetails,
    DailySummaryReportDetails,
  },

  setup() {
    const fromDate = moment(new Date()).format("yyyy-MM-DD" + " 00:00:00");
    const toDate = moment(new Date()).format("yyyy-MM-DD" + " 23:59:59");
    const today = moment(new Date()).format("yyyy-MM-DD");
    let reportingType = ref("");
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    const loading = ref(false);
    let filterActive = ref(false);

    let date = reactive({
      range: {
        start: fromDate,
        end: toDate,
      },
    });

    let printCountReport = reactive({
      reportType: "",
      fromDate: "",
      toDate: "",
      summaryDate: "",
    });

    const showDatePicker = computed(() => {
      if (
          (reportingType.value === "ticket" ||
              reportingType.value === "tripWiseReport")
      ) {
        return "dateTimeRange";
      } else if (
          reportingType.value === "dailySummaryReport"
      ) {
        return "dateRange";
      }
      return "nothing";
    });

    watch(
        () => date.range.start,
        (date) => {
          printCountReport.fromDate = date;
        }
    );
    watch(
        () => date.range.end,
        (date) => {
          printCountReport.toDate = date;
        }
    );

    const filterCountReport = () => {
      filterActive.value = true;
      showLoader();
      loading.value = false;
      printCountReport.reportType = reportingType.value;
      if (reportingType.value) {
        store
            .dispatch("getPrintCountList", printCountReport)
            .then(() => {
              loading.value = true;
              hideLoader();
            })
            .catch((error) => {
              loading.value = true;
              hideLoader();
              if (error.status === 401) {
                filterCountReport();
              }
            });
      } else {
        hideLoader();
      }
    };

    onBeforeMount(() => {
      printCountReport.fromDate = fromDate;
      printCountReport.toDate = toDate;
      printCountReport.summaryDate = today;
    });

    const exportExcel = (type, fn, dl) => {
      if (document.getElementById("exportable_data") !== null) {
        var elt = document.getElementById("exportable_data");
        var wb = XLSX.utils.table_to_book(elt, {sheet: "sheet Js"});
        return dl
            ? XLSX.write(wb, {bookType: type, bookSST: true, type: "base64"})
            : XLSX.writeFile(
                wb,
                fn || "Admin_PrintCount_Report." + (type || "xlsx")
            );
      }
    };

    return {
      dropdownVariables,
      date,
      printCountReport,
      filterCountReport,
      exportExcel,
      showDatePicker,
      reportingType,
      loading,
      filterActive,
    };
  },
};
</script>

<style scoped></style>
