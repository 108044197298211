import web from "@/urls/web";

export  function companyStatus ({ next, store, to,   }){
    
    if(to.query.id != store.getters.getSingleCompanyData && !store.getters.getSingleCompanyData.status){
        return next({
            name: web.Default.LOGIN_URL.name
        })
    }
    return next()
}