<template>
  <Modal @close="closeModal">
    <template v-slot:title>{{ title }}</template>
    <template v-slot:body>
      <form>
        <div class="form-group row my-1">
          <ModalDropdown
              v-model="tripData.route"
              v-bind:id="'route'"
              v-bind:label="'Routes'"
              v-bind:defaultOption="'---select a route---'"
              v-bind:defaultValue="''"
              v-bind:dropdown="$store.getters.getRouteListForFilterAdmin"
          >
            <template v-slot:error>
              <ModalError v-if="errors.route" v-bind:error="errors.route"/>
              <ModalError v-if="error" v-bind:error="error"/>
            </template>
          </ModalDropdown>
        </div>
        <div v-if="tripId" class="form-group row my-1">
          <DatePicker
              v-model="tripData.tripDate"
              v-bind:id="'tripDate'"
              v-bind:label="'Trips Date'"
          >
            <template v-slot:error>
              <ModalError
                  v-if="errors.tripDate"
                  v-bind:error="errors.tripDate"
              />
            </template>
          </DatePicker>
        </div>

        <div v-else>
          <div class="form-group row my-1">
            <DatePicker
                v-model="tripData.fromDate"
                v-bind:id="'fromDate'"
                v-bind:label="'From'"
            >
              <template v-slot:error>
                <ModalError
                    v-if="errors.fromDate"
                    v-bind:error="errors.fromDate"
                />
              </template>
            </DatePicker>
          </div>

          <div class="form-group row my-1">
            <DatePicker
                v-model="tripData.toDate"
                v-bind:id="'toDate'"
                v-bind:label="'To'"
            >
              <template v-slot:error>
                <ModalError v-if="errors.toDate" v-bind:error="errors.toDate"/>
              </template>
            </DatePicker>
          </div>
        </div>

        <div class="form-group row my-1">
          <TimePicker
              v-model="tripData.tripTime"
              v-bind:id="'tripTime'"
              v-bind:label="'Time'"
          >
            <template v-slot:error>
              <ModalError
                  v-if="errors.tripTime"
                  v-bind:error="errors.tripTime"
              />
            </template>
          </TimePicker>
        </div>

        <div class="form-group row my-1">
          <ModalDropdown
              v-model="tripData.coach"
              v-bind:id="'coach'"
              v-bind:label="'Coach'"
              v-bind:defaultOption="'---select a coach---'"
              v-bind:defaultValue="''"
              v-bind:dropdown="$store.getters.getAllCoachListForFilterAdmin"
          >
            <template v-slot:error>
              <ModalError v-if="errors.coach" v-bind:error="errors.coach"/>
            </template>
          </ModalDropdown>
        </div>
        <div class="form-group row my-1">
          <ModalDropdown
              v-model="tripData.seatPlan"
              v-bind:id="'seatPlan'"
              v-bind:label="'Seat Plan'"
              v-bind:defaultOption="'---select a seat plan---'"
              v-bind:defaultValue="''"
              v-bind:dropdown="$store.getters.getSeatPlanListForFilterAdmin"
          >
            <template v-slot:error>
              <ModalError
                  v-if="errors.seatPlan"
                  v-bind:error="errors.seatPlan"
              />
            </template>
          </ModalDropdown>
        </div>
        <div class="form-group row my-1">
          <ModalInput
              v-model="tripData.depotName"
              v-bind:placeholder="'--- coach depot ---'"
              v-bind:label="'Depot'"
              v-bind:id="'depot'"
              v-bind:type="'text'"
              v-bind:disable="true"
          >
            <template v-slot:error>
              <ModalError v-if="errors.depot" v-bind:error="errors.depot"/>
            </template>
          </ModalInput>
        </div>
        <div class="form-group row my-1">
          <ModalDropdown
              v-if="$store.getters.getStoppageListForFilter"
              v-model="tripData.fromStoppage"
              v-bind:id="'fromStoppage'"
              v-bind:label="'From Stoppage'"
              v-bind:dropdown="$store.getters.getStoppageListForFilter"
              v-bind:defaultOption="'--select stoppage--'"
              v-bind:defaultValue="''"
              v-bind:trip="'active'"
          >
            <template v-slot:error>
              <ModalError
                  v-if="errors.fromStoppage"
                  v-bind:error="errors.fromStoppage"
              />
            </template>
          </ModalDropdown>
        </div>
        <div class="form-group row my-1">
          <ModalDropdown
              v-if="$store.getters.getStoppageListForFilter"
              v-model="tripData.toStoppage"
              v-bind:id="'toStoppage'"
              v-bind:label="'To Stoppage'"
              v-bind:dropdown="$store.getters.getStoppageListForFilter"
              v-bind:defaultOption="'--select stoppage--'"
              v-bind:defaultValue="''"
              v-bind:trip="'active'"
          >
            <template v-slot:error>
              <ModalError
                  v-if="errors.toStoppage"
                  v-bind:error="errors.toStoppage"
              />
            </template>
          </ModalDropdown>
        </div>
        <div class="form-group row my-1">
          <ModalDropdown
              v-if="digitalTicketingStatus"
              v-model="tripData.dtStatus"
              v-bind:id="'dtStatus'"
              v-bind:label="'DT Status'"
              v-bind:dropdown="digitalTicketingStatus"
          ></ModalDropdown>
        </div>
        <div class="form-group row my-1">
          <ModalDropdown
              v-if="status"
              v-model="tripData.status"
              v-bind:id="'status'"
              v-bind:label="'Status'"
              v-bind:dropdown="status"
          ></ModalDropdown>
        </div>

        <div class="form-group row my-1">
          <label class="col-sm-3 col-form-label"></label>
          <div class="col-sm-9">
            <input
                v-if="!tripId"
                type="submit"
                class="mt-3 py-2 submit_button font_detail float-end"
                @click.prevent="addTrip"
            />
            <input
                v-else
                type="submit"
                class="mt-3 py-2 submit_button font_detail float-end"
                @click.prevent="updateTrip"
            />
          </div>
        </div>
      </form>
    </template>
  </Modal>
</template>

<script>
import dropdownVariables from "@/modules/dropdownVariables";
import useInputValidation from "@/modules/useInputValidations";
import {computed, inject, onBeforeMount, ref, watch} from "vue";
import {reactive} from "@vue/reactivity";
import store from "@/store";
import moment from "moment";
import {useRoute} from "vue-router";

export default {
  name: "TripsAddUpdateModal",
  props: {
    trip: String,
    pagination: Object,
  },
  setup(props, {emit}) {
    const route = useRoute();
    let {requiredValidation, errors} = useInputValidation();
    let tripData = reactive({
      id: "",
      companyId: "",
      fromDate: "",
      toDate: "",
      tripTime: "",
      route: "",
      fare: "",
      coach: "",
      depot: "",
      depotName: "",
      seatPlan: "",
      driver: null,
      supervisor: null,
      fromStoppage: "",
      toStoppage: "",
      tripDate: "",
      blockedSeats: [],
      dtStatus: 0,
      status: "ACTIVE",
    });
    const tripId = ref(props.trip);
    const title = ref("Add Trips");
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    let error = ref("");

    const digitalTicketingStatus = dropdownVariables.boolean

    const status = computed(() => {
      let allTripStatus = {};
      for (let [key, value] of Object.entries(dropdownVariables.tripStatus)) {
        allTripStatus[key] = value.value;
      }
      return allTripStatus;
    });

    // watch(
    //     () => tripData.coach,
    //     (value) => {
    //       store.getters.getCoachs.forEach((coach) => {
    //         if (coach._id === value) {
    //           tripData.seatPlan = coach.seatPlanId._id;
    //         }
    //       });
    //     }
    // );

    watch(
        () => tripData.route,
        (value) => {
          requiredValidation("route", tripData.route);
          if(errors.route === null || errors.route === ""){
            allTripFare(value)
          }
          store.getters.getRoutes.forEach((route) => {
            if (route._id === value) {
              tripData.fromStoppage = route.fromStoppageId;
              tripData.toStoppage = route.toStoppageId;
            }
          });
        }
    );

    watch(
        () => tripData.coach,
        (value) => {
          requiredValidation("coach", tripData.coach);
          if(errors.coach === null || errors.coach === ""){
            store.getters.getCoachs.forEach((coach) => {
              if (coach._id === value) {
                tripData.seatPlan = coach.seatPlanId._id;
              }
            });
            store.getters.getCoachs.forEach((coach) => {
              if (coach._id === value) {
                tripData.depot = coach.depot._id;
                tripData.depotName = coach.depot.name;
              }
            });
          }
        }
    );
    watch(
        () => tripData.seatPlan,
        () => {
          requiredValidation("seatPlan", tripData.seatPlan);
        }
    );

    const allTripFare = (routeId) => {
      store
          .dispatch("allTripFare", {
            companyId: route.query.id,
            routeId: routeId,
          })
          .then((response) => {
            error.value = "";
            if (response.data.fare[0]?.fareModality?.length > 0) {
              response.data.fare.forEach((fare) => {
                tripData.fare = fare._id;
              });
            } else {
              error.value = "Fare not found for this route";
            }
          });
    }
    const validate = () => {
      if (error.value !== "") {
        return false;
      }
      Object.keys(errors).forEach(function (key) {
        errors[key] = "";
      });
      requiredValidation("coach", tripData.coach);
      requiredValidation("depotName", tripData.depotName);
      requiredValidation("seatPlan", tripData.seatPlan);
      requiredValidation("route", tripData.route);
      requiredValidation("tripTime", tripData.tripTime);
      if (tripId.value !== "") {
        requiredValidation("tripDate", tripData.tripDate);
      } else {
        requiredValidation("fromDate", tripData.fromDate);
        requiredValidation("toDate", tripData.toDate);
      }
      return !(
          Object.values(errors).length <= 0 ||
          !Object.values(errors).every((x) => x === null || x === "")
      );
    };
    const closeModal = () => {
      emit("update:modelValue", false);
    };
    const getAllCoachList = () => {
      store.dispatch("getAllCoachList", {companyId: route.query.id});
    }
    const addTrip = () => {
      if (validate()) {
        showLoader();
        store
            .dispatch("addTrip", {
              tripData: tripData,
              pagination: {
                ...props.pagination
              },
            })
            .then(() => {
              hideLoader();
              emit("close");
            })
            .catch((error) => {
              hideLoader();
              if (error.status === 401) {
                addTrip();
              }
            });
      }
    };
    const updateTrip = () => {
      if (validate()) {
        showLoader();
        store
            .dispatch("updateTrip", {
              tripData: tripData,
              pagination: {
                ...props.pagination
              },
            })
            .then(() => {
              hideLoader();
              emit("getAllTrips");
              emit("close");
            })
            .catch((error) => {
              hideLoader();
              if (error.status === 401) {
                updateTrip();
              }
            });
      }
    };

    onBeforeMount(() => {
      getAllCoachList()
      Object.keys(errors).forEach(function (key) {
        delete errors[key];
      });
      if (tripId.value !== "") {
        const tripDetails = store.getters.getTrip(tripId.value);
        title.value = "Update Trip";
        tripData.id = tripDetails[0]._id;
        tripData.route = tripDetails[0].route._id;
        tripData.coach = tripDetails[0].coach._id;
        tripData.depot = tripDetails[0].depot._id;
        tripData.depotName = tripDetails[0].depot.name;
        tripData.seatPlan = tripDetails[0].seatPlan._id;
        tripData.driver = tripDetails[0].driver;
        tripData.supervisor = tripDetails[0].supervisor;
        tripData.fromStoppage = tripDetails[0].fromStoppage._id;
        tripData.toStoppage = tripDetails[0].toStoppage._id;
        tripData.fare = tripDetails[0].fare?._id;
        tripData.tripDate = moment(tripDetails[0].tripDateTime)
            .utcOffset(0, false)
            .format("yyyy-MM-DD");
        tripData.tripTime = moment(tripDetails[0].tripDateTime)
            .utcOffset(0, false)
            .format("HH:mm:00");
        tripData.dtStatus = tripDetails[0].dtStatus ? 1 : 0;
        tripData.status = tripDetails[0].status;
      } else {
        tripData.fromDate = moment(new Date()).format("yyyy-MM-DD");
        tripData.toDate = moment(new Date()).format("yyyy-MM-DD");
        tripData.tripTime = moment(new Date()).format("HH:mm:00");
      }
      tripData.companyId = route.query.id;
    });

    return {
      tripId,
      errors,
      status,
      digitalTicketingStatus,
      title,
      tripData,
      error,
      addTrip,
      updateTrip,
      closeModal,
    };
  },
};
</script>

<style scoped></style>
