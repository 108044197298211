<template>
  <div class="col-sm-3">
    <label :for="inputId" class="font_detail"
      >{{ t(inputLabel, {}, { locale: $store.getters.getLang }) }} :</label
    >
  </div>
  <div class="col-sm-9">
    <input
      :id="inputId"
      v-model="input"
      :type="inputType"
      :placeholder="inputPlaceholder"
      autocomplete="on"
      class="form-control font_detail"
    />
    <slot name="error"></slot>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";
export default {
  name: "Input",
  props: {
    modelValue: [String, Number],
    placeholder: String,
    label: String,
    id: String,
    type: String,
  },
  setup(props, { emit }) {
    const inputPlaceholder = ref(props.placeholder);
    const inputLabel = ref(props.label);
    const inputId = ref(props.id);
    const inputType = ref(props.type);
    const input = ref(props.modelValue);
    const { t, locale } = useI18n();
    watch(
      () => input.value,
      () => {
        emit("update:modelValue", input.value);
      }
    );
    watch(
      () => props.modelValue,
      (value) => {
        input.value = value;
      }
    );
    return {
      t,
      locale,
      inputPlaceholder,
      inputLabel,
      inputId,
      inputType,
      input,
    };
  },
};
</script>

<style scoped></style>
