<template>
  <div>
    <button
      v-if="hasPermission(Admin.adminWrite)"
      @click="open"
      class="floating_btn"
    >
      <span><i class="fa fa-plus my-float"></i></span>
    </button>
    <AdminList v-model="showAdd" />
    <!--    <router-view @open="open"></router-view>-->
  </div>
</template>

<script>
import { ref, watch } from "vue";
import web from "@/urls/web";
import permission from "@/modules/permission";
import roles from "@/modules/roles";
import AdminList from "@/views/Admin/Admins/AdminList";
import store from "@/store";
import router from "@/router";

const { hasPermission } = permission();
const { Admin } = roles();

export default {
  name: "AdminAdmins",
  components: { AdminList },
  setup() {
    const addUrl = web.Admin.ADMINS_URL.children.ADD_URL.name;
    const listUrl = web.Admin.ADMINS_URL.children.LIST_URL.name;
    const showAdd = ref(false);
    watch(
      () => store.getters.userRoles,
      (value) => {
        if (!value?.includes(Admin.adminRead)) {
          router
            .push(web.Default.LOGIN_URL.path)
            .catch((error) => console.log(error));
        }
      }
    );
    const open = () => {
      showAdd.value = !showAdd.value;
    };

    return {
      showAdd,
      addUrl,
      listUrl,
      Admin,
      open,
      hasPermission,
    };
  },
};
</script>

<style scoped>
</style>