<template>
  <div v-if="reportActive">
    <FilterTab>
      <template v-slot:body>
        <div class="col-sm-6 col-md-2">
          <DropdownFilter
              v-bind:id="'depot'"
              v-bind:label="'Depot'"
              v-bind:defaultOption="'--select depot--'"
              v-model="coachWiseReport.depot"
              v-bind:defaultValue="''"
              v-bind:dropdown="$store.getters.getAllDepotList"
          />
        </div>
        <div class="col-sm-6 col-md-2" v-if="coachWiseReport.depot">
          <DropdownFilter
              v-bind:id="'coach'"
              v-bind:label="'Coach'"
              v-bind:defaultOption="'--select coach--'"
              v-model="coachWiseReport.coach"
              v-bind:defaultValue="''"
              v-bind:dropdown="$store.getters.getCoachList"
          />
        </div>
        <div class="col-sm-6 col-md-4" v-if="coachWiseReport.depot">
          <DateTimeRangePicker v-model="date.range"/>
        </div>
        <div
            class="col-sm-6 col-md-2 col-xxl-1"
            v-if="coachWiseReport.depot"
        >
          <DropdownFilter
              v-bind:id="'status'"
              v-bind:label="'Status'"
              v-bind:defaultOption="'--select status--'"
              v-model="coachWiseReport.status"
              v-bind:defaultValue="''"
              v-bind:dropdown="dropdownVariables.reportStatus"
          />
        </div>
        <div
            class="col-sm-6 col-md-2 col-xxl-1"
            v-if="coachWiseReport.depot"
        >
          <CheckboxSingle v-model="isDiscount"/>
        </div>
      </template>
      <template v-slot:button2>
        <button class="edit_table_data font_detail" @click="filterReport">
          <i class="fas fa-filter"></i><span class="mx-1 bold">Filter</span>
        </button>
      </template>
      <template v-slot:button1>
        <button
            v-if="
            coachWiseReport.depot &&
            $store.getters.getCoachWiseReportAdminIntercity.length > 0
          "
            class="edit_table_data font_detail"
            @click="
            exportExcel(
              'xlsx',
              'Admin_Coach_Wise_Report.',
              undefined,
              'exportable_data'
            )
          "
        >
          <i class="fas fa-download"></i><span class="mx-1 bold">Export</span>
        </button>
      </template>
    </FilterTab>
    <div
        class="alert alert-warning"
        role="alert"
        v-if="coachWiseReport.depot === '' && selectDepot"
    >
      Please,Select a
      <a href="#" class="alert-link">depot</a> from dropdown
    </div>
    <Table
        v-if="
        coachWiseReport.depot !== '' &&
        filterActive &&
        $store.getters.getCoachWiseReportAdminIntercity.length > 0
      "
        id="exportable_data"
    >
      <template v-slot:header>
        <tr>
          <td class="font_detail">SL</td>
          <td class="font_detail">Coach Name</td>
          <td class="font_detail">Seat Ticket</td>
          <td class="font_detail">Seat Amount</td>
          <td class="font_detail">Details</td>
        </tr>
      </template>
      <template v-slot:body>
        <tr
            v-for="(item, index) in $store.getters
            .getCoachWiseReportAdminIntercity"
            :key="item._id"
        >
          <td class="font_detail">{{ index + 1 }}</td>
          <td class="font_detail">{{ item?.coachName }}</td>
          <td class="font_detail">{{ item?.seatTicket }}</td>
          <td class="font_detail">{{ item?.seatAmount }} Taka</td>

          <td class="font_detail">
            <button
                class="btn btn-primary m-1"
                @click="seatDetails(item.coachId, item.coachName)"
            >
              Seat
            </button>
          </td>
        </tr>
        <tr class="bg-dark text-white">
          <td class="font_detail"></td>
          <td class="font_detail"></td>
          <td class="font_detail text-white">
            Total Ticket: {{ seatTicketTotal }}
          </td>
          <td class="font_detail text-white">
            Total Amount: {{ seatAmountTotal }} Taka
          </td>
          <td class="font_detail"></td>
        </tr>
      </template>
    </Table>
  </div>

  <SeatDetails
      v-if="seatDetailsActive"
      :status="parseInt(coachWiseReport.status)"
      :fromDate="date.range.start"
      :toDate="date.range.end"
      :discount="coachWiseReport.discount"
      :coachId="coachIdPass"
      :coachName="coachNamePass"
      v-on:backToCoachWiseReport="onChildClick"
  />
  <router-view></router-view>
</template>

<script>
import DropdownFilter from "@/components/layouts/Filter/Inputs/Dropdown";
import dropdownVariables from "@/modules/dropdownVariables";
import {reactive} from "@vue/reactivity";
import moment from "moment";
import {watch, onBeforeMount, ref, inject} from "vue";
import store from "@/store";
import SeatDetails from "./SeatDetails.vue";
import CheckboxSingle from "@/components/layouts/Inputs/CheckboxSingle.vue";

export default {
  name: "CoachReport",
  components: {
    DropdownFilter,
    SeatDetails,
    CheckboxSingle,
  },
  emits: ["backToCoachWiseReport"],

  setup() {
    let seatDetailsActive = ref(false);
    let reportActive = ref(true);
    let coachIdPass = ref("");
    let coachNamePass = ref("");
    let filterActive = ref(false);
    let selectDepot = ref(false);

    let seatTicketTotal = ref(0);
    let seatAmountTotal = ref(0);
    let isDiscount = ref(false);
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    const exportExcel = inject("exportExcel");
    let summary = ref(false);

    const fromDate = moment(new Date()).format("yyyy-MM-DD" + " 00:00:00");
    const toDate = moment(new Date()).format("yyyy-MM-DD" + " 23:59:59");

    let date = reactive({
      range: {
        start: fromDate,
        end: toDate,
      },
    });

    let coachWiseReport = reactive({
      depot: "",
      coach: "",
      fromDate: "",
      toDate: "",
      discount: 0,
      status: 1,
    });

    const options = {
      texts: {
        count: "",
      },
    };

    watch(
        () => isDiscount.value,
        () => {
          isDiscount.value
              ? (coachWiseReport.discount = 1)
              : (coachWiseReport.discount = "");
        }
    );

    watch(
        () => coachWiseReport.depot,
        () => {
          coachWiseReport.coach = ""
          filterActive.value = false
          getAllCoachList();
        }
    );
    // watch(
    //     () => coachWiseReport.coach,
    //     (value) => {
    //       coachWiseReport.coach = value;
    //     }
    // );
    //
    // watch(
    //     () => coachWiseReport.status,
    //     (value) => {
    //       coachWiseReport.status = value;
    //     }
    // );
    watch(
        () => date.range.start,
        (value) => {
          coachWiseReport.fromDate = moment(value).format(
              "yyyy-MM-DD" + " 00:00:00"
          );
        }
    );
    watch(
        () => date.range.end,
        (value) => {
          coachWiseReport.toDate = moment(value).format(
              "yyyy-MM-DD" + " 23:59:59"
          );
        }
    );
    // watch(
    //   () => coachWiseReport.companyId,
    //   () => {
    //     if (coachWiseReport.companyId !== "") {
    //       getAllShips();
    //     }
    //   }
    // );

    // const getAllShips = () => {
    //     store.dispatch("getShipList", {companyId: coachWiseReport.companyId}).then(() => {})
    //         .catch((error) => {
    //             if (error.status === 401) {
    //                 getAllShips();
    //             }
    //         });
    // };

    const getAllDepotList = () => {
      showLoader();
      store.dispatch("getAllDepotList").then(() => hideLoader())
          .catch((error) => {
            hideLoader();
            if (error.status === 401) {
              getAllDepotList();
            }
          });
    };
    const getAllCoachList = () => {
      showLoader();
      store.dispatch("getAllCoachListForFilter", {depot: coachWiseReport.depot}).then(() => hideLoader())
          .catch((error) => {
            hideLoader();
            if (error.status === 401) {
              getAllCoachList();
            }
          });
    };
    const getAllCoachReport = () => {
      showLoader();
      store
          .dispatch("getAllCoachReport", coachWiseReport)
          .then((response) => {
            if (response.length === 0) {
              summary.value = false;
              seatTicketTotal.value = 0;
              seatAmountTotal.value = 0;
            } else {
              summary.value = true;
              seatTicketTotal.value = 0;
              seatAmountTotal.value = 0;
              sumListData();
            }
            hideLoader();
          })
          .catch((error) => {
            hideLoader()
            if (error.status === 401) {
              getAllCoachReport();
            }
          });
    };
    const filterReport = () => {
      selectDepot.value = true;
      if (coachWiseReport.depot !== "") {
        filterActive.value = true;

        getAllCoachReport();
      }
    };

    const sumListData = () => {
      store.getters.getCoachWiseReportAdminIntercity.forEach((data) => {
        seatTicketTotal.value += data.seatTicket;
        seatAmountTotal.value += data.seatAmount;
      });
    };

    onBeforeMount(() => {
      getAllDepotList();
      coachWiseReport.fromDate = fromDate;
      coachWiseReport.toDate = toDate;
    });

    const extraDetails = (coachId, coachName) => {
      coachNamePass.value = coachName;
      coachIdPass.value = coachId;
      seatDetailsActive.value = false;
      reportActive.value = false;
    };

    const goodsDetails = (coachId, coachName) => {
      coachNamePass.value = coachName;
      coachIdPass.value = coachId;
      seatDetailsActive.value = false;
      reportActive.value = false;
    };

    const seatDetails = (coachId, coachName) => {
      coachNamePass.value = coachName;
      coachIdPass.value = coachId;
      seatDetailsActive.value = true;
      reportActive.value = false;
    };

    const onChildClick = (value) => {
      reportActive.value = value;
      seatDetailsActive.value = !value;
    };

    return {
      dropdownVariables,
      date,
      coachWiseReport,
      filterReport,
      extraDetails,
      goodsDetails,
      seatDetails,
      seatDetailsActive,
      reportActive,
      coachIdPass,
      filterActive,
      selectDepot,
      onChildClick,
      coachNamePass,
      seatAmountTotal,
      seatTicketTotal,
      exportExcel,
      summary,
      options,
      getAllDepotList,
      isDiscount,
    };
  },
};
</script>

<style scoped></style>
