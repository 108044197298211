import tokenCookie from "@/modules/tokenCookie";
import apiResponse from "@/modules/apiResponse";
const { hasValidToken, getToken } = tokenCookie();
const { errorResponse } = apiResponse();
import toast from "@/modules/Toast";
const { successToast } = toast();
import axios from "axios";
import Apis from "@/urls/apis";

const state = {
  trips: {},
  singleTripHistory: [],
};
const getters = {
  getCountermanTrips: (state) => state.trips.docs,
  getCountermanTotalTrips: (state) => state.trips.totalDocs,
  getSingleTripHistory: (state) => state.singleTripHistory,
};
const actions = {
  async cancelTicket(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };
      return new Promise((resolve, reject) => {
        axios
          .post(Apis.TICKET_CANCEL_COUNTERMAN, payload, { headers: header })
          .then((response) => {
            successToast(response.data.message);
            resolve(response);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },
  async extraTicketCancel(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };
      return new Promise((resolve, reject) => {
        axios
          .post(Apis.EXTRA_TICKET_CANCEL, payload, { headers: header })
          .then((response) => {
            successToast(response.data.message);
            resolve(response);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },
  async goodsTicketCancel(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };
      return new Promise((resolve, reject) => {
        axios
          .post(Apis.GOODS_TICKET_CANCEL, payload, { headers: header })
          .then((response) => {
            successToast(response.data.message);
            resolve(response);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },
  async getAllTripsForCounterman({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.COUNTER_ALL_TRIPS, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              commit("setAllTripsForCounterman", data.trip);
              resolve(data.trip);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
	},
	async singleTripHistoryAction({ commit }, payload) {
		if (await hasValidToken()) {
			const header = {
				Authorization: "Bearer " + getToken(),
			};
			return new Promise((resolve, reject) => {
				axios
					.get(Apis.GET_SINGLE_TRIP_LOG_HISTORY, {
						params: payload,
						headers: header,
					})
					.then(({ data, status }) => {
						if (status === 200) {
							commit("setSingleTripHistory", data.trips);
							resolve(data.trip);
						}
					})
					.catch((error) => {
						reject(errorResponse(error));
					});
			});
		}
	}
	
};
const mutations = {
  setAllTripsForCounterman: (state, allTrips) => (state.trips = allTrips),
  setSingleTripHistory: (state, trips) => (state.singleTripHistory = trips),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
