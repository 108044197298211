<template>
  <Modal @close="closeModal">
    <template v-slot:title>{{ title }}</template>
    <template v-slot:body>
      <form>
        <div class="form-group row my-1">
          <ModalInput
            v-model="seatClassData.name"
            v-bind:placeholder="'Enter Name'"
            v-bind:label="'Name'"
            v-bind:id="'name'"
            v-bind:type="'text'"
          >
            <template v-slot:error>
              <ModalError v-if="errors.name" v-bind:error="errors.name" />
            </template>
          </ModalInput>
        </div>

        <div class="form-group row my-1">
          <ModalDropdown
            v-if="status"
            v-model="seatClassData.status"
            v-bind:id="'status'"
            v-bind:label="'Status'"
            v-bind:dropdown="status"
          ></ModalDropdown>
        </div>

        <div class="form-group row my-1">
          <label class="col-sm-3 col-form-label"></label>
          <div class="col-sm-9">
            <input
              v-if="!counterId"
              type="submit"
              class="mt-3 py-2 submit_button font_detail float-end"
              @click.prevent="addSeatClass"
            />
            <input
              v-else
              type="submit"
              class="mt-3 py-2 submit_button font_detail float-end"
              @click.prevent="updateSeatClass"
            />
          </div>
        </div>
      </form>
    </template>
  </Modal>
</template>

<script>
import dropdownVariables from "@/modules/dropdownVariables";
import useInputValidation from "@/modules/useInputValidations";
import { inject, onBeforeMount, ref, watch } from "vue";
import { reactive } from "@vue/reactivity";
import store from "@/store";
import { useRoute } from "vue-router";

export default {
  name: "SeatClassAddUpdateModal",
  props: {
    counter: String,
    company: String,
  },
  setup(props, { emit }) {
    const route = useRoute();
    let seatClassData = reactive({
      id: "",
      companyId: "",
      name: "",
      status: 1,
    });
    let { requiredValidation, errors } = useInputValidation();
    const counterId = ref(props.counter);
    const companyId = ref(props.company);
    const status = dropdownVariables.status;
    const title = ref("Add Seat Class");
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");

    let pagination = reactive({
      page: 1,
      limit: 25,
    });

    watch(
      () => seatClassData.name,
      () => {
        requiredValidation("name", seatClassData.name);
      }
    );

    const validate = () => {
      Object.keys(errors).forEach(function (key) {
        errors[key] = "";
      });
      requiredValidation("name", seatClassData.name);
      return !(
        Object.values(errors).length <= 0 ||
        !Object.values(errors).every((x) => x === null || x === "")
      );
    };
    const closeModal = () => {
      emit("update:modelValue", false);
    };
    const addSeatClass = () => {
      if (validate()) {
        showLoader();
        store
          .dispatch("addSeatClass", { seatClassData, pagination })
          .then(() => {
            store.dispatch("getAllSeatClass", { companyId: route.query.id });
            hideLoader();
            closeModal();
          })
          .catch((error) => {
            hideLoader();
            if (error.status === 401) {
              addSeatClass();
            }
          });
      }
    };
    const updateSeatClass = () => {
      if (validate()) {
        showLoader();
        store
          .dispatch("updateSeatClass", { seatClassData })
          .then(() => {
            hideLoader();
            closeModal();
          })
          .catch((error) => {
            hideLoader();
            if (error.status === 401) {
              updateSeatClass();
            }
          });
      }
    };

    onBeforeMount(() => {
      Object.keys(errors).forEach(function (key) {
        delete errors[key];
      });
      if (counterId.value !== "") {
        const counterDetails = store.getters.getSeatCls(counterId.value);
        title.value = "Update Seat Class";
        seatClassData.id = counterDetails[0]._id;
        seatClassData.name = counterDetails[0].name;
        seatClassData.status = counterDetails[0].status ? 1 : 0;
      }
      seatClassData.companyId = companyId.value;
    });

    return {
      counterId,
      seatClassData,
      errors,
      status,
      title,
      closeModal,
      addSeatClass,
      updateSeatClass,
    };
  },
};
</script>

<style scoped></style>
