<template>
  <div class="row d-md-none px-0 mx-auto">
    <span class="fw-bold">{{ t("SEAT INFORMATION", {}, {locale: selectedLanguage}) }}</span>
    <div class="card card-body">
      <div class="row">
        <div class="col-md-12">
          <p class="text-center font_detail m-0">
            {{ t("SEATS", {}, {locale: selectedLanguage}) }}
            <span class="d-md-none">({{ seats.length || 0 }})</span>
          </p>
          <div class="rounded-end available_other available span_font w-100">
            <span class="orange-bold-text">#{{ seats.map(a => Object.values(a.seat_no).join('')).join(', ') }}</span>
          </div>
          <p class="text-center font_detail m-0 d-md-block d-none">
            {{ t("Total Seats", {}, {locale: selectedLanguage}) }}: {{ seats.length || 0 }}
          </p>
        </div>
        <div :class="!allowDue && !allowRefund ? 'col-6':'col-4'" class="col-md my-1 my-md-0">
          <p class="text-center font_detail m-0">
            {{ t("FARE(TK)", {}, {locale: selectedLanguage}) }}
          </p>
          <input
              v-if="fareType === 'predefined' || fareType === 'trip_fare'"
              :disabled="!allowNetPayEditable"
              v-model="ticketAccountDetails.totalAmount"
              type="Number"
              min="0"
              class="input_field form-control rounded px-3 w-100 text-center"
          />
          <input
              v-else
              v-model="ticketAccountDetails.inputFare"
              type="Number"
              min="0"
              class="input_field form-control rounded px-3 w-100 text-center"
          />
        </div>
        <div :class="!allowDue && !allowRefund ? 'col-6':'col-4'" class="my-1 my-md-0 col-md"
             v-if="hasPermission(Counterman.fareDiscount)"
        >
          <p class="text-center font_detail m-0">
            {{ t("DISCOUNT", {}, {locale: selectedLanguage}) }}
          </p>
          <input
              v-model.number="ticketAccountDetails.discount"
              type="Number"
              oninput="this.value = Math.abs(this.value)"
              class="input_field form-control rounded px-3 w-100 text-center"
          />
          <p class="text-danger" v-if="discountErrorMsg">
            {{ t("Discount_Fare", {}, {locale: selectedLanguage}) }}
          </p>
        </div>
        <div :class="!allowDue && !allowRefund ? 'col-6':'col-4'" class="my-1 my-md-0 col-md">
          <p class="text-center font_detail m-0">
            {{ t("PAID(TK)", {}, {locale: selectedLanguage}) }}
          </p>
          <input
              v-model="ticketAccountDetails.paid"
              min="0"
              oninput="this.value = Math.abs(this.value)"
              type="number"
              class="input_field form-control rounded px-3 w-100 text-center"
          />
        </div>
        <div :class="!allowDue && !allowRefund ? 'col-6':'col-4'" class="my-1 my-md-0 col-md">
          <p class="text-center font_detail m-0">
            {{ t("PAYABLE(TK)", {}, {locale: selectedLanguage}) }}
          </p>
          <div class="rounded-end available_other available span_font w-100">
              <span class="orange-bold-text">{{ ticketAccountDetails.payable || 0 }}
              </span>
          </div>
        </div>
        <div v-if="allowDue" class="col-4 my-1 my-md-0 col-md">
          <p class="text-center font_detail m-0">
            {{ t("DUE(TK)", {}, {locale: selectedLanguage}) }}
          </p>
          <div class="rounded-end available_other available span_font w-100">
            <span class="orange-bold-text">{{ ticketAccountDetails.due }}</span>
          </div>
        </div>
        <div v-if="allowRefund" class="col-4 my-1 my-md-0 col-md">
          <p class="text-center font_detail m-0">
            {{
              t("REFUND(TK)", {}, {locale: selectedLanguage})
            }}
          </p>
          <div class="rounded-end available_other available span_font w-100">
            <span class="orange-bold-text">{{ ticketAccountDetails.refund }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {useI18n} from "vue-i18n";
import {computed, watch, reactive, ref} from "vue";
import store from "@/store";
import roles from "@/modules/roles";
import permission from "@/modules/permission";

const {hasPermission} = permission();
const {Counterman} = roles();

export default {
  name: "SeatInformationMobile",
  props: {
    singleTripDetails: Object,
  },
  setup(props) {
    const {t, locale} = useI18n();
    const discountErrorMsg = ref('');
    let ticketAccountDetails = reactive({
      inputFare: '',
      totalAmount: 0,
      fare: 0,
      discount: 0,
      payable: 0,
      paid: 0,
      due: 0,
      refund: 0,
    });

    const selectedLanguage = computed(() => store.getters.getLang)
    const seats = computed(() => store.getters.getSelectedSeat)
    const seatFares = computed(() => store.getters.getSeatFare);
    const fareType = store.getters.userCompanyData.fareType;
    const allowNetPayEditable = store.getters.allowNetPayEdit;
    const allowDue = store.getters.allowDue;
    const allowRefund = store.getters.allowRefund;

    watch(() => seatFares, () => {
      if (fareType === 'predefined') {
        ticketAccountDetails.fare = seatFares.value.map(item => item.fare).reduce((prev, curr) => prev + curr, 0);
      } else if (fareType === 'trip_fare') {
        ticketAccountDetails.fare = seats.value.length * parseInt(props.singleTripDetails.tripFare);
      }
    }, {deep: true});

    //when input for instant fare
    watch(() => ticketAccountDetails.inputFare, () => {
      ticketAccountDetails.fare = ticketAccountDetails.inputFare
      store.commit("setSeatFare", [parseInt(ticketAccountDetails.inputFare)])
    });

    //when fare editable
    watch(() => ticketAccountDetails.totalAmount, () => {
      ticketAccountDetails.fare = ticketAccountDetails.totalAmount
    });

    //fare changes effect
    watch(() => ticketAccountDetails.fare, () => {
      checkDiscountError();
      ticketAccountDetails.totalAmount = ticketAccountDetails.fare
      ticketAccountDetails.paid = ticketAccountDetails.fare - ticketAccountDetails.discount
      ticketAccountDetails.payable = ticketAccountDetails.fare - ticketAccountDetails.discount
    });

    //discount changes effect
    watch(() => ticketAccountDetails.discount, () => {
      checkDiscountError();
      ticketAccountDetails.paid = ticketAccountDetails.fare - ticketAccountDetails.discount
      ticketAccountDetails.payable = ticketAccountDetails.fare - ticketAccountDetails.discount
    })

    //paid changes effect
    watch(() => ticketAccountDetails.paid, () => {
      if (allowRefund || allowDue) {
        if (ticketAccountDetails.paid > ticketAccountDetails.payable && allowRefund) {
          ticketAccountDetails.refund = ticketAccountDetails.paid - ticketAccountDetails.payable
          ticketAccountDetails.due = 0
        } else if (ticketAccountDetails.paid < ticketAccountDetails.payable && allowDue) {
          ticketAccountDetails.due = ticketAccountDetails.payable - ticketAccountDetails.paid
          ticketAccountDetails.refund = 0;
        }
      }
    })

    watch(() => seats, () => {
      store.commit("setTicketDetails", ticketAccountDetails)
    }, {deep: true})

    watch(() => ticketAccountDetails, () => {
      store.commit("setTicketDetails", ticketAccountDetails)
    }, {deep: true})

    //Discount Error
    const checkDiscountError = () => {
      if (ticketAccountDetails.discount > ticketAccountDetails.fare) {
        discountErrorMsg.value = "Discount amount can't be greater than netfare";
        return true;
      } else {
        discountErrorMsg.value = ''
      }
    }

    return {
      t,
      locale,
      selectedLanguage,
      seats,
      Counterman,
      hasPermission,
      ticketAccountDetails,
      seatFares,
      fareType,
      allowNetPayEditable,
      allowDue,
      allowRefund,
      discountErrorMsg,
    }
  }
}
</script>

<style scoped>
.available {
  color: #000000;
  padding: 5px 10px;
  border-radius: 4px;
  text-transform: uppercase;
}
</style>
