import {reactive} from "vue";
import store from "@/store";

const counter = () => {
    let payload = reactive({
        page: 1,
        limit: 10,
    });
    const getAllStoppages = () => {
        store
            .dispatch("getStoppageListForCounterman", payload)
            .then(() => { })
            .catch((error) => {
                if (error.status === 401) {
                    getAllStoppages();
                }
            });
    };

    return {getAllStoppages}
}

const coach = () => {
    let payload = reactive({
        page: 1,
        limit: 10,
    });
    const getAllCoach = () => {
        store
            .dispatch("getShipListForCounterman", payload)
            .then(() => {
            })
            .catch((error) => {
                if (error.status === 401) {
                    getAllCoach();
                }
            });
    };
    return {getAllCoach}
}

export { counter, coach };