import apiResponse from "@/modules/apiResponse";
import toast from "@/modules/Toast";
import tokenCookie from "@/modules/tokenCookie";
import Apis from "@/urls/apis";
import axios from "axios";

const {infoToast} = toast();
const {hasValidToken, getToken} = tokenCookie();
const {errorResponse, successResponse} = apiResponse();

const state = {
  filter: {
    depot: {},
    coach: {},
  },
  coachWiseReportList: [],
  coachWiseSeatDetails: [],

  counterWiseReportList: [],
  counterWiseSeatDetails: [],

  dtReport: [],
  dtReportDetails: [],

  printTicketCountList: [],
  printTripWiseCountList: [],
  printDailySummaryWiseCountList: [],
  counterWiseExtraDetails: []

};
const getters = {
  getCoachWiseSeatDetails: (state => state.coachWiseSeatDetails),
  getCoachWiseReportAdminIntercity: (state => state.coachWiseReportList),
  getAllDepotList: (state) => state.filter.depot,
  getCoachList: (state) => state.filter.coach,
  getCounterWiseReport: (state => state.counterWiseReportList),
  getDtReport: (state => state.dtReport),
  getDtReportList: (state => state.dtReportDetails),
  getCounterWiseSeatDetails: (state => state.counterWiseSeatDetails),
  getPrintTicketCountList: (state => state.printTicketCountList),
  getPrintTripWiseCountList: (state => state.printTripWiseCountList),
  getPrintDailySummaryWiseCountList: (state => state.printDailySummaryWiseCountList),
};
const actions = {
  async getAllDepotList({commit}) {
    if (await hasValidToken()) {
      const header = {
        'Authorization': 'Bearer ' + getToken()
      };
      return new Promise((resolve, reject) => {
        axios.get(Apis.GET_ALL_DEPOTS_ALL, {
          headers: header
        })
          .then(({data, status}) => {
            if (status === 200) {
              let depotList = {}
              data.depot.forEach(depot => {
                depotList[depot._id] = depot.name;
              });
              commit("setDepotList", depotList)
              resolve(data.depot)
            }
          })
          .catch(error => {
            reject(errorResponse(error))
          });
      });
    }
  },

  async getAllCoachListForFilter({commit}, payload) {
    if (await hasValidToken()) {
      commit("setAllCoachList", {});
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_ALL_LIST_COACH, {
            params: payload,
            headers: header,
          })
          .then((response) => {
            let allCoachList = {};
            response.data.coach.forEach((coachList) => {
              allCoachList[coachList._id] = coachList.name;
            });
            commit("setCoachList", allCoachList);
            resolve(response.data.coach);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getAllCoachReport({commit}, payload) {
    if (await hasValidToken()) {
      const header = {
        'Authorization': 'Bearer ' + getToken()
      };

      return new Promise((resolve, reject) => {
        axios.get(Apis.COACH_WISE_REPORT, {
          params: payload,
          headers: header
        })
          .then((response) => {
            commit("setCoachWiseList", response.data.ticket)
            resolve(response.data.ticket)
          })
          .catch(error => {
            reject(errorResponse(error))
          });
      });
    }
  },

  async getDtReport({commit}, payload) {
    if (await hasValidToken()) {
      const header = {
        'Authorization': 'Bearer ' + getToken()
      };

      return new Promise((resolve, reject) => {
        axios.get(Apis.DIGITAL_TICKET_WISE_REPORT, {
          params: payload,
          headers: header
        })
          .then((response) => {
            if (response.data.trips.length === 0) {
              infoToast(response.data.message)

            }
            commit("setDtList", response.data.trips)
            resolve(response.data.trips)

          })
          .catch(error => {
            reject(errorResponse(error))
          });
      });
    }
  },

  async cancelOnlineTicket({commit}, payload) {
    if (await hasValidToken()) {
      const header = {
        'Authorization': 'Bearer ' + getToken()
      };

      return new Promise((resolve, reject) => {
        axios.get(Apis.DIGITAL_TICKET_CANCEL, {
          params: payload,
          headers: header
        })
          .then((response) => {
            response.data.ticket.length === 0 ? infoToast(response.data.message) : successResponse(response)
            commit("setDtReportDetails", response.data.ticket)
            resolve(response.data.ticket)
          })
          .catch(error => {
            reject(errorResponse(error))
          });
      });
    }
  },


  async getAllCounterList({commit}, payload) {
    if (await hasValidToken()) {
      const header = {
        'Authorization': 'Bearer ' + getToken()
      };

      return new Promise((resolve, reject) => {
        axios.get(Apis.COUNTER_WISE_REPORT_OWNER, {
          params: payload,
          headers: header
        })
          .then(({data, status}) => {
            if (status === 200) {
              commit("setAllCounterListOwner", data.ticket)
              resolve(data.ticket)
            }
          })
          .catch(error => {
            reject(errorResponse(error))
          });
      });
    }
  },

  async getSeatDetails({commit}, payload) {
    if (await hasValidToken()) {
      const header = {
        'Authorization': 'Bearer ' + getToken()
      };

      return new Promise((resolve, reject) => {
        axios.get(Apis.COACH_WISE_SEAT_REPORT_DETAILS, {
          params: payload,
          headers: header
        })
          .then((response) => {

            response.data.ticket.length === 0 ? infoToast(response.data.message) : successResponse(response)
            commit("setCoachWiseSeatDetails", response.data.ticket)
            resolve(response.data.ticket)
          })
          .catch(error => {
            reject(errorResponse(error))
          });
      });
    }
  },
  async getDtReportDetails({commit}, payload) {
    if (await hasValidToken()) {
      const header = {
        'Authorization': 'Bearer ' + getToken()
      };

      return new Promise((resolve, reject) => {
        axios.get(Apis.DIGITAL_TICKET_WISE_REPORT_DETAILS, {
          params: payload,
          headers: header
        })
          .then((response) => {
            response.data.ticket.length === 0 ? infoToast(response.data.message) : successResponse(response)
            commit("setDtReportDetails", response.data.ticket)
            resolve(response.data.ticket)
          })
          .catch(error => {
            reject(errorResponse(error))
          });
      });
    }
  },

  async getCountermanWiseSeatReportDetails({commit}, payload) {
    if (await hasValidToken()) {
      const header = {
        'Authorization': 'Bearer ' + getToken()
      };

      return new Promise((resolve, reject) => {
        axios.get(Apis.COUNTERMAN_WISE_REPORT_DETAILS, {
          params: payload,
          headers: header
        })
          .then((response) => {
            response.data.ticket.length === 0 ? infoToast(response.data.message) : successResponse(response)
            commit("setCounterWiseSeatDetails", response.data.ticket)
            resolve(response.data.ticket)
          })
          .catch(error => {
            reject(errorResponse(error))
          });
      });
    }
  },

  async getfilterCounterWiseReport({commit}, payload) {
    if (await hasValidToken()) {
      const header = {
        'Authorization': 'Bearer ' + getToken()
      };
      return new Promise((resolve, reject) => {
        axios.get(Apis.COUNTER_WISE_REPORT, {
          params: payload,
          headers: header
        })
          .then((response) => {
            response.data.ticket.length === 0 ? infoToast(response.data.message) : successResponse(response)
            commit("setFilterCounterWiseReport", response.data.ticket);
            resolve(response.data.ticket)
          })
          .catch(error => {
            reject(errorResponse(error))
          });
      });
    }
  },

  async getPrintCountList({commit}, payload) {
    if (await hasValidToken()) {
      const header = {
        'Authorization': 'Bearer ' + getToken()
      };

      return new Promise((resolve, reject) => {
        axios.get(Apis.ADMIN_PRINT_COUNT_REPORT, {
          params: payload,
          headers: header
        })
          .then((response) => {
            // infoToast(response.data.message)
            successResponse(response);
            if (response.status === 200) {
              let data = response.data
              commit("setCountReport", {data, payload})
              resolve();
            }
          })
          .catch(error => {
            reject(errorResponse(error))
          });
      });
    }
  },
};
const mutations = {
  setFilterCounterWiseReport: ((state, updateList) => state.counterWiseReportList = updateList),
  setCoachWiseSeatDetails: ((state, seatDetails) => state.coachWiseSeatDetails = seatDetails),
  setCounterWiseSeatDetails: ((state, seatDetails) => state.counterWiseSeatDetails = seatDetails),
  setDtReportDetails: ((state, dtDetails) => state.dtReportDetails = dtDetails),
  setCoachWiseList: ((state, coachWiseReport) => state.coachWiseReportList = coachWiseReport),
  setDtList: ((state, dtReport) => state.dtReport = dtReport),
  setDepotList: ((state, depotList) => state.filter.depot = depotList),
  setCoachList: ((state, coachList) => state.filter.coach = coachList),
  setCountReport: ((state, value) => {
    state.printTicketCountList = []
    state.printTripWiseCountList = []
    state.printDailySummaryWiseCountList = []
    if (value.payload.reportType === 'ticket') {
      state.printTicketCountList = value.data.ticket
    } else if (value.payload.reportType === 'tripWiseReport') {
      state.printTripWiseCountList = value.data.report
    } else if (value.payload.reportType === 'dailySummaryReport') {
      state.printDailySummaryWiseCountList = value.data.report
    }
  }),

};

export default {
  state,
  getters,
  actions,
  mutations
};
