<template>
  <header id="topHeader">
    <div class="text-center py-1 text-white nav-style fw-bold text-uppercase">
      {{ $store.getters.userCompanyData?.name }}
    </div>
    <nav class="navbar navbar-expand-lg navbar-light navbar_bg">
      <div class="container-fluid">
        <router-link
          v-if="hasPermission(Counterman.ticketBooking)"
          v-bind:to="{ name: web.Counterman.DASHBOARD_URL.name }"
          class="navbar-brand brand_name menu_font_detail home-logout"
          >{{
            t(
              web.Counterman.DASHBOARD_URL.title,
              {},
              { locale: $store.getters.getLang }
            )
          }}
        </router-link>
        <button
          class="navbar-toggler mobile_nav_toggle"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarTogglerDemo03"
          aria-controls="navbarTogglerDemo03"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarTogglerDemo03">
          <ul
            class="
              navbar-nav
              dashboard_nav
              me-auto
              mb-2 mb-lg-0
              menu_font_detail
            "
          >
            <li class="nav-item">
              <router-link
                v-if="hasPermission(Counterman.reports)"
                v-bind:to="{ name: web.Counterman.REPORT_URL.name }"
                class="nav-link menu_font_detail"
              >
                {{
                  t(
                    web.Counterman.REPORT_URL.title,
                    {},
                    { locale: $store.getters.getLang }
                  )
                }}
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                v-if="hasPermission(Counterman.trips)"
                v-bind:to="{ name: web.Counterman.TRIPS_URL.name }"
                class="nav-link menu_font_detail"
                >{{
                  t(
                    web.Counterman.TRIPS_URL.title,
                    {},
                    { locale: $store.getters.getLang }
                  )
                }}
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                v-if="hasPermission(Counterman.coach) && hasPermission(Counterman.ticketBooking)"
                v-bind:to="{ name: web.Counterman.COACH_URL.name }"
                class="nav-link menu_font_detail"
                >{{
                  t(
                    web.Counterman.COACH_URL.title,
                    {},
                    { locale: $store.getters.getLang }
                  )
                }}
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                  v-if="hasPermission(Counterman.coach) && hasPermission(Counterman.ticketBooking)"
                  v-bind:to="{ name: web.Counterman.VERIFY_URL.name }"
                  class="nav-link menu_font_detail"
              >{{
                  t(
                      web.Counterman.VERIFY_URL.title,
                      {},
                      { locale: $store.getters.getLang }
                  )
                }}
              </router-link>
            </li>
          </ul>
          <ul class="navbar-nav dashboard_nav d-flex menu_font_detail">
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle menu_font_detail"
                href="#"
                id="navbarScrollingDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span
                  v-bind:class="
                    currentPath ===
                    web.Counterman.path + '/' + web.Counterman.PROFILE_URL.path
                      ? 'nav-active'
                      : ''
                  "
                  >{{ $store.getters.userName }}</span
                >
              </a>
              <ul
                class="dropdown-menu intercity_login_dropdown"
                aria-labelledby="navbarScrollingDropdown"
              >
                <!-- <li>
                  <router-link v-bind:to="{name:web.Counterman.PROFILE_URL.name}" class="dropdown-item text-dark menu_font_detail">
                    {{ web.Counterman.PROFILE_URL.title }}
                  </router-link>

                </li> -->
                <!-- <li>
                  <hr class="dropdown-divider">
                </li> -->
                <li>
                  <button
                    @click="logout"
                    class="dropdown-item text-dark menu_font_detail"
                  >
                    {{
                      t(
                        web.Default.LOGOUT_URL.title,
                        {},
                        { locale: $store.getters.getLang }
                      )
                    }}
                  </button>
                </li>
                <li></li>
              </ul>
            </li>
          </ul>

          <ul class="navbar-nav dashboard_nav d-flex menu_font_detail">
            <li class="nav-item dropdown">
              <ul
                class="dropdown-menu intercity_login_dropdown"
                aria-labelledby="navbarScrollingDropdown"
              >
                <li>
                  <button
                    @click="logout"
                    class="dropdown-item text-dark menu_font_detail"
                  >
                    {{
                      t(
                        web.Default.LOGOUT_URL.title,
                        {},
                        { locale: $store.getters.getLang }
                      )
                    }}
                  </button>
                </li>
                <li></li>
              </ul>
            </li>
          </ul>

          <!-- <ul class="navbar-nav dashboard_nav d-flex menu_font_detail">
            <select class="px-2 py-1" v-model="lang">
              <option value="en">English</option>
              <option value="bn">
                {{ t("Bangla", {}, { locale: $store.getters.getLang }) }}
              </option>
            </select>
          </ul> -->

          <!-- <ul class="navbar-nav dashboard_nav d-flex menu_font_detail"> -->
<!--          <div class="d-flex justify-content-between gap-1 text-white">-->
<!--            <div>বাংলা</div>-->
<!--            <div class="form-check form-switch">-->
<!--              <input-->
<!--                @click="langValue"-->
<!--                class="form-check-input"-->
<!--                type="checkbox"-->
<!--                role="switch"-->
<!--                id="flexSwitchCheckChecked"-->
<!--                :checked="lang === 'en' ? true : false"-->
<!--              />-->
<!--            </div>-->
<!--            <div>English</div>-->
<!--          </div>-->
          <!-- </ul> -->
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
import { useRoute, useRouter } from "vue-router";
import { ref, computed, watch } from "vue";
import store from "@/store";
import web from "@/urls/web";
import permission from "@/modules/permission";
import roles from "@/modules/roles";
import { useI18n } from "vue-i18n";

const { hasPermission } = permission();
const { Counterman } = roles();

export default {
  name: "CountermanNav",
  setup() {
    const route = useRoute();
    const router = useRouter();
    const currentPath = computed(() => route.path);
    const lang = ref(store.getters.getLang);
    const { t, locale } = useI18n();
    watch(
      () => lang.value,
      () => {
        store.commit("setLang", lang.value);
      }
    );

    const logout = () => {
      store
        .dispatch("logout")
        .then(() => {
          router
            .push(web.Default.LOGIN_URL.path)
            .catch((error) => console.log(error));
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const langValue = (e) => {
      if (e.target.checked) {
        lang.value = "en";
        store.commit("setLang", lang.value);
      } else {
        lang.value = "bn";
        store.commit("setLang", lang.value);
      }
    };

    return {
      t,
      locale,
      langValue,
      currentPath,
      web,
      lang,
      Counterman,
      hasPermission,
      logout,
    };
  },
};
</script>

<style scoped>
.nav-style {
  background: #3b3b3b;
}

nav .active,
nav .exact-active,
.nav-active {
  color: #ff6347 !important;
  outline: none;
}

.dashboard_nav li a:hover {
  color: #ff6347 !important;
}
</style>
