<template>
  <Modal @close="closeModal" v-bind:size="'xl'">
    <template v-slot:title>{{ title }}</template>
    <template v-slot:body>
      <form>
        <div class="auth_form py-3">
          <div class="row my-2">
            <div class="col-md-3">
              <Input
                  v-model="countermanData.name"
                  v-bind:placeholder="'Enter Counterman Name'"
                  v-bind:label="'Name'"
                  v-bind:id="'name'"
                  v-bind:type="'text'"
              />
              <Error v-if="errors.name" v-bind:error="errors.name"/>
            </div>
            <div class="col-md-3">
              <Input
                  v-model="countermanData.phone"
                  v-bind:placeholder="'Enter Phone Number'"
                  v-bind:label="'Phone'"
                  v-bind:id="'phone'"
                  v-bind:type="'text'"
              />
              <Error v-if="errors.phone" v-bind:error="errors.phone"/>
            </div>
            <div class="col-md-3">
              <Input
                  v-model="countermanData.password"
                  v-bind:placeholder="'Enter Password'"
                  autocomplete="off"
                  v-bind:label="'Password'"
                  v-bind:id="'password'"
                  v-bind:type="fieldTypes.password"
              />
              <Error v-if="errors.password" v-bind:error="errors.password"/>
            </div>

            <div class="col-md-3">
              <Input
                  v-model="countermanData.email"
                  v-bind:placeholder="'Email Address'"
                  autocomplete="off"
                  v-bind:label="'Email'"
                  v-bind:id="'email'"
                  v-bind:type="fieldTypes.email"
              />
            </div>
          </div>
          <div class="row my-2">
            <div class="col-md-3">
              <label for="address" class="form-label all_permission_label"
              >Address</label
              >
              <div>
                <textarea
                    class="form-control font_detail"
                    id="address"
                    v-model="countermanData.address"
                ></textarea>
              </div>
            </div>
            <div class="col-md-3">
              <Input
                  v-model="countermanData.nid"
                  v-bind:placeholder="'NID Number'"
                  v-bind:label="'NID'"
                  v-bind:id="'nid'"
                  v-bind:type="'text'"
              />
            </div>
            <div class="col-md-3">
              <Dropdown
                  v-if="status"
                  v-model="countermanData.status"
                  v-bind:id="'status'"
                  v-bind:label="'Status'"
                  v-bind:dropdown="status"
                  v-bind:hideLabel="'inactive'"
              />
            </div>
            <div class="col-md-3">
              <Dropdown
                  v-bind:id="'counterman'"
                  v-bind:label="'Counter'"
                  v-model="countermanData.counterId"
                  :dropdown="$store.getters.getCounterListForFilterAll"
                  v-bind:defaultOption="'--select a counter--'"
                  v-bind:defaultValue="''"
                  v-bind:hideLabel="'inactive'"
              />
              <Error v-if="errors.counter" v-bind:error="errors.counter"/>
            </div>
<!--            <div class="col-md-3">-->
<!--              <Dropdown-->
<!--                  v-if="depot"-->
<!--                  v-model="countermanData.depot"-->
<!--                  v-bind:id="'depot'"-->
<!--                  v-bind:label="'Depot'"-->
<!--                  v-bind:dropdown="depot"-->
<!--                  v-bind:hideLabel="'inactive'"-->
<!--                  v-bind:defaultOption="'Select Depot'"-->
<!--                  v-bind:defaultValue="''"-->
<!--              />-->
<!--              <Error v-if="errors.depot" v-bind:error="errors.depot" />-->
<!--            </div>-->
            <div class="col-md-3">
              <Dropdown
                  v-if="status"
                  v-model="countermanData.commissionType"
                  v-bind:id="'commissionType'"
                  v-bind:label="'Commission Type'"
                  v-bind:dropdown="dropdownVariables.commissionType"
                  v-bind:defaultOption="'--select commission Type--'"
                  v-bind:defaultValue="''"
                  v-bind:hideLabel="'inactive'"
              />
            </div>
            <div class="col-md-3">
              <Input
                  v-model.number="countermanData.commission"
                  v-bind:placeholder="'Enter Counterman Name'"
                  v-bind:label="'Commission'"
                  v-bind:id="'Commission'"
                  v-bind:type="'number'"
                  :active="'active'"
              />
              <Error
                  v-if="errors.commission"
                  v-bind:error="errors.commission"
              />
            </div>
          </div>
        </div>

        <hr class="section_devider"/>

        <div class="role_saection">
          <div class="row">
            <div class="d-flex justify-content-between align-items-center">
              <h5><b>Roles</b></h5>
              <div class="form-check form-check-inline">
                <input
                    class="form-check-input all_permission_label"
                    type="checkbox"
                    id="select-all"
                    name="select-all"
                    @click="giveAllPermission"
                    v-model="allSelected"
                />
                <label
                    class="form-check-label all_permission_label"
                    for="select-all"
                >Give All Permission</label
                >
              </div>
            </div>
          </div>
          <span v-if="errors.roles" class="text-danger err_msg">{{
              errors.roles
            }}</span>
          <span
              v-else-if="errors[Counterman.fareDiscount]"
              class="text-danger err_msg"
          >{{ errors[Counterman.fareDiscount] }}</span
          >
          <span
              v-else-if="errors[Counterman.trips]"
              class="text-danger err_msg"
          >{{ errors[Counterman.trips] }}</span
          >
          <span
              v-else-if="errors[Counterman.reports]"
              class="text-danger err_msg"
          >{{ errors[Counterman.reports] }}</span
          >
          <span
              v-else-if="errors[Counterman.ticketCancel]"
              class="text-danger err_msg"
          >{{ errors[Counterman.ticketCancel] }}</span
          >
          <span
              v-else-if="errors[Counterman.fares]"
              class="text-danger err_msg"
          >{{ errors[Counterman.fares] }}</span
          >
          <span
              v-else-if="errors[Counterman.cancelOthersTicket]"
              class="text-danger err_msg"
          >{{ errors[Counterman.cancelOthersTicket] }}</span
          >
          <div class="assigned_role">
            <div class="row">
              <div class="col-6 col-sm-4 col-md-3">
                <div class="role_select_area">
                  <h5>Ticket Booking</h5>
                  <Checkbox
                      v-model="checked"
                      v-bind:label="'Yes'"
                      v-bind:role="Counterman.ticketBooking"
                      :key="allSelected"
                      @toggleAllPermission="toggleAllPermission"
                  />
                </div>
              </div>
              <div class="col-6 col-sm-4 col-md-3">
                <div class="role_select_area">
                  <h5>Fare Discount</h5>
                  <Checkbox
                      v-model="checked"
                      v-bind:label="'Yes'"
                      v-bind:role="Counterman.fareDiscount"
                      :key="allSelected"
                      @toggleAllPermission="toggleAllPermission"
                  />
                </div>
              </div>
              <div class="col-6 col-sm-4 col-md-3">
                <div class="role_select_area">
                  <h5>Report</h5>
                  <Checkbox
                      v-model="checked"
                      v-bind:label="'Yes'"
                      v-bind:role="Counterman.reports"
                      :key="allSelected"
                      @toggleAllPermission="toggleAllPermission"
                  />
                </div>
              </div>
              <div class="col-6 col-sm-4 col-md-3">
                <div class="role_select_area">
                  <h5>Trip</h5>
                  <Checkbox
                      v-model="checked"
                      v-bind:label="'Yes'"
                      v-bind:role="Counterman.trips"
                      :key="allSelected"
                      @toggleAllPermission="toggleAllPermission"
                  />
                </div>
              </div>

              <div class="col-6 col-sm-4 col-md-3">
                <div class="role_select_area">
                  <h5>Fare</h5>
                  <Checkbox
                      v-model="checked"
                      v-bind:label="'Yes'"
                      v-bind:role="Counterman.fares"
                      :key="allSelected"
                      @toggleAllPermission="toggleAllPermission"
                  />
                </div>
              </div>
              <div class="col-6 col-sm-4 col-md-3">
                <div class="role_select_area">
                  <h5>Cancel Ticket</h5>
                  <Checkbox
                      v-model="checked"
                      v-bind:label="'Yes'"
                      v-bind:role="Counterman.ticketCancel"
                      :key="allSelected"
                      @toggleAllPermission="toggleAllPermission"
                  />
                </div>
              </div>
              <div class="col-6 col-sm-4 col-md-3">
                <div class="role_select_area">
                  <h5>Cancel Other Ticket</h5>
                  <Checkbox
                      v-model="checked"
                      v-bind:label="'Yes'"
                      v-bind:role="Counterman.cancelOthersTicket"
                      :key="allSelected"
                      @toggleAllPermission="toggleAllPermission"
                  />
                </div>
              </div>
              <div class="col-6 col-sm-4 col-md-3">
                <div class="role_select_area">
                  <h5>Trip Edit</h5>
                  <Checkbox
                      v-model="checked"
                      v-bind:label="'Yes'"
                      v-bind:role="Counterman.tripEdit"
                      :key="allSelected"
                      @toggleAllPermission="toggleAllPermission"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group row my-1">
          <label class="col-sm-3 col-form-label"></label>
          <div class="col-sm-9">
            <input
                v-if="!countermanIdForUpdate"
                type="submit"
                class="mt-3 py-2 submit_button font_detail float-end"
                @click.prevent="addCounterMan"
            />
            <input
                v-else
                type="submit"
                class="mt-3 py-2 submit_button font_detail float-end"
                @click.prevent="updateCounterMan"
            />
          </div>
        </div>
      </form>
    </template>
  </Modal>
</template>

<script>
import {reactive} from "@vue/reactivity";
import dropdownVariables from "@/modules/dropdownVariables";
import useInputValidation from "@/modules/useInputValidations";
import {inject, onBeforeMount, ref, watch} from "vue";
import roles from "@/modules/roles";
import store from "@/store";

const {Counterman} = roles();

export default {
  name: "CountermanAddUpdateModal",
  components: {},
  props: {
    modalValue: Boolean,
    company: String,
    counterMan: String,
  },
  setup(props, {emit}) {
    let countermanData = reactive({
      id: "",
      name: "",
      // depot: "",
      designation: "",
      phone: "",
      password: "",
      email: "",
      nid: "",
      address: "",
      avatar: "",
      transactionType: "",
      balance: "",
      commission: 0,
      userType: "counterman",
      companyId: "",
      counterId: "",
      commissionType: "",
      roles: "",
      status: "1",
      api_token: "",
      device_token: "",
      printingType: "Individual",
      reset_token: "",
      printerType: "",
    });
    let countermanIdForUpdate = ref(props.counterMan);
    let companyId = ref(props.company);
    let checked = ref([]);
    let allSelected = ref(false);
    let {
      requiredValidation,
      passwordValidation,
      phoneValidation,
      requiredRoleValidation,
      commission,
      errors,
    } = useInputValidation();
    let printerType = dropdownVariables.countermanPrinterType; //["T2mini", "Bluetooth"];
    const status = dropdownVariables.status;
    const printingType = dropdownVariables.printingType;
    const depot = store.getters.getDepotListForFilter;
    const title = ref("Add Counterman");
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");

    let fieldTypes = reactive({
      email: "text",
      password: "text",
    });

    watch(
        () => countermanData.name,
        () => {
          requiredValidation("name", countermanData.name);
        }
    );
    watch(
        () => countermanData.counterId,
        () => {
          requiredValidation("counterId", countermanData.counterId);
        }
    );
    watch(
        () => countermanData.phone,
        () => {
          phoneValidation("phone", countermanData.phone);
        }
    );
    watch(
        () => countermanData.roles,
        () => {
          requiredValidation("roles", countermanData.roles);
        }
    );
    watch(
        () => countermanData.printerType,
        () => {
          requiredValidation("printerType", countermanData.printerType);
        }
    );
    watch(
        () => countermanData.password,
        () => {
          passwordValidation("password", countermanData.password);
        }
    );

    watch(
        () => countermanData.password,
        () => {
          fieldTypes.password = "password";
        }
    );
    watch(
        () => countermanData.email,
        () => {
          fieldTypes.email = "email";
        }
    );

    watch(
        () => countermanData.commission,
        () => {
          commission("commission", countermanData.commission);
        }
    );

    const giveAllPermission = () => {
      checked.value = [];
      if (!allSelected.value) {
        checked.value = Object.values(Counterman);
      }
    };
    const closeModal = () => {
      emit("update:modelValue", false);
    };
    const toggleAllPermission = () => {
      allSelected.value =
          checked.value.length === Object.values(Counterman).length;
    };
    const validate = () => {
      Object.keys(errors).forEach(function (key) {
        errors[key] = "";
      });
      requiredValidation("name", countermanData.name);
      phoneValidation("phone", countermanData.phone);
      requiredValidation("counter", countermanData.counterId);
      // requiredValidation("depot", countermanData.depot);
      requiredValidation("roles", countermanData.roles);
      if (countermanData.commissionType === "PERCENTAGE") {
        commission("commission", countermanData.commission);
      }
      requiredRoleValidation(
          Counterman.fareDiscount.toString(),
          checked.value,
          Counterman.ticketBooking,
          Counterman.fareDiscount,
          Counterman
      );
      requiredRoleValidation(
          Counterman.trips.toString(),
          checked.value,
          Counterman.ticketBooking,
          Counterman.trips,
          Counterman
      );
      requiredRoleValidation(
          Counterman.reports.toString(),
          checked.value,
          Counterman.ticketBooking,
          Counterman.reports,
          Counterman
      );
      requiredRoleValidation(
          Counterman.ticketCancel.toString(),
          checked.value,
          Counterman.ticketBooking,
          Counterman.ticketCancel,
          Counterman
      );
      requiredRoleValidation(
          Counterman.fares.toString(),
          checked.value,
          Counterman.ticketBooking,
          Counterman.fares,
          Counterman
      );
      requiredRoleValidation(
          Counterman.cancelOthersTicket.toString(),
          checked.value,
          Counterman.ticketBooking,
          Counterman.cancelOthersTicket,
          Counterman
      );
      if (countermanIdForUpdate.value === "") {
        passwordValidation("password", countermanData.password);
      } else {
        if (countermanData.password !== "") {
          passwordValidation("password", countermanData.password);
        }
      }
      return !(
          Object.values(errors).length <= 0 ||
          !Object.values(errors).every((x) => x === null || x === "")
      );
    };

    const addCounterMan = () => {
      countermanData.roles = checked.value.sort().join(",");

      if (validate()) {
        showLoader();
        store
            .dispatch("addCounterMan", countermanData)
            .then(() => {
              hideLoader();
              emit("getAllCounterMans");
              closeModal();
            })
            .catch((error) => {
              hideLoader();
              if (error.status === 401) {
                addCounterMan();
              }
            });
      }
    };
    const updateCounterMan = () => {
      countermanData.roles = checked.value.sort().join(",");
      if (validate()) {
        showLoader();
        store
            .dispatch("updateCounterMan", countermanData)
            .then(() => {
              emit("getAllCounterMans");
              closeModal();
            })
            .catch((error) => {
              hideLoader();
              if (error.status === 401) {
                updateCounterMan();
              }
            });
      }
    };

    onBeforeMount(() => {
      Object.keys(errors).forEach(function (key) {
        delete errors[key];
      });
      countermanData.companyId = companyId.value;
      //   countermanData.counterId = counterId.value;
      if (countermanIdForUpdate.value !== "") {
        const countermanDetails = store.getters.getCounterMan(
            countermanIdForUpdate.value
        );
        title.value = "Update Counterman";
        countermanData.id = countermanDetails[0]._id;
        countermanData.companyId = countermanDetails[0].companyId;
        countermanData.name = countermanDetails[0].name;
        // countermanData.depot = countermanDetails[0].depot?._id;
        countermanData.phone = countermanDetails[0].phone;
        countermanData.email = countermanDetails[0].email;
        countermanData.counterId = countermanDetails[0].counterId?._id;
        countermanData.printerType = countermanDetails[0].printerType;
        countermanData.status = countermanDetails[0].status ? 1 : 0;
        countermanData.printingType = countermanDetails[0].printingType;
        countermanData.nid = countermanDetails[0].nid;
        countermanData.address = countermanDetails[0].address;
        countermanData.commission = countermanDetails[0].commission;
        countermanData.commissionType = countermanDetails[0].commissionType;
        countermanDetails[0].roles[0]
            .split(",")
            .forEach((element) => checked.value.push(parseInt(element)));
        Object.keys(Counterman).length === checked.value.length
            ? (allSelected.value = true)
            : (allSelected.value = false);
      }
    });

    return {
      status,
      countermanData,
      errors,
      Counterman,
      title,
      checked,
      depot,
      allSelected,
      countermanIdForUpdate,
      printingType,
      fieldTypes,
      giveAllPermission,
      toggleAllPermission,
      updateCounterMan,
      addCounterMan,
      closeModal,
      printerType,
      dropdownVariables,
    };
  },
};
</script>

<style scoped></style>
