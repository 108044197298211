<template>
  <div>
    <div v-if="addUpdate">
      <DepotAddUpdateModal
        v-model="addUpdate"
        v-bind:depot="depotId"
        @getAllDepots="getAllDepots"
      />
    </div>

    <div>
      <FilterTab>
        <!-- <template v-slot:title><h6>Counter</h6></template> -->
        <template v-slot:body>
          <div class="col-sm-6 col-md-2"></div>
        </template>
        <template v-if="hasPermission(Admin.companyDeployWrite)" v-slot:button1>
          <button class="edit_table_data font_detail" @click="addDepot">
            <i class="fas fa-plus"></i><span class="mx-1 bold">Add New</span>
            <!--              <span v-if="!Counterman"><i class="fas fa-plus"></i><span class="mx-1 bold">Add New</span></span>-->
            <!--              <span v-else><i class="fa fa-arrow-left"></i><span class="mx-1 bold">Go Back</span></span>-->
          </button>
        </template>
      </FilterTab>
      <Table v-if="$store.getters.getDepots">
        <template v-slot:header>
          <tr>
            <td class="font_detail">SL</td>
            <td class="font_detail">NAME</td>
            <td class="font_detail">COMPLAIN NUMBER</td>
            <td class="font_detail">STATUS</td>

            <td
              v-if="hasPermission(Admin.companyDeployWrite)"
              class="font_detail"
            >
              ACTION
            </td>
          </tr>
        </template>
        <template v-slot:body>
          <tr
            v-for="(depot, index) in $store.getters.getDepots"
            :key="depot._id"
          >
            <td class="font_detail">
              {{ index + 1 + (pagination.page - 1) * 25 }}
            </td>
            <td class="font_detail">{{ depot.name }}</td>
            <td class="font_detail">{{ depot.complainNumber }}</td>
            <td>
              <span v-if="depot.status" class="badge bg-success">{{
                dropdownVariables.status[1]
              }}</span>
              <span v-else class="badge bg-danger">{{
                dropdownVariables.status[0]
              }}</span>
            </td>

            <td v-if="hasPermission(Admin.companyDeployWrite)">
              <button
                class="edit_table_data font_detail edit_table_button"
                @click="updateDepot(depot._id)"
              >
                <i class="fas fa-pencil-alt"></i><span class="mx-1">Edit</span>
              </button>
            </td>
          </tr>
        </template>
        <template v-slot:pagination>
          <pagination
            v-model="pagination.page"
            :records="$store.getters.getTotalDepots"
            :per-page="pagination.limit"
            @paginate="getAllDepots"
            :options="options"
          />
        </template>
      </Table>
    </div>
  </div>
</template>

<script>
import { reactive } from "@vue/reactivity";
import { inject, onBeforeMount, ref } from "vue";
import store from "@/store";
import dropdownVariables from "@/modules/dropdownVariables";
import DepotAddUpdateModal from "./DepotAddUpdateModal";
import permission from "@/modules/permission";
import roles from "@/modules/roles";

const { hasPermission } = permission();
const { Admin } = roles();

export default {
  name: "Depot",
  components: { DepotAddUpdateModal },
  setup() {
    let pagination = reactive({
      page: 1,
      limit: 25,
    });
    const addUpdate = ref(false);
    const depotId = ref("")
    const options = {
      texts: {
        count: "",
      },
    };
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");

    const getAllDepots = () => {
      showLoader();
      store
        .dispatch("getDepotsByPage", pagination)
        .then(() => {
          hideLoader();
        })
        .catch((error) => {
          hideLoader();
          if (error.status === 401) {
            getAllDepots();
          }
        });
    };
    const toggle = () => {
      addUpdate.value = !addUpdate.value;
    };
    const updateDepot = (id) => {
      depotId.value = id;
      toggle()
    };
    const addDepot = () => {
      depotId.value = "";
      toggle()
    };

    onBeforeMount(() => {
      getAllDepots();
    });

    return {
      options,
      pagination,
      dropdownVariables,
      addUpdate,
      depotId,
      Admin,
      hasPermission,
      getAllDepots,
      toggle,
      updateDepot,
      addDepot,
    };
  },
};
</script>

<style scoped>
.bold {
  font-weight: bold;
}
</style>
