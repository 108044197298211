<template>
  <div>
    <div
        class="card text-center pb-3 rounded-0"
        style="background-color: #dcdcdc"
    >
      <div class="card-body">
        <div v-if="printPreview">
          <TicketPrintPreview
              v-if="verifyData.type === 'intercity'"
              :ticket="$store.getters.getTicketDetailsVerified"
              :ticketType="'ticket'"
              @close="closePrintPreview"
          />
        </div>
        <div v-if="!printPreview">
          <div class="card-text"><h2>Verify By Type & Phone</h2></div>
          <div class="row">
            <div class="col-md-3">
            </div>
            <div class="col-md-3">
              <Dropdown v-model="verifyData.type" v-bind:id="'type'" v-bind:label="'Type'"
                        v-bind:dropdown="dtTypes"/>
            </div>
            <div class="col-md-3">
              <div class="input-group mb-3">
                <input type="text"
                       class="form-control mt-1"
                       placeholder="Enter Passenger phone"
                       aria-label="Enter phone"
                       aria-describedby="button-addon2"
                       v-model="verifyData.passengerPhone"
                       @keyup.enter="submit"
                >
                <button class="btn btn-secondary mt-1"
                        type="button"
                        id="button-addon2"
                        @click="submit"
                >Verify
                </button>
              </div>
            </div>
            <div class="w-25 m-auto">

            </div>
            <div class="w-25 m-auto">

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {inject, ref} from "vue";
import {reactive} from "@vue/reactivity";
import toast from "@/modules/Toast";
import store from "@/store";
import dropdownVariables from "@/modules/dropdownVariables";

export default {
  name: "Verify",
  setup() {
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    let verifyData = reactive({
      passengerPhone: "",
      type: "intercity",
    });
    const dtTypes = dropdownVariables.dtTypes;
    const printPreview = ref(false);
    const {errorToast} = toast();
    const closePrintPreview = () => {
      // focusInput();
      printPreview.value = false;
      verifyData.passengerPhone = "";
    };
    const submit = () => {
      if (verifyData.passengerPhone) {
        showLoader();
        store
            .dispatch("verifyCountermanTicket", verifyData)
            .then(() => {
              if(verifyData.type === 'intercity'){
                printPreview.value = true;
              }else{
                printPreview.value = false;
              }
              hideLoader();
            })
            .catch((error) => {
              hideLoader();
              closePrintPreview();
              if (error.status === 401) {
                submit();
              } else if (error.status === 400) {
                const {data} = error;
                if (!Object.prototype.hasOwnProperty.call(data, "message")) {
                  errorToast("Invalid Code!");
                }
              }
            });
      }
    };

    return {
      dtTypes,
      verifyData,
      submit,
      printPreview,
      closePrintPreview,
    };
  },
}
</script>

<style scoped>

</style>
