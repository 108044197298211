<template>
  <Modal @close="closeModal" v-bind:size="'xl'">
    <template v-slot:title>{{ title }}</template>
    <template v-slot:body>
      <form>
        <div class="row">
          <span v-if="errors" class="text-danger err_msg">{{
            errors.routeSequence
          }}</span>
          <div class="col-md-3">
            <Dropdown
              v-model="fareModalityData.fareModality.fromStoppageId"
              v-bind:id="'route'"
              v-bind:label="'From Stoppage'"
              v-bind:dropdown="
                $store.getters.getSequenceRouteListForFilterAdmin
              "
              v-bind:hideLabel="'inactive'"
              v-bind:defaultOption="'--select sequence route--'"
              v-bind:defaultValue="''"
              v-bind:trip="'active'"
            />
          </div>
          <div class="col-md-3">
            <Dropdown
              v-model="fareModalityData.fareModality.toStoppageId"
              v-bind:id="'route'"
              v-bind:label="'To Stoppage'"
              v-bind:dropdown="
                $store.getters.getSequenceRouteListForFilterAdmin
              "
              v-bind:hideLabel="'inactive'"
              v-bind:defaultOption="'--select sequence route--'"
              v-bind:defaultValue="''"
              v-bind:trip="'active'"
            />
          </div>
          <div class="col-md-3">
            <Dropdown
              v-model="fareModalityData.status"
              v-bind:id="'status'"
              v-bind:label="'Status'"
              v-bind:dropdown="status"
              v-bind:hideLabel="'inactive'"
              v-bind:trip="'active'"
            />
          </div>
          <div class="col-md-3 mt-4">
            <input
              type="submit"
              class="submit_button mt-1 w-50"
              @click.prevent="updateFareModality"
            />
          </div>
          <div
            class="d-flex"
            v-if="fareModalityData.fareModality.fare.length !== 0"
          >
            <Input
              class="m-1"
              v-for="seatClass in fareModalityData.fareModality.fare"
              :key="seatClass.index"
              v-model.number="seatClass.fare"
              v-bind:placeholder="`Enter ${seatClass.class_name} Fare`"
              v-bind:id="'number'"
              v-bind:type="'number'"
              :label="`${seatClass.class_name}`"
              :active="'active'"
            />
          </div>
        </div>
      </form>
    </template>
  </Modal>
</template>

<script>
import { reactive } from "@vue/reactivity";
// import { watch } from "vue";
import useInputValidation from "@/modules/useInputValidations";
import { ref, inject, onBeforeMount } from "vue";
import store from "@/store";
import dropdownVariables from "@/modules/dropdownVariables";

export default {
  name: "EditRoute",
  props: {
    id: String,
    companyId: String,
    routeId: String,
    fromStoppageId: String,
    toStoppageId: String,
    statuss: [String, Boolean],
    fare: Array,
  },
  setup(props, { emit }) {
    let { routeSequenceValidation, errors } = useInputValidation();
    const status = dropdownVariables.status;
    const id = ref(props.id);
    const routeId = ref(props.routeId);
    const companyId = ref(props.companyId);
    const seatAllClass = ref({});
    const allSeatClass = ref([]);
    const routeSequence = ref({});
    const title = ref("Edit Route Fare");
    const fromStoppageId = ref(props.fromStoppageId);
    const toStoppageId = ref(props.toStoppageId);
    const statuss = ref(props.statuss);
    const fareArray = ref(props.fare);

    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");

    seatAllClass.value = store.getters.getAllSeatClassAdmin;
    let fareModalityData = reactive({
      id: id.value,
      companyId: companyId.value,
      routeId: routeId.value,
      fareModality: {
        fromStoppageId: "",
        toStoppageId: "",
        fare: [],
      },
      status: 1,
    });
    allSeatClass.value = Object.entries(seatAllClass.value);

    const validate = () => {
      routeSequence.value = store.getters.getSequenceRouteListForFilterAdmin;
      routeSequenceValidation(
        "routeSequence",
        routeSequence.value,
        fareModalityData.fareModality.fromStoppageId,
        fareModalityData.fareModality.toStoppageId
      );
      if (errors.routeSequence === "") {
        return true;
      } else {
        return false;
      }
    };
    const updateFareModality = () => {
      if (validate()) {
        showLoader();
        store
          .dispatch("updateFareModality", fareModalityData)
          .then(() => {
            emit("getroutefare");
            emit("updateFareModality");
            emit("update:modelValue", false);
            hideLoader();
          })
          .catch((error) => {
            hideLoader();
              if (error.status === 401) {
                  updateFareModality();
              }
          });
      }
    };

    const closeModal = () => {
      emit("update:modelValue", false);
      updateValue();
    };

    const updateValue = () => {
      let obj = {};
      allSeatClass.value.forEach((seatClass) => {
        obj[seatClass[0]] = {
          class_id: seatClass[0],
          class_name: seatClass[1],
          fare: "",
        };
      });
      fareModalityData.fareModality.fromStoppageId = fromStoppageId.value;
      fareModalityData.fareModality.toStoppageId = toStoppageId.value;
      fareModalityData.status = statuss.value ? 1 : 0;
      fareModalityData.fareModality.fare = [];
      fareArray.value.forEach((item) => {
        allSeatClass.value.forEach((seatClass) => {
          if (item?.class_id === seatClass[0]) {
            fareModalityData.fareModality.fare.push(item);
            delete obj[seatClass[0]];
          }
        });
      });
      Object.entries(obj).forEach((item) => {
        fareModalityData.fareModality.fare.push(item[1]);
      });
      obj = {};
    };

    onBeforeMount(() => {
      updateValue();
    });

    return {
      updateFareModality,
      closeModal,
      fareModalityData,
      status,
      errors,
      title,
    };
  },
};
</script>

<style scoped></style>
