<template>
  <!--  <div class="card">-->
  <div>
    <div v-if="companyDeployTabs">
      <div class="text-center py-1 text-white nav-style fw-bold text-uppercase">
        {{ $store.getters.getSingleCompanyData.name }}
      </div>
      <ul
        class="nav nav-tabs justify-content-center nav-style"
        id="myTab"
        role="tablist"
      >
        <li
          v-for="(tab, index) in companyDeployTabs"
          :key="index"
          class="nav-item"
          role="presentation"
        >
          <button
            class="nav-link"
            :id="tab.name + '-tab'"
            data-bs-toggle="tab"
            v-bind:class="
              tab.name === activeTab
                ? 'active active-button'
                : 'inactive-button'
            "
            type="button"
            @click="setActive(tab.name)"
            role="tab"
            :aria-controls="tab.path"
            aria-selected="true"
          >
            {{ tab?.title }}
          </button>
        </li>
      </ul>

      <div class="tab-content" id="myTabContent">
        <div
          v-for="(tab, index) in companyDeployTabs"
          :key="index"
          class="tab-pane fade"
          v-bind:class="tab.name === activeTab ? 'show active' : ''"
          :id="tab.path"
          role="tabpanel"
          :aria-labelledby="tab.name + '-tab'"
        ></div>
        <Depot v-if="activeTab === companyDeployTabs.DEPOT_URL?.name" />
        <DtOwner v-if="activeTab === companyDeployTabs.DT_OWNER_URL?.name" />
        <Owner v-if="activeTab === companyDeployTabs.OWNER_URL?.name" />
        <Stoppage v-if="activeTab === companyDeployTabs.STOPPAGE_URL?.name" />
        <Counter v-if="activeTab === companyDeployTabs.COUNTER_URL?.name" />
        <Route v-if="activeTab === companyDeployTabs.ROUTE_URL?.name" />
        <div
          v-if="$store.getters.getSingleCompanyData?.fareType === 'predefined'"
        >
          <SeatFare
            v-if="activeTab === companyDeployTabs.SEAT_FARE_URL?.name"
          />
        </div>
        <SeatClass
          v-if="activeTab === companyDeployTabs.SEAT_CLASS_URL?.name"
        />
        <SeatPlan v-if="activeTab === companyDeployTabs.SEAT_PLAN_URL?.name" />
        <Coach v-if="activeTab === companyDeployTabs.COACH_URL?.name" />
        <Trips v-if="activeTab === companyDeployTabs.TRIPS_URL?.name" />
        <Counterman
          v-if="activeTab === companyDeployTabs.COUNTERMAN_URL?.name"
        />
        <Supervisor
          v-if="activeTab === companyDeployTabs.SUPERVISOR_URL?.name"
        />
        <Driver v-if="activeTab === companyDeployTabs.DRIVER_URL?.name" />
      </div>
    </div>
  </div>
</template>

<script>
import web from "@/urls/web";
import Stoppage from "./Children/Stoppage/Stoppage";
import DtOwner from "./Children/DtOwner/DtOwner.vue";
import Owner from "./Children/Owner/Owner.vue";
import Route from "./Children/Routes/Routes";
import SeatClass from "./Children/SeatClass/SeatClass";
import SeatPlan from "./Children/SeatPlan/SeatPlan/SeatPlan.vue";
import SeatFare from "./Children/SeatFare/SeatFare/SeatFare.vue";
import { onBeforeMount, onBeforeUnmount, ref, watch } from "vue";
import store from "@/store";
import Counter from "./Children/Counter/Counter";
import Trips from "./Children/Trips/Trips.vue";
import Coach from "./Children/Coach/Coach.vue";
import Counterman from "./Children/Counterman/Counterman.vue";
import Supervisor from "@/views/Admin/CompanyDeploy/Children/Supervisor/Supervisor.vue";
import Driver from "@/views/Admin/CompanyDeploy/Children/Driver/Driver.vue";
import Depot from "@/views/Admin/CompanyDeploy/Children/Depot/Depot.vue";
// import permission from "@/modules/permission";
import roles from "@/modules/roles";

// const {hasPermission} = permission();
const { Admin } = roles();

export default {
  name: "CompanyManage",
  components: {
    Route,
    DtOwner,
    Owner,
    Stoppage,
    Counter,
    Trips,
    Counterman,
    SeatClass,
    SeatPlan,
    SeatFare,
    Coach,
    Supervisor,
    Driver,
    Depot,
  },
  setup() {
    let companyDeployTabs = ref({
      ...web.Admin.COMPANY_DEPLOY_URL.children.MANAGE_URL.children,
    });
    let activeTab = ref("");

    watch(
      () => store.getters.userRoles,
      (value) => {
        if (!value?.includes(Admin.adminRead)) {
          delete companyDeployTabs.value["TRIPS_URL"];
        } else {
          companyDeployTabs.value = {
            ...web.Admin.COMPANY_DEPLOY_URL.children.MANAGE_URL.children,
          };
        }
      }
    );

    watch(
      () => store.getters.getSingleCompanyData?.fareType,
      () => {
        companyDeployTabs.value = {
          ...web.Admin.COMPANY_DEPLOY_URL.children.MANAGE_URL.children,
        };
      }
    );

    const setActive = (index) => {
      activeTab.value = index;
      store.commit("setActiveTab", activeTab.value);
    };

    onBeforeMount(() => {
      activeTab.value = store.getters.getActiveTab
        ? store.getters.getActiveTab
        : companyDeployTabs.value.DEPOT_URL.name;
    });
    onBeforeUnmount(() => {
      if (store.getters.isLoggedIn) {
        store.commit("setActiveTab", "");
      }
    });

    return {
      companyDeployTabs,
      activeTab,
      setActive,
    };
  },
};
</script>

<style scoped>
/*.card {*/
/*  padding: 20px 30px;*/
/*}*/

.nav-style {
  background: #3b3b3b;
  border-top: 1px;
}

.active-button {
  background: #242424 !important;
  border: 1px solid #242424 !important;
  color: #fff !important;
}

.inactive-button {
  color: #fff !important;
}
</style>
