import apiResponse from "@/modules/apiResponse";
import toast from "@/modules/Toast";
import tokenCookie from "@/modules/tokenCookie";
import Apis from "@/urls/apis";
import axios from "axios";

const { infoToast, successToast } = toast();
const { hasValidToken, getToken } = tokenCookie();
const { errorResponse, successResponse } = apiResponse();

const state = {
  filter: {
    counterOwner: {},
    coachOwner: {},
    counterOwnerPanel: {},
  },
  dtReportOwner: [],
  dtReportDetailsOwner: [],
  counterWiseSeatDetailsOwner: [],
  counterWiseReportListOwner: [],
  printTicketCountListOwner: [],
  printTripWiseCountListOwner: [],
  printDailySummaryWiseCountListOwner: [],
  coachwiseTripOwner: [],
  chalanTripList: [],
  chalanReport: {},
  coachWiseReportListOwner: [],
  coachWiseSeatDetailsOwner: [],
  counterWiseReportListOWNER: [],
};
const getters = {
  getCounterListForFilterOwnerPanel: (state) => state.filter.counterOwnerPanel,
  getCoachWiseSeatDetailsOwner: (state) => state.coachWiseSeatDetailsOwner,
  getCoachWiseReportOwnerIntercity: (state) => state.coachWiseReportListOwner,
  getCoachListForFilterOwner: (state) => state.filter.coachOwner,
  getChalanReportDetails: (state) => state.chalanReport,
  getDtReportOwner: (state => state.dtReportOwner),
  getDtReportDetailsOwner: (state => state.dtReportDetailsOwner),
  getChalanCountermanSeatReport: (state) =>
    Object.values(state.chalanReport.countermanSeatReport),
  getChalanReportDetail: (state) => (id) =>
    state.chalanReport.filter(function(trip) {
      return trip._id === id;
    }),
  getCounterListForFilterOwner: (state) => state.filter.counterOwner,
  getAllCoachwiseTripListOwner: (state) => state.coachwiseTripOwner.docs,
  // getTotalCoachwiseTripList:(state)=> state.coachwiseTrip.totalDocs,
  getCoachwiseTripOwner: (state) => (id) =>
    state.coachwiseTripOwner.docs.filter(function(trip) {
      return trip._id === id;
    }),
  getChalanTripList: (state) => state.chalanTripList,
  getcounterWiseSeatDetailsOwner: (state) => state.counterWiseSeatDetailsOwner,
  getCounterWiseReportListOwner: (state) => state.counterWiseReportListOwner,
  getCounterWiseReportOwner: (state) => state.counterWiseReportListOWNER,
  getPrintTicketCountListOwner: (state) => state.printTicketCountListOwner,
  getPrintTripWiseCountListOwner: (state) => state.printTripWiseCountListOwner,
  getPrintDailySummaryWiseCountListOwner: (state) =>
    state.printDailySummaryWiseCountListOwner,
};
const actions = {
  async getPrintCountListOwnerPanel({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.OWNER_PRINT_COUNT_REPORT, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            successToast(data.message);
            if (status === 200) {
              commit("setCountReportOwnerPanel", { data, payload });
              resolve();
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getCountermanWiseReportDetailsOwner(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.COUNTERMAN_WISE_REPORT_DETAILS_OWNER, {
            params: payload,
            headers: header,
          })
          .then((response) => {
            response.data.ticket.length === 0 ? infoToast(response.data.message) : successResponse(response)

            // commit("setCounterWiseSeatDetailsOwner", response.data.ticket);
            resolve(response.data.ticket);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getDtReportOwner({commit}, payload) {
    console.log("lled")
    if (await hasValidToken()) {
      const header = {
        'Authorization': 'Bearer ' + getToken()
      };

      return new Promise((resolve, reject) => {
        axios.get(Apis.DIGITAL_TICKET_WISE_REPORT_OWNER, {
          params: payload,
          headers: header
        })
          .then((response) => {
            if (response.data.trips.length === 0) {
              infoToast(response.data.message)

            }
            commit("setDtListOwner", response.data.trips)
            resolve(response.data.trips)

          })
          .catch(error => {
            reject(errorResponse(error))
          });
      });
    }
  },
  async cancelOnlineTicketForOwner({commit}, payload) {
    if (await hasValidToken()) {
      const header = {
        'Authorization': 'Bearer ' + getToken()
      };

      return new Promise((resolve, reject) => {
        axios.get(Apis.DIGITAL_TICKET_CANCEL_OWNER, {
          params: payload,
          headers: header
        })
          .then((response) => {
            response.data.ticket.length === 0 ? infoToast(response.data.message) : successResponse(response)
            commit("setDtReportDetailsOwner", response.data.ticket)
            resolve(response.data.ticket)
          })
          .catch(error => {
            reject(errorResponse(error))
          });
      });
    }
  },
  async getDtReportOwnerDetails({commit}, payload) {
    if (await hasValidToken()) {
      const header = {
        'Authorization': 'Bearer ' + getToken()
      };

      console.log(Apis.DIGITAL_TICKET_WISE_REPORT_DETAILS_OWNER)
      return new Promise((resolve, reject) => {
        axios.get(Apis.DIGITAL_TICKET_WISE_REPORT_DETAILS_OWNER, {
          params: payload,
          headers: header
        })
          .then((response) => {
            response.data.ticket.length === 0 ? infoToast(response.data.message) : successResponse(response)
            commit("setDtReportDetailsOwner", response.data.ticket)
            resolve(response.data.ticket)
          })
          .catch(error => {
            reject(errorResponse(error))
          });
      });
    }
  },

  async getCountermanWiseReportDetails({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.COUNTERMAN_WISE_REPORT_DETAILS, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (data.ticket.length === 0) {
              infoToast("No Data Available");
            }
            if (status === 200) {
              commit("setCoachWiseSeatDetails", data.ticket);
            }
            resolve(data.ticket);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },
  async getCounterListForFilterOwnerPanel({ commit }, payload) {
    if (await hasValidToken()) {
      commit("setCounterListInFilter", {});
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_ALL_COUNTERS_IN_FILTER_OWNER_PANEL, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              let counterList = {};
              data.counter.forEach((counter) => {
                counterList[counter._id] = counter.name;
              });
              commit("setCounterListInFilterOwnerPanel", counterList);
              resolve(data.counter);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },
  async getfilterCounterWiseReportOwnerPanel({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };
      return new Promise((resolve, reject) => {
        axios
          .get(Apis.COUNTER_WISE_REPORT_OWNER_PANEL, {
            params: payload,
            headers: header,
          })
          .then((response) => {
            response.data.ticket.length === 0 ? infoToast(response.data.message) : successResponse(response)
            commit("setFilterCounterWiseReportOWNER", response.data.ticket);
            resolve(response.data.ticket);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },
  async getSeatDetailsOwner({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.COACH_WISE_SEAT_REPORT_DETAILS_OWNER, {
            params: payload,
            headers: header,
          })
          .then((response) => {
            response.data.ticket.length === 0 ? infoToast(response.data.message) : successResponse(response)

            commit("setCoachWiseSeatDetailsOwner", response.data.ticket);
            resolve(response.data.ticket);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },
  async getAllCoachListOwner({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_ALL_LIST_COACH_OWNER, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              let coachList = {};
              data.coach.forEach((coach) => {
                coachList[coach._id] = coach.name;
              });
              commit("setCoachListInFilterOwner", coachList);
              resolve(data.coach);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },
  async getAllCoachReportOwner({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.COACH_WISE_REPORT_OWNER, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              if(data.ticket.length === 0){
                infoToast(data.message)
              }
              commit("setCoachWiseListOwner", data.ticket);
              resolve(data.ticket);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },
  async getChalanTripList({ commit }, payload) {
    if (await hasValidToken()) {
      commit("addChalanTripList", []);
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.CHALAN_TRIP_WISE_REPORT_OWNER, {
            params: payload,
            headers: header,
          })
          .then((response) => {
            commit("addChalanTripList", response.data.trips);
            successToast(response.data.message);
            resolve(response);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getChalanDetails({ commit }, payload) {
    if (await hasValidToken()) {
      commit("setChalanDetails", []);
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_CHALAN_REPORT, {
            params: payload,
            headers: header,
          })
          .then((response) => {
            commit("setChalanDetails", response.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },
  async getAllCoachwiseTripByPageOwner({ commit }, payload) {
    if (await hasValidToken()) {
      commit("addCoach", []);
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_ALL_COACHWISE_TRIP_OWNER, {
            params: payload,
            headers: header,
          })
          .then((response) => {
            commit("addCoachwiseTripListOwner", response.data.coaches);
            resolve(response);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getCounterListOwner({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_ALL_COUNTER_OWNER, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              let counterList = {};
              data.counter.forEach((counter) => {
                counterList[counter._id] = counter.name;
              });
              commit("setCounterListInFilterOwner", counterList);
              resolve(counterList);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getPrintCountListOwner({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.PRINT_COUNT_OWNER, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              commit("setCountReportOwner", { data, payload });
              resolve(data);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },
};

const mutations = {
  setFilterCounterWiseReportOWNER: (state, updateList) =>
    (state.counterWiseReportListOWNER = updateList),
  setDtListOwner: (state, dtDetails) =>
    (state.dtReportOwner = dtDetails),
  setDtReportDetailsOwner: (state, dtDetails) =>
    (state.dtReportDetailsOwner = dtDetails),
  setCoachWiseSeatDetailsOwner: (state, seatDetails) =>
    (state.coachWiseSeatDetailsOwner = seatDetails),
  setCoachWiseListOwner: (state, coachWiseReport) =>
    (state.coachWiseReportListOwner = coachWiseReport), //
  setCounterListInFilterOwner: (state, counterList) =>
    (state.filter.counterOwner = counterList),
  addChalanTripList: (state, chalanTripList) =>
    (state.chalanTripList = chalanTripList),
  setFilterCounterWiseReportOwner: (state, updateList) =>
    (state.counterWiseReportListOwner = updateList),
  setAllCounterListOwner: (state, counterWiseReport) =>
    (state.counterWiseReportListOwner = counterWiseReport),
  setChalanDetails: (state, chalanReport) =>
    (state.chalanReport = chalanReport),
  setCoachListInFilterOwner: (state, coachList) =>
    (state.filter.coachOwner = coachList),
  setCounterListInFilterOwnerPanel: (state, counterList) =>
    (state.filter.counterOwnerPanel = counterList),
  setCountReportOwnerPanel: (state, value) => {
    state.printTicketCountListOwner = [];
    state.printTripWiseCountListOwner = [];
    state.printDailySummaryWiseCountListOwner = [];
    if (value.payload.reportType === "ticket") {
      state.printTicketCountListOwner = value.data.ticket;
    } else if (value.payload.reportType === "tripWiseReport") {
      state.printTripWiseCountListOwner = value.data.report;
    } else if (value.payload.reportType === "dailySummaryReport") {
      state.printDailySummaryWiseCountListOwner = value.data.report;
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
