import toast from "@/modules/Toast";

const {successToast, errorToast} = toast();

export default function validationMessage() {
    const messageExtractor = (response, status) => {
        if (status === 200) {
            success200(response)
        } else if (status === 400) {
            error400(response)
        } else if (status === 401) {
            error401(response)
        }else if (status === 402) {
            error402(response)
        } else if (status === 403) {
            error403(response)
        }else if (status === 504) {
            error504(response)
        }else if (status === 404) {
            error404(response)
        }
    }

    const success200 = (response) => {
        successToast(response)
    }
    const error400 = (response) => {
        if (response.error) {
            //Backend validation error segment
            if(response.error.errors){
                Object.values(response.error.errors).forEach(entry => {
                    errorToast(entry.properties.message)
                });
            }
        } else if (response.message) {
            errorToast(response.message)
        }else{
            console.log("ValidationMessage: "+response)
        }
    }
    const error401 = (response) => {
        console.log(response)
        // errorToast(response.message)
    }
    const error402 = (response) => {
        errorToast(response.message)
    }
    const error403 = (response) => {
        errorToast(response.message)
    }
    const error504 = (response) => {
        errorToast(response.error.message)
    }
    const error404 = (response) => {
        errorToast(response.message)
    }
    return {
        messageExtractor
    };

}