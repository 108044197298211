<template>
  <div class="col-md-12 col-lg-4 col-xl-4 col-sm-12 bus_border">
    <div class="rounded mt-2 py-2">
      <div class="d-flex justify-content-between borderinner">
        <img alt="stairing" src="@/assets/images/jatri_stair.svg"/>
        <img alt="" class="stairing" src="@/assets/images/jatri_wheel.svg"/>
      </div>
      <div v-for="(plan, index) in seatLists"
           :key="index"
           class="row row-cols-12 mt-1 py-2 text-center fw-bold"
      >
        <div v-for="(seat, seatId) in plan"
             :key="'seat' + seatId + index"
             class="col"
        >
          <div v-if="seat.seat_no !== '' && !$store.getters.getSelectedSeatsBookedDatabase.includes(seat.seat_no)"
               @click="selectSeatHandler(seat.seat_no, seat.class_id)"
          >

            <span
                :class="getSelectedSeatStatusClass(selectedSeat, seat)"
                class="seat selected cursor user-select-none">
                    {{ seat.seat_no }}
                    <div class="hand"></div>
              </span>
          </div>

          <div
              v-if="
                seat.seat_no !== '' &&
                $store.getters.getSelectedSeatsBookedDatabase.includes(seat.seat_no)
             "
              @click="openModalReservation(seat.seat_no)"
          >

            <Popper :arrow="true" arrowPadding="20" hover
                    @open:popper="tooltipData($store.getters.getBlockedSeatsTicketsCounterman[seat.seat_no])">
                <span
                    :class="!$store.getters.getSelectedSeatsBookedDatabase.includes(seat.seat_no) ? 'cursor' : getBookedSeatStatusClass(seat.seat_no)"
                    class="seat selected"
                >
                    {{ seat.seat_no }}
                    <div class="hand"></div
                    >
                </span>
              <template #content>
                <PopperBody :seat="seat.seat_no" :tooltipInfo="tooltipInfo"/>
              </template>
            </Popper>
          </div>
          <div v-if="seat.seat_no === ''">
            <span>{{ seat.seat_no }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <slot name="seatinfo"></slot>
</template>

<script>
import {onBeforeMount, ref, reactive, watch, computed} from "vue";
import store from "@/store";
import {getBookedSeatStatusClass, getSelectedSeatStatusClass} from "@/composables/counterman/seatList"
import PopperBody from "@/components/layouts/Popper/PopperBody.vue";

export default {
  name: "SeatList",
  props: {
    singleTripDetails: Object,
  },
  emits: ["openGlobalFilter"],

  components: {
    PopperBody,
  },
  setup(props, {emit}) {
    let seatLists = ref({});
    let bookedSeatList = ref([]);
    let tripDetails = reactive(props.singleTripDetails);
    //let farePerSeat = ref([]);
    let seatFare = ref([]);
    let tooltipInfo = ref({});
    const selectedSeat = computed(() => store.getters.getSelectedSeat)
    const isActiveTicketModal = ref(false)
    const seatNumber = ref('')


    onBeforeMount(() => {
      seatLists.value = tripDetails.seatPlan.seatList;
      bookedSeatList.value = tripDetails.bookedSeats;
      store.commit("setBookedSeatList", tripDetails.bookedSeats)

    })
    const maxSeatPerTicket = computed(() => store.getters.maxSeatPerTicket)
    //Convert ticket to seatWise Ticket
    // watch(() => store.getters.getTicketByTripCounterman, () => {
    //   store.commit("setBlockedSeatsTicketsCounterman", {tickets: store.getters.getTicketByTripCounterman});
    // });

    watch(() => seatFare, () => {
      store.commit("setSeatFare", seatFare.value)
    }, {deep: true})

    getBookedSeatStatusClass();
    const openGlobalFilter = (value) => {
      emit("openGlobalFilter", value)

    }
    const openModalReservation = (seat) => {
      const ticketsBySeatNumber = store.getters.getTicketByTripCounterman.filter((ticketItem) => {
        for (let i = 0; i < ticketItem.seatNumbers.length; i++) {
          if (ticketItem.seatNumbers[i] === seat) {
            return true
          }
        }
        return false
      })
      ticketsBySeatNumber[0].trip = store.getters.getTripForTicketing;
      console.log(store.getters.getTicketByTripCounterman)
      store.commit("setSearchTickets", ticketsBySeatNumber)
      openGlobalFilter({isOpen: true, from: 'seatList'})
      isActiveTicketModal.value = true
    }


    const selectSeatHandler = (seatNo, seatClassId) => {
      if (!store.getters.getBlockedSeatsTicketsCounterman.includes(seatNo)) {
        let seat = {
          seat_no: seatNo,
          class_id: seatClassId,
        };
        store.commit("setSelectedSeat", {seat, tripDetails, maxSeatPerTicket})
      }
    }

    const tooltipData = (data) => {
      tooltipInfo.value = data;
    };

    return {
      seatLists,
      selectedSeat,
      getBookedSeatStatusClass,
      getSelectedSeatStatusClass,
      selectSeatHandler,
      tooltipData,
      tooltipInfo,
      bookedSeatList,
      openModalReservation,
      isActiveTicketModal,
      seatNumber,
      openGlobalFilter,
    }
  }
}
</script>

<style scoped>
/* responsive start  */
@media only screen and (min-width: 1400px) {
  .font_detail {
    font-size: 12px !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .font_detail {
    width: 200px !important;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .font_detail {
    overflow-x: scroll;
    width: 100%;
  }

  button.font_detail {
    overflow-x: unset;
  }
}

@media only screen and (max-width: 991px) and (min-width: 768px) {
  .font_detail {
    height: auto;
    padding-bottom: 0;
  }
}

@media only screen and (max-width: 767px) {
  .padding-left {
    padding-left: 32px;
  }

  .font_detail,
  .booking_info {
    margin-bottom: 4px;
  }

  /* .booking_info_sm_device{
          margin-left: 13px;
      } */
}

/* responsive end */

.spacing {
  letter-spacing: 1px;
}

.corporate_btn {
  background-color: #00b74a;
  border: 1px solid #ece3e2;
  color: #fff;
}

.corporate_btn:hover {
  color: #00b74a;
}

.confirm_btn {
  color: #fff;
  border-radius: 50px;
  text-transform: uppercase;
  padding: 8px;
  width: 170px;
}

.confirm_btn:hover {
  background-color: #fff;
}

.tableInfo_border {
  border: 1px solid #f04935;
  border-radius: 10px 10px 10px 10px;
  padding: 10px;
  margin-bottom: 10px;
}

.booking_info {
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: 5px;
}

.booking_info label {
  text-align: right;
}

.booking_info label:after {
  content: ":";
}

.disabled {
  cursor: default;
}

.cursor {
  cursor: pointer;
}

.seat.selected,
.seat.selected:before {
  border-color: #645f5f;
  background: #fff;
}

.seat {
  position: relative;
  width: 32px;
  height: 32px;
  background: #fff;
  border: 1px solid #645f5f;
  display: inline-block;
  border-radius: 3px;
  color: #000;
}

/* .seat:after, .seat:before {
  content: "";
  position: absolute;
  width: 20px;
  background: #fff;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
} */
.seat:before {
  height: 5px;
  border: 1px solid #645f5f;
  bottom: 3px;
}

.seat:after {
  position: absolute;
  top: -5px;
  height: 8px !important;
  width: 10px !important;
  background-color: #f7f7f7;
  border: 1px solid #645f5f;
}

.seat.selected .hand,
.seat.selected:after {
  background: #fff;
}

.seat .hand {
  width: 18px;
  height: 3px;
  left: 2px;
  top: 23px;
}

.seat .hand,
.seat .hand:before {
  position: absolute;
  background: #fff;
}

.seat.selected .hand:after,
.seat.selected .hand:before {
  border-color: #645f5f;
  background: #fff;
}

.seat .hand:after,
.seat .hand:before {
  content: "";
  width: 6px;
  height: 13px;
  border: 1px solid #645f5f;
  bottom: -1px;
}

.seat .hand:before {
  right: -14px;
}

.seat.selected .hand:after,
.seat.selected .hand:before {
  border-color: #645f5f;
  background: #fff;
}

.seat .hand:after {
  position: absolute;
  background: #fff;
  left: -5px;
}

.seat .hand:after,
.seat .hand:before {
  content: "";
  width: 7px;
  height: 15px;
  border: 1px solid #645f5f;
  bottom: 0px;
}

.seat.selected .hand,
.seat.selected:after {
  background: #fff;
}

.seat:after {
  height: 3px;
  bottom: 0;
}

.seat:after,
.seat:before {
  content: "";
  position: absolute;
  width: 30px;
  background: #fff;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.borderinner {
  border-bottom: 1px dashed rgb(73, 71, 71);
}

.stairing {
  height: 50px;
}

.txt_color {
  color: #f04935;
}

.bus_border {
  border-radius: 10px 10px 10px 10px;
  border: 1px solid #f04935;
}

.remove_button {
  border: 2px solid #ff072c;
  border-radius: 20px;
  background-color: #ff072c;
  padding: 5px 10px;
  color: #ffffff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.remove_button:hover,
.remove_button:focus {
  border: 2px solid #ff072c;
  background-color: #fff;
  color: #ff072c;
  transition: 0.4s;
  outline: 0;
}

.edit_button {
  border: 2px solid #ffc107;
  border-radius: 20px;
  background-color: #ffc107;
  padding: 5px 10px;
  color: #ffffff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.edit_button:hover,
.edit_button:focus {
  border: 2px solid #ffc107;
  background-color: #fff;
  color: #ffc107;
  transition: 0.4s;
  outline: 0;
}

.right_border {
  border-right: 1px solid #ccc;
}

.seatSelect {
  cursor: pointer;
  width: 45px;
  color: #fff;
}

/* .seat {
  cursor: default;
  background-color: #ff6347;
  width: 45px;
  color: #fff;
} */
.seatNormal {
  cursor: pointer;
  background-color: rgb(216, 215, 215);
  width: 45px;
  color: rgb(20, 19, 19);
}

.ticket-info {
  font-size: 12px !important;
}

.cancel_button {
  margin: 1px 2px;
  border: 2px solid #ff0000;
  border-radius: 4px;
  background-color: #ff0000;
  padding: 2px 6px;
  color: #fff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.cancel_button:hover,
.cancel_button:focus {
  border: 2px solid #ff0000;
  background-color: #fff;
  color: #ff0000;
  transition: 0.4s;
  outline: 0;
}

.confirm_button {
  margin: 1px 2px;
  border: 2px solid #34a853;
  border-radius: 4px;
  background-color: #34a853;
  padding: 2px 6px;
  color: #fff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.confirm_button:hover,
.confirm_button:focus {
  border: 2px solid #34a853;
  background-color: #fff;
  color: #34a853;
  transition: 0.4s;
  outline: 0;
}

.reset_button {
  margin: 1px 2px;
  border: 2px solid #ffc107;
  border-radius: 4px;
  background-color: #ffc107;
  padding: 2px 6px;
  color: #ffff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.reset_button:hover,
.reset_button:focus {
  border: 2px solid #ffc107;
  background-color: #fff;
  color: #ffc107;
  transition: 0.4s;
  outline: 0;
}

.seat-empty {
  cursor: default;
  background-color: #ff6347;
  width: 45px;
  color: #fff;
}
</style>
