<template>
  <Modal @close="closeModal">
    <template v-slot:title>{{ title }}</template>
    <template v-slot:body>
      <form>
        <!-- <div class="form-group row my-1">
          <ModalInput
            v-model="routeData.name"
            v-bind:placeholder="'Enter Name'"
            v-bind:label="'Name'"
            v-bind:id="'name'"
            v-bind:type="'text'"
          >
            <template v-slot:error>
              <ModalError v-if="errors.name" v-bind:error="errors.name" />
            </template>
          </ModalInput>
        </div> -->
        <div class="form-group row my-1">
          <ModalDropdown
              v-if="$store.getters.getStoppageListForFilter"
              v-model="routeData.fromStoppageId"
              v-bind:id="'fromStoppageId'"
              v-bind:label="'From Stoppage'"
              v-bind:dropdown="$store.getters.getStoppageListForFilter"
              v-bind:defaultOption="'--select stoppage--'"
              v-bind:defaultValue="''"
          >
            <template v-slot:error>
              <ModalError
                  v-if="errors.fromStoppageId"
                  v-bind:error="errors.fromStoppageId"
              />
            </template>
          </ModalDropdown>
        </div>
        <div class="form-group row my-1">
          <ModalDropdown
              v-if="$store.getters.getStoppageListForFilter"
              v-model="routeData.toStoppageId"
              v-bind:id="'toStoppageId'"
              v-bind:label="'To Stoppage'"
              v-bind:dropdown="$store.getters.getStoppageListForFilter"
              v-bind:defaultOption="'--select stoppage--'"
              v-bind:defaultValue="''"
          >
            <template v-slot:error>
              <ModalError
                  v-if="errors.toStoppageId"
                  v-bind:error="errors.toStoppageId"
              />
            </template>
          </ModalDropdown>
        </div>
        <div class="form-group row my-1">
          <ModalInput
              v-bind:id="'routename'"
              v-model="routeData.name"
              v-bind:label="'Name'"
          ></ModalInput>
        </div>
        <div class="form-group row my-1">
          <ModalDropdown
              v-if="direction"
              v-model="routeData.direction"
              v-bind:id="'direction'"
              v-bind:label="'Direction'"
              v-bind:defaultOption="'--select direction--'"
              v-bind:defaultValue="''"
              v-bind:dropdown="direction"
          >
            <template v-slot:error>
              <ModalError
                  v-if="errors.direction"
                  v-bind:error="errors.direction"
              />
            </template
            >
          </ModalDropdown>
        </div>
        <div class="form-group row my-1">
          <ModalDropdown
              v-if="status"
              v-model="routeData.status"
              v-bind:id="'status'"
              v-bind:label="'Status'"
              v-bind:dropdown="status"
          ></ModalDropdown>
        </div>
        <div class="form-group row my-1">
          <div class="col-sm-3 col-form-label font_detail">
            <label class="label_font">Route Sequence:</label>
          </div>
          <div class="col-sm-9">
            <Multiselect
                placeholder="Select Route Sequence"
                v-model="routeData.routeSequence"
                :options="$store.getters.getStoppageListForFilter"
                id="multiselectId"
                mode="tags"
                :searchable="true"
                :createTag="true"
                class="col font_detail w-100"
            />
            <Error
                v-if="errors.routeSequence"
                v-bind:error="errors.routeSequence"
            />
          </div>
        </div>

        <div class="form-group row my-1">
          <label class="col-sm-3 col-form-label"></label>
          <div class="col-sm-9">
            <input
                v-if="!routesId"
                type="submit"
                class="mt-3 py-2 submit_button font_detail float-end"
                @click.prevent="addRoute"
            />
            <input
                v-else
                type="submit"
                class="mt-3 py-2 submit_button font_detail float-end"
                @click.prevent="updateRoute"
            />
          </div>
        </div>
      </form>
    </template>
  </Modal>
</template>

<script>
import dropdownVariables from "@/modules/dropdownVariables";
import useInputValidation from "@/modules/useInputValidations";
import {inject, onBeforeMount, ref, watch} from "vue";
import {reactive} from "@vue/reactivity";
import store from "@/store";
import {useRoute} from "vue-router";
import Multiselect from "@vueform/multiselect";

export default {
  name: "RoutesAddUpdateModal",
  props: {
    route: String,
    company: String,
  },
  components: {
    Multiselect,
  },
  setup(props, {emit}) {
    const route = useRoute();
    let {requiredValidation, compareRouteSequenceValidation, errors} =
        useInputValidation();
    let routeData = reactive({
      id: "",
      companyId: route.query.id,
      name: "",
      fromStoppageId: "",
      toStoppageId: "",
      status: 1,
      direction: "",
      routeSequence: [],
    });
    const routesId = ref(props.route);
    // const companyId = ref(props.company);
    const title = ref("Add Route");
    const status = dropdownVariables.status;
    const direction = dropdownVariables.direction;
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");

    let pagination = reactive({
      page: 1,
      limit: 25,
    });

    // watch(
    //   () => routeData.name,
    //   () => {
    //     requiredValidation("name", routeData.name);
    //   }
    // );
    watch(
        () => routeData.routeSequence,
        () => {
          compareRouteSequenceValidation(
              "routeSequence",
              routeData.fromStoppageId,
              routeData.toStoppageId,
              routeData.routeSequence[0],
              routeData.routeSequence.slice(-1)[0]
          );
        }
    );
    watch(
        () => routeData.fromStoppageId,
        () => {
          if (routeData.fromStoppageId && routeData.toStoppageId) {
            initRouteName()
          }
          requiredValidation("fromStoppageId", routeData.fromStoppageId);
        }
    );
    watch(
        () => routeData.toStoppageId,
        () => {
          if (routeData.fromStoppageId && routeData.toStoppageId) {
            initRouteName()
          }
          requiredValidation("toStoppageId", routeData.toStoppageId);
        }
    );
    watch(
        () => routeData.direction,
        () => {
          requiredValidation("direction", routeData.direction);
        }
    );

    const validate = () => {
      Object.keys(errors).forEach(function (key) {
        errors[key] = "";
      });
      // requiredValidation("name", routeData.name);
      requiredValidation("fromStoppageId", routeData.fromStoppageId);
      requiredValidation("toStoppageId", routeData.toStoppageId);
      requiredValidation("direction", routeData.direction);
      compareRouteSequenceValidation(
          "routeSequence",
          routeData.fromStoppageId,
          routeData.toStoppageId,
          routeData.routeSequence[0],
          routeData.routeSequence.slice(-1)[0]
      );
      return !(
          Object.values(errors).length <= 0 ||
          !Object.values(errors).every((x) => x === null || x === "")
      );
    };
    const closeModal = () => {
      emit("update:modelValue", false);
    };
    const initRouteName = () => {
      routeData.name = store.getters.getStoppageListForFilter[routeData.fromStoppageId] +
          "-" +
          store.getters.getStoppageListForFilter[routeData.toStoppageId];
      routeData.name.trim()
    }
    const addRoute = () => {
      if (routeData.routeSequence.length === 0) {
        routeData.routeSequence.push(routeData.fromStoppageId);
        routeData.routeSequence.push(routeData.toStoppageId);
      }
      if (validate()) {
        showLoader();
        store
            .dispatch("addRoute", {routeData, pagination})
            .then(() => {
              hideLoader();
              closeModal();
            })
            .catch((error) => {
              hideLoader();
              if (error.status === 401) {
                addRoute();
              }
            });
      }
    };
    const updateRoute = () => {
      if (routeData.routeSequence.length === 0) {
        routeData.routeSequence.push(routeData.fromStoppageId);
        routeData.routeSequence.push(routeData.toStoppageId);
      }
      if (validate()) {
        showLoader();
        store
            .dispatch("updateRoute", {routeData, pagination})
            .then(() => {
              hideLoader();
              closeModal();
            })
            .catch((error) => {
              hideLoader();
              if (error.status === 401) {
                updateRoute();
              }
            });
      }
    };

    onBeforeMount(() => {
      Object.keys(errors).forEach(function (key) {
        delete errors[key];
      });
      // Object.entries(props.stoppageList).forEach(el => {
      //   stoppages[el[0]] = el[1];
      // });
      if (routesId.value !== "") {
        const routeDetails = store.getters.getRoute(routesId.value);
        title.value = "Update Route";
        routeData.id = routeDetails[0]._id;
        routeData.fromStoppageId = routeDetails[0].fromStoppageId;
        routeData.toStoppageId = routeDetails[0].toStoppageId;
        routeData.name = routeDetails[0].name;
        routeData.direction = routeDetails[0].direction;
        routeData.routeSequence = routeDetails[0].routeSequence;
        routeData.status = routeDetails[0].status ? 1 : 0;
      }
      // routeData.companyId = companyId.value;
    });

    return {
      routesId,
      routeData,
      errors,
      status,
      title,
      direction,
      addRoute,
      updateRoute,
      closeModal,
    };
  },
};
</script>

<style scoped></style>
