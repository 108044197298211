<template>
  <div class="col-sm-12 col-md-12 col-lg-8 col-xl-8 back-red p-0 px-md-2 mt-2 mt-md-0">
    <div class="card card-body py-4 tableInfo_border" style="background-color: whitesmoke">
      <div class="row">
        <div class="row m-0 p-0">
          <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
            <h5 class="txt_color fs-5 fw-normal fw-bold">
              {{ t("Booking Information", {}, {locale: $store.getters.getLang}) }}
            </h5>
            <div class="row">
              <div class="col">
                <div class="booking_info padding-left">
                  <TicketInputNew
                      :id="'name'"
                      v-model="passenger.name"
                      :icon="'fas fa-file-signature'"
                      :label="'Name'"
                      :placeholder="'Passenger Name'"
                      :type="'text'"
                  />
                  <TicketInputPhoneNew
                      v-bind:id="'mobile'"
                      v-model="passenger.phoneNo"
                      v-bind:label="'Mobile'"
                      v-bind:placeholder="'Passenger Mobile'"
                      v-bind:type="'number'"
                  >
                    <template v-slot:search-btn>
                      <button :disabled="passenger.phoneNo === ''"
                              class="btn btn-primary"
                              @click="searchWithPassengerPhone"
                      >
                        <i class="fa fa-search"/>
                      </button>
                    </template>
                  </TicketInputPhoneNew>

                  <TicketDropdownNew
                      v-bind:id="'passengerGender'"
                      v-model="passenger.gender"
                      :color="'text-danger'"
                      :icon="'fas fa-venus-mars'"
                      v-bind:defaultOption="'--select gender --'"
                      v-bind:defaultValue="''"
                      v-bind:dropdown="dropdownVariables.gender"
                      v-bind:label="'Gender'"
                  >
                  </TicketDropdownNew>
                  <TicketInputNew
                      v-bind:id="'nid'"
                      v-model="passenger.nid"
                      :color="'text-danger'"
                      :icon="'far fa-flag'"
                      v-bind:label="'NID'"
                      v-bind:placeholder="'Passenger NID'"
                      v-bind:type="'number'"
                  />

                  <TicketInputNew
                      v-bind:id="'address'"
                      v-model="passenger.address"
                      :icon="'far fa-address-card'"
                      v-bind:label="'Address'"
                      v-bind:placeholder="' '"
                      v-bind:type="'text'"
                  />
                </div>
                <div class="booking_info d-grid d-md-none">
                  <!-- <p>{{passenger.fromStoppage}}</p> -->

                  <TicketDropdownNew
                      v-bind:id="'fromStoppage'"
                      v-model="passenger.fromStoppage"
                      :color="'text-danger'"
                      :icon="'fas fa-map-marker-alt'"
                      v-bind:defaultOption="'---select a stoppage---'"
                      v-bind:defaultValue="''"
                      v-bind:dropdown="$store.getters.getRouteSequenceFilter"
                      v-bind:label="'From Stoppage'"
                  >
                  </TicketDropdownNew>
                  <TicketDropdownNew
                      v-bind:id="'toStoppage'"
                      v-model="passenger.toStoppage"
                      :color="'text-danger'"
                      :icon="'fas fa-map-marker-alt'"
                      v-bind:defaultOption="'---select a stoppage---'"
                      v-bind:defaultValue="''"
                      v-bind:dropdown="$store.getters.getRouteSequenceFilter"
                      v-bind:label="'To Stoppage'"
                  >
                  </TicketDropdownNew>
                  <TicketDropdownNew
                      v-bind:id="'boardingPlace'"
                      v-model="passenger.boardingPlace"
                      :icon="'fas fa-search-location'"
                      v-bind:defaultOption="'---select a boarding place---'"
                      v-bind:defaultValue="''"
                      v-bind:dropdown="boardingPlaceDropDownFilter"
                      v-bind:label="'Boarding place'"
                  >
                  </TicketDropdownNew>
                  <TicketDropdownNew
                      v-bind:id="'droppingPoint'"
                      v-model="passenger.droppingPoint"
                      :icon="'fas fa-search-location'"
                      v-bind:defaultOption="'---select a dropping point---'"
                      v-bind:defaultValue="''"
                      v-bind:dropdown="droppingPointDropdownFilter"
                      v-bind:label="'Dropping place'"
                  >
                  </TicketDropdownNew>
                </div>
              </div>
              <div class="col d-none d-md-grid">
                <div class="booking_info booking_info_sm_device">
                  <TicketDropdownNew
                      v-bind:id="'fromStoppage'"
                      v-model="passenger.fromStoppage"
                      :color="'text-danger'"
                      :icon="'fas fa-map-marker-alt'"
                      v-bind:defaultOption="'---select a stoppage---'"
                      v-bind:defaultValue="''"
                      v-bind:dropdown="$store.getters.getRouteSequenceFilter"
                      v-bind:label="'From Stoppage'"
                  >
                  </TicketDropdownNew>
                  <TicketDropdownNew
                      v-bind:id="'toStoppage'"
                      v-model="passenger.toStoppage"
                      :color="'text-danger'"
                      :icon="'fas fa-map-marker-alt'"
                      v-bind:defaultOption="'---select a stoppage---'"
                      v-bind:defaultValue="''"
                      v-bind:dropdown="$store.getters.getRouteSequenceFilter"
                      v-bind:label="'To Stoppage'"
                  >
                  </TicketDropdownNew>
                  <TicketDropdownNew
                      v-bind:id="'boardingPlace'"
                      v-model="passenger.boardingPlace"
                      :icon="'fas fa-search-location'"
                      v-bind:defaultOption="'---select a boarding place---'"
                      v-bind:defaultValue="''"
                      v-bind:dropdown="boardingPlaceDropDownFilter"
                      v-bind:label="'Boarding place'"
                  >
                  </TicketDropdownNew>
                  <TicketDropdownNew
                      v-bind:id="'droppingPoint'"
                      v-model="passenger.droppingPoint"
                      :icon="'fas fa-search-location'"
                      v-bind:defaultOption="'---select a dropping place---'"
                      v-bind:defaultValue="''"
                      v-bind:dropdown="droppingPointDropdownFilter"
                      v-bind:label="'Dropping place'"
                  >
                  </TicketDropdownNew>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col col-sm-12 col-md-12 col-lg-12">
          <button
              class="confirm_btn corporate_btn font_detail mx-3 px-3 spacing"
              @click.prevent="purchaseTicket"
          >
            {{ t("Confirm", {}, {locale: $store.getters.getLang}) }}
          </button>
        </div>
      </div>
      <Error v-if="ticketPurchaseError" v-bind:error="ticketPurchaseError"/>
      <Error v-if="errors.boardingPlace" :error="errors.boardingPlace"/>
      <Error v-if="errors.droppingPoint" :error="errors.droppingPoint"/>
      <Error v-if="err" :error="err"/>
    </div>
  </div>
</template>

<script>
import {useI18n} from "vue-i18n";
import permission from "@/modules/permission";
import {computed, onBeforeMount, watch, reactive, inject, ref} from "vue";
import dropdownVariables from "@/modules/dropdownVariables";
import {getFilterBoardingAndDroppingPlace} from '@/composables/counterman/bookingInformation'
import store from "@/store";
import useInputValidation from "@/modules/useInputValidations";
import moment from "moment";
import {getTripFromAndToStoppageGenerate,} from "@/composables/counterman/trips";

export default {
  name: "BookingInformation",
  props: {
    singleTripDetails: Object,
  },
  emits: ['refreshPageGoBack'],
  setup(props, {emit}) {
    const {t, locale} = useI18n();
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    let {requiredValidation, errors} = useInputValidation();
    const {hasPermission} = permission();
    const seatList = computed(() => store.getters.getSelectedSeat)
    const SeatDetails = computed(() => store.getters.getSeatDetails)
    const resetStatus = computed(() => store.getters.getResetStatus)
    const ticketPurchaseError = ref('');
    const loadCounter = ref(0)
    const {
      passenger,
      boardingPlaceDropDownFilter,
      droppingPointDropdownFilter,
      filterBoardingAndDroppingPlace,
      renderSeatListBasedOnStoppageSelect,
      searchWithPassengerPhone,
      err
    } = getFilterBoardingAndDroppingPlace(props.singleTripDetails);

    const {toStoppage, fromStoppage} = getTripFromAndToStoppageGenerate(store.getters.getTripForTicketing)

    onBeforeMount(async () => {
      passenger.fromStoppage = await fromStoppage;
      passenger.toStoppage = await toStoppage;
      loadCounter.value = await loadCounter.value + 1;
      passenger.trip = props.singleTripDetails._id;
      passenger.coach = props.singleTripDetails.coach._id;
    });

    watch(() => passenger, () => {
      store.commit("setPassengerDetails", passenger)
      store.commit("setResetStatus", false)
    }, {deep: true})

    watch(() => passenger.fromStoppage, async () => {
      if (loadCounter.value > 0) {
        await filterBoardingAndDroppingPlace();
        await store.commit("resetTicketDetails")
        await renderSeatListBasedOnStoppageSelect();
      }
    })

    watch(() => passenger.toStoppage, async () => {
      await filterBoardingAndDroppingPlace();
      await store.commit("resetTicketDetails")
      await renderSeatListBasedOnStoppageSelect();
    })

    const refreshPageGoBack = () => {
      emit("refreshPageGoBack")
    }

    const purchaseTicket = () => {
      if (validate()) {
        showLoader()
        let passengerDetails = reactive({
          trip: passenger.trip,
          coach: passenger.coach,
          fromStoppage: passenger.fromStoppage,
          toStoppage: passenger.toStoppage,
          ticketType: "SEAT",
          passengerName: passenger.name,
          passengerPhone: passengerPhoneNumber,
          passengerGender: passenger.gender,
          passengerNID: passenger.nid,
          passengerAddress: passenger.address,
          boardingPlace: passenger.boardingPlace,
          droppingPoint: passenger.droppingPoint,
          farePerTicket: calculateFareTicket().farePerTicket,
          seatClass: props.singleTripDetails.seatPlan.seatClass,
          seatNumbers: seatList.value.map((seat) => seat.seat_no),
          totalAmount: calculateFareTicket().seatTotalAmount,
          discount: SeatDetails.value.ticketDetails.discount,
          payable: SeatDetails.value.ticketDetails.payable,
          farePerSeat: calculateFareTicket().farePerSeat,
          paid: SeatDetails.value.ticketDetails.paid,
          dues: SeatDetails.value.ticketDetails.due,
          refund: SeatDetails.value.ticketDetails.refund,
          referenced_by: null,
          ticketDateTime: moment(new Date())
              .utcOffset(0, true)
              .format("yyyy-MM-DD HH:mm:ss")
        });
        store.dispatch("confirmPassengerTicket", passengerDetails)
            .then((ticket) => {
              store.commit("setPurchasedTicket", ticket)
              store.commit("setPurchaseStatus", true)
              window.location.href = "#ticketPrintPreview";
              store.commit("resetTicketDetails")
              hideLoader();
            })
            .catch(() => {
              store.commit("resetTicketDetails")
              hideLoader();
            });
      }
    }

    const calculateFareTicket = () => {
      let farePerSeat = {};
      let seatTotalAmount = 0;
      let farePerTicket = 0;

      if (store.getters.userCompanyData.fareType === "predefined") {
        SeatDetails.value.seatFare.forEach((seat) => {
          seatTotalAmount = seatTotalAmount + seat.fare;
        });
        //Changed fare value
        if (seatTotalAmount !== SeatDetails.value.ticketDetails.totalAmount) {
          seatTotalAmount = SeatDetails.value.ticketDetails.totalAmount;
        }
        farePerTicket = Math.floor(SeatDetails.value.ticketDetails.payable / SeatDetails.value.seatFare.length)
        SeatDetails.value.seatFare.forEach((seat) => {
          farePerSeat[seat.seat_no] = farePerTicket && farePerTicket !== 0 ? farePerTicket : seat.fare;
        });
      } else if (store.getters.userCompanyData.fareType === "instant_fare") {
        farePerTicket = Math.floor(SeatDetails.value.ticketDetails.payable / SeatDetails.value.selectedSeat.length)
        seatTotalAmount = SeatDetails.value.ticketDetails.totalAmount;
        SeatDetails.value.selectedSeat.forEach((seat) => {
          farePerSeat[seat.seat_no] = farePerTicket;
        });
      } else if (store.getters.userCompanyData.fareType === "trip_fare") {
        farePerTicket = Math.floor(SeatDetails.value.ticketDetails.payable / SeatDetails.value.selectedSeat.length)
        seatTotalAmount = SeatDetails.value.ticketDetails.totalAmount;
        SeatDetails.value.selectedSeat.forEach((seat) => {
          farePerSeat[seat.seat_no] = farePerTicket;
        });
      }
      return {
        'farePerTicket': farePerTicket,
        'farePerSeat': farePerSeat,
        'seatTotalAmount': seatTotalAmount
      };
    }

    watch(() => store.getters.getTripForTicketing, () => {
      if (Object.keys(store.getters.getTripForTicketing).length > 0) {
        const {toStoppage, fromStoppage} = getTripFromAndToStoppageGenerate(store.getters.getTripForTicketing)
        passenger.fromStoppage = fromStoppage.value;
        passenger.toStoppage = toStoppage.value;
        renderSeatListBasedOnStoppageSelect();
      }
    })

    const passengerPhoneNumber = computed(() => {
      if (passenger.phoneNo !== '') {
        return '0' + passenger.phoneNo;
      }
      return passenger.phoneNo;
    })


    const validate = () => {
      Object.keys(errors).forEach(function (key) {
        errors[key] = "";
      });
      // if (passengerPhoneNumber.value === "" || passengerPhoneNumber.value.length !== 11) {
      //   ticketPurchaseError.value = "Mobile no is required and must be valid"
      //   return false
      // }
      if (SeatDetails.value.ticketDetails.payable <= 0 && seatList.value.length <= 0) {
        ticketPurchaseError.value = "May be, You did not Select any seat / Your selected route does not have any fare!";
        return false
      }
      requiredValidation("boardingPlace", passenger.boardingPlace);
      requiredValidation("droppingPoint", passenger.droppingPoint);
      return !(
          Object.values(errors).length <= 0 ||
          !Object.values(errors).every((x) => x === null || x === "")
      );
    };


    watch(() => resetStatus, () => {
      if (store.getters.getResetStatus === true) {
        passenger.fromStoppage = props.singleTripDetails.fromStoppage._id;
        passenger.toStoppage = props.singleTripDetails.toStoppage._id;
        passenger.trip = props.singleTripDetails._id;
        passenger.coach = props.singleTripDetails.coach._id;
        passenger.name = '';
        passenger.phoneNo = '';
        passenger.nid = '';
        passenger.address = '';
        passenger.boardingPlace = '';
        passenger.droppingPoint = '';
        passenger.gender = 'Male';
        passenger.ticketType = "SEAT"
      }
    }, {deep: true})

    return {
      t,
      locale,
      passenger,
      dropdownVariables,
      boardingPlaceDropDownFilter,
      droppingPointDropdownFilter,
      err,
      errors,
      ticketPurchaseError,
      passengerPhoneNumber,
      renderSeatListBasedOnStoppageSelect,
      purchaseTicket,
      refreshPageGoBack,
      searchWithPassengerPhone,
      hasPermission,
    }
  }
}
</script>

<style scoped>
.tableInfo_border {
  border: 1px solid #f04935;
  border-radius: 10px 10px 10px 10px;
  padding: 10px;
  margin-bottom: 10px;
}

.corporate_btn {
  background-color: #00b74a;
  border: 1px solid #ece3e2;
  color: #fff;
}

.booking_btn {
  background-color: red;
  border: 1px solid #ece3e2;
  color: #fff;
}

.booking_btn:hover {
  color: red;
}

.corporate_btn:hover {
  color: #00b74a;
}

.confirm_btn {
  color: #fff;
  border-radius: 50px;
  text-transform: uppercase;
  padding: 8px;
  width: 170px;
}

.confirm_btn:hover {
  background-color: #fff;
}

</style>
