<template>
  <Modal @close="closeModal">
    <template v-slot:title>{{ title }}</template>
    <template v-slot:body>
      <form>
        <div class="form-group row my-1">
          <ModalInput
            v-model="bPlace.name"
            v-bind:placeholder="'Enter Name'"
            v-bind:label="'Name'"
            v-bind:id="'name'"
            v-bind:type="'text'"
          >
            <template v-slot:error>
              <ModalError v-if="errors.name" v-bind:error="errors.name" />
            </template>
          </ModalInput>
        </div>
        <div class="form-group row my-1">
          <ModalCheckCheck
            v-model="bPlace.default"
            v-bind:label="'Default Boarding'"
            v-bind:id="'default-boarding'"
          >
            <!-- <template v-slot:error>
              <Error
                v-if="errors.deckAvailability"
                v-bind:error="errors.deckAvailability"
              />
            </template> -->
          </ModalCheckCheck>
        </div>

        <div class="form-group row my-1">
          <label class="col-sm-3 col-form-label"></label>
          <div class="col-sm-9">
            <input
              v-if="!bpId"
              type="submit"
              class="mt-3 py-2 submit_button font_detail float-end"
              @click.prevent="addBoardingPlace"
            />
            <input
              v-else
              type="submit"
              class="mt-3 py-2 submit_button font_detail float-end"
              @click.prevent="updateBoardingPlace"
            />
          </div>
        </div>
      </form>
    </template>
  </Modal>
</template>

<script>
import dropdownVariables from "@/modules/dropdownVariables";
import useInputValidation from "@/modules/useInputValidations";
import { inject, onBeforeMount, ref, watch } from "vue";
import { reactive } from "@vue/reactivity";
import { useRoute } from "vue-router";
import store from "../../../../../../store";

export default {
  name: "BoardingPlaceAddUpdateModal",
  props: {
    boardingPlace: String,
    stoppage: String,
    boardingPlaceName: String,
    defaultBoarding: Boolean,
  },
  setup(props, { emit }) {
    let { requiredValidation, errors } = useInputValidation();
    let bPlace = reactive({
      stoppageId: "",
      name: "",
      previousName: "",
      newName: "",
      default: false,
    });
    const bpId = ref(props.boardingPlace);
    const bpName = ref(props.boardingPlaceName);
    const sId = ref(props.stoppage);
    const defaultBoardingProp = ref(props.defaultBoarding);
    const route = useRoute();
    const status = dropdownVariables.status;
    const title = ref("Add Boarding Place");
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");

    watch(
      () => bPlace.name,
      () => {
        requiredValidation("name", bPlace.name);
      }
    );

    const validate = () => {
      Object.keys(errors).forEach(function (key) {
        errors[key] = "";
      });
      requiredValidation("name", bPlace.name);
      return !(
        Object.values(errors).length <= 0 ||
        !Object.values(errors).every((x) => x === null || x === "")
      );
    };
    const closeModal = () => {
      emit("update:modelValue", false);
    };
    const addBoardingPlace = () => {
      if (validate()) {
        showLoader();
        store
          .dispatch("addBoardingPlaceForStoppage", { bPlace })
          .then(() => {
            hideLoader();
            closeModal();
          })
          .catch((error) => {
            hideLoader();
            if (error.status === 401) {
              addBoardingPlace();
            }
          });
      }
    };
    const updateBoardingPlace = () => {
      if (validate()) {
        showLoader();
        bPlace.newName = bPlace.name;
        store
          .dispatch("updateBoardingPlaceForStoppage", { bPlace })
          .then(() => {
            hideLoader();
            closeModal();
          })
          .catch((error) => {
            hideLoader();
            if (error.status === 401) {
              updateBoardingPlace();
            }
          });
      }
    };

    onBeforeMount(() => {
      Object.keys(errors).forEach(function (key) {
        delete errors[key];
      });

      if (route.query.id) {
        bPlace.stoppageId = sId.value;
      }

      if (bpId.value !== "") {
        title.value = "Update Boarding Place";
        bPlace.previousName = bpName.value;
        bPlace.name = bPlace.previousName;
        bPlace.default = defaultBoardingProp;
      }
    });

    return {
      bpId,
      bPlace,
      errors,
      status,
      title,
      addBoardingPlace,
      updateBoardingPlace,
      closeModal,
    };
  },
};
</script>

<style scoped></style>
