import axios from "axios";
import Apis from "@/urls/apis";
import tokenCookie from "@/modules/tokenCookie";
import apiResponse from "@/modules/apiResponse";

const {hasValidToken, getToken} = tokenCookie();
const {errorResponse} = apiResponse();

const state = {
    reports: {},
    ticketHistory:{}
};
const getters = {
    getCountermanReport: (state) => state.reports,
    getTicketsHistory: (state) => state.ticketHistory.docs,
    getTicketsHistoryTotalDocs: (state) => state.ticketHistory.totalDocs,
};
const actions = {
    async ticketHistory({commit}, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };

            return new Promise((resolve, reject) => {
                axios.get(Apis.GET_ALL_TICKETS_HISTORY, {
                    params: payload,
                    headers: header
                })
                    .then(({data, status}) => {
                        if (status === 200) {
                            commit("setTickesHistory", data.tickets)
                            resolve(data.tickets)
                        }
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },

    async getAllCountermanReport({commit}, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };

            return new Promise((resolve, reject) => {
                axios.get(Apis.GET_ALL_COUNTERMAN_REPORT, {
                    params: payload,
                    headers: header
                })
                    .then(({data, status}) => {
                        if (status === 200) {
                            commit("setAllCounterManReport", data)
                            resolve(data.ship)
                        }
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },

    async getCountermanTripReport(_, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };

            return new Promise((resolve, reject) => {
                axios.get(Apis.GET_COUNTERMAN_TRIPWISE_REPORT, {
                    params: payload,
                    headers: header
                })
                    .then(({data, status}) => {
                        if (status === 200) {
                            resolve(data)
                        }
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },
};
const mutations = {
    setAllCounterManReport: (state, reports) => (state.reports = reports),
    setTickesHistory: (state, tickets) => (state.ticketHistory = tickets),
};

export default {
    state,
    getters,
    actions,
    mutations
};