<template>
  <Modal @close="closeModal" v-bind:size="'xl'">
    <template v-slot:title>{{ title }}</template>
    <template v-slot:body>
      <form>
        <div class="auth_form py-3">
          <div class="row my-2">
            <div class="col-md-3">
              <Input
                  v-model="companyData.name"
                  v-bind:placeholder="'Enter Company Name'"
                  v-bind:label="'Name'"
                  v-bind:id="'name'"
                  v-bind:type="'text'"
              />
              <Error v-if="errors.name" v-bind:error="errors.name"/>
            </div>
            <div class="col-md-3">
              <Input
                  v-model="companyData.phone"
                  v-bind:placeholder="'Company Number'"
                  v-bind:label="'Company Number'"
                  v-bind:id="'companyNumber'"
                  v-bind:type="'text'"
              />
            </div>
            <div class="col-md-3">
              <Input
                  v-model="companyData.email"
                  v-bind:placeholder="'Email'"
                  v-bind:label="'Email Address'"
                  v-bind:id="'email'"
                  v-bind:type="'text'"
              />
            </div>
            <div class="col-md-3">
              <Input
                  v-model="companyData.address"
                  v-bind:placeholder="'Address'"
                  v-bind:label="'Address'"
                  v-bind:id="'address'"
                  v-bind:type="'text'"
              />
            </div>
            <div class="col-md-3">
              <Input
                  v-model="companyData.contactPerson"
                  v-bind:placeholder="'Enter Contact Person'"
                  v-bind:label="'Contact Person'"
                  v-bind:id="'contactPerson'"
                  v-bind:type="'text'"
              />
            </div>
            <div class="col-md-3">
              <Dropdown
                  v-model="companyData.printerType"
                  v-bind:label="'Print Type'"
                  v-bind:id="'printType'"
                  v-bind:dropdown="printerType"
                  v-bind:hideLabel="'inactive'"
                  v-bind:defaultOption="'Select PrintType'"
                  v-bind:defaultValue="''"
              />
              <Error
                  v-if="errors.printerType"
                  v-bind:error="errors.printerType"
              />
            </div>
            <div class="col-md-3">
              <Input
                  v-model="companyData.maxSeatPerTicket"
                  v-bind:placeholder="'Enter Phone Number'"
                  v-bind:label="'Max seat per ticket'"
                  v-bind:id="'maxSeatPerTicket'"
                  v-bind:type="'number'"
              />
              <Error
                  v-if="errors.maxSeatPerTicket"
                  v-bind:error="errors.maxSeatPerTicket"
              />
            </div>
            <div class="col-md-3">
              <Input
                  v-model="companyData.paymentGatewayRate"
                  v-bind:placeholder="'Enter Payment Gateway Rate'"
                  v-bind:label="'Payment Gateway Rate'"
                  v-bind:id="'paymentGatewayRate'"
                  v-bind:type="'number'"
              />
              <Error
                  v-if="errors.paymentGatewayRate"
                  v-bind:error="errors.paymentGatewayRate"
              />
            </div>
            <div class="col-md-3">
              <Dropdown
                  v-model="companyData.allowFarewiseReport"
                  v-bind:label="'Allow Farewise Report'"
                  v-bind:id="'allowFarewiseReport'"
                  v-bind:dropdown="editAccess"
                  v-bind:hideLabel="'inactive'"
              />
            </div>
            <div class="col-md-3">
              <Input
                  v-model="companyData.hotline"
                  v-bind:placeholder="'Complain Number'"
                  v-bind:label="'Complain Number'"
                  v-bind:id="'hotline'"
                  v-bind:type="'text'"
              />
            </div>
            <div class="col-md-3">
              <Dropdown
                  v-if="status"
                  v-model="companyData.status"
                  v-bind:id="'status'"
                  v-bind:label="'Status'"
                  v-bind:dropdown="status"
                  v-bind:hideLabel="'inactive'"
              />
            </div>
          </div>
        </div>

        <div class="form-group row my-1">
          <label class="col-sm-3 col-form-label"></label>
          <div class="col-sm-9">
            <input
                v-if="!companyId"
                type="submit"
                class="mt-3 py-2 submit_button font_detail float-end"
                @click.prevent="addCompany"
            />
            <input
                v-else
                type="submit"
                class="mt-3 py-2 submit_button font_detail float-end"
                @click.prevent="updateCompany"
            />
          </div>
        </div>
      </form>
    </template>
  </Modal>
</template>

<script>
import {reactive} from "@vue/reactivity";
import dropdownVariables from "@/modules/dropdownVariables";
import useInputValidation from "@/modules/useInputValidations";
import {inject, onBeforeMount, ref, watch} from "vue";
import store from "@/store";

export default {
  name: "CompanyAddUpdateModal",
  props: {
    modalValue: Boolean,
    company: String,
  },
  setup(props, {emit}) {
    let companyData = reactive({
      id: "",
      name: "",
      phone: "",
      email: "",
      address: "",
      contactPerson: "",
      logo: "",
      printerType: "",
      maxSeatPerTicket: 1,
      fareType: "predefined",
      allowFarewiseReport: "0",
      paymentGatewayRate:"",
      hotline: "",
      status: "1",
    });
    let companyId = ref(props.company);
    let {requiredValidation, errors} = useInputValidation();
    const title = ref("Add Company");
    const boolean = dropdownVariables.boolean;
    // const reportByIndividualSeat = dropdownVariables.reportByIndividualSeat;
    // const individualTicketPrint = dropdownVariables.individualTicketPrint;
    const status = dropdownVariables.status;
    const printerType = dropdownVariables.printerType;
    const editAccess = dropdownVariables.editAccess;
    const fareType = dropdownVariables.fareType;
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");

    let pagination = reactive({
      page: 1,
      limit: 25,
    });

    watch(
        () => companyData.name,
        () => {
          requiredValidation("name", companyData.name);
        }
    );
    watch(
        () => companyData.maxSeatPerTicket,
        () => {
          requiredValidation(
              "maxSeatPerTicket",
              companyData.maxSeatPerTicket.toString()
          );
        }
    );
    watch(
        () => companyData.printerType,
        () => {
          requiredValidation("printerType", companyData.printerType);
        }
    );

    const closeModal = () => {
      emit("update:modelValue", false);
    };
    const validate = () => {
      Object.keys(errors).forEach(function (key) {
        errors[key] = "";
      });
      requiredValidation("name", companyData.name);
      requiredValidation("printerType", companyData.printerType);
      requiredValidation("fareType", companyData.fareType);
      requiredValidation(
          "maxSeatPerTicket",
          companyData.maxSeatPerTicket.toString()
      );
      return !(
          Object.values(errors).length <= 0 ||
          !Object.values(errors).every((x) => x === null || x === "")
      );
    };
    const addCompany = () => {
      if (validate()) {
        showLoader();
        store
            .dispatch("addCompany", {companyData, pagination})
            .then(() => {
              emit("getAllCompanies");
              hideLoader();
              closeModal();
            })
            .catch((error) => {
              hideLoader();
              if (error.status === 401) {
                addCompany();
              }
            });
      }
    };
    const updateCompany = () => {
      if (validate()) {
        showLoader();
        store
            .dispatch("updateCompany", {companyData, pagination})
            .then(() => {
              emit("getAllCompanies");
              hideLoader();
              closeModal();
            })
            .catch((error) => {
              hideLoader();
              if (error.status === 401) {
                updateCompany();
              }
            });
      }
    };

    onBeforeMount(() => {
      Object.keys(errors).forEach(function (key) {
        delete errors[key];
      });
      if (companyId.value !== "") {
        const companyDetails = store.getters.getCompany(companyId.value);
        title.value = "Update Company";
        companyData.id = companyDetails[0]._id;
        companyData.name = companyDetails[0].name;
        companyData.phone = companyDetails[0].phone;
        companyData.email = companyDetails[0].email;
        companyData.address = companyDetails[0].address;
        companyData.contactPerson = companyDetails[0].contactPerson;
        companyData.logo = companyDetails[0].logo;
        companyData.printerType = companyDetails[0].printerType;
        companyData.maxSeatPerTicket = companyDetails[0].maxSeatPerTicket;
        companyData.fareType = companyDetails[0].fareType;
        companyData.paymentGatewayRate = companyDetails[0].paymentGatewayRate;
        companyData.allowFarewiseReport = companyDetails[0].allowFarewiseReport
            ? 1
            : 0;
        companyData.hotline = companyDetails[0].hotline;
        companyData.status = companyDetails[0].status ? 1 : 0;
      }
    });

    return {
      companyData,
      status,
      title,
      errors,
      companyId,
      boolean,
      printerType,
      editAccess,
      fareType,
      addCompany,
      updateCompany,
      closeModal,
    };
  },
};
</script>

<style scoped></style>
