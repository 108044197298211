<template>
  <!-- <div>
    <Filter>
      <template v-slot:body>
        <div class="col-sm-6 col-md-2">
          <DatePickerFilter
            v-bind:id="'datePickerDate'"
            v-bind:label="'Date'"
            v-model="pagination.date"
          />
        </div>
        <div class="col-sm-6 col-md-2">
          <DropdownFilter
            v-bind:id="'status'"
            v-bind:label="'Status'"
            v-bind:defaultOption="'--select status--'"
            v-model="pagination.status"
            v-bind:defaultValue="''"
            v-bind:dropdown="dropdownVariables.status"
          />
        </div>
      </template>
    </Filter> -->
  <div v-if="hasPermission(Counterman.coach)">
    <div class="p-2 position-relative d-md-none">
      <!-- Table Data Pannel -->
      <div>
        <div
          class="card w-100 my-2"
          v-for="coach in $store.getters.getAllCoachesCounter"
          :key="coach._id"
        >
          <div class="card-body position-relative">
            <div class="row">
              <div class="col-12 mb-2">
                <!-- <span
                  v-if="coach.status"
                  class="badge bg-success position-absolute top-0 end-0 m-1"
                  >{{ dropdownVariables.status[1] }}</span
                >
                <span
                  v-else
                  class="badge bg-danger position-absolute top-0 end-0 m-1"
                  >{{ dropdownVariables.status[0] }}</span
                > -->
              </div>
              <div class="col-6">
                <p>
                  <b
                    >{{ t("NAME", {}, { locale: $store.getters.getLang }) }}:</b
                  >
                  {{ coach?.name }}
                </p>
              </div>
              <div class="col-6">
                <p>
                  <b
                    >{{
                      t(
                        "REGISTRATION_NUMBER",
                        {},
                        { locale: $store.getters.getLang }
                      )
                    }}:</b
                  >
                  {{ coach?.registrationNumber }}
                </p>
              </div>
              <div class="col-6">
                <p>
                  <b
                    >{{
                      t("SEAT_PLAN", {}, { locale: $store.getters.getLang })
                    }}:</b
                  >
                  {{ coach?.seatPlanId?.name }}
                </p>
              </div>
              <div class="col-6">
                <p>
                  <b
                    >{{ t("STATUS", {}, { locale: $store.getters.getLang }) }}:
                  </b>
                  <span v-if="coach.status" class="badge bg-success">{{
                    dropdownVariables.status[1]
                  }}</span>
                  <span v-else class="badge bg-danger">{{
                    dropdownVariables.status[0]
                  }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Table class="d-none d-md-block" v-if="$store.getters.getAllCoachesCounter">
      <template v-slot:header>
        <tr>
          <td class="font_detail">
            {{ t("SL", {}, { locale: $store.getters.getLang }) }}
          </td>
          <td class="font_detail">
            {{ t("NAME", {}, { locale: $store.getters.getLang }) }}
          </td>
          <td class="font_detail">
            {{
              t("REGISTRATION_NUMBER", {}, { locale: $store.getters.getLang })
            }}
          </td>
          <td class="font_detail">
            {{ t("SEAT_PLAN", {}, { locale: $store.getters.getLang }) }}
          </td>
          <td class="font_detail">
            {{ t("STATUS", {}, { locale: $store.getters.getLang }) }}
          </td>
        </tr>
      </template>
      <template v-slot:body>
        <tr
          v-for="(coach, index) in $store.getters.getAllCoachesCounter"
          :key="index"
        >
          <td class="font_detail">{{ index + 1 }}</td>
          <td class="font_detail">{{ coach?.name }}</td>
          <td class="font_detail">{{ coach?.registrationNumber }}</td>
          <td class="font_detail">{{ coach?.seatPlanId?.name }}</td>
          <td>
            <span v-if="coach.status" class="badge bg-success">{{
              dropdownVariables.status[1]
            }}</span>
            <span v-else class="badge bg-danger">{{
              dropdownVariables.status[0]
            }}</span>
          </td>
        </tr>
      </template>
      <template v-slot:pagination>
        <pagination
          v-model="pagination.page"
          :records="$store.getters.getTotalCoachesCounter"
          :per-page="pagination.limit"
          @paginate="getAllCoachCounter"
          :options="options"
        />
      </template>
    </Table>
  </div>
  <!-- </div> -->
</template>

<script>
import { onBeforeMount, inject, watch } from "vue";
import store from "@/store";
import moment from "moment";
import { reactive } from "@vue/reactivity";
import dropdownVariables from "@/modules/dropdownVariables";
import router from "@/router";
import web from "@/urls/web";
import permission from "@/modules/permission";
import roles from "@/modules/roles";

const { hasPermission } = permission();
const { Counterman } = roles();

import { useI18n } from "vue-i18n";
export default {
  name: "Coach",

  setup() {
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    const { t, locale } = useI18n();
    let pagination = reactive({
      page: 1,
      limit: 25,
      date: moment(new Date()).format("yyyy-MM-DD"),
      status: "1",
    });

    const options = {
      texts: {
        count: "",
      },
    };

    watch(
      () => store.getters.userRoles,
      (value) => {
        if (!value.includes(Counterman.coach)) {
          router
            .push(web.Default.LOGIN_URL.path)
            .catch((error) => console.log(error));
        }
      }
    );
    watch(
      () => pagination.status,
      () => {
        getAllCoachCounter();
      }
    );
    watch(
      () => pagination.date,
      () => {
        getAllCoachCounter();
      }
    );

    const getAllCoachCounter = () => {
      showLoader();
      store
        .dispatch("getAllCoachCounter", pagination)
        .then(() => {
          hideLoader();
        })
        .catch((error) => {
          hideLoader();
          if (error.status === 401) {
            getAllCoachCounter();
          }
        });
    };

    onBeforeMount(() => {
      getAllCoachCounter();
    });

    return {
      t,
      locale,
      pagination,
      dropdownVariables,
      options,
      Counterman,
      hasPermission,
      getAllCoachCounter,
    };
  },
};
</script>
<style scoped>
.card-body p {
  font-size: 12px;
  margin-bottom: 5px;
}
</style>
