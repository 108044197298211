<template>
  <!-- <div class="large_device_view"> -->
  <div>
    <section class="select_area py-1">
      <!-- Searching menu -->
      <div class="text-white text-center bold">
        <slot name="title"></slot>
      </div>
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12 col-md-9 col-lg-9">
            <div class="row font_detail">
              <slot name="body"></slot>
            </div>
          </div>
          <div class="col-sm-12 col-md-3 col-lg-3">
            <div class="row button_section justify-content-end">
              <!--                            <div class="col-sm-4 col-md-6 col-lg-4 order-md-2 order-sm-3 order-lg-3 p-1 d-flex justify-content-end"><slot name="button1"></slot></div>-->
              <!--                            <div class="col-sm-4 col-md-6 col-lg-4 order-md-1 order-sm-2 order-lg-2 p-1 d-flex justify-content-end"><slot name="button2"></slot></div>-->
              <!--                            <div class="col-sm-4 col-md-6 col-lg-4 order-md-3 order-sm-1 order-lg-1 p-1 d-flex justify-content-end"><slot name="button3"></slot></div>-->
              <div class="col-auto order-md-2 order-sm-3 order-lg-3 p-1">
                <slot name="button1"></slot>
              </div>
              <div class="col-auto order-md-1 order-sm-2 order-lg-2 p-1">
                <slot name="button2"></slot>
              </div>
              <div class="col-auto order-md-3 order-sm-1 order-lg-1 p-1">
                <slot name="button3"></slot>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "FilterTab",
};
</script>

<style scoped>
.bold {
  font-weight: bold;
}

.button_section {
  padding-top: 13px;
  margin: 2px 0;
}
</style>