import store from "@/store";

const seatFareCalculate = (tripDetails, seatNo = "", classId = "") => {
    let seatFare = [];
    let farePerSeat = [];
    //let selectedSeat = store.getters.selectedSeat;
    let ticketDetails = store.getters.getTicketDetails;
    let passengerDetails = store.getters.getPassengerDetails;

    const fareType = store.getters.userCompanyData.fareType;
    if (fareType === "trip_fare") {
        console.log("trip_fare")
        seatFare = [parseInt(tripDetails.tripFare)]
    } else if (fareType === "predefined") {
        console.log("predefined")
        tripDetails.fare?.fareModality.forEach((stoppage) => {
            let passengerFromStoppage = passengerDetails.fromStoppage;
            let passengerToStoppage = passengerDetails.toStoppage;
            if (stoppage.fromStoppageId === passengerFromStoppage && stoppage.toStoppageId === passengerToStoppage) {
                stoppage.fare.forEach((fare) => {
                    if (fare.class_id === classId && fare.fare > 0) {
                        seatFare.push({
                            seat_no: seatNo,
                            fare: fare.fare,
                        });
                        if (seatNo !== "") {
                            farePerSeat[seatNo] = fare.fare;
                        }
                    }
                });
            }
        });
    } else if (fareType === "instant_fare") {
        console.log("instant_fare")
        seatFare = [parseInt(ticketDetails.inputFare)]
    }

    return {
        seatFare: seatFare,
        farePerSeat: farePerSeat
    };
}

export {
    seatFareCalculate
}
