<template>
  <div>
    <FilterTab>
      <template v-slot:body>
        <div class="col-sm-6 col-md-2"></div>
      </template>
      <template v-slot:button2>
        <button class="edit_table_data font_detail" @click="goBack">
          <i class="fas fa-arrow-left"></i
          ><span class="mx-1 bold">Go Back</span>
        </button>
      </template>
      <!--      <template v-slot:button1>-->
      <!--        <button-->
      <!--          class="edit_table_data font_detail"-->
      <!--          @click="exportTable('xlsx')"-->
      <!--        >-->
      <!--          <i class="fas fa-download"></i><span class="mx-1 bold">Export</span>-->
      <!--        </button>-->
      <!--      </template>-->
    </FilterTab>
  </div>
  <div
      class="card h-100 mt-4"
      v-for="(itemValue, itemKey) in dtReports"
      :key="itemKey"
  >
    <div class="card-header text-center bg-white" v-if="dtReports && payload.type==='intercity'">
      <div class="row">
        <div class="col-6">
          <h5 class="mb-0 text-uppercase">
            Trip Code: {{ itemValue.trip.tripCode }}
          </h5>
        </div>
        <div class="col-6">
          <h5 class="mb-0 text-uppercase">
            Trip Date Time: {{ tripDateFormat(itemValue.trip.tripDateTime) }}
          </h5>
        </div>
      </div>
    </div>

    <div class="card-body mt-0 py-0" v-if="dtReports && payload.type==='intercity'">
      <table-simple :id="itemKey">
        <template v-slot:header>
          <tr>
            <td class="font_detail">SL</td>
            <td class="font_detail">Ticket Date Time</td>
            <td class="font_detail">Coach Name</td>
            <td class="font_detail">PNR Code</td>
            <td class="font_detail">TXN ID</td>
            <td class="font_detail">Passenger Name</td>
            <td class="font_detail">Passenger Phone</td>
            <td class="font_detail">Boarding Place</td>
            <td class="font_detail">Dropping Place</td>
            <td class="font_detail">Seat Numbers</td>
            <td class="font_detail">Total Ticket</td>
            <td class="font_detail">Total Amount</td>
            <td class="font_detail">Verified By</td>
            <td class="font_detail" v-if="payload.status === 0">Cancelled By</td>
            <td class="font_detail" v-if="payload.status === 0">
              Cancellation Cause
            </td>
            <td class="font_detail">Action</td>
          </tr>
        </template>
        <template v-slot:body>
          <tr v-for="(item, index) in itemValue.totalReport" :key="index">
            <td class="font_detail">
              {{ index + 1 }}
            </td>
            <td class="font_detail">{{ dateFormat(item?.ticketDateTime) }}</td>

            <td class="font_detail">{{ item?.coach?.name }}</td>
            <td class="font_detail">{{ item?.pnrCode }}</td>
            <td class="font_detail">{{ item?.transactionId }}</td>
            <td class="font_detail">{{ item?.passengerName }}</td>
            <td class="font_detail">{{ item?.passengerPhone }}</td>
            <td class="font_detail">{{ item?.boardingPlace }}</td>
            <td class="font_detail">{{ item?.droppingPoint }}</td>
            <td class="font_detail">
              {{ item?.seatNumbers.toString() }}
            </td>

            <td class="font_detail">{{ item?.totalSeat }}</td>
            <td class="font_detail">
              {{ item?.payable }}
            </td>
            <td class="font_detail">{{ item?.issuedBy?.name }}</td>
            <td class="font_detail" v-if="payload.status === 0">
              {{ item?.cancelledBy?.name }}
            </td>
            <td class="font_detail" v-if="payload.status === 0">
              {{ item?.cancellationCause }}
            </td>
            <td class="font_detail">
              <button
                  class="edit_table_data font_detail edit_table_button_update"
                  @click="cancelOnlineTicket(item?._id)"
              >
                <i class="fas fa-trash"></i><span class="mx-1">Cancel</span>
              </button>
            </td>
          </tr>
          <tr class="bg-dark text-white">
            <td class="font_detail"></td>
            <td class="font_detail"></td>
            <td class="font_detail"></td>
            <td class="font_detail"></td>
            <td class="font_detail"></td>
            <td class="font_detail"></td>
            <td class="font_detail"></td>
            <td class="font_detail"></td>
            <td class="font_detail"></td>
            <td class="font_detail"></td>
            <td class="font_detail text-white">`
              Total Ticket: {{ itemValue.totalTicket }}
            </td>
            <td class="font_detail text-white">
              Total Amount: {{ itemValue.totalAmount }} Taka
            </td>
            <td class="font_detail"></td>
            <td class="font_detail" v-if="payload.status === 0"></td>
            <td class="font_detail" v-if="payload.status === 0"></td>
            <td class="font_detail"></td>
          </tr>
        </template>
      </table-simple>
    </div>

    <div class="card-body mt-0 py-0" v-if="dtReports && payload.type==='sd_intercity'">
      <table-simple :id="itemKey">
        <template v-slot:header>
          <tr>
            <td class="font_detail">SL</td>
            <td class="font_detail">Ticket Date Time</td>
            <!--            <td class="font_detail">Coach Name</td>-->
            <td class="font_detail">PNR Code</td>
            <td class="font_detail">TXN ID</td>
            <td class="font_detail">Passenger Name</td>
            <td class="font_detail">Passenger Phone</td>
            <td class="font_detail">Boarding Place</td>
            <td class="font_detail">Dropping Place</td>
            <td class="font_detail">Fare Per Ticket</td>
            <td class="font_detail">Total Ticket</td>
            <td class="font_detail">Total Amount</td>
            <td class="font_detail">Verified By</td>
            <td class="font_detail" v-if="payload.status === 0">Cancelled By</td>
            <td class="font_detail" v-if="payload.status === 0">
              Cancellation Cause
            </td>
          </tr>
        </template>
        <template v-slot:body>
          <tr v-for="(item, index) in itemValue.totalReport" :key="index">
            <td class="font_detail">
              {{ index + 1 }}
            </td>
            <td class="font_detail">{{ dateFormat(item?.ticketDateTime) }}</td>
            <td class="font_detail">{{ item?.pnrCode }}</td>
            <td class="font_detail">{{ item?.transactionId }}</td>
            <td class="font_detail">{{ item?.passengerName }}</td>
            <td class="font_detail">{{ item?.passengerPhone }}</td>
            <td class="font_detail">{{ item?.fromStoppage }}</td>
            <td class="font_detail">{{ item?.toStoppage }}</td>
            <td class="font_detail">{{ item?.farePerTicket }}</td>
            <td class="font_detail">{{ item?.totalTickets }}</td>
            <td class="font_detail">
              {{ item?.payable }}
            </td>
            <td class="font_detail">{{ item?.issuedBy?.name }}</td>
            <td class="font_detail" v-if="payload.status === 0">
              {{ item?.cancelledBy?.name }}
            </td>
            <td class="font_detail" v-if="payload.status === 0">
              {{ item?.cancellationCause }}
            </td>
          </tr>
          <tr class="bg-dark text-white">
            <td class="font_detail"></td>
            <td class="font_detail"></td>
            <td class="font_detail"></td>
            <td class="font_detail"></td>
            <td class="font_detail"></td>
            <td class="font_detail"></td>
            <td class="font_detail"></td>
            <td class="font_detail"></td>
            <td class="font_detail"></td>
            <td class="font_detail text-white">
              Total Ticket: {{ itemValue.totalTicket }}
            </td>
            <td class="font_detail text-white">
              Total Amount: {{ itemValue.totalAmount }} Taka
            </td>
            <td class="font_detail"></td>
            <td class="font_detail" v-if="payload.status === 0"></td>
            <td class="font_detail" v-if="payload.status === 0"></td>
          </tr>
        </template>
      </table-simple>
    </div>
  </div>
</template>

<script>
// import { reactive } from "@vue/reactivity";
import {ref, onBeforeMount, inject, computed} from "vue";
import store from "@/store";
import moment from "moment";
// import TableSimple from "@/components/layouts/Tables/TableSimple.vue";

export default {
  name: "DtReportDetails",
  // components: { TableSimple },
  props: {
    tripWiseDtReport: Object
  },
  emits: ["backToDtReport"],
  setup(props, {emit}) {
    let payload = ref(props.tripWiseDtReport);
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    // const exportExcel = inject("exportExcel");
    let dtReports = computed(()=>{
      if (payload.value.type === 'intercity') {
        return classifyByTripForIntercity(store.getters.getDtReportDetailsOwner)
      } else {
        return ticketsForSdIntercity(store.getters.getDtReportDetailsOwner)
      }
    })

    const options = {
      texts: {
        count: "",
      },
    };
    const dateFormat = (date) => {
      return moment(new Date(String(date)))
          .utcOffset(0, true)
          .format("DD-MMM-yyyy hh:mm A");
    };

    const tripDateFormat = (date) => {
      return moment(new Date(String(date)))
          .utcOffset(0, false)
          .format("DD-MMM-yyyy hh:mm A");
    };

    const getDtReportDetails = () => {
      showLoader();
      store.dispatch("getDtReportOwnerDetails", payload.value).then((response) => {
        hideLoader();
        if (payload.value.type === 'intercity') {
          classifyByTripForIntercity(response)
        } else {
          ticketsForSdIntercity(response)
        }
      }).catch((error) => {
        hideLoader();
        if (error.status === 401) {
          getDtReportDetails();
        }
      });
    };

    const cancelOnlineTicket = (ticketId) => {
      showLoader();
      store.dispatch("cancelOnlineTicketForOwner", {
        ticket: ticketId
      }).then(() => {
        hideLoader();
        // if (payload.value.type === 'intercity') {
        //   classifyByTripForIntercity(response)
        // } else {
        //   ticketsForSdIntercity(response)
        // }
      }).catch((error) => {
        hideLoader();
        if (error.status === 401) {
          cancelOnlineTicket();
        }
      });
    };

    const ticketsForSdIntercity = (reports) => {
      let reportParse = {}
      reports.forEach((report) => {
        if (report.company in reportParse) {
          reportParse[report.company].totalReport.push(report);
          reportParse[report.company].totalTicket += report.totalTickets
          reportParse[report.company].totalAmount += report.payable
        } else {
          reportParse[report.company] = {
            totalReport: [],
            totalTicket: report.totalTickets,
            totalAmount: report.payable,
          };
          reportParse[report.company].totalReport.push(report);
        }
      });
    }

    const classifyByTripForIntercity = (reports) => {
      let reportParse = {}
      reports.forEach((report) => {
        if (report.trip._id in reportParse) {
          reportParse[report.trip._id].totalReport.push(report);
        } else {
          reportParse[report.trip._id] = {
            totalReport: [],
            totalTicket: 0,
            totalAmount: 0,
            trip: report.trip,
          };
          reportParse[report.trip._id].totalReport.push(report);
        }
      });
      totalCalculation(reportParse);
      return reportParse;
    };

    const totalCalculation = (reportParse) => { //TODO typo
      for (const key in reportParse) {
        reportParse[key].totalReport.forEach((data) => {
          reportParse[key].totalAmount += data.payable
          reportParse[key].totalTicket += data.totalSeat;
          // dtReports[key].totalDiscount += data.discount;
        });
      }
    };

    const goBack = () => {
      emit("backToDtReport", true);
    };

    // const exportTable = (type) => {
    //   for (const key in dtReports) {
    //     exportExcel(
    //       type,
    //       key + "_Admin_Intercity_DT_Report.",
    //       undefined,
    //       key
    //     );
    //   }
    // };

    onBeforeMount(() => {
      getDtReportDetails();
    });

    return {
      dtReports,
      payload,
      goBack,
      cancelOnlineTicket,
      getDtReportDetails,
      options,
      dateFormat,
      tripDateFormat
    };
  },
};
</script>
<style scoped>
h3,
h5 {
  font-size: 36px;
  color: #f04935;
}

h5 {
  font-size: 18px;
}
</style>
