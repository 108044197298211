<template>
  <Table
    id="exportable_data"
    v-if="$store.getters.getPrintTripWiseCountList.length > 0"
  >
    <template v-slot:header>
      <tr>
        <td class="font_detail">SL</td>
        <td class="font_detail">Counterman Name</td>
        <td class="font_detail">Trip Datetime</td>
        <td class="font_detail">Print Count</td>
      </tr>
    </template>
    <template v-slot:body>
      <tr v-for="(item, index) in tripWisePrintList" :key="index">
        <td class="font_detail">{{ index + 1 }}</td>
        <td class="font_detail">
          {{ item?.counterman }}
        </td>
        <td class="font_detail">
          {{ dateFormat(item?.tripDateTime) }}
        </td>
        <td class="font_detail">
          {{ item?.printCount }}
        </td>
      </tr>
      <!-- <tr class="bg-dark text-white"  >
        <td class="font_detail"></td>
        <td class="font_detail"></td>
        <td class="font_detail"></td>
        <td class="font_detail"></td>
        <td class="font_detail"></td>
        <td class="font_detail"></td>
      </tr> -->
    </template>
    <!-- <template v-slot:pagination>
      <Pagination
        v-model="shipWiseSeatReport.page"
        :records="$store.getters.getShipWiseSeatDetailsOwner.length"
        :per-page="shipWiseSeatReport.limit"
        @paginate="getSeatDetails"
        :options="options"
      />
    </template> -->
  </Table>
</template>

<script>
import moment from "moment";
import store from "@/store";
import { computed } from "vue";
export default {
  name: "tripWiseTicketPrintCountDetails",
  setup() {
    const dateFormat = (date) => {
      return moment(new Date(String(date)))
        .utcOffset(0, false)
        .format("DD-MMM-yyyy hh:mm:ss A");
    };
    let tripWisePrintList = computed(() => {
      return store.getters.getPrintTripWiseCountList.filter((item) => {
        return item.printCount > 0;
      });
    });
    return {
      dateFormat,
      tripWisePrintList,
    };
  },
};
</script>

<style></style>
