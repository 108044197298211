<template>
  <div class="table-responsive">
    <table
      class="table table-hover table-striped text-center vehicle_table mb-1"
    >
      <thead class="font_detail bg_corporate">
        <slot name="header"></slot>
      </thead>
      <tbody>
        <slot name="body"></slot>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "Table",
};
</script>

<style scoped>
</style>