
export default function roles() {
    const Admin = {
        "dashboard": 1,
        "reports": 2,
        "adminRead": 3,
        "adminWrite": 4,
        "companyDeployRead": 5,
        "companyDeployWrite": 6
    };
    const Owner = {
        "ownerDashboard": 51,
        "ownerReports": 52,
        "ownerCompanySetupRead": 53,
        "ownerCompanySetupWrite": 54,
        "ownerTripRead": 55,
        "ownerTripWrite": 56,
        "ownerRead": 57,
        "ownerWrite": 58,
    };
    const Counterman = {
        "ticketBooking": 101,
        "fareDiscount": 102,
        "reports": 103,
        "trips": 104,
        "coach": 105,
        "fares": 106,
        "ticketCancel": 107,
        "cancelOthersTicket": 108,
        "tripEdit": 112,
    };
    return {
        Admin,Owner,Counterman
    };
}
//1-49 for Admin
//50-99 for owner
//100-149 for Counterman
