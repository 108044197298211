<template>
  <div>
    <div v-if="addUpdate"></div>
    <div v-else-if="printPreview">
      <TicketPrintPreview
          v-if="
          ticketTypeForPrint === 'lastTicket' ||
          ticketTypeForPrint === 'pnrTicket' ||
          ticketTypeForPrint === 'ticket'"
          v-bind:ticket="lastTicketDetails"
          v-bind:ticketType="ticketTypeForPrint"
          @close="closePrintPreview"
      />
      <TripReportPrintPreview
          v-else-if="ticketTypeForPrint === 'tripReport'"
          :detailsTrip="propTripDetails"
          :printCount="printCount"
          :tripId="trip"
          v-bind:ticketType="ticketTypeForPrint"
          v-bind:trip="userTripReport"
          @close="closePrintPreview"
      />
    </div>
    <div
        v-else
        class="card card-body py-4"
        style="background-color: whitesmoke"
    >
      <div class="row">
        <!-- Seat Information Start -->
        <div v-if="!ticketPurchased" class="row m-0 p-0">
          <SeatSummery/>
          <SeatInformation :singleTripDetails="singleTripDetails"/>
          <div
              class="col-sm-12 col-md-12 col-lg-1 col-xl-1 order-md-2 order-xl-3"
          >
            <span class="fw-bold">{{
                t("PRINT", {}, {locale: $store.getters.getLang})
              }}</span>
            <div class="card card-body">
              <div class="row">
                <div class="col-6 col-md-12">
                  <p class="text-center font_detail m-0">
                    {{
                      t("TRIP REPORT", {}, {locale: $store.getters.getLang})
                    }}
                  </p>
                  <button class="trip_button w-100" @click="tripReport(trip)">
                    <i class="fas fa-print"></i>
                  </button>
                </div>
                <div class="col-6 col-md-12">
                  <p class="text-center font_detail m-0">
                    {{
                      t("LAST TICKET", {}, {locale: $store.getters.getLang})
                    }}
                  </p>
                  <button class="last_ticket w-100" @click="lastTicket(trip)">
                    <i class="fas fa-print"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
              class="col-sm-12 col-md-12 col-lg-2 col-xl-2 order-md-3 order-xl-4"
          >
            <span class="fw-bold">{{
                t("ACTIONS", {}, {locale: $store.getters.getLang})
              }}</span>
            <div class="card card-body">
              <div class="row">
                <div
                    v-if="hasPermission(Counterman.tripEdit)"
                    class="col-4 col-md-6"
                >
                  <p class="text-center font_detail m-0">
                    {{
                      t("UPDATE TRIP", {}, {locale: $store.getters.getLang})
                    }}
                  </p>
                  <button class="update_trip_button w-100" @click="updateTrip">
                    <i class="fas fa-edit"></i>
                  </button>
                </div>
                <div
                    v-if="
                    $store.getters.allowPass &&
                    hasPermission(Counterman.tripPass)
                  "
                    class="col-4 col-md-6"
                >
                  <p class="text-center font_detail m-0">
                    {{ t("TRIP PASS", {}, {locale: $store.getters.getLang}) }}
                  </p>
                  <button class="trip_pass_button w-100" @click="tripPass">
                    <i class="fas fa-location-arrow"></i>
                  </button>
                </div>

                <div class="col-4 col-md-6">
                  <p class="text-center font_detail m-0">
                    {{ t("RESET", {}, {locale: $store.getters.getLang}) }}
                  </p>
                  <button class="remove_button w-100" @click="reset">
                    <i class="fas fa-trash-alt"></i>
                  </button>
                </div>
                <div class="col-4 col-md-6">
                  <p class="text-center font_detail m-0">
                    {{ t("REFRESH", {}, {locale: $store.getters.getLang}) }}
                  </p>
                  <button
                      class="refresh_button w-100"
                      @click="refreshPageGoBack()"
                  >
                    <i class="fas fa-sync-alt"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Seat Information End -->
        <div id="ticketPrintPreview" class="row m-0 mt-2">
          <div v-if="$store.getters.getTicketPurchasedDetails.purchaseStatus" class="row m-0">
            <TicketPrintPreview
                :ticket="$store.getters.getTicketPurchasedDetails.purchasedTicket"
                :ticketType="'ticket'"
                @close="close"
            />
          </div>
          <SeatList v-if="!ticketPurchased" :singleTripDetails="singleTripDetails" @openGlobalFilter="openGlobalFilter">
            <template v-if="!ticketPurchased" v-slot:seatinfo>
              <SeatInformationMobile :singleTripDetails="singleTripDetails"/>
            </template>
          </SeatList>
          <BookingInformation v-if="!ticketPurchased" :singleTripDetails="singleTripDetails"
                              @refreshPageGoBack="refreshPageGoBack"/>
        </div>
      </div>
    </div>
  </div>
  <PnrTicket v-if="pnrModal" v-model="pnrModal" @pnrPrint="pnrPrint"/>
  <UpdateTrip v-if="updateTripModal" v-model="updateTripModal" :tripId="trip"/>
</template>

<script>
import {computed, inject, onBeforeMount, ref, watch,} from "vue";
import SeatList from "./SeatList";
import PnrTicket from "./PnrModal.vue";
import UpdateTrip from "./UpdateTripModal.vue";
import {reactive} from "@vue/reactivity";
import fareModality from "@/modules/fareModality";
import store from "@/store";
import dropdownVariables from "../../../../modules/dropdownVariables";
// import toast from "@/modules/Toast";
import permission from "@/modules/permission";
import roles from "@/modules/roles";
import {useI18n} from "vue-i18n";

// const { errorToast } = toast();
const {hasPermission} = permission();
const {Counterman} = roles();

const {getNetFare} = fareModality();
import moment from "moment";
import SeatSummery from "@/components/counterman/SeatSummery";
import SeatInformation from "@/components/counterman/SeatInformation";
import BookingInformation from "@/components/counterman/BookingInformation";
import SeatInformationMobile from "@/components/counterman/SeatInformationMobile";

export default {
  name: "TicketBooking",
  components: {
    SeatInformationMobile,
    BookingInformation,
    SeatInformation,
    SeatSummery,
    SeatList,
    PnrTicket,
    UpdateTrip,
  },
  props: {
    tripId: String,
    shouldRefreshPageGoBack: Boolean,
    tripDetails: Object,
    paginationData: Object,
  },
  emits: ["openGlobalFilter"],

  setup(props, {emit}) {
    const propsPaginationData = ref(props.paginationData);
    const propTripDetails = ref(props.tripDetails);
    const trip = ref(props.tripId);
    const addUpdate = ref(false);
    const printPreview = ref(false);
    const tripIdForPrint = ref("");
    const ticketTypeForPrint = ref("");
    const userTripReport = ref([]);
    const lastTicketDetails = ref({});
    const {t, locale} = useI18n();
    let discountErrorMsg = ref("");
    let totalBookedSeatCount = ref(0);
    let totalBlockedSeatCount = ref(0);
    let totalAvailableSeatCount = ref(0);
    let printCount = ref(0);
    let fromStoppage = ref("");
    let toStoppage = ref("");
    let singleTripDetails = ref({});
    let seats = ref([]);
    let fare = ref("");
    let inputFare = ref("");
    let resetAllData = ref(0);
    let fareArray = ref([]);
    let extraTicketModalName = ref("");
    let extraTicketType = ref("");
    let ticketExtraModal = ref(false);
    let tripPassModal = ref(false);
    let pnrModal = ref(false);
    let updateTripModal = ref(false);
    let calculateDue = ref(0);
    let calculateRefund = ref(0);
    let ticketPurchased = ref(false);
    let confirmButtonDisable = ref(false);
    const title = ref("Print Booking");
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");

    let seatsDetails = reactive({
      tripId: trip.value,
      seats: [],
      seatClassId: "",
      discount: "",
      totalAmount: 0,
      payable: 0,
      paid: "",
      refund: 0,
      due: 0,
    });

    let pagination = reactive({
      page: 1,
      limit: 10,
      companyId: store.getters.userCompany,
      from: "",
      to: "",
      coach: "",
      date: moment(new Date()).format("yyyy-MM-DD"),
      status: "ACTIVE",
    });
    const seatPlans = [];
    let seatPlan = ref();

    const openGlobalFilter = (value) => {
      emit("openGlobalFilter", value)
    }

    watch(() => store.getters.getTicketPurchasedDetails.purchaseStatus, () => {
      ticketPurchased.value = store.getters.getTicketPurchasedDetails.purchaseStatus;
    })

    watch(
        () => seatsDetails.discount,
        (value) => {
          if (parseInt(seatsDetails.discount) > parseInt(fare.value)) {
            discountErrorMsg.value =
                "Discount amount can't be greater than netfare";
            // seatsDetails.discount = 0;
            confirmButtonDisable.value = true;
            seatsDetails.payable = 0;
          } else {
            confirmButtonDisable.value = false;
            discountErrorMsg.value = "";
            seatsDetails.payable = fare.value - value;
            seatsDetails.paid = fare.value - value;
          }
        }
    );
    watch(
        () => fare.value,
        () => {
          seatsDetails.payable = fare.value - seatsDetails.discount;
          seatsDetails.paid = fare.value - seatsDetails.discount;
        }
    );

    watch(
        () => inputFare.value,
        () => {
          fare.value = inputFare.value;
        }
    );

    const ticketDashboardHide = () => {
      ticketPurchased.value = true;
    };

    const close = () => {
      addUpdate.value = false;
      store.commit("setPurchaseStatus", false)
      store.commit("setPurchasedTicket", {})
      // this place need optimise
      refreshPageGoBack();
    };

    calculateDue = computed(() => {
      let calculateValue = 0;
      if (seatsDetails.paid <= seatsDetails.payable) {
        calculateValue = seatsDetails.payable - seatsDetails.paid;
      }
      return calculateValue;
    });
    calculateRefund = computed(() => {
      let calculateValue = 0;
      if (seatsDetails.paid > seatsDetails.payable) {
        calculateValue = seatsDetails.paid - seatsDetails.payable;
      }
      return calculateValue;
    });

    const calculateFare = computed(() => {
      let netFare = 0;
      seatPlans.forEach((plan) => {
        if (plan._id === seatsDetails.seatClassId) {
          netFare = getNetFare(
              seatsDetails.seats,
              plan.upFare,
              plan.downFare,
              plan.direction
          );
        }
      });
      return netFare;
    });

    const reset = () => {
      store.commit("setResetStatus", true)
      store.commit("resetTicketDetails")
    };

    const discountedFare = computed(() => {
      return calculateFare.value - seatsDetails.discount;
    });

    const setSeatPlan = (plan) => {
      seatsDetails.discount = "";
      seatsDetails.seatClassName = plan.name;
      seatPlan.value = plan;
      seatsDetails.seatClassId = seatPlan.value._id;
      seatsDetails.seats = [];
    };

    const refreshPage = () => {
      showLoader();
      store
          .dispatch("getActiveTripsForTicketing", pagination)
          .then(() => {
            hideLoader();
          })
          .catch((error) => {
            hideLoader();
            if (error.status === 401) {
              refreshPage();
            }
          });
    };

    const refreshPageGoBack = () => {
      if (trip.value !== "") {
        showLoader();
        store
            .dispatch("getTicketByTrip", {tripId: trip.value})
            .then(() => {
              getTripForTicketing();
              ticketPurchased.value = false;
              hideLoader();
            })
            .catch(() => {
              hideLoader();
            });
      }
    };

    const tripReport = (tripId) => {
      tripIdForPrint.value = tripId;
      showLoader();
      store
          .dispatch("getCountermanTripReport", {tripId: tripIdForPrint.value})
          .then((tripReport) => {
            hideLoader();
            if (tripReport) {
              userTripReport.value = tripReport;
              ticketTypeForPrint.value = "tripReport";
            }
            reportPrintCount();
          })
          .catch((error) => {
            hideLoader();
            if (error.status === 401) {
              tripReport(tripId);
            }
          });
    };
    const reportPrintCount = () => {
      showLoader();

      store
          .dispatch("reportPrintCount", {
            reportType: "tripWiseReport",
            tripId: trip.value,
          })
          .then((data) => {
            hideLoader();
            printCount.value = data.printCount + 1;
            printPreview.value = true;
          })
          .catch((error) => {
            hideLoader();
            if (error.status === 401) {
              reportPrintCount();
            }
          });
    };
    const lastTicket = (tripId) => {
      tripIdForPrint.value = tripId;
      showLoader();
      store
          .dispatch("getCountermanLastTicket", {tripId: tripIdForPrint.value})
          .then((lastTicket) => {
            hideLoader();
            if (lastTicket) {
              lastTicketDetails.value = lastTicket;
              ticketTypeForPrint.value = "lastTicket";
              printPreview.value = true;
            }
          })
          .catch((error) => {
            hideLoader();
            if (error.status === 401) {
              lastTicket(tripId);
            }
          });
    };
    const pnrPrint = (payload) => {
      hideLoader();
      printPreview.value = true;
      lastTicketDetails.value = payload;
      ticketTypeForPrint.value = "pnrTicket";
    };
    const extraSeatPurchase = (payload) => {
      hideLoader();
      printPreview.value = payload.printPreview;
      lastTicketDetails.value = payload.lastTicketDetails;
      ticketTypeForPrint.value = payload.ticketTypeForPrint;
    };
    const extraGoodsPurchase = (payload) => {
      hideLoader();
      printPreview.value = payload.printPreview;
      lastTicketDetails.value = payload.lastTicketDetails;
      ticketTypeForPrint.value = payload.ticketTypeForPrint;
    };
    const closePrintPreview = () => {
      printPreview.value = false;
      tripIdForPrint.value = "";
      ticketTypeForPrint.value = "";
    };

    const tripInfo = () => {
      if (printPreview.value) {
        const tripSingle = store.getters.getTripForTicketing;
        const bookedSeats = tripSingle.bookedSeats;
        const seatPlan = tripSingle.seatPlan;

        seatPlan.forEach((item) => {
          let flag = false;
          item.seatList.forEach((seat) => {
            totalAvailableSeatCount.value += seat.split(",").length;
          });
          if (item.blockedSeat) {
            totalBlockedSeatCount.value += item.blockedSeat.split(",").length;
          }
          bookedSeats.forEach((seat) => {
            if (item._id === seat.seat_class_id) {
              const object = {
                bookedList: seat.seat_no,
                blockedSeats: item.blockedSeat,
                seatList: item.seatList,
                _id: item._id,
                name: item.name,
                upFare: item.upFare,
                downFare: item.downFare,
                direction: store.getters.getTripForTicketing.direction,
              };
              seatPlans.push(object);
              flag = true;
            }
          });
          if (!flag) {
            const object = {
              bookedList: "",
              blockedSeats: item.blockedSeat,
              seatList: item.seatList,
              _id: item._id,
              name: item.name,
              upFare: item.upFare,
              downFare: item.downFare,
              direction: store.getters.getTripForTicketing.direction,
            };
            seatPlans.push(object);
            flag = false;
          }
        });
        totalAvailableSeatCount.value -=
            totalBookedSeatCount.value + totalBlockedSeatCount.value;
      }
    };

    const getTripForTicketing = () => {
      singleTripDetails.value = store.getters.getTripForTicketing;
      if (store.getters.getUserInfo?.counter?.preferredStoppage && store.getters.getUserInfo?.counter?.preferredStoppage.length > 0) {
        fromStoppage.value = "";
        for (let item of store.getters.getUserInfo?.counter?.preferredStoppage) {
          let withoutLastStoppage = [...singleTripDetails.value.route.routeSequence];
          withoutLastStoppage.pop();
          if (withoutLastStoppage.some((data) => data._id === item._id)) {
            fromStoppage.value = item._id;
            break;
          }
        }
        if (fromStoppage.value === "") {
          fromStoppage.value = singleTripDetails.value.route.fromStoppageId;
        }
      } else {
        fromStoppage.value = singleTripDetails.value.route.fromStoppageId;
      }
      toStoppage.value = singleTripDetails.value.route.toStoppageId;

      //store.commit("setBlockedSeatsTicketsCounterman", {trip: store.getters.getTicketByTripCounterman});

      store.commit(
          "setRouteSequence",
          singleTripDetails.value.route.routeSequence
      );
      store.commit(
          "setBoardingPlace",
          singleTripDetails.value.route.boardingPlace
      );
      store.commit(
          "setDroppingPoint",
          singleTripDetails.value.route.droppingPoint
      );
    };
    const reducer = (accumulator, curr) => accumulator + curr;
    const seatBookedAndFare = ({seatFare, selectedSeatsArray}) => {
      if (seatFare.length === 0 && selectedSeatsArray.length === 0) {
        fare.value = 0;
        seatsDetails.payable = fare.value;
        seats.value = [];
        seatsDetails.due = 0;
        seatsDetails.refund = 0;
        seatsDetails.paid = 0;
        seatsDetails.discount = 0;
        inputFare.value = 0;
      } else {
        if (store.getters.userCompanyData.fareType === "predefined") {
          fareArray.value = [];
          seatFare.forEach((fare) => {
            fareArray.value.push(fare.fare);
          });
          seats.value = [];
          selectedSeatsArray.forEach((seat) => {
            seats.value.push(seat.seat_no);
          });
          if (fareArray.value.length !== 0) {
            fare.value = fareArray.value.reduce(reducer);
            seatsDetails.payable = fare.value;
            seatsDetails.paid = fare.value;
          }
        } else if (store.getters.userCompanyData.fareType === "trip_fare") {
          fareArray.value = [];
          seatFare.forEach((fare) => {
            fareArray.value.push(fare);
          });
          seats.value = [];
          selectedSeatsArray.forEach((seat) => {
            seats.value.push(seat.seat_no);
          });
          if (fareArray.value.length !== 0) {
            fare.value = fareArray.value.reduce(reducer);
            seatsDetails.payable = fare.value;
            seatsDetails.paid = fare.value;
          }
        } else if (store.getters.userCompanyData.fareType === "instant_fare") {
          fareArray.value = [];
          seatFare.forEach((fare) => {
            fareArray.value.push(fare);
          });
          seats.value = [];
          selectedSeatsArray.forEach((seat) => {
            seats.value.push(seat.seat_no);
          });
          if (fareArray.value.length !== 0) {
            fare.value = fareArray.value.reduce(reducer);
            seatsDetails.payable = fare.value - seatsDetails.discount;
            seatsDetails.paid = fare.value - seatsDetails.discount;
          }
        }
      }
    };
    const tripPass = () => {
      tripPassModal.value = true;
    };
    const updateTrip = () => {
      updateTripModal.value = true;
    };
    const pnrTicket = () => {
      pnrModal.value = true;
    };

    const ticketExtra = (name, ticketType) => {
      extraTicketModalName.value = name;
      extraTicketType.value = ticketType;
      ticketExtraModal.value = true;
    };


    onBeforeMount(() => {
      if (props.shouldRefreshPageGoBack === true) {
        console.log("aaaa", props.shouldRefreshPageGoBack)
        ticketPurchased.value = true;
        refreshPageGoBack()
      } else {
        tripInfo();
        getTripForTicketing();
      }
    });
    return {
      t,
      locale,
      discountErrorMsg,
      ticketTypeForPrint,
      printPreview,
      Counterman,
      addUpdate,
      seatsDetails,
      userTripReport,
      lastTicketDetails,
      totalAvailableSeatCount,
      totalBookedSeatCount,
      dropdownVariables,
      seatPlans,
      title,
      trip,
      seatPlan,
      calculateFare,
      propTripDetails,
      discountedFare,
      totalBlockedSeatCount,
      propsPaginationData,
      extraTicketModalName,
      extraTicketType,
      tripPassModal,
      updateTripModal,
      ticketExtra,
      hasPermission,
      tripReport,
      reset,
      close,
      lastTicket,
      updateTrip,
      closePrintPreview,
      setSeatPlan,
      refreshPage,
      refreshPageGoBack,
      seatBookedAndFare,
      extraSeatPurchase,
      extraGoodsPurchase,
      tripPass,
      pnrTicket,
      pnrPrint,
      printCount,
      fromStoppage,
      toStoppage,
      singleTripDetails,
      seats,
      fare,
      resetAllData,
      ticketExtraModal,
      pnrModal,
      calculateDue,
      calculateRefund,
      inputFare,
      ticketPurchased,
      ticketDashboardHide,
      confirmButtonDisable,
      openGlobalFilter,
    };
  },
};
</script>

<style scoped>
.nav-style {
  background: whitesmoke;
  border-top: 1px;
}

.active-button {
  background: white !important;
  /*border: 1px solid #242424 !important;*/
  color: #000 !important;
}

.inactive-button {
  color: #888 !important;
}

.available {
  color: #000000;
  padding: 5px 10px;
  border-radius: 4px;
  text-transform: uppercase;
}

.span_font {
  font-size: 14px;
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: 500;
}

.trip_button {
  margin: 1px 2px;
  border: 2px solid #9400d3;
  border-radius: 4px;
  background-color: #9400d3;
  padding: 2px 6px;
  color: #fff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.trip_button:hover,
.trip_button:focus {
  border: 2px solid #9400d3;
  background-color: #fff;
  color: #9400d3;
  transition: 0.4s;
  outline: 0;
}

.extra_ticket_button {
  margin: 1px 2px;
  border: 2px solid #3cbbc4;
  border-radius: 4px;
  background-color: #3cbbc4;
  padding: 2px 6px;
  color: #fff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.extra_ticket_button:hover,
.extra_ticket_button:focus {
  border: 2px solid #3cbbc4;
  background-color: #fff;
  color: #3cbbc4;
  transition: 0.4s;
  outline: 0;
}

.goods_ticket_button {
  margin: 1px 2px;
  border: 2px solid #6edc58;
  border-radius: 4px;
  background-color: #6edc58;
  padding: 2px 6px;
  color: #fff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.goods_ticket_button:hover,
.goods_ticket_button:focus {
  border: 2px solid #6edc58;
  background-color: #fff;
  color: #6edc58;
  transition: 0.4s;
  outline: 0;
}

.last_ticket {
  margin: 1px 2px;
  border: 2px solid #00bfff;
  border-radius: 4px;
  background-color: #00bfff;
  padding: 2px 6px;
  color: #fff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.last_ticket:hover,
.last_ticket:focus {
  border: 2px solid #00bfff;
  background-color: #fff;
  color: #00bfff;
  transition: 0.4s;
  outline: 0;
}

.pnr_ticket {
  margin: 1px 2px;
  border: 2px solid #9b5f76;
  border-radius: 4px;
  background-color: #9b5f76;
  padding: 2px 6px;
  color: #fff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.pnr_ticket:hover,
.pnr_ticket:focus {
  border: 2px solid #9b5f76;
  background-color: #fff;
  color: #9b5f76;
  transition: 0.4s;
  outline: 0;
}

.remove_button {
  margin: 1px 2px;
  border: 2px solid #ff0000;
  border-radius: 4px;
  background-color: #ff0000;
  padding: 2px 6px;
  color: #fff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.remove_button:hover,
.remove_button:focus {
  border: 2px solid #ff0000;
  background-color: #fff;
  color: #ff0000;
  transition: 0.4s;
  outline: 0;
}

.update_trip_button {
  margin: 1px 2px;
  border: 2px solid #0051ff;
  border-radius: 4px;
  background-color: #0051ff;
  padding: 2px 6px;
  color: #fff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.update_trip_button:hover,
.update_trip_button:focus {
  border: 2px solid #0051ff;
  background-color: #fff;
  color: #0051ff;
  transition: 0.4s;
  outline: 0;
}

.trip_pass_button {
  margin: 1px 2px;
  border: 2px solid #87bb62;
  border-radius: 4px;
  background-color: #87bb62;
  padding: 2px 6px;
  color: #fff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.trip_pass_button:hover,
.trip_pass_button:focus {
  border: 2px solid #87bb62;
  background-color: #fff;
  color: #87bb62;
  transition: 0.4s;
  outline: 0;
}

.confirm_button {
  margin: 1px 2px;
  border: 2px solid #34a853;
  border-radius: 4px;
  background-color: #34a853;
  padding: 2px 6px;
  color: #fff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.confirm_button:hover,
.confirm_button:focus {
  border: 2px solid #34a853;
  background-color: #fff;
  color: #34a853;
  transition: 0.4s;
  outline: 0;
}

.refresh_button {
  margin: 1px 2px;
  border: 2px solid #ffc107;
  border-radius: 4px;
  background-color: #ffc107;
  padding: 2px 6px;
  color: #ffff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.refresh_button:hover,
.refresh_button:focus {
  border: 2px solid #ffc107;
  background-color: #fff;
  color: #ffc107;
  transition: 0.4s;
  outline: 0;
}
</style>
