<template>
  <Modal @close="closeModal">
    <template v-slot:title>{{
        t(title, {}, {locale: $store.getters.getLang})
      }}
    </template>
    <template v-slot:body>
      <form>
        <div class="form-group row my-1">
          <ModalInput
              v-model="coachData.name"
              v-bind:placeholder="'Enter Coach Name'"
              v-bind:label="'Name'"
              v-bind:id="'name'"
              v-bind:type="'text'"
          >
            <template v-slot:error>
              <ModalError v-if="errors.name" v-bind:error="errors.name"/>
            </template>
          </ModalInput>
        </div>
        <div
            class="form-group row my-1"
        >
          <ModalInput
              v-model="coachData.registrationNumber"
              v-bind:placeholder="'Registration Number'"
              v-bind:label="'Registration Number'"
              v-bind:id="'registration'"
              v-bind:type="'text'"
          >
            <template v-slot:error>
              <ModalError
                  v-if="errors.registrationNumber"
                  v-bind:error="errors.registrationNumber"
              />
            </template>
          </ModalInput>
        </div>
        <div class="form-group row my-1">
          <ModalDropdown
              v-if="depot"
              v-model="coachData.depot"
              v-bind:id="'depot'"
              v-bind:label="'Depot'"
              v-bind:dropdown="depot"
              v-bind:hideLabel="'inactive'"
              v-bind:defaultOption="'---select a depot---'"
              v-bind:defaultValue="''"
          />
          <Error v-if="errors.depot" v-bind:error="errors.depot"/>
        </div>
        <div
            class="form-group row my-1"
        >
          <ModalDropdown
              v-model="coachData.seatPlanId"
              v-bind:id="'seatPlan'"
              v-bind:label="'Seat Plan'"
              v-bind:defaultOption="'---select a seat plan---'"
              v-bind:defaultValue="''"
              v-bind:dropdown="
              $store.getters.getOwnerServicesSeatPlanListForFilter
            "
          >
            <template v-slot:error>
              <ModalError
                  v-if="errors.seatPlanId"
                  v-bind:error="errors.seatPlanId"
              />
            </template>
          </ModalDropdown>
        </div>
        <div class="form-group row my-1">
          <ModalDropdown
              v-if="status"
              v-model="coachData.status"
              v-bind:id="'status'"
              v-bind:label="'Status'"
              v-bind:dropdown="status"
          />
        </div>

        <div class="form-group row my-1">
          <label class="col-sm-3 col-form-label"></label>
          <div class="col-sm-9">
            <button
                v-if="!coachId"
                class="mt-3 py-2 submit_button font_detail float-end"
                @click.prevent="addCoach"
            >
              {{ t("Submit", {}, {locale: $store.getters.getLang}) }}
            </button>
            <button
                v-else
                class="mt-3 py-2 submit_button font_detail float-end"
                @click.prevent="updateCoach"
            >
              {{ t("Submit", {}, {locale: $store.getters.getLang}) }}
            </button>
          </div>
        </div>
      </form>
    </template>
  </Modal>
</template>

<script>
import dropdownVariables from "@/modules/dropdownVariables";
import useInputValidation from "@/modules/useInputValidations";
import {inject, onBeforeMount, ref, watch} from "vue";
import {reactive} from "@vue/reactivity";
import store from "@/store";
import {useI18n} from "vue-i18n";

export default {
  name: "CoachAddUpdateModal",
  props: {
    coach: String,
  },
  setup(props, {emit}) {
    let {requiredValidation, errors} = useInputValidation();
    let coachData = reactive({
      id: "",
      name: "",
      registrationNumber: "",
      seatPlanId: "",
      depot: "",
      status: "1",
    });
    const coachId = ref(props.coach);
    const title = ref("Add Coach");
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    const status = dropdownVariables.status;
    const depot = store.getters.getDepotListForFilterOwnerServices;
    const {t, locale} = useI18n();
    let pagination = reactive({
      page: 1,
      limit: 25,
    });
    watch(
        () => coachData.name,
        () => {
          requiredValidation("name", coachData.name);
        }
    );
    watch(
        () => coachData.registrationNumber,
        () => {
          requiredValidation("registrationNumber", coachData.registrationNumber);
        }
    );
    watch(
        () => coachData.seatPlanId,
        () => {
          requiredValidation("seatPlanId", coachData.seatPlanId);
        }
    );
    watch(
        () => coachData.depot,
        () => {
          requiredValidation("depot", coachData.depot);
        }
    );

    const validate = () => {
      Object.keys(errors).forEach(function (key) {
        errors[key] = "";
      });
      requiredValidation("name", coachData.name);
      requiredValidation("registrationNumber", coachData.registrationNumber);
      requiredValidation("seatPlanId", coachData.seatPlanId);
      requiredValidation("depot", coachData.depot);
      return !(
          Object.values(errors).length <= 0 ||
          !Object.values(errors).every((x) => x === null || x === "")
      );
    };
    const closeModal = () => {
      emit("update:modelValue", false);
    };
    const getAllSeatPlan = () => {
      store.dispatch("getOwnerServicesAllSeatPlan", {});
    }
    const addCoach = () => {
      if (validate()) {
        showLoader();
        store
            .dispatch("addOwnerServicesCoach", {coachData, pagination})
            .then(() => {
              emit("close");
              hideLoader();
            })
            .catch((error) => {
              emit("close");
              hideLoader();
              if (error.status === 401) {
                addCoach();
              }
            });
      }
    };
    const updateCoach = () => {
      if (validate()) {
        showLoader();
        store
            .dispatch("updateOwnerServicesCoach", coachData)
            .then(() => {
              emit("close");
              hideLoader();
            })
            .catch(() => {
              emit("close");
              hideLoader();
              // if (error.status === 401) {
              //   updateCoach();
              // }
            });
      }
    };

    onBeforeMount(() => {
      getAllSeatPlan()
      Object.keys(errors).forEach(function (key) {
        errors[key] = "";
      });
      if (coachId.value !== "") {
        const coachDetails = store.getters.getOwnerServicesCoach(coachId.value);
        title.value = "Update Coach";
        coachData.id = coachDetails[0]._id;
        coachData.name = coachDetails[0].name;
        coachData.registrationNumber = coachDetails[0]?.registrationNumber;
        coachData.seatPlanId = coachDetails[0]?.seatPlanId?._id;
        coachData.depot = coachDetails[0]?.depot?._id;
        coachData.status = coachDetails[0].status ? 1 : 0;
      }
    });

    return {
      t,
      locale,
      coachId,
      errors,
      status,
      title,
      coachData,
      depot,
      addCoach,
      updateCoach,
      closeModal,
    };
  },
};
</script>

<style scoped></style>
