<template>
  <div>
    <FilterTab>
      <!-- <template v-slot:title><h6>Seat Fare</h6></template> -->
      <template v-slot:body>
        <div class="col-sm-6 col-md-2"></div>
      </template>
    </FilterTab>
    <div
      v-if="hasPermission(Admin.companyDeployWrite)"
      class="card container new-class"
    >
      <div class="card-body">
        <h6 class="card-title bold">Add Fare</h6>
        <span v-if="error" class="text-danger err_msg">* {{ error }}</span>
        <div class="row">
          <div class="col-md-4">
            <Dropdown
              v-if="status"
              v-model="seatPlan.routeId"
              v-bind:id="'route'"
              v-bind:label="'Route'"
              v-bind:dropdown="$store.getters.getRouteListForFilterAdmin"
              v-bind:hideLabel="''"
              v-bind:defaultOption="'--select route--'"
              v-bind:defaultValue="''"
            />
            <Error v-if="errors.route" v-bind:error="errors.route" />
          </div>
          <div class="col-md-4">
            <Dropdown
              v-if="status"
              v-model="seatPlan.status"
              v-bind:id="'status'"
              v-bind:label="'Status'"
              v-bind:dropdown="status"
              v-bind:hideLabel="''"
            />
          </div>
          <div class="col-md-4">
            <input
              type="submit"
              class="submit_button mt-1 w-50"
              @click.prevent="addNewSeatFare"
            />
          </div>
        </div>
      </div>
    </div>
    <div id="accordion" v-if="$store.getters.getFares">
      <div v-for="fare in $store.getters.getFares" :key="fare._id">
        <SeatFareCard
          v-bind:company="fare.companyId"
          :seatPlanId="fare._id"
          @getAllSeatFare="getAllSeatFareList"
        />
      </div>
      <div class="d-flex justify-content-end mx-5 my-2">
        <pagination
          v-model="payload.page"
          :records="$store.getters.getTotalFares"
          :per-page="payload.limit"
          @paginate="getAllSeatFareList"
          :options="options"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { reactive } from "@vue/reactivity";
import useInputValidation from "@/modules/useInputValidations";
import dropdownVariables from "@/modules/dropdownVariables";
import { inject, onBeforeMount, ref } from "vue";
import SeatFareCard from "./SeatFareCard";
import store from "@/store";
import FilterTab from "@/components/layouts/Filter/FilterTab";
import permission from "@/modules/permission";
import roles from "@/modules/roles";
import Error from "@/components/layouts/Inputs/Error";
import { useRoute } from "vue-router";
const { hasPermission } = permission();
const { Admin } = roles();

export default {
  name: "SeatPlan",
  components: { FilterTab, SeatFareCard, Error },
  setup(props) {
    let { errors, requiredValidation } = useInputValidation();
    let shipId = ref(props.ship);
    let companyId = ref(props.company);
    let error = ref("");
    const route = useRoute();
    const status = dropdownVariables.status;
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    let seatPlan = reactive({
      companyId: route.query.id,
      routeId: "",
      fareModality: [],
      status: "1",
    });
    let pagination = reactive({
      page: 1,
      limit: 25,
    });
    let payload = reactive({
      page: 1,
      limit: 25,
      companyId: route.query.id,
    });
    const clear = () => {
      seatPlan.routeId = "";
      seatPlan.fareModality = [];
      seatPlan.status = "1";
    };
    const options = {
      texts: {
        count: "",
      },
    };

    const validate = () => {
      Object.keys(errors).forEach(function (key) {
        errors[key] = "";
      });
      requiredValidation("route", seatPlan.routeId);
      return !(
        Object.values(errors).length <= 0 ||
        !Object.values(errors).every((x) => x === null || x === "")
      );
    };

    const getAllSeatFareList = () => {
      store
        .dispatch("getNewSeatFare", payload)
        .then(() => {})
        .catch((error) => {
          if (error.status === 401) {
            getAllSeatFareList();
          }
        });
    };
    const getRouteListFilter = () => {
      store
        .dispatch("getRouteListFilter", { companyId: route.query.id })
        .then(() => {})
        .catch((error) => {
          if (error.status === 401) {
            getRouteListFilter();
          }
        });
    };

    const getAllSeatClass = () => {
      store
        .dispatch("getAllSeatClass", { companyId: route.query.id })
        .then(() => {})
        .catch((error) => {
          if (error.status === 401) {
            getAllSeatClass();
          }
        });
    };

    const addNewSeatFare = () => {
      if (validate()) {
        showLoader();
        error.value = "";
        store
          .dispatch("addNewSeatFare", { seatPlan, pagination })
          .then(() => {
            hideLoader();
            clear();
          })
          .catch((error) => {
            hideLoader();
            if (error.status === 401) {
              addNewSeatFare();
            }
          });
      }
    };
    const getStoppageList = () => {
      store
        .dispatch("getStoppageList", { companyId: route.query.id })
        .then(() => {
          hideLoader();
        })
        .catch((error) => {
          hideLoader();
          if (error.status === 401) {
            getStoppageList();
          }
        });
    };

    onBeforeMount(() => {
      getStoppageList();
      getRouteListFilter();
      getAllSeatFareList();
      getAllSeatClass();
      if (route.query.id) {
        seatPlan.companyId = route.query.id;
      }
    });

    return {
      seatPlan,
      errors,
      status,
      shipId,
      companyId,
      error,
      Admin,
      options,
      payload,
      hasPermission,
      addNewSeatFare,
      getAllSeatFareList,
    };
  },
};
</script>

<style scoped>
.new-class {
  background: #f3f3f3;
}

.bold {
  font-weight: bold;
}
</style>
