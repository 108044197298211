<template>
  <Modal @close="closeModal" v-bind:size="'xl'">
    <template v-slot:title>{{ title }}</template>
    <template v-slot:body>
      <form>
        <div class="auth_form py-3">
          <div class="row my-2">
            <div class="col-md-4">
              <Input
                  v-model="supervisorData.name"
                  v-bind:placeholder="'Enter Supervisor Name'"
                  v-bind:label="'Name'"
                  v-bind:id="'name'"
                  v-bind:type="'text'"
              />
              <Error v-if="errors.name" v-bind:error="errors.name"/>
            </div>
            <div class="col-md-4">
              <Input
                  v-model="supervisorData.phone"
                  v-bind:placeholder="'Enter Phone Number'"
                  v-bind:label="'Phone'"
                  v-bind:id="'phone'"
                  v-bind:type="'text'"
              />
              <Error v-if="errors.phone" v-bind:error="errors.phone"/>
            </div>

            <div class="col-md-4">
              <Input
                  v-model="supervisorData.email"
                  v-bind:placeholder="'Email Address'"
                  autocomplete="off"
                  v-bind:label="'Email'"
                  v-bind:id="'email'"
                  v-bind:type="fieldTypes.email"
              />
            </div>
<!--            <div class="col-md-3">-->
<!--              <Dropdown-->
<!--                  v-if="depot"-->
<!--                  v-model="supervisorData.depot"-->
<!--                  v-bind:id="'depot'"-->
<!--                  v-bind:label="'Depot'"-->
<!--                  v-bind:dropdown="depot"-->
<!--                  v-bind:hideLabel="'inactive'"-->
<!--                  v-bind:defaultOption="'Select Depot'"-->
<!--                  v-bind:defaultValue="''"-->
<!--              />-->
<!--              <Error v-if="errors.depot" v-bind:error="errors.depot"/>-->
<!--            </div>-->
          </div>
          <div class="row my-2">
            <div class="col-md-4">
              <label for="address" class="form-label all_permission_label">
                Address
              </label>
              <div>
                <textarea
                    class="form-control font_detail"
                    id="address"
                    v-model="supervisorData.address"
                ></textarea>
              </div>
            </div>
            <div class="col-md-4">
              <Input
                  v-model="supervisorData.nid"
                  v-bind:placeholder="'NID Number'"
                  v-bind:label="'NID'"
                  v-bind:id="'nid'"
                  v-bind:type="'text'"
              />
            </div>
            <div class="col-md-4">
              <Dropdown
                  v-if="status"
                  v-model="supervisorData.status"
                  v-bind:id="'status'"
                  v-bind:label="'Status'"
                  v-bind:dropdown="status"
                  v-bind:hideLabel="'inactive'"
              />
            </div>
          </div>
        </div>

        <div class="form-group row my-1">
          <label class="col-sm-3 col-form-label"></label>
          <div class="col-sm-9">
            <input
                v-if="!supervisorIdForUpdate"
                type="submit"
                class="mt-3 py-2 submit_button font_detail float-end"
                @click.prevent="addSupervisor"
            />
            <input
                v-else
                type="submit"
                class="mt-3 py-2 submit_button font_detail float-end"
                @click.prevent="updateSupervisor"
            />
          </div>
        </div>
      </form>
    </template>
  </Modal>
</template>

<script>
import {reactive} from "@vue/reactivity";
import dropdownVariables from "@/modules/dropdownVariables";
import useInputValidation from "@/modules/useInputValidations";
import {inject, onBeforeMount, ref, watch} from "vue";
import store from "@/store";

export default {
  name: "SupervisorAddUpdateModal",
  components: {},
  props: {
    modalValue: Boolean,
    company: String,
    supervisor: String,
  },
  setup(props, {emit}) {
    let supervisorData = reactive({
      id: "",
      name: "",
      depot: "",
      designation: "",
      phone: "",
      email: "",
      nid: "",
      address: "",
      avatar: "",
      transactionType: "",
      balance: "",
      commission: "",
      userType: "counterman",
      companyId: "",
      commissionType: "",
      status: "1",
      api_token: "",
      device_token: "",
      printingType: "Individual",
      reset_token: "",
      printerType: "",
    });
    let supervisorIdForUpdate = ref(props.supervisor);
    let companyId = ref(props.company);
    let allSelected = ref(false);
    let {requiredValidation, phoneValidation, errors} = useInputValidation();
    let printerType = dropdownVariables.countermanPrinterType; //["T2mini", "Bluetooth"];
    const status = dropdownVariables.status;
    const printingType = dropdownVariables.printingType;
    const depot = store.getters.getDepotListForFilter;
    const title = ref("Add Supervisor");
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");

    let fieldTypes = reactive({
      email: "text",
      password: "text",
    });

    watch(
        () => supervisorData.name,
        () => {
          requiredValidation("name", supervisorData.name);
        }
    );

    watch(
        () => supervisorData.phone,
        () => {
          phoneValidation("phone", supervisorData.phone);
        }
    );

    watch(
        () => supervisorData.printerType,
        () => {
          requiredValidation("printerType", supervisorData.printerType);
        }
    );

    watch(
        () => supervisorData.email,
        () => {
          fieldTypes.email = "email";
        }
    );

    const closeModal = () => {
      emit("update:modelValue", false);
    };

    const validate = () => {
      Object.keys(errors).forEach(function (key) {
        errors[key] = "";
      });
      requiredValidation("name", supervisorData.name);
      phoneValidation("phone", supervisorData.phone);
      // requiredValidation("depot", supervisorData.depot);

      return !(
          Object.values(errors).length <= 0 ||
          !Object.values(errors).every((x) => x === null || x === "")
      );
    };
    const addSupervisor = () => {
      if (validate()) {
        showLoader();
        store
            .dispatch("addSupervisor", supervisorData)
            .then(() => {
              hideLoader();
              emit("getAllSupervisors");
              closeModal();
            })
            .catch((error) => {
              hideLoader();
              if (error.status === 401) {
                addSupervisor();
              }
            });
      }
    };
    const updateSupervisor = () => {
      if (validate()) {
        showLoader();
        store
            .dispatch("updateSupervisor", supervisorData)
            .then(() => {
              hideLoader();
              emit("getAllSupervisors");
              closeModal();
            })
            .catch((error) => {
              hideLoader();
              if (error.status === 401) {
                updateSupervisor();
              }
            });
      }
    };

    onBeforeMount(() => {
      Object.keys(errors).forEach(function (key) {
        delete errors[key];
      });
      supervisorData.companyId = companyId.value;
      if (supervisorIdForUpdate.value !== "") {
        const supervisorDetails = store.getters.getSupervisor(
            supervisorIdForUpdate.value
        );
        title.value = "Update Supervisor";
        supervisorData.id = supervisorDetails[0]._id;
        supervisorData.companyId = supervisorDetails[0].companyId;
        supervisorData.name = supervisorDetails[0].name;
        // supervisorData.depot = supervisorDetails[0].depot?._id;
        supervisorData.phone = supervisorDetails[0].phone;
        supervisorData.email = supervisorDetails[0].email;
        supervisorData.printerType = supervisorDetails[0].printerType;
        supervisorData.status = supervisorDetails[0].status ? 1 : 0;
        supervisorData.printingType = supervisorDetails[0].printingType;
        supervisorData.address = supervisorDetails[0].address;
        supervisorData.nid = supervisorDetails[0].nid;
      }
    });

    return {
      status,
      supervisorData,
      errors,
      title,
      allSelected,
      supervisorIdForUpdate,
      printingType,
      fieldTypes,
      depot,
      updateSupervisor,
      addSupervisor,
      closeModal,
      printerType,
    };
  },
};
</script>

<style scoped></style>
