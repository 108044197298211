<template>
  <div class="field_error">
    <span class="error">
      {{ t(errorMessage, {}, { locale: $store.getters.getLang }) }}
    </span>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";

export default {
  name: "Error",
  props: {
    error: String,
  },
  setup(props) {
    const errorMessage = ref(props.error);
    const { t, locale } = useI18n();

    watch(
      () => props.error,
      (message) => {
        errorMessage.value = message;
      }
    );
    return {
      errorMessage,
      t,
      locale,
    };
  },
};
</script>

<style scoped>
</style>