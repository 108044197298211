<template>
  <FilterTab>
    <template v-slot:title>
      <h6>Trip Log History</h6>
    </template>
    <!-- <template v-slot:body>
      <div class="col-sm-6 col-md-2"></div>
    </template> -->
    <template v-slot:button1>
      <button class="edit_table_data font_detail" @click="goBack">
        <i class="fas fa-arrow-left"></i><span class="mx-1 bold">Go Back</span>
      </button>
    </template>
  </FilterTab>
  <div>
    <div class="p-2 position-relative d-md-none">
      <div
        v-if="
          $store.getters.getSingleTripHistoryOwner &&
          $store.getters.getSingleTripHistoryOwner.length > 0
        "
      >
        <div
          class="card w-100 my-2"
          v-for="trip in $store.getters.getSingleTripHistoryOwner"
          :key="trip._id"
        >
          <div class="card-body position-relative">
            <div class="row">
              <div class="col-12 mb-2"></div>
              <div class="col-12">
                <p>
                  <b
                    >{{
                      t(
                        "TRIP TIME & DATE",
                        {},
                        { locale: $store.getters.getLang }
                      )
                    }}:</b
                  >
                  <span>{{ dateFormat(trip.tripDateTime) }}</span>
                </p>
              </div>
              <div class="col-6">
                <p>
                  <b
                    >{{
                      t("TRIP CODE", {}, { locale: $store.getters.getLang })
                    }}:</b
                  >
                  {{ trip.tripCode }}
                </p>
              </div>
              <div class="col-6">
                <p>
                  <b
                    >{{
                      t("ROUTE NAME", {}, { locale: $store.getters.getLang })
                    }}:</b
                  >
                  {{ trip.route.name }}
                </p>
              </div>
              <div class="col-6">
                <p>
                  <b
                    >{{
                      t("COACH NAME", {}, { locale: $store.getters.getLang })
                    }}:</b
                  >
                  {{ trip.coach.name }}
                </p>
              </div>
              <div class="col-6">
                <p>
                  <b
                    >{{
                      t(
                        "FROM STOPPAGE",
                        {},
                        { locale: $store.getters.getLang }
                      )
                    }}:</b
                  >
                  {{ trip.fromStoppage.name }}
                </p>
              </div>
              <div class="col-6">
                <p>
                  <b
                    >{{
                      t("TO STOPPAGE", {}, { locale: $store.getters.getLang })
                    }}:</b
                  >
                  {{ trip.toStoppage.name }}
                </p>
              </div>
              <div class="col-6">
                <p>
                  <b
                    >{{
                      t(
                        "SEATPLAN NAME",
                        {},
                        { locale: $store.getters.getLang }
                      )
                    }}:</b
                  >
                  {{ trip.seatPlan.name }}
                </p>
              </div>
              <div class="col-6">
                <p>
                  <b
                    >{{
                      t("CLASS NAME", {}, { locale: $store.getters.getLang })
                    }}:</b
                  >
                  {{ trip.seatPlan.seatClass }}
                </p>
              </div>
              <!-- <div class="col-6">
                <p>
                  <b
                    >{{
                      t("STATUS", {}, { locale: $store.getters.getLang })
                    }}:</b
                  >
                  <span v-if="trip.status" class="badge bg-success mx-2">{{
                    dropdownVariables.status[1]
                  }}</span>
                  <span v-else class="badge bg-danger mx-2">{{
                    dropdownVariables.status[0]
                  }}</span>
                </p>
              </div> -->

              <!-- <div v-if="hasPermission(Counterman.ticketCancel)" class="col-12">
                <button
                  class="btn btn-warning btn-sm w-100"
                  @click="Ticket(trip._id)"
                >
                  <i></i
                  ><span class="mx-1">{{
                    t("Ticket History", {}, { locale: $store.getters.getLang })
                  }}</span>
                </button>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <Table
      class="d-none d-md-block"
      v-if="
        $store.getters.getSingleTripHistoryOwner &&
        $store.getters.getSingleTripHistoryOwner.length > 0
      "
    >
      <template v-slot:header>
        <tr>
          <td class="font_detail">
            {{ t("SL", {}, { locale: $store.getters.getLang }) }}
          </td>
          <td class="font_detail">
            {{ t("TRIP TIME & DATE", {}, { locale: $store.getters.getLang }) }}
          </td>
          <td class="font_detail">
            {{ t("TRIP CODE", {}, { locale: $store.getters.getLang }) }}
          </td>
          <td class="font_detail">
            {{ t("ROUTE NAME", {}, { locale: $store.getters.getLang }) }}
          </td>
          <td class="font_detail">
            {{ t("COACH NAME", {}, { locale: $store.getters.getLang }) }}
          </td>
          <td class="font_detail">
            {{ t("FROM STOPPAGE", {}, { locale: $store.getters.getLang }) }}
          </td>
          <td class="font_detail">
            {{ t("TO STOPPAGE", {}, { locale: $store.getters.getLang }) }}
          </td>
          <td class="font_detail">
            {{ t("SEATPLAN NAME", {}, { locale: $store.getters.getLang }) }}
          </td>
          <td class="font_detail">
            {{ t("CLASS NAME", {}, { locale: $store.getters.getLang }) }}
          </td>
          <td class="font_detail">
            {{ t("STATUS", {}, { locale: $store.getters.getLang }) }}
          </td>
          <td class="font_detail">
            LOG STATUS
          </td>
          <td class="font_detail">
            CANCELLED BY
          </td>
        </tr>
      </template>
      <template v-slot:body>
        <tr
          v-for="(trip, index) in $store.getters.getSingleTripHistoryOwner"
          :key="trip._id"
        >
          <td class="font_detail">{{ index + 1 }}</td>
          <td class="font_detail">
            <span>{{ dateFormat(trip.tripDateTime) }}</span>
          </td>
          <td class="font_detail">{{ trip.tripCode }}</td>
          <td class="font_detail">{{ trip.route.name }}</td>
          <td class="font_detail">{{ trip.coach.name }}</td>
          <td class="font_detail">{{ trip.fromStoppage.name }}</td>
          <td class="font_detail">{{ trip.toStoppage.name }}</td>
          <td class="font_detail">{{ trip.seatPlan.name }}</td>
          <td class="font_detail">{{ trip.seatPlan.seatClass }}</td>
          <td class="font_detail">
            <span
              class="badge"
              :class="{
                'bg-success': trip.status === 'ACTIVE',
                'bg-danger': trip.status === 'CANCELLED',
                'bg-primary': trip.status === 'COMPLETED',
              }"
            >
              {{ trip.status }}</span
            >
          </td>
          <td class="font_detail bold">{{trip.logStatus}}</td>
          <td class="font_detail bold">{{trip?.cancelledBy?.name }}</td>
        </tr>
      </template>
    </Table>
  </div>
</template>

<script>
import { onBeforeMount, inject, reactive } from "vue";
import store from "@/store";
import { useI18n } from "vue-i18n";
import moment from "moment";


export default {
  name: "LogHistory",
  props: {
    tripId: String,
    modelValue: Boolean,
  },
  setup(props, { emit }) {
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    const { t, locale } = useI18n();

    let payload = reactive({
      tripId: props.tripId,
    });

    const goBack = () => {
      emit("update:modelValue", false);
    };

    const getASingleTripLogHistory = () => {
      showLoader();
      store
        .dispatch("singleTripHistoryActionOwner", payload)
        .then(() => {
          hideLoader();
        })
        .catch((error) => {
          hideLoader();
          if (error.status === 401) {
            // getASingleTripLogHistory();
          }
        });
    };
    const dateFormat = (date) => {
      return moment(String(date))
        .utcOffset(0, true)
        .format("DD-MMM-yyyy hh:mm:ss A");
    };

    onBeforeMount(() => {
      getASingleTripLogHistory();
    });

    

    return {
      goBack,
      t,
      locale,
      dateFormat,
    };
  },
};
</script>

<style></style>
