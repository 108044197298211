import roles from "@/modules/roles";

const {Admin: AdminRoles, Owner: OwnerRoles, Counterman: CountermanRoles} = roles()

const Default = {
  'LOGIN_URL': {
    "title": "Login",
    "path": "/",
    "name": "login",
  },
  'LOGOUT_URL': {
    "title": "Logout",
    "path": "/logout",
    "name": "logout",
  },
  'ERROR_URL': {
    "title": "Error",
    "path": "/:pathMatch(.*)*",
    "name": "error",
  }
}

const Admin = {
  'path': '/admin',
  'name': 'admin',
  'DASHBOARD_URL': {
    "title": "Dashboard",
    "path": "dashboard",
    "name": "admin-dashboard",
    "permission": AdminRoles.dashboard,
  },
  'REPORT_URL': {
    "title": "Report",
    "path": "report",
    "name": "admin-report",
    "permission": AdminRoles.reports,
    "children": {
      'COACH_REPORT_URL': {
        "title": "Coach Wise",
        "path": "coach-report",
        "name": "admin-report-coach-report",
      },
      'COUNTER_REPORT_URL': {
        "title": "Counter Wise",
        "path": "counter-report",
        "name": "admin-report-counter-report",
      },
      'DIGITAL_TICKET_REPORT_URL': {
        "title": "Digital Ticketing",
        "path": "digital-ticketing-report",
        "name": "admin-report-digital-ticketing-report",
      },
      'PRINT_COUNT_REPORT_URL': {
        "title": "Print Count",
        "path": "print-count",
        "name": "admin-report-print-count-report",
      },
    }
  },
  'ADMINS_URL': {
    "title": "Admins",
    "path": "admins",
    "name": "admin-admins",
    "permission": AdminRoles.adminRead,
    "children": {
      'LIST_URL': {
        "title": "List",
        "path": "list",
        "name": "admin-admins-list",
      },
      'ADD_URL': {
        "title": "Add",
        "path": "add",
        "name": "admin-admins-add",
      },
      'UPDATE_URL': {
        "title": "Update",
        "path": "update",
        "name": "admin-admins-update",
      }
    }
  },
  'COMPANY_DEPLOY_URL': {
    "title": "Company Deploy",
    "path": "company-deploy",
    "name": "admin-company-deploy",
    "permission": AdminRoles.companyDeployRead,
    "children": {
      'LIST_URL': {
        "title": "List",
        "path": "list",
        "name": "admin-company-deploy-list",
      },
      'ADD_URL': {
        "title": "Add",
        "path": "add",
        "name": "admin-company-deploy-add",
      },
      'UPDATE_URL': {
        "title": "Update",
        "path": "update",
        "name": "admin-company-deploy-update",
      },
      'MANAGE_URL': {
        "title": "Manage",
        "path": "manage",
        "name": "admin-company-deploy-manage",
        "children": {
          'DEPOT_URL': {
            "title": "Depot",
            "path": "depot",
            "name": "admin-company-deploy-depot",
          },
          'DT_OWNER_URL': {
            "title": "DT Owner",
            "path": "dtOwner",
            "name": "admin-company-deploy-dtOwner",
          },
          'OWNER_URL': {
            "title": "Lease Holder",
            "path": "Owner",
            "name": "admin-company-deploy-Owner",
          },
          'STOPPAGE_URL': {
            "title": "Stoppage",
            "path": "stoppage",
            "name": "admin-company-deploy-stoppage",
          },
          'COUNTER_URL': {
            "title": "Counter",
            "path": "counter",
            "name": "admin-company-deploy-counter",
          },
          'ROUTE_URL': {
            "title": "Route",
            "path": "route",
            "name": "admin-company-deploy-route",
          },
          'SEAT_CLASS_URL': {
            "title": "Seat-Class",
            "path": "seatClass",
            "name": "admin-company-deploy-seatClass",
          },
          'SEAT_PLAN_URL': {
            "title": "Seat-Plan",
            "path": "seatPlan",
            "name": "admin-company-deploy-seatPlan",
          },
          'SEAT_FARE_URL': {
            "title": "Seat-Fare",
            "path": "seatFare",
            "name": "admin-company-deploy-seatFare",
          },
          'COACH_URL': {
            "title": "Coach",
            "path": "coach",
            "name": "admin-company-deploy-coach",
          },
          'TRIPS_URL': {
            "title": "Trips",
            "path": "trips",
            "name": "admin-company-deploy-trips",
          },
          'COUNTERMAN_URL': {
            "title": "Counterman",
            "path": "counterman",
            "name": "admin-company-deploy-Counterman",
          },
          'SUPERVISOR_URL': {
            "title": "Supervisor",
            "path": "supervisor",
            "name": "admin-company-deploy-supervisor",
          },
          'DRIVER_URL': {
            "title": "Driver",
            "path": "driver",
            "name": "admin-company-deploy-driver",
          },
        }
      }
    }
  },
  'PROFILE_URL': {
    "title": "Profile",
    "path": "profile",
    "name": "admin-profile",
  }
}

const Owner = {
  'path': '/owner',
  'name': 'owner',
  'DASHBOARD_URL': {
    "title": "Dashboard",
    "path": "dashboard",
    "name": "owner-dashboard",
    "permission": OwnerRoles.ownerDashboard,
  },
  'REPORT_URL': {
    "title": "Report",
    "path": "report",
    "name": "owner-report",
    "permission": OwnerRoles.ownerReports,
    "children": {
      // 'CHALAN_REPORT_URL': {
      //   "title": "Chalan Report",
      //   "path": "chalan-report",
      //   "name": "owner-report-chalan-report",
      // },
      'COACH_REPORT_URL': {
        "title": "Coach Wise",
        "path": "coach-report",
        "name": "owner-report-coach-report",
      },
      // 'COUNTER_REPORT_URL': {
      //   "title": "Counter Wise",
      //   "path": "counter-report",
      //   "name": "owner-report-counter-report",
      // },
      'DIGITAL_TICKET_REPORT_URL': {
        "title": "Digital Ticketing",
        "path": "digital-ticketing-report",
        "name": "owner-report-digital-ticketing-report",
      },
      // 'PRINT_COUNT_REPORT_URL': {
      //   "title": "Print Count",
      //   "path": "print-count",
      //   "name": "owner-report-print-count-report",
      // },
    }
  },
  // 'OWNERS_URL': {
  //     "title": "Owners",
  //     "path": "owners",
  //     "name": "owner-owners",
  //     "permission": OwnerRoles.ownerRead,
  //     "children": {
  //         'LIST_URL': {
  //             "title": "List",
  //             "path": "list",
  //             "name": "admin-admins-list",
  //         },
  //         'ADD_URL': {
  //             "title": "Add",
  //             "path": "add",
  //             "name": "admin-admins-add",
  //         },
  //         'UPDATE_URL': {
  //             "title": "Update",
  //             "path": "update",
  //             "name": "admin-admins-update",
  //         }
  //     }
  // },
  'SERVICES_URL': {
    "title": "Services",
    "path": "services",
    "name": "owner-services",
    "permission": OwnerRoles.ownerCompanySetupRead,
    "children": {
      // 'STOPPAGE_URL': {
      //   "title": "Stoppage",
      //   "path": "stoppage",
      //   "name": "owner-services-stoppage",
      // },
      // 'COUNTER_URL': {
      //   "title": "Counter",
      //   "path": "counter",
      //   "name": "owner-services-counter",
      // },
      // 'ROUTE_URL': {
      //   "title": "Route",
      //   "path": "route",
      //   "name": "owner-services-route",
      // },
      // 'SEAT_CLASS_URL': {
      //   "title": "Seat Class",
      //   "path": "seatClass",
      //   "name": "owner-services-seatClass",
      // },
      // 'SEAT_PLAN_URL': {
      //   "title": "Seat-Plan",
      //   "path": "seatPlan",
      //   "name": "owner-services-seatPlan",
      // },
      // 'SEAT_FARE_URL': {
      //   "title": "Seat-Fare",
      //   "path": "seatFare",
      //   "name": "owner-services-seatFare",
      // },
      'COACH_URL': {
        "title": "Coach",
        "path": "coach",
        "name": "owner-services-coach",
      },
      'TRIPS_URL': {
        "title": "Trips",
        "path": "trips",
        "name": "owner-services-trips",
      },
      'COUNTERMAN_URL': {
        "title": "Counterman",
        "path": "counterman",
        "name": "owner-services-Counterman",
      },
      'SUPERVISOR_URL': {
        "title": "Supervisor",
        "path": "supervisor",
        "name": "owner-services-supervisor",
      },
      'DRIVER_URL': {
        "title": "Driver",
        "path": "driver",
        "name": "owner-services-driver",
      },
    }
  },
  'PROFILE_URL': {
    "title": "PROFILE",
    "path": "profile",
    "name": "owner-profile",
  }
}

const Counterman = {
  'path': '/Counterman',
  'name': 'counterman',
  'DASHBOARD_URL': {
    "title": "Dashboard",
    "path": "dashboard",
    "name": "counterman-dashboard",
    "permission": CountermanRoles.ticketBooking,
  },
  'REPORT_URL': {
    "title": "Report",
    "path": "report",
    "name": "Counterman-report",
    "permission": CountermanRoles.reports,
  },
  'TRIPS_URL': {
    "title": "Trips",
    "path": "trip",
    "name": "Counterman-trip",
    "permission": CountermanRoles.trips,
  },
  'COACH_URL': {
    "title": "Coach",
    "path": "coach",
    "name": "Counterman-coach",
    "permission": CountermanRoles.coach,
  },
  'VERIFY_URL': {
    title: "Digital Ticket Verify",
    path: "verify",
    name: "counterman-verify",
  },
  'PROFILE_URL': {
    "title": "Profile",
    "path": "profile",
    "name": "Counterman-profile",
  }
}

export default {
  Default,
  Admin,
  Owner,
  Counterman
}
