import SecureLS from "secure-ls";
import Vuex from 'vuex';
import { createStore } from 'vuex-extensions';
import createPersistedState from "vuex-persistedstate";
import Admins from "./modules/Admin/admins";
import Company from "./modules/Admin/company";
import Dashboard from "./modules/Admin/dashboard";
import Report from "./modules/Admin/report";
import Auth from './modules/auth';
import CountermanDashboard from "./modules/Counterman/countermanDashboard";
import CountermanReport from "./modules/Counterman/countermanReport";
import CountermanCoach from "./modules/Counterman/countermanCoach";
import CountermanTrip from "./modules/Counterman/countermanTrip";
import CountermanVerify from "./modules/Counterman/countermanVerify";
import OwnerDashBoard from './modules/Owner/ownerDashboard';
import OwnerOwners from './modules/Owner/ownerOwners';
import OwnerReport from './modules/Owner/ownerReport';
import OwnerServices from "./modules/Owner/ownerServices";
var ls = new SecureLS({encodingType: 'base64', isCompression: false});
const store = createStore(Vuex.Store, {
    modules: {
        auth: Auth,
        admin: Admins,
        company: Company,
        report: Report,
        countermanDashboard: CountermanDashboard,
        countermanCoach: CountermanCoach,
        countermanTrip: CountermanTrip,
        countermanReport: CountermanReport,
        countermanVerify: CountermanVerify,
        dashboard: Dashboard,
        ownerDashBoard: OwnerDashBoard,
        ownerReport: OwnerReport,
        ownerOwners: OwnerOwners,
        ownerServices: OwnerServices,
    },
    plugins: [createPersistedState({
        key:'intercity',
        storage: {
            getItem: (key) => ls.get(key),
            setItem: (key, value) => ls.set(key, value),
            removeItem: (key) => ls.remove(key),
        }
    })]
})

export default store;
