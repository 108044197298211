<template>
  <LogHistory
      v-if="logHistory"
      v-model="logHistory"
      :tripId="tripIdIndividual"
  />
  <div v-else>
    <div v-if="addUpdate">
      <TripsAddUpdateModal
          v-bind:trip="tripId"
          v-bind:pagination="pagination"
          @close="close"
          @getAllTrips="getAllTrips"
      />
    </div>
    <FilterTab>
      <!-- <template v-slot:title><h6>Trips</h6></template> -->
      <template v-slot:body>
        <div class="col-sm-6 col-md-2">
          <DropdownFilter
              v-bind:id="'from'"
              v-bind:label="'From Stoppage'"
              v-bind:defaultOption="'--select stoppage--'"
              v-model="pagination.from"
              v-bind:defaultValue="''"
              v-bind:dropdown="$store.getters.getStoppageListForFilter"
          />
        </div>
        <div class="col-sm-6 col-md-2">
          <DropdownFilter
              v-bind:id="'to'"
              v-bind:label="'To Stoppage'"
              v-bind:defaultOption="'--select stoppage--'"
              v-model="pagination.to"
              v-bind:defaultValue="''"
              v-bind:dropdown="$store.getters.getStoppageListForFilter"
          />
        </div>
        <div class="col-sm-6 col-md-2">
          <DropdownFilter
              v-bind:id="'coach'"
              v-bind:label="'Coach'"
              v-bind:defaultOption="'--select coach--'"
              v-model="pagination.coach"
              v-bind:defaultValue="''"
              v-bind:dropdown="$store.getters.getAllCoachListForFilterAdmin"
          />
        </div>
        <div class="col-sm-6 col-md-2">
          <DatePickerFilter
              v-bind:id="'datePickerDate'"
              v-bind:label="'Date'"
              v-model="pagination.date"
          />
        </div>
        <div class="col-sm-6 col-md-2">
          <DropdownFilter
              v-bind:id="'status'"
              v-bind:label="'Status'"
              v-bind:defaultOption="'--select status--'"
              v-model="pagination.status"
              v-bind:defaultValue="''"
              v-bind:dropdown="status"
          />
        </div>
      </template>
      <template v-if="hasPermission(Admin.companyDeployWrite)" v-slot:button1>
        <button class="edit_table_data font_detail" @click="addModal">
          <i class="fas fa-plus"></i><span class="mx-1 bold">Add New</span>
        </button>
      </template>
    </FilterTab>
    <div>
      <Table v-if="$store.getters.getTrips">
        <template v-slot:header>
          <tr>
            <td class="font_detail">SL</td>
            <td class="font_detail">TRIP TIME & DATE</td>
            <td class="font_detail">TRIP CODE</td>
            <td class="font_detail">ROUTE NAME</td>
            <td class="font_detail">COACH NAME</td>
            <td class="font_detail">FROM STOPPAGE</td>
            <td class="font_detail">TO STOPPAGE</td>
            <td class="font_detail">SEATPLAN NAME</td>
            <td class="font_detail">DT STATUS</td>
            <td class="font_detail">STATUS</td>
            <td
                v-if="hasPermission(Admin.companyDeployWrite)"
                class="font_detail"
            >
              ACTION
            </td>
          </tr>
        </template>
        <template v-slot:body>
          <tr v-for="(trip, index) in $store.getters.getTrips" :key="trip._id">
            <td class="font_detail">
              {{ index + 1 + (pagination.page - 1) * 25 }}
            </td>
            <td class="font_detail bold">
              {{ dateFormat(trip.tripDateTime) }}
            </td>
            <td class="font_detail">{{ trip.tripCode }}</td>
            <td class="font_detail">{{ trip.route.name }}</td>
            <td class="font_detail">{{ trip.coach.name }}</td>
            <td class="font_detail">
              <span
                  v-for="[key, value] of Object.entries(
                  $store.getters.getStoppageListForFilter
                )"
                  :key="key.index"
              >
                <span v-if="key === trip.route.fromStoppageId">
                  {{ value }}
                </span>
              </span>
            </td>
            <td class="font_detail">
              <span
                  v-for="[key, value] of Object.entries(
                  $store.getters.getStoppageListForFilter
                )"
                  :key="key.index"
              >
                <span v-if="key === trip.route.toStoppageId">
                  {{ value }}
                </span>
              </span>
            </td>
            <td class="font_detail">{{ trip.seatPlan.name }}</td>
            <td class="font_detail">{{ dropdownVariables.boolean[trip.dtStatus ? 1 : 0] }}</td>
            <!-- <td class="font_detail">{{ trip.tripCode }}</td>
            <td class="font_detail bold">{{ trip.ship.name }}</td>
            <td class="font_detail">{{ trip.route.name }}</td> -->
            <td>
              <span
                  class="badge"
                  :class="'bg-' + dropdownVariables.tripStatus[trip.status].badge"
              >{{
                  dropdownVariables.tripStatus[trip.status].value ===
                  "Cancelled"
                      ? `${dropdownVariables.tripStatus[trip.status].value} by ${
                          trip.cancelledBy.name
                      }`
                      : dropdownVariables.tripStatus[trip.status].value
                }}</span
              >
            </td>
            <td v-if="hasPermission(Admin.companyDeployWrite)">
<!--              <button-->
<!--                  class="edit_table_data font_detail edit_table_button"-->
<!--                  @click="handleLogHistory(trip._id)"-->
<!--              >-->
<!--                <i class="fas fa-history"></i><span class="mx-1">History</span>-->
<!--              </button>-->
              <button
                  class="edit_table_data font_detail edit_table_button"
                  @click="updateModal(trip._id)"
              >
                <i class="fas fa-edit"></i><span class="mx-1">Edit</span>
              </button>
              <button
                  v-if="trip.status === 'ACTIVE'"
                  class="edit_table_data font_detail edit_table_button_update"
                  @click="cancel(trip._id)"
              >
                <i class="fas fa-trash"></i><span class="mx-1">Cancel</span>
              </button>
            </td>
          </tr>
        </template>
        <template v-slot:pagination>
          <pagination
              v-model="pagination.page"
              :records="$store.getters.getTotalTrips"
              :per-page="pagination.limit"
              @paginate="getAllTrips"
              :options="options"
          />
        </template>
      </Table>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import {reactive} from "@vue/reactivity";
import {inject, onBeforeMount, ref, watch, computed} from "vue";
import store from "@/store";
import dropdownVariables from "@/modules/dropdownVariables";
import {useRoute} from "vue-router";
import TripsAddUpdateModal from "@/views/Admin/CompanyDeploy/Children/Trips/TripsAddUpdateModal";
import permission from "@/modules/permission";
import roles from "@/modules/roles";
import LogHistory from "./LogHistory.vue";

const {hasPermission} = permission();
const {Admin} = roles();

export default {
  name: "Trips",
  components: {TripsAddUpdateModal, LogHistory},
  setup() {
    const route = useRoute();
    let pagination = reactive({
      page: 1,
      limit: 25,
      companyId: "",
      fareType: "predefined",
      from: "",
      to: "",
      coach: "",
      date: moment(new Date()).format("yyyy-MM-DD"),
      status: "ACTIVE",
    });

    let tripIdIndividual = ref("");
    let logHistory = ref(false);
    const addUpdate = ref(false);
    const tripId = ref("");
    const options = {
      texts: {
        count: "",
      },
    };
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");

    watch(
        () => pagination.status,
        () => {
          getAllTrips();
        }
    );
    watch(
        () => pagination.from,
        () => {
          getAllTrips();
        }
    );
    watch(
        () => pagination.to,
        () => {
          getAllTrips();
        }
    );
    watch(
        () => pagination.date,
        () => {
          getAllTrips();
        }
    );
    watch(
        () => pagination.coach,
        () => {
          getAllTrips();
        }
    );

    const status = computed(() => {
      let allTripStatus = {};
      for (let [key, value] of Object.entries(dropdownVariables.tripStatus)) {
        allTripStatus[key] = value.value;
      }
      return allTripStatus;
    });

    const close = () => {
      addUpdate.value = false;
    };
    const addModal = () => {
      tripId.value = "";
      addUpdate.value = true;
    };
    const updateModal = (id) => {
      tripId.value = id;
      addUpdate.value = true;
    };
    const dateFormat = (date) => {
      return moment(String(date))
          .utcOffset(0, false)
          .format("DD-MMM-yyyy hh:mm:ss A");
    };
    const getAllRoutes = () => {
      showLoader();
      store
          .dispatch("getRoutesByPage", {page: 1, limit: 100})
          .then(() => {
            getStoppageList();
            hideLoader();
          })
          .catch((error) => {
            hideLoader();
            if (error.status === 401) {
              getAllRoutes();
            }
          });
    };
    const getAllTrips = () => {
      showLoader();
      store
          .dispatch("getTripsByPage", pagination)
          .then(() => {
            hideLoader();
          })
          .catch((error) => {
            hideLoader();
            if (error.status === 401) {
              getAllTrips();
            }
          });
    };
    const getAllStoppages = () => {
      showLoader();
      store
          .dispatch("getStoppagesByPage", {page: 1, limit: 100})
          .then(() => {
            hideLoader();
          })
          .catch((error) => {
            hideLoader();
            if (error.status === 401) {
              getAllStoppages();
            }
          });
    };
    const getStoppageList = () => {
      store
          .dispatch("getStoppageList", {companyId: pagination.companyId})
          .then(() => {
            hideLoader();
          })
          .catch((error) => {
            hideLoader();
            if (error.status === 401) {
              getStoppageList();
            }
          });
    };
    const getAllCoach = () => {
      showLoader();
      store
          .dispatch("getAllCoachByPage", {page: 1, limit: 100})
          .then(() => {
            hideLoader();
          })
          .catch(() => {
            hideLoader();
          });
    };
    const getRouteListFilter = () => {
      store.dispatch("getRouteListFilter", {companyId: route.query.id});
    }
    const getAllSeatPlan = () => {
      store.dispatch("getAllSeatPlan", {companyId: route.query.id});
    }
    const getAllCoachList = () => {
      store.dispatch("getAllCoachList", {companyId: route.query.id});
    }

    const cancel = (id) => {
      showLoader();
      store
          .dispatch("cancelTrip", {
            payload: {id: id, companyId: route.query.id},
          })
          .then(() => {
            getAllTrips();
            hideLoader();
          })
          .catch((error) => {
            hideLoader();
            if (error.status === 401) {
              getAllTrips();
            }
          });
    };

    const handleLogHistory = (tripId) => {
      logHistory.value = true;
      tripIdIndividual.value = tripId;
    };

    onBeforeMount(() => {
      if (route.query.id) {
        pagination.companyId = route.query.id;
        getAllRoutes();
        getAllTrips();
        getAllCoach();
        getAllStoppages();
        getStoppageList();
        getRouteListFilter();
        getAllSeatPlan();
        getAllCoachList();
      }
    });

    return {
      options,
      pagination,
      dropdownVariables,
      addUpdate,
      tripId,
      Admin,
      cancel,
      hasPermission,
      getAllTrips,
      addModal,
      updateModal,
      close,
      dateFormat,
      status,
      handleLogHistory,
      logHistory,
      tripIdIndividual,
    };
  },
};
</script>

<style scoped>
.bold {
  font-weight: bold;
}
</style>
