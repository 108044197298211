<template>
  <label class="my-auto">
    <span> {{ t(inputLabel, {}, { locale: $store.getters.getLang }) }}</span>
    <span class="mx-2"> <i class="fas fa-mobile-alt"></i></span>
  </label>
  <div class="input-group form-width mt-md-2">
    <!--    <span class="input-group-text font_detail p-md-2" id="basic-addon1"-->
    <!--      >+880</span-->
    <!--    >-->
    <input
        class="form-control font_detail form-width"
        aria-label="Passenger Mobile"
        aria-describedby="basic-addon1"
        :id="inputId"
        v-model="input"
        :type="inputType"
        :placeholder="inputPlaceholder"
        autocomplete="on"
    />
    <slot name="search-btn"></slot>
  </div>
  <slot name="error"></slot>
</template>

<script>
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";
export default {
  name: "Input",
  props: {
    modelValue: [String, Number],
    placeholder: String,
    label: String,
    id: String,
    type: String,
  },
  setup(props, { emit }) {
    const inputPlaceholder = ref(props.placeholder);
    const inputLabel = ref(props.label);
    const inputId = ref(props.id);
    const inputType = ref(props.type);
    const input = ref(props.modelValue);
    const { t, locale } = useI18n();
    watch(
        () => input.value,
        () => {
          emit("update:modelValue", input.value);
        }
    );
    watch(
        () => props.modelValue,
        (value) => {
          input.value = value;
        }
    );
    return {
      t,
      locale,
      inputPlaceholder,
      inputLabel,
      inputId,
      inputType,
      input,
    };
  },
};
</script>

<style scoped>
@media only screen and (max-width: 767px) {
  .booking_info {
    margin-bottom: 4px;
  }
  label {
    font-size: 11px;
  }
  input.form-width {
    width: 132px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  input.form-width {
    width: 150px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  input.form-width {
    width: 100px !important;
  }
}
/* @media only screen and (max-width: 613px) and (min-width: 360px) {
  input.form-width  {
    width: 210px !important;
  }
} */
.font_detail {
  /* font-size: 15px !important; */
  /* font-family: Helvetica Neue; */
  font-style: normal;
  font-weight: 500;
  margin-left: 5px;
}
.form_width {
  width: 350px !important;
}
.form-control:focus {
  border-color: #f04935 !important;
  box-shadow: 0 0 0 2px rgba(255, 99, 71, 25%) !important;
}
.booking_info {
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: 5px;
}
</style>
