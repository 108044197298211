<template>
  <Modal @close="closeModal">
    <template v-slot:title>Remove Route Fare</template>
    <template v-slot:body>
      Are you sure you want to remove this Route Fare?
      <div class="form-group row my-1">
        <label class="col-sm-3 col-form-label"></label>
        <div class="col-sm-9">
          <input
            type="submit"
            class="mt-3 py-2 submit_button float-end font_detail"
            value="Remove"
            @click.prevent="removeRouteFare"
          />
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
export default {
  name: "SeatFareCardConfirmDeleteModal",
  props: {
    fareId: String,
  },
  setup(props, { emit }) {
    const closeModal = () => {
      emit("update:modelValue", false);
    };
    const removeRouteFare = () => {
      emit("deleteSeatFare", props.fareId);
    };
    return {
      closeModal,
      removeRouteFare,
    };
  },
};
</script>

<style scoped></style>
