<template>
  <Modal @close="closeModal" v-bind:size="'xl'">
    <template v-slot:title>{{ title }}</template>
    <template v-slot:body>
      <form>
        <div class="auth_form py-3">
          <div class="row my-2">
            <div class="col-md-4">
              <Input
                  v-model="driverData.name"
                  v-bind:placeholder="'Enter Driver Name'"
                  v-bind:label="'Name'"
                  v-bind:id="'name'"
                  v-bind:type="'text'"
              />
              <Error v-if="errors.name" v-bind:error="errors.name"/>
            </div>
            <div class="col-md-4">
              <Input
                  v-model="driverData.phone"
                  v-bind:placeholder="'Enter Phone Number'"
                  v-bind:label="'Phone'"
                  v-bind:id="'phone'"
                  v-bind:type="'text'"
              />
              <Error v-if="errors.phone" v-bind:error="errors.phone"/>
            </div>

            <div class="col-md-4">
              <Input
                  v-model="driverData.email"
                  v-bind:placeholder="'Email Address'"
                  autocomplete="off"
                  v-bind:label="'Email'"
                  v-bind:id="'email'"
                  v-bind:type="fieldTypes.email"
              />
            </div>
<!--            <div class="col-md-3">-->
<!--              <Dropdown-->
<!--                  v-if="depot"-->
<!--                  v-model="driverData.depot"-->
<!--                  v-bind:id="'depot'"-->
<!--                  v-bind:label="'Depot'"-->
<!--                  v-bind:dropdown="depot"-->
<!--                  v-bind:hideLabel="'inactive'"-->
<!--                  v-bind:defaultOption="'Select Depot'"-->
<!--                  v-bind:defaultValue="''"-->
<!--              />-->
<!--              <Error v-if="errors.depot" v-bind:error="errors.depot"/>-->
<!--            </div>-->
          </div>
          <div class="row my-2">
            <div class="col-md-4">
              <label for="address" class="form-label all_permission_label">
                Address
              </label>
              <div>
                <textarea
                    class="form-control font_detail"
                    id="address"
                    v-model="driverData.address"
                ></textarea>
              </div>
            </div>
            <div class="col-md-4">
              <Input
                  v-model="driverData.nid"
                  v-bind:placeholder="'NID Number'"
                  v-bind:label="'NID'"
                  v-bind:id="'nid'"
                  v-bind:type="'text'"
              />
            </div>
            <div class="col-md-4">
              <Dropdown
                  v-if="status"
                  v-model="driverData.status"
                  v-bind:id="'status'"
                  v-bind:label="'Status'"
                  v-bind:dropdown="status"
                  v-bind:hideLabel="'inactive'"
              />
            </div>
          </div>
        </div>

        <div class="form-group row my-1">
          <label class="col-sm-3 col-form-label"></label>
          <div class="col-sm-9">
            <input
                v-if="!driverIdForUpdate"
                type="submit"
                class="mt-3 py-2 submit_button font_detail float-end"
                @click.prevent="addDriver"
            />
            <input
                v-else
                type="submit"
                class="mt-3 py-2 submit_button font_detail float-end"
                @click.prevent="updateDriver"
            />
          </div>
        </div>
      </form>
    </template>
  </Modal>
</template>

<script>
import {reactive} from "@vue/reactivity";
import dropdownVariables from "@/modules/dropdownVariables";
import useInputValidation from "@/modules/useInputValidations";
import {inject, onBeforeMount, ref, watch} from "vue";
import store from "@/store";

export default {
  name: "SupervisorAddUpdateModal",
  components: {},
  props: {
    modalValue: Boolean,
    company: String,
    driver: String,
  },
  setup(props, {emit}) {
    let driverData = reactive({
      id: "",
      name: "",
      depot: "",
      designation: "",
      phone: "",
      email: "",
      nid: "",
      address: "",
      avatar: "",
      transactionType: "",
      balance: "",
      commission: "",
      userType: "counterman",
      companyId: "",
      commissionType: "",
      status: "1",
      api_token: "",
      device_token: "",
      printingType: "Individual",
      reset_token: "",
      printerType: "",
    });
    let driverIdForUpdate = ref(props.driver);
    let companyId = ref(props.company);
    let {requiredValidation, phoneValidation, errors} = useInputValidation();
    let printerType = dropdownVariables.countermanPrinterType; //["T2mini", "Bluetooth"];
    const status = dropdownVariables.status;
    const printingType = dropdownVariables.printingType;
    const depot = store.getters.getDepotListForFilter;
    const title = ref("Add Driver");
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");

    let fieldTypes = reactive({
      email: "text",
      password: "text",
    });

    watch(
        () => driverData.name,
        () => {
          requiredValidation("name", driverData.name);
        }
    );

    watch(
        () => driverData.phone,
        () => {
          phoneValidation("phone", driverData.phone);
        }
    );

    watch(
        () => driverData.printerType,
        () => {
          requiredValidation("printerType", driverData.printerType);
        }
    );

    watch(
        () => driverData.email,
        () => {
          fieldTypes.email = "email";
        }
    );

    const closeModal = () => {
      emit("update:modelValue", false);
    };

    const validate = () => {
      Object.keys(errors).forEach(function (key) {
        errors[key] = "";
      });
      requiredValidation("name", driverData.name);
      phoneValidation("phone", driverData.phone);
      // requiredValidation("depot", driverData.depot);

      return !(
          Object.values(errors).length <= 0 ||
          !Object.values(errors).every((x) => x === null || x === "")
      );
    };
    const addDriver = () => {
      if (validate()) {
        showLoader();
        store
            .dispatch("addDriver", driverData)
            .then(() => {
              hideLoader();
              emit("getAllDrivers");
              closeModal();
            })
            .catch((error) => {
              hideLoader();
              if (error.status === 401) {
                addDriver();
              }
            });
      }
    };
    const updateDriver = () => {
      if (validate()) {
        showLoader();
        store
            .dispatch("updateDriver", driverData)
            .then(() => {
              hideLoader();
              emit("getAllDrivers");
              closeModal();
            })
            .catch((error) => {
              hideLoader();
              if (error.status === 401) {
                updateDriver();
              }
            });
      }
    };

    onBeforeMount(() => {
      Object.keys(errors).forEach(function (key) {
        delete errors[key];
      });
      driverData.companyId = companyId.value;
      if (driverIdForUpdate.value !== "") {
        const driverDetails = store.getters.getDriver(driverIdForUpdate.value);
        title.value = "Update Driver";
        driverData.id = driverDetails[0]._id;
        driverData.companyId = driverDetails[0].companyId;
        driverData.name = driverDetails[0].name;
        // driverData.depot = driverDetails[0].depot?._id;
        driverData.phone = driverDetails[0].phone;
        driverData.email = driverDetails[0].email;
        driverData.printerType = driverDetails[0].printerType;
        driverData.status = driverDetails[0].status ? 1 : 0;
        driverData.printingType = driverDetails[0].printingType;
        driverData.nid = driverDetails[0].nid;
        driverData.address = driverDetails[0].address;
      }
    });

    return {
      status,
      driverData,
      errors,
      title,
      driverIdForUpdate,
      printingType,
      fieldTypes,
      depot,
      updateDriver,
      addDriver,
      closeModal,
      printerType,
    };
  },
};
</script>

<style scoped></style>
