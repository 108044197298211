<template>
  <!--  <div class="card">-->
  <div>
    <div v-if="companyDeployTabs">
      <ul
        class="nav nav-tabs justify-content-center nav-style"
        id="myTab"
        role="tablist"
      >
        <li
          v-for="(tab, index) in companyDeployTabs"
          :key="index"
          class="nav-item"
          role="presentation"
        >
          <button
            class="nav-link"
            :id="tab.name + '-tab'"
            data-bs-toggle="tab"
            v-bind:class="
              tab.name === activeTab
                ? 'active active-button'
                : 'inactive-button'
            "
            type="button"
            @click="setActive(tab.name)"
            role="tab"
            :aria-controls="tab.path"
            aria-selected="true"
          >
            {{ t(tab?.title, {}, { locale: $store.getters.getLang }) }}
          </button>
        </li>
      </ul>
      <div class="tab-content" id="myTabContent">
        <div
          v-for="(tab, index) in companyDeployTabs"
          :key="index"
          class="tab-pane fade"
          v-bind:class="tab.name === activeTab ? 'show active' : ''"
          :id="tab.path"
          role="tabpanel"
          :aria-labelledby="tab.name + '-tab'"
        ></div>
<!--        <Stoppage v-if="activeTab === companyDeployTabs.STOPPAGE_URL?.name" />-->
<!--        <Route v-if="activeTab === companyDeployTabs.ROUTE_URL?.name" />-->
<!--        <Counter v-if="activeTab === companyDeployTabs.COUNTER_URL?.name" />-->
<!--        <SeatClass v-if="activeTab === companyDeployTabs.SEAT_CLASS_URL?.name"/>-->
<!--        <SeatPlan v-if="activeTab === companyDeployTabs.SEAT_PLAN_URL?.name" />-->
<!--        <SeatFare v-if="activeTab === companyDeployTabs.SEAT_FARE_URL?.name"/>-->
        <Coach v-if="activeTab === companyDeployTabs.COACH_URL?.name" />
        <Trips v-if="activeTab === companyDeployTabs.TRIPS_URL?.name" />
        <Counterman v-if="activeTab === companyDeployTabs.COUNTERMAN_URL?.name"/>
        <Supervisor v-if="activeTab === companyDeployTabs.SUPERVISOR_URL?.name"/>
        <Driver v-if="activeTab === companyDeployTabs.DRIVER_URL?.name" />
      </div>
    </div>
  </div>
</template>

<script>
import web from "@/urls/web";
// import Stoppage from "./Children/Stoppage/Stoppage.vue";
// import Route from "./Children/Routes/Routes";
import { onBeforeMount, onBeforeUnmount, ref } from "vue";
import store from "@/store";
import Counterman from "./Children/counterman/Counterman.vue";
// import SeatClass from "./Children/SeatClass/SeatClass.vue";
// import SeatPlan from "./Children/SeatPlan/SeatPlan/SeatPlan.vue";
// import SeatFare from "./Children/SeatFare/SeatFare/SeatFare.vue";
// import Counter from "./Children/Counter/Counter";
import Trips from "./Children/Trips/Trips.vue";
import Coach from "./Children/Coach/Coach.vue";
import Supervisor from "./Children/Supervisor/Supervisor.vue";
import Driver from "./Children/Driver/Driver.vue";
// import permission from "@/modules/permission";
// import roles from "@/modules/roles";
import { useI18n } from "vue-i18n";
// const {hasPermission} = permission();
// const { Owner } = roles();

export default {
  name: "CompanyManage",
  components: {
    // Route,
    // Stoppage,
    // Counter,
    Trips,
    Counterman,
    // SeatClass,
    // SeatPlan,
    // SeatFare,
    Coach,
    Supervisor,
    Driver,
  },
  setup() {
    const { t, locale } = useI18n();
    let activeTab = ref("");
    let companyDeployTabs = ref({ ...web.Owner.SERVICES_URL.children });

    const setActive = (index) => {
      activeTab.value = index;
      store.commit("setActiveTab", activeTab.value);
    };

    onBeforeMount(() => {
      activeTab.value = store.getters.getActiveTab
        ? store.getters.getActiveTab
        : companyDeployTabs.value.COACH_URL.name;
    });
    onBeforeUnmount(() => {
      if (store.getters.isLoggedIn) {
        store.commit("setActiveTab", "");
      }
    });

    return {
      t,
      locale,
      companyDeployTabs,
      activeTab,
      setActive,
    };
  },
};
</script>

<style scoped>
/*.card {*/
/*  padding: 20px 30px;*/
/*}*/

.nav-style {
  background: #3b3b3b;
  border-top: 1px;
}

.active-button {
  background: #242424 !important;
  border: 1px solid #242424 !important;
  color: #fff !important;
}

.inactive-button {
  color: #fff !important;
}
</style>
