<template>
  <div v-if="reportActive">
    <FilterTab>
      <template v-slot:body>
        <div class="col-sm-6 col-md-2">
          <DropdownFilter
              v-bind:id="'type'"
              v-bind:label="'Type'"
              v-bind:defaultOption="'--select digital ticketing type--'"
              v-model="tripWiseDtReport.type"
              v-bind:defaultValue="''"
              v-bind:dropdown="dropdownVariables.dtTypes"
          />
        </div>
        <div class="col-sm-6 col-md-2" v-if="tripWiseDtReport.type === 'intercity'">
          <DropdownFilter
              v-bind:id="'depot'"
              v-bind:label="'Depot'"
              v-bind:defaultOption="'--select depot--'"
              v-model="tripWiseDtReport.depot"
              v-bind:defaultValue="''"
              v-bind:dropdown="$store.getters.getOwnerDepotsReport"
          />
        </div>
        <div class="col-sm-6 col-md-4" v-if="tripWiseDtReport.type">
          <DateTimeRangePicker v-model="date.range"/>
        </div>
        <div
            class="col-sm-6 col-md-2 col-xxl-1"
            v-if="tripWiseDtReport.type"
        >
          <DropdownFilter
              v-bind:id="'status'"
              v-bind:label="'Status'"
              v-bind:defaultOption="'--select status--'"
              v-model="tripWiseDtReport.status"
              v-bind:defaultValue="''"
              v-bind:dropdown="dropdownVariables.status"
          />
        </div>
      </template>
      <template v-slot:button2>
        <button class="edit_table_data font_detail" @click="filterReport">
          <i class="fas fa-filter"></i><span class="mx-1 bold">Filter</span>
        </button>
      </template>
      <template v-slot:button1>
        <button
            v-if="
            $store.getters.getDtReportOwner.length > 0
          "
            class="edit_table_data font_detail"
            @click="
            exportExcel(
              'xlsx',
              'Admin_Coach_Wise_Report.',
              undefined,
              'exportable_data'
            )
          "
        >
          <i class="fas fa-download"></i><span class="mx-1 bold">Export</span>
        </button>
      </template>
    </FilterTab>
    <div
        class="alert alert-warning"
        role="alert"
        v-if="tripWiseDtReport.type === '' && selectType"
    >
      Please,Select a
      <a href="#" class="alert-link">digital ticketing type</a> from dropdown
    </div>
    <div
        class="alert alert-warning"
        role="alert"
        v-if="tripWiseDtReport.type === 'intercity' && tripWiseDtReport.depot === '' && selectType"
    >
      Please,Select a
      <a href="#" class="alert-link">depot</a> from dropdown
    </div>
    <Table
        v-if="
        tripWiseDtReport.type !== '' &&
        tripWiseDtReport.type === 'intercity' &&
        filterActive &&
        $store.getters.getDtReportOwner.length > 0
      "
        id="exportable_data"
    >
      <template v-slot:header>
        <tr>
          <td class="font_detail">SL</td>
          <td class="font_detail">Trip Date Time</td>
          <td class="font_detail">Coach</td>
          <td class="font_detail">Route</td>
          <td class="font_detail">Trip Code</td>
          <td class="font_detail">Seat Ticket</td>
          <td class="font_detail">Seat Amount</td>
          <td class="font_detail">Details</td>
        </tr>
      </template>
      <template v-slot:body>
        <tr
            v-for="(item, index) in $store.getters
            .getDtReportOwner"
            :key="item._id"
        >
          <td class="font_detail">{{ index + 1 }}</td>
          <td class="font_detail">{{ dateFormat(item?.tripInfo?.tripDateTime) }}</td>
          <td class="font_detail">{{ item?.coach }}</td>
          <td class="font_detail">{{ item?.route }}</td>
          <td class="font_detail">{{ item?.tripInfo?.tripCode }}</td>
          <td class="font_detail">{{ item?.seatTicket }}</td>
          <td class="font_detail">{{ item?.seatAmount }} Taka</td>
          <td class="font_detail">
            <button
                class="btn btn-primary m-1"
                @click="intercityDetails(item?.tripInfo?._id)"
            >
              Seat
            </button>
          </td>
        </tr>
        <tr class="bg-dark text-white">
          <td class="font_detail"></td>
          <td class="font_detail"></td>
          <td class="font_detail"></td>
          <td class="font_detail"></td>
          <td class="font_detail"></td>
          <td class="font_detail text-white">
            Total Ticket: {{ totalTicket }}
          </td>
          <td class="font_detail text-white">
            Total Amount: {{ totalAmount }} Taka
          </td>
          <td class="font_detail"></td>
        </tr>
      </template>
    </Table>

    <Table
        v-else-if="
        tripWiseDtReport.type !== '' &&
        tripWiseDtReport.type === 'sd_intercity' &&
        filterActive &&
        $store.getters.getDtReportOwner.length > 0
      "
        id="exportable_data"
    >
      <template v-slot:header>
        <tr>
          <td class="font_detail">SL</td>
          <td class="font_detail">Date</td>
          <td class="font_detail">Total Ticket</td>
          <td class="font_detail">Total Amount</td>
        </tr>
      </template>
      <template v-slot:body>
        <tr
            v-for="(item, index) in $store.getters
            .getDtReportOwner"
            :key="item._id"
        >
          <td class="font_detail">{{ index + 1 }}</td>
          <td class="font_detail">{{ dateFormat(item?._id) }}</td>
          <td class="font_detail">{{ item?.totalTickets }}</td>
          <td class="font_detail">{{ item?.totalAmount }} Taka</td>
        </tr>
        <tr class="bg-dark text-white">
          <td class="font_detail"></td>
          <td class="font_detail"></td>
          <td class="font_detail text-white">
            Total Ticket: {{ totalTicket }}
          </td>
          <td class="font_detail text-white">
            Total Amount: {{ totalAmount }} Taka
          </td>
        </tr>
      </template>
    </Table>
  </div>
  <DtReportDetails v-if="!reportActive && detailsActive"
                   v-on:backToDtReport="onChildClick"
                   v-bind:tripWiseDtReport="tripWiseDtReport"
  />
</template>

<script>
import dropdownVariables from "@/modules/dropdownVariables";
import {reactive} from "@vue/reactivity";
import moment from "moment";
import {watch, onBeforeMount, ref, inject} from "vue";
import store from "@/store";
import DtReportDetails from "@/views/Owner/Report/Children/DigitalTicket/DtReportDetails";

export default {
  name: "DigitalTicketReport",
  components: {
    DtReportDetails
  },
  setup() {
    let reportActive = ref(true);
    let detailsActive = ref(false);
    let filterActive = ref(false);
    let selectType = ref(false);
    let totalTicket = ref(0);
    let totalAmount = ref(0);
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    const exportExcel = inject("exportExcel");
    let summary = ref(false);

    const fromDate = moment(new Date()).format("yyyy-MM-DD" + " 00:00:00");
    const toDate = moment(new Date()).format("yyyy-MM-DD" + " 23:59:59");

    let date = reactive({
      range: {
        start: fromDate,
        end: toDate,
      },
    });

    let tripWiseDtReport = reactive({
      trip: "",
      depot: "",
      date: "",
      fromDate: "",
      toDate: "",
      type: "",
      userType: "ONLINE",
      status: 1,
    });

    const options = {
      texts: {
        count: "",
      },
    };

    watch(
        () => date.range.start,
        (value) => {
          tripWiseDtReport.fromDate = moment(value).format(
              "yyyy-MM-DD" + " 00:00:00"
          );
        }
    );
    watch(
        () => date.range.end,
        (value) => {
          tripWiseDtReport.toDate = moment(value).format(
              "yyyy-MM-DD" + " 23:59:59"
          );
        }
    );
    watch(
        () => tripWiseDtReport.type,
        () => {
          filterActive.value = false
        }
    );
    const getAllDtReport = () => {
      showLoader();
      store
          .dispatch("getDtReportOwner", tripWiseDtReport)
          .then((response) => {
            if (response.length === 0) {
              summary.value = false;
              totalTicket.value = 0;
              totalAmount.value = 0;
            } else {
              summary.value = true;
              totalTicket.value = 0;
              totalAmount.value = 0;
              sumListData();
            }
            hideLoader();
          })
          .catch((error) => {
            hideLoader()
            if (error.status === 401) {
              getAllDtReport();
            }
          });
    };
    const dateFormat = (date) => {
      if (tripWiseDtReport.type === 'intercity') {
        return moment(new Date(String(date)))
            .utcOffset(0, false)
            .format("DD-MMM-yyyy hh:mm:ss A");
      } else {
        return moment(new Date(String(date)))
            .utcOffset(0, false)
            .format("DD-MMM-yyyy");
      }
    };
    const filterReport = () => {
      selectType.value = true;
      if (tripWiseDtReport.type === "intercity" && tripWiseDtReport.depot !== "") {
        filterActive.value = true;
        getAllDtReport();
      }else if(tripWiseDtReport.type === "sd_intercity"){
        filterActive.value = true;
        getAllDtReport();
      }
    };

    const sumListData = () => {
      store.getters.getDtReportOwner.forEach((data) => {
        if (tripWiseDtReport.type === 'intercity') {
          totalTicket.value += data.seatTicket;
          totalAmount.value += data.seatAmount;
        } else {
          totalTicket.value += data.totalTickets;
          totalAmount.value += data.totalAmount;
        }
      });
    };

    onBeforeMount(() => {
      tripWiseDtReport.fromDate = fromDate;
      tripWiseDtReport.toDate = toDate;
    });

    const intercityDetails = (trip) => {
      tripWiseDtReport.trip = trip;
      reportActive.value = false;
      detailsActive.value = true;
    };
    const sdIntercityDetails = (date) => {
      tripWiseDtReport.date = date;
      reportActive.value = false;
      detailsActive.value = true;
    };

    const onChildClick = (value) => {
      reportActive.value = value;
      detailsActive.value = !value;
    };

    return {
      dropdownVariables,
      date,
      tripWiseDtReport,
      filterReport,
      intercityDetails,
      dateFormat,
      sdIntercityDetails,
      reportActive,
      detailsActive,
      filterActive,
      selectType,
      onChildClick,
      totalAmount,
      totalTicket,
      exportExcel,
      summary,
      options
    };
  },

};
</script>

<style scoped></style>
