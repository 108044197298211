<template>
  <label class="my-auto">
    <span>{{ t(inputLabel, {}, { locale: $store.getters.getLang }) }}</span>
    <span class="mx-2" :class="inputColor"> <i :class="inputIcon"></i></span>
  </label>
  <input
    class="form-control form_width font_detail p-md-2 mt-md-2"
    :id="inputId"
    v-model="input"
    :type="inputType"
    :placeholder="inputPlaceholder"
    autocomplete="on"
  />
  <!-- <div class="col-sm-3">
    <label :for="inputId" class="font_detail">{{ inputLabel }} :</label>
  </div> -->

  <slot name="error"></slot>
</template>

<script>
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";
export default {
  name: "Input",
  props: {
    modelValue: [String, Number],
    placeholder: String,
    label: String,
    id: String,
    type: String,
    icon: String,
    color: String,
  },
  setup(props, { emit }) {
    const inputPlaceholder = ref(props.placeholder);
    const inputLabel = ref(props.label);
    const inputId = ref(props.id);
    const inputType = ref(props.type);
    const input = ref(props.modelValue);
    const inputIcon = ref(props.icon);
    const inputColor = ref(props.color);
    const { t, locale } = useI18n();
    watch(
      () => input.value,
      () => {
        emit("update:modelValue", input.value);
      }
    );
    watch(
      () => props.modelValue,
      (value) => {
        input.value = value;
      }
    );
    return {
      t,
      locale,
      inputPlaceholder,
      inputLabel,
      inputId,
      inputType,
      input,
      inputIcon,
      inputColor,
    };
  },
};
</script>

<style scoped>
@media only screen and (max-width: 767px) {
  .booking_info {
    margin-bottom: 4px;
  }
  label {
    font-size: 11px;
  }
  input.form_width {
    width: 180px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  input.form_width {
    width: 200px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  input.form_width {
    width: 200px !important;
  }
}
/* @media only screen and (max-width: 613px) and (min-width: 360px) {
  input.form_width {
    width: 210px !important;
  }
} */
.font_detail {
  /* font-size: 15px !important; */
  /* font-family: Helvetica Neue; */
  font-style: normal;
  font-weight: 500;
  margin-left: 5px;
}
.form_width {
  width: 350px !important;
}
.form-control:focus {
  border-color: #f04935 !important;
  box-shadow: 0 0 0 2px rgba(255, 99, 71, 25%) !important;
}
.booking_info {
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: 5px;
}
</style>
