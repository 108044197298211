<template>
  <div
    class="card"
    style="
      width: 15rem;
      border: 0;
      border-bottom: 7px solid #f04935;
      border-radius: 5px;
    "
  >
    <div class="card-header p-1" style="background-color: #f04935">
      {{ seatNo }}
    </div>
    <div class="card-body p-2">
      <table class="table table-striped mb-0">
        <tbody>
          <tr>
            <td>Name</td>
            <td>{{ toolTip?.passengerName }}</td>
          </tr>
          <tr>
            <td>Phone</td>
            <td>{{ toolTip?.passengerPhone }}</td>
          </tr>
          <tr>
            <td>From-To</td>
            <td>
              {{ toolTip?.fromStoppage?.name }}-{{ toolTip?.toStoppage?.name }}
            </td>
          </tr>
          <tr>
            <td>Counterman</td>
            <td v-if="toolTip?.issuedBy">
              {{ toolTip?.issuedBy?.name }}({{
                toolTip?.issuedBy?.counterId?.name
              }})
            </td>
            <td v-else>
              {{ toolTip?.userType }}
            </td>
          </tr>
          <tr>
            <td>PNR</td>
            <td>
              <span class="badge bg-primary my-1">{{ toolTip?.pnrCode }}</span>
            </td>
          </tr>
          <tr>
            <td>Issued On</td>
            <td>{{ dateFormat(toolTip?.ticketDateTime) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import moment from "moment";
export default {
  props: {
    tooltipInfo: Object,
    seat: String,
  },
  setup(props) {
    let toolTip = ref(props.tooltipInfo);
    let seatNo = ref(props.seat);
    const dateFormat = (date) => {
      return moment(new Date(String(date)))
        .utcOffset(0, true)
        .format("DD-MMM-yyyy hh:mm:ss A");
    };

    watch(
      () => props.tooltipInfo,
      (value) => {
        toolTip.value = value;
      }
    );
    watch(
      () => props.seat,
      (value) => {
        seatNo.value = value;
      }
    );

    return {
      toolTip,
      dateFormat,
      seatNo,
    };
  },
};
</script>

<style scoped>
td {
  padding: 2px;
  font-size: 12px !important;
}
</style>
