<template>
  <div v-if="!reportPrint && hasPermission(Counterman.reports)">
    <div class="accordion d-md-none">
      <div class="accordion-item">
        <h2 class="accordion-header" id="panelsStayOpen-headingOne">
          <button
            class="accordion-button collapsed p-2"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#panelsStayOpen-collapseOne"
            aria-expanded="true"
            aria-controls="panelsStayOpen-collapseOne"
          >
            {{ t("Filter By", {}, { locale: $store.getters.getLang }) }}
          </button>
        </h2>
        <div
          id="panelsStayOpen-collapseOne"
          class="accordion-collapse collapse"
          aria-labelledby="panelsStayOpen-headingOne"
        >
          <div class="accordion-body p-0">
            <Filter>
              <template v-slot:body>
                <div class="col-sm-6 col-md-4">
                  <DateTimeRangePicker v-model="date.range" />
                </div>
              </template>
              <template v-slot:button3>
                <button
                  class="edit_table_data font_detail"
                  @click="getCounterManReport"
                >
                  <i class="fas fa-filter"></i
                  ><span class="mx-1 bold">{{
                    t("Filter", {}, { locale: $store.getters.getLang })
                  }}</span>
                </button>
              </template>
              <template v-slot:button2>
                <button
                  class="edit_table_data font_detail"
                  @click="printUserReport"
                >
                  <i class="fas fa-print"></i
                  ><span class="mx-1 bold">{{
                    t("Print", {}, { locale: $store.getters.getLang })
                  }}</span>
                </button>
              </template>
              <template v-slot:button1>
                <button
                  class="edit_table_data font_detail d-none d-md-block"
                  @click="exportExcel('xlsx')"
                >
                  <i class="fas fa-download"></i
                  ><span class="mx-1 bold">{{
                    t("Export", {}, { locale: $store.getters.getLang })
                  }}</span>
                </button>
              </template>
            </Filter>
          </div>
        </div>
      </div>
    </div>
    <Filter class="d-none d-md-block">
      <template v-slot:body>
        <div class="col-sm-6 col-md-4">
          <DateTimeRangePicker v-model="date.range" />
        </div>
        <div class="col-md-2 mt-auto">
          <button
            class="edit_table_data font_detail"
            @click="getCounterManReport"
          >
            <i class="fas fa-filter"></i
            ><span class="mx-1 bold">{{
              t("Filter", {}, { locale: $store.getters.getLang })
            }}</span>
          </button>
        </div>
      </template>

      <template v-slot:button2>
        <button class="edit_table_data font_detail" @click="printUserReport">
          <i class="fas fa-print"></i
          ><span class="mx-1 bold">{{
            t("PRINT", {}, { locale: $store.getters.getLang })
          }}</span>
        </button>
      </template>
      <template v-slot:button1>
        <button
          class="edit_table_data font_detail"
          @click="exportExcel('xlsx')"
        >
          <i class="fas fa-download"></i
          ><span class="mx-1 bold">{{
            t("Export", {}, { locale: $store.getters.getLang })
          }}</span>
        </button>
      </template>
    </Filter>
    <div class="p-2 position-relative mobile_view">
      <!-- Table Data Pannel -->
      <div class="tripReportMobile">
        <div
          class="card w-100 my-2"
          v-for="report in $store.getters.getCountermanReport.reportInfo.ticket"
          :key="report.seatClassId"
        >
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <p>
                  <b
                    >{{
                      t("Seat Ticket", {}, { locale: $store.getters.getLang })
                    }}:</b
                  >
                  {{ report.seatTicket }}
                </p>
              </div>
              <div class="col-6">
                <p>
                  <b
                    >{{
                      t("Seat Amount", {}, { locale: $store.getters.getLang })
                    }}:</b
                  >
                  {{ report.seatAmount }}
                </p>
              </div>
              <div class="col-6">
                <p>
                  <b
                    >{{
                      t("Extra Ticket", {}, { locale: $store.getters.getLang })
                    }}:</b
                  >
                  {{ report.extraTicket }}
                </p>
              </div>
              <div class="col-6">
                <p>
                  <b
                    >{{
                      t("Extra Amount", {}, { locale: $store.getters.getLang })
                    }}:</b
                  >
                  {{ report.extraAmount }}
                </p>
              </div>
              <div class="col-6">
                <p>
                  <b
                    >{{
                      t("Goods Ticket", {}, { locale: $store.getters.getLang })
                    }}:</b
                  >
                  {{ report.goodsTicket }}
                </p>
              </div>
              <div class="col-6">
                <p>
                  <b
                    >{{
                      t("Goods Amount", {}, { locale: $store.getters.getLang })
                    }}:</b
                  >
                  {{ report.goodsAmount }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Total Amount Pannel -->
      <div class="card w-100 fixed-bottom">
        <div class="card-body bg-dark text-white">
          <div class="row">
            <div class="col-6">
              <p>
                <b
                  >{{
                    t(
                      "Total Seat Ticket",
                      {},
                      { locale: $store.getters.getLang }
                    )
                  }}:</b
                >
                {{ seatTicketTotal }}
              </p>
            </div>
            <div class="col-6">
              <p>
                <b
                  >{{
                    t(
                      "Total Seat Amount",
                      {},
                      { locale: $store.getters.getLang }
                    )
                  }}:</b
                >
                {{ seatAmountTotal }}
              </p>
            </div>
            <div class="col-6">
              <p>
                <b
                  >{{
                    t(
                      "Total Extra Ticket",
                      {},
                      { locale: $store.getters.getLang }
                    )
                  }}:</b
                >
                {{ extraTicketTotal }}
              </p>
            </div>
            <div class="col-6">
              <p>
                <b
                  >{{
                    t(
                      "Total Extra Amount",
                      {},
                      { locale: $store.getters.getLang }
                    )
                  }}:</b
                >
                {{ extraAmountTotal }}
              </p>
            </div>
            <div class="col-6">
              <p>
                <b
                  >{{
                    t(
                      "Total Goods Ticket",
                      {},
                      { locale: $store.getters.getLang }
                    )
                  }}:</b
                >
                {{ goodsTicketTotal }}
              </p>
            </div>
            <div class="col-6">
              <p>
                <b
                  >{{
                    t(
                      "Total Goods Amount",
                      {},
                      { locale: $store.getters.getLang }
                    )
                  }}:</b
                >
                {{ goodsAmountTotal }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Table id="exportable_data" class="large_device_view">
      <template v-slot:header>
        <tr>
          <td class="font_detail">
            {{ t("SL", {}, { locale: $store.getters.getLang }) }}
          </td>
          <td class="font_detail">
            {{ t("Trip Code", {}, { locale: $store.getters.getLang }) }}
          </td>
          <td class="font_detail">
            {{ t("Seat Ticket", {}, { locale: $store.getters.getLang }) }}
          </td>
          <td class="font_detail">
            {{ t("Seat Amount", {}, { locale: $store.getters.getLang }) }}
          </td>
          <td class="font_detail">
            {{ t("Extra Ticket", {}, { locale: $store.getters.getLang }) }}
          </td>
          <td class="font_detail">
            {{ t("Extra Amount", {}, { locale: $store.getters.getLang }) }}
          </td>
          <td class="font_detail">
            {{ t("Goods Ticket", {}, { locale: $store.getters.getLang }) }}
          </td>
          <td class="font_detail">
            {{ t("Goods Amount", {}, { locale: $store.getters.getLang }) }}
          </td>
        </tr>
      </template>
      <template v-slot:body>
        <tr
          v-for="(report, index) in $store.getters.getCountermanReport
            .reportInfo.ticket"
          :key="report.seatClassId"
        >
          <td class="font_detail">
            {{ index + 1 + (pagination.page - 1) * 25 }}
          </td>
          <td class="font_detail">{{ report?.trip?.tripCode }}</td>
          <td class="font_detail">{{ report.seatTicket }}</td>
          <td class="font_detail">{{ report.seatAmount }}</td>
          <td class="font_detail">{{ report.extraTicket }}</td>
          <td class="font_detail">{{ report.extraAmount }}</td>
          <td class="font_detail">{{ report.goodsTicket }}</td>
          <td class="font_detail">{{ report.goodsAmount }}</td>
        </tr>
        <tr class="bg-dark text-white">
          <td class="font_detail"></td>
          <td class="font_detail"></td>
          <td class="font_detail text-white">
            {{ t("Total Ticket", {}, { locale: $store.getters.getLang }) }}:
            {{ seatTicketTotal }}
          </td>
          <td class="font_detail text-white">
            {{ t("Total Amount", {}, { locale: $store.getters.getLang }) }}:
            {{ seatAmountTotal }}
          </td>
          <td class="font_detail text-white">
            {{ t("Total Ticket", {}, { locale: $store.getters.getLang }) }}:
            {{ extraTicketTotal }}
          </td>
          <td class="font_detail text-white">
            {{ t("Total Amount", {}, { locale: $store.getters.getLang }) }}:
            {{ extraAmountTotal }}
          </td>
          <td class="font_detail text-white">
            {{ t("Total Ticket", {}, { locale: $store.getters.getLang }) }}:
            {{ goodsTicketTotal }}
          </td>
          <td class="font_detail text-white">
            {{ t("Total Amount", {}, { locale: $store.getters.getLang }) }}:
            {{ goodsAmountTotal }}
          </td>
        </tr>
      </template>
      <!-- <template v-slot:pagination>
              <Pagination v-model="pagination.page" :records="$store.getters.getTotalShipWiseReport" :per-page="pagination.limit"
                          @paginate="getAllCompanyList" :options="options"/>
           </template> -->
    </Table>
  </div>
  <div class="card card-body" v-else>
    <UserReportPrintPreview
      v-bind:report="$store.getters.getCountermanReport.reportInfo"
      v-bind:ticketType="'userReport'"
      :printCount="printCount"
      @close="close"
    />
  </div>
</template>

<script>
import moment from "moment";
import { reactive } from "@vue/reactivity";
import { onBeforeMount, ref, watch, inject } from "vue";
import store from "@/store";
import XLSX from "xlsx";
import router from "@/router";
import web from "@/urls/web";
import permission from "@/modules/permission";
import roles from "@/modules/roles";
import { useI18n } from "vue-i18n";

const { hasPermission } = permission();
const { Counterman } = roles();
export default {
  name: "Report",
  setup() {
    let pagination = reactive({
      page: 1,
      limit: 25,
      shipId: "",
      fromDate: "",
      toDate: "",
    });
    const options = {
      texts: {
        count: "",
      },
    };
    const fromDate = moment(new Date()).format("yyyy-MM-DD" + " 00:00:00");
    const toDate = moment(new Date()).format("yyyy-MM-DD" + " 23:59:59");
    let seatTicketTotal = ref(0);
    let seatAmountTotal = ref(0);
    let extraTicketTotal = ref(0);
    let extraAmountTotal = ref(0);
    let goodsTicketTotal = ref(0);
    let goodsAmountTotal = ref(0);
    let printCount = ref(0);
    let reportPrint = ref(false);
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    const { t, locale } = useI18n();
    let date = reactive({
      range: {
        start: fromDate,
        end: toDate,
      },
    });
    watch(
      () => store.getters.userRoles,
      (value) => {
        if (!value?.includes(Counterman.reports)) {
          router
            .push(web.Default.LOGIN_URL.path)
            .catch((error) => console.log(error));
        }
      }
    );

    watch(
      () => date.range.start,
      (value) => {
        pagination.fromDate = moment(value).format("yyyy-MM-DD" + " 00:00:00");
      }
    );
    watch(
      () => date.range.end,
      (value) => {
        pagination.toDate = moment(value).format("yyyy-MM-DD" + " 23:59:59");
      }
    );

    const printUserReport = () => {
      showLoader();
      store
        .dispatch("reportPrintCount", {
          reportType: "dailySummaryReport",
          dailySummaryDate: moment(new Date()).format("yyyy-MM-DD"),
        })
        .then((data) => {
          printCount.value = data.printCount + 1;
          hideLoader();
          reportPrint.value = true;
        })
        .catch((error) => {
          hideLoader();
          if (error.status === 401) {
            printUserReport();
          }
        });
    };
    const close = () => {
      reportPrint.value = false;
    };
    const sumListData = () => {
      store.getters.getCountermanReport.reportInfo.ticket.forEach((data) => {
        seatTicketTotal.value += data.seatTicket;
        seatAmountTotal.value += data.seatAmount;
        extraTicketTotal.value += data.extraTicket;
        extraAmountTotal.value += data.extraAmount;
        goodsTicketTotal.value += data.goodsTicket;
        goodsAmountTotal.value += data.goodsAmount;
      });
    };
    const exportExcel = (type, fn, dl) => {
      let elt = document.getElementById("exportable_data");
      let wb = XLSX.utils.table_to_book(elt, { sheet: "sheet Js" });
      return dl
        ? XLSX.write(wb, { bookType: type, bookSST: true, type: "base64" })
        : XLSX.writeFile(wb, fn || "Counterman_Report." + (type || "xlsx"));
    };
    const getCounterManReport = () => {
      store
        .dispatch("getAllCountermanReport", pagination)
        .then(() => {
          seatTicketTotal.value = 0;
          seatAmountTotal.value = 0;
          extraTicketTotal.value = 0;
          extraAmountTotal.value = 0;
          goodsTicketTotal.value = 0;
          goodsAmountTotal.value = 0;
          sumListData();
        })
        .catch((error) => {
          hideLoader();
          if (error.status === 401) {
            getCounterManReport();
          }
        });
    };
    onBeforeMount(() => {
      pagination.fromDate = fromDate;
      pagination.toDate = toDate;
      getCounterManReport();
    });
    return {
      t,
      locale,
      options,
      pagination,
      date,
      seatTicketTotal,
      seatAmountTotal,
      reportPrint,
      Counterman,
      exportExcel,
      getCounterManReport,
      printUserReport,
      close,
      hasPermission,
      printCount,
      extraTicketTotal,
      extraAmountTotal,
      goodsTicketTotal,
      goodsAmountTotal,
    };
  },
};
</script>

<style scoped>
button.accordion-button {
  background-color: #242424;
  color: #fff;
  border: 0;
  padding-left: 10px;
  padding-right: 10px;
}

.accordion-button::after {
  filter: invert(1);
}

.tripReportMobile {
  height: 435px;
  overflow: scroll;
}

.card-body p {
  font-size: 12px;
  margin-bottom: 5px;
}
</style>
