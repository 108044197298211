// import validationMessage from "@/modules/ValidationMessage"
import apiResponse from "@/modules/apiResponse";
import toast from "@/modules/Toast";
import tokenCookie from "@/modules/tokenCookie";
import Apis from "@/urls/apis";
import axios from "axios";
import {seatFareCalculate} from "@/helpers/fareCalculate";

const {successToast, warningToast} = toast();
const {hasValidToken, getToken} = tokenCookie();
// const {messageExtractor} = validationMessage();
const {errorResponse} = apiResponse();

const state = {
  filter: {
    stoppage: {},
    ship: {},
    route: {},
    counter: {},
    supervisors: {},
    drivers: {},
    droppingPoint: {},
    boardingPlace: {},
    defaultBoardingPlace: {},
    defaultDroppingPoint: {},
  },
  trip: {},
  trips: [],
  routeSequence: [],
  boardingPlace: [],
  droppingPoint: [],
  tickets: {},
  ticketByTrip: [],
  blockedSeatsTicketsCounterman: [],
  bookedSeatList: [],
  selectedSeatsBookedDatabase: [],
  seatDetails: {
    selectedSeat: [],
    seatFare: [],
    farePerSeat: [],
    ticketDetails: []
  },
  passengerDetails: {},
  ticketPurchasedDetails: {
    purchasedTicket: {},
    purchaseStatus: false
  },
  resetStatus: false,
  searchTickets: []
};
const getters = {
  getDefaultBoardingPlaceBasedOnStoppage: (state) => state.filter.defaultBoardingPlace,
  getDefaultDroppingPointBasedOnStoppage: (state) => state.filter.defaultDroppingPoint,
  getBoardingPlaceBasedOnStoppage: (state) => state.filter.boardingPlace,
  getDroppingPointBasedOnStoppage: (state) => state.filter.droppingPoint,
  getBlockedSeatsTicketsCounterman: (state) => state.blockedSeatsTicketsCounterman,
  getTicketByTripCounterman: (state) => state.ticketByTrip,
  getAllSupervisors: (state) => state.filter.supervisors,
  getAllDrivers: (state) => state.filter.drivers,

  getCountermanStoppageListForFilter: (state) => state.filter.stoppage,
  getCountermanCoachListForFilter: (state) => state.filter.ship,
  getCountermanRouteListForFilter: (state) => state.filter.route,

  getTicketsHistoryCounter: (state) => state.tickets.docs,
  getTotalTicketsHistoyCounter: (state) => state.tickets.totalDocs,
  getTripsForTicketing: (state) => state.trips.docs,
  getTotalTripsForTicketing: (state) => state.trips.totalDocs,
  getTripForTicketing: (state) => state.trip,
  getRouteSequenceFilter: (state) => {
    let routeSequenceList = {}
    state.routeSequence.forEach(routeSequence => {
      routeSequenceList[routeSequence._id] = routeSequence.name;
    });
    return routeSequenceList
  },
  getBoardingPlaceFilter: (state) => {
    let boardingPlaceList = {}
    state.boardingPlace.forEach(boardingPlace => {
      boardingPlaceList[boardingPlace] = boardingPlace;
    });
    return boardingPlaceList
  },
  getDroppingPointFilter: (state) => {
    let droppingPointList = {}
    state.droppingPoint.forEach(droppingPoint => {
      droppingPointList[droppingPoint] = droppingPoint;
    });
    return droppingPointList
  },
  getSelectedSeat: (state) => state.seatDetails.selectedSeat,
  getSeatFare: (state) => state.seatDetails.seatFare,
  getTicketDetails: (state) => state.seatDetails.ticketDetails,
  getBookedSeatList: (state) => state.bookedSeatList,
  getPassengerDetails: (state) => state.passengerDetails,
  getSeatDetails: (state) => state.seatDetails,
  getTicketPurchasedDetails: (state) => state.ticketPurchasedDetails,
  getSelectedSeatsBookedDatabase: (state) => state.selectedSeatsBookedDatabase,
  getResetStatus: (state) => state.resetStatus,
  getSearchTickets: (state) => state.searchTickets,
  getSearchSingleTicket: (state) => (selectedTicket) => state.searchTickets.filter(ticket => ticket._id === selectedTicket)
};
const actions = {
  async getRefundPayment(_, payload) {
    if (await hasValidToken()) {
      const header = {
        'Authorization': 'Bearer ' + getToken()
      };
      return new Promise((resolve, reject) => {
        axios.post(Apis.REFUND_RESERVED_TICKET_PAYMENT, payload, {headers: header})
          .then((response) => {
            resolve(response)
          })
          .catch(error => {
            reject(errorResponse(error))
          });
      });
    }
  },

  async payReservedTicket(_, payload) {
    if (await hasValidToken()) {
      const header = {
        'Authorization': 'Bearer ' + getToken()
      };
      return new Promise((resolve, reject) => {
        axios.post(Apis.RESERVED_TICKET_PAYMENT, payload, {headers: header})
          .then((response) => {
            successToast(response.data.message);
            resolve(response)
          })
          .catch(error => {
            reject(errorResponse(error))
          });
      });
    }
  },

  async cancelReservationTicket(_, payload) {
    if (await hasValidToken()) {
      const header = {
        'Authorization': 'Bearer ' + getToken()
      };
      return new Promise((resolve, reject) => {
        axios.post(Apis.RESERVATION_TICKET_CANCEL_COUNTERMAN, payload, {headers: header})
          .then((response) => {
            successToast(response.data.message);
            resolve(response)
          })
          .catch(error => {
            reject(errorResponse(error))
          });
      });
    }
  },

  async getTicketByTrip({commit}, payload) {
    if (await hasValidToken()) {
      const header = {
        'Authorization': 'Bearer ' + getToken(),
        // 'x-no-compression': 'true'
      };
      return new Promise((resolve, reject) => {
        axios.get(Apis.TICKET_BY_TRIP, {headers: header, params: payload})
          .then((response) => {
            commit('setTicketByTrip', response.data.tickets)
            commit("setTrip", response.data.trip);
            commit("setBlockedSeatsTicketsCounterman", {tickets: response.data.tickets})
            resolve(response)
          })
          .catch(error => {
            reject(errorResponse(error))
          });
      });
    }
  },
  async searchWithPnrOrPhone({commit}, payload) {
    if (await hasValidToken()) {
      const header = {
        'Authorization': 'Bearer ' + getToken()
      };
      return new Promise((resolve, reject) => {
        axios.post(Apis.SEARCH_WITH_PNR_OR_PHONE, payload, {headers: header})
          .then((response) => {
            commit('setSearchTickets', response.data.tickets)
            resolve(response)
          })
          .catch(error => {
            reject(errorResponse(error))
          });
      });
    }
  },
  // async getStoppageListForCounterman({commit}, payload) {
  //     if (await hasValidToken()) {
  //         commit("setStoppageListInFilter", {});
  //         const header = {
  //             'Authorization': 'Bearer ' + getToken()
  //         };
  //
  //         return new Promise((resolve, reject) => {
  //             axios.get(Apis.GET_ALL_COUNTERMAN_STOPPAGES, {
  //                 params: payload,
  //                 headers: header
  //             })
  //                 .then(({data, status}) => {
  //                     if (status === 200) {
  //                         let stoppageList = {}
  //                         let boardingPlace = {}
  //                         let droppingPoint = {}
  //                         data.stoppage.forEach(stoppage => {
  //                             stoppageList[stoppage._id] = stoppage.name;
  //                             boardingPlace[stoppage._id] = stoppage.boardingPlace
  //                             droppingPoint[stoppage._id] = stoppage.droppingPoint
  //                         });
  //                         commit("setStoppageListInFilter", stoppageList)
  //                         commit("setBoardingPlaceFilter",boardingPlace)
  //                         commit("setDroppingPointFilter",droppingPoint)
  //                         resolve(data.stoppage);
  //                     }
  //                 })
  //                 .catch(error => {
  //                     reject(errorResponse(error))
  //                 });
  //         });
  //     }
  // },

  async getStoppageListForCounterman({commit}, payload) {
    if (await hasValidToken()) {
      commit("setStoppageListInFilter", {});
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_ALL_COUNTERMAN_STOPPAGES, {
            params: payload,
            headers: header,
          })
          .then(({data, status}) => {
            if (status === 200) {
              let stoppageList = {};
              let boardingPlace = {};
              let droppingPoint = {};
              let defaultBoardingPlace = {};
              let defaultDroppingPoint = {};
              data.stoppage.forEach((stoppage) => {
                stoppageList[stoppage._id] = stoppage.name;
                boardingPlace[stoppage._id] = stoppage.boardingPlace;
                droppingPoint[stoppage._id] = stoppage.droppingPoint;
                defaultBoardingPlace[stoppage._id] = stoppage.defaultBoardingPlace;
                defaultDroppingPoint[stoppage._id] = stoppage.defaultDroppingPoint;
              });
              commit("setStoppageListInFilter", stoppageList);
              commit("setBoardingPlaceFilter", boardingPlace);
              commit("setDroppingPointFilter", droppingPoint);
              commit("setDefaultBoardingPlaceFilter", defaultBoardingPlace);
              commit("setDefaultDroppingPointFilter", defaultDroppingPoint);
              resolve(data.stoppage);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getRouteListForCounterman({commit}, payload) {
    if (await hasValidToken()) {
      commit("setRouteListInFilter", {});
      const header = {
        'Authorization': 'Bearer ' + getToken()
      };

      return new Promise((resolve, reject) => {
        axios.get(Apis.GET_ALL_COUNTERMAN_ROUTES, {
          params: payload,
          headers: header
        })
          .then(({data, status}) => {
            if (status === 200) {
              let routeList = {}
              data.route.forEach(route => {
                routeList[route._id] = route.name;
              });
              commit("setRouteListInFilter", routeList)
              resolve(data.route);
            }
          })
          .catch(error => {
            reject(errorResponse(error))
          });
      });
    }
  },

  async getCounterListForCounterman({commit}, payload) {
    if (await hasValidToken()) {
      commit("setCounterListInFilter", {});
      const header = {
        'Authorization': 'Bearer ' + getToken()
      };

      return new Promise((resolve, reject) => {
        axios.get(Apis.GET_ALL_COUNTERMAN_COUNTERS, {
          params: payload,
          headers: header
        })
          .then(({data, status}) => {
            if (status === 200) {
              let counterList = {}
              data.counter.forEach(counter => {
                counterList[counter._id] = counter.name;
              });
              commit("setCounterListInFilter", counterList)
              resolve(data.counter);
            }
          })
          .catch(error => {
            reject(errorResponse(error))
          });
      });
    }
  },

  async getShipListForCounterman({commit}, payload) {
    if (await hasValidToken()) {
      const header = {
        'Authorization': 'Bearer ' + getToken()
      };

      return new Promise((resolve, reject) => {
        axios.get(Apis.GET_ALL_COUNTERMAN_SHIPS, {
          params: payload,
          headers: header
        })
          .then(({data, status}) => {
            if (status === 200) {
              let coachList = {}
              data.coach.forEach(coach => {
                coachList[coach._id] = coach.name;
              });
              commit("setShipListInFilter", coachList)
              resolve(data.coach);
            }
          })
          .catch(error => {
            reject(errorResponse(error))
          });
      });
    }
  },

  async getTicketsHistory({commit}, payload) {
    if (await hasValidToken()) {
      const header = {
        'Authorization': 'Bearer ' + getToken()
      };

      return new Promise((resolve, reject) => {
        axios.get(Apis.TICKETS_HISTORY_COUNTER, {
          params: payload,
          headers: header
        })
          .then(({data, status}) => {
            if (status === 200) {
              commit("setTicketsHistory", data.ticket)
              resolve(data.ticket)
            }
          })
          .catch(error => {
            reject(errorResponse(error))
          });
      });
    }
  },

  async ticketDelete(_, payload) {
    if (await hasValidToken()) {
      const header = {
        'Authorization': 'Bearer ' + getToken()
      };
      return new Promise((resolve, reject) => {
        axios.post(Apis.TICKET_CANCEL, payload, {headers: header})
          .then((response) => {
            successToast('Ticket Cancelled Successfully')
            resolve(response)
          })
          .catch(error => {
            reject(errorResponse(error))
          });
      });
    }
  },

  async tripPass(_, payload) {
    if (await hasValidToken()) {
      const header = {
        'Authorization': 'Bearer ' + getToken()
      };
      return new Promise((resolve, reject) => {
        axios.post(Apis.TRIP_PASS, payload, {headers: header})
          .then((response) => {
            successToast('Ticket Passed Successfully')
            resolve(response)
          })
          .catch(error => {
            reject(errorResponse(error))
          });
      });
    }
  },
  async pnrTicket(_, payload) {
    if (await hasValidToken()) {
      const header = {
        'Authorization': 'Bearer ' + getToken()
      };
      return new Promise((resolve, reject) => {
        axios.get(Apis.PNR_TICKET, {headers: header, params: payload})
          .then((response) => {
            resolve(response)
          })
          .catch(error => {
            reject(errorResponse(error))
          });
      });
    }
  },
  async updateTripCounterman({commit}, payload) {
    if (await hasValidToken()) {
      const header = {
        'Authorization': 'Bearer ' + getToken()
      };
      return new Promise((resolve, reject) => {
        axios.post(Apis.UPDATE_TRIP, payload, {headers: header})
          .then((response) => {
            commit('updateTrip', response.data.trip)
            successToast(response.data.message)
            resolve(response)
          })
          .catch(error => {
            reject(errorResponse(error))
          });
      });
    }
  },


  async extraSeatPurchase(_, payload) {
    if (await hasValidToken()) {
      const header = {
        'Authorization': 'Bearer ' + getToken()
      };
      return new Promise((resolve, reject) => {
        axios.post(Apis.EXTRA_TICKET_SEATS, payload, {headers: header})
          .then((response) => {
            successToast(response.data.message)
            resolve(response)
          })
          .catch(error => {
            reject(errorResponse(error))
          });
      });
    }
  },
  async extraGoodsPurchase(_, payload) {
    if (await hasValidToken()) {
      const header = {
        'Authorization': 'Bearer ' + getToken()
      };
      return new Promise((resolve, reject) => {
        axios.post(Apis.EXTRA_TICKET_GOODS, payload, {headers: header})
          .then((response) => {
            successToast(response.data.message)
            resolve(response)
          })
          .catch(error => {
            reject(errorResponse(error))
          });
      });
    }
  },

  async confirmPassengerTicket(_, payload) {
    if (await hasValidToken()) {
      const header = {
        'Authorization': 'Bearer ' + getToken()
      };
      return new Promise((resolve, reject) => {
        axios.post(Apis.TICKET_PURCHASE, payload, {headers: header})
          .then((response) => {
            successToast(response.data.message)
            resolve(response.data.ticket)
          })
          .catch(error => {
            reject(errorResponse(error))
          });
      });
    }
  },

  async reserveTicket(_, payload) {
    if (await hasValidToken()) {
      const header = {
        'Authorization': 'Bearer ' + getToken()
      };
      return new Promise((resolve, reject) => {
        axios.post(Apis.TICKET_RESERVE, payload, {headers: header})
          .then((response) => {
            successToast(response.data.message)
            resolve(response.data.ticket)
          })
          .catch(error => {
            reject(errorResponse(error))
          });
      });
    }
  },

  async getActiveTripsForTicketing({commit}, payload) {
    if (await hasValidToken()) {
      commit("setTrips", []);
      const header = {
        'Authorization': 'Bearer ' + getToken(),
        // 'x-no-compression': 'true'
      };
      return new Promise((resolve, reject) => {
        axios.get(Apis.GET_ALL_TRIPS_FOR_TICKETING, {
          params: payload,
          headers: header
        })
          .then(({data, status}) => {
            if (status === 200) {
              commit("setTrips", data.trip);
              resolve({'success': 'success'});
            }
          })
          .catch(error => {
            reject(errorResponse(error))
          });
      });
    }
  },

  async getAllDriver({commit}) {
    if (await hasValidToken()) {
      commit("setDrivers", []);
      const header = {
        'Authorization': 'Bearer ' + getToken()
      };
      return new Promise((resolve, reject) => {
        axios.get(Apis.ALL_DRIVER, {
          headers: header
        })
          .then(({data}) => {
            let driverList = {}
            data.drivers.forEach(driver => {
              driverList[driver._id] = driver.name;
            });
            commit("setDrivers", driverList);
            resolve({'success': 'success'});

          })
          .catch(error => {
            reject(errorResponse(error))
          });
      });
    }
  },
  async getAllSupervisor({commit}) {
    if (await hasValidToken()) {
      commit("setSupervisors", []);
      const header = {
        'Authorization': 'Bearer ' + getToken()
      };
      return new Promise((resolve, reject) => {
        axios.get(Apis.ALL_SUPERVISOR, {
          headers: header
        })
          .then(({data}) => {
            let superVisorList = {}
            data.supervisors.forEach(supervisor => {
              superVisorList[supervisor._id] = supervisor.name;
            });
            commit("setSupervisors", superVisorList);
            resolve({'success': 'success'});
          })
          .catch(error => {
            reject(errorResponse(error))
          });
      });
    }
  },

  async getCountermanLastTicket(_, payload) {
    if (await hasValidToken()) {
      const header = {
        'Authorization': 'Bearer ' + getToken()
      };
      return new Promise((resolve, reject) => {
        axios.get(Apis.LAST_TICKET_PRINT_COUNTERMAN, {
          params: payload,
          headers: header
        })
          .then(({data, status}) => {
            if (status === 200) {
              resolve(data.ticket);
            }
          })
          .catch(error => {
            reject(errorResponse(error))
          });
      });
    }
  },
  async ticketPrintCount(_, payload) {
    if (await hasValidToken()) {
      const header = {
        'Authorization': 'Bearer ' + getToken()
      };
      return new Promise((resolve, reject) => {
        axios.post(Apis.TICKET_PRINT_COUNT, payload, {headers: header})
          .then((response) => {
            resolve(response.data.ticket)
          })
          .catch(error => {
            reject(error)
          });
      });
    }
  },
  async reportPrintCount(_, payload) {
    if (await hasValidToken()) {
      const header = {
        'Authorization': 'Bearer ' + getToken()
      };
      return new Promise((resolve, reject) => {
        axios.post(Apis.REPORT_PRINT_COUNT, payload, {headers: header})
          .then((response) => {
            resolve(response.data)
          })
          .catch(error => {
            reject(errorResponse(error))
          });
      });
    }
  },
  async reportPrintCountIncrease(_, payload) {
    if (await hasValidToken()) {
      const header = {
        'Authorization': 'Bearer ' + getToken()
      };
      return new Promise((resolve, reject) => {
        axios.post(Apis.REPORT_PRINT_COUNT_INCREASE, payload, {headers: header})
          .then((response) => {
            resolve(response.data)
          })
          .catch(error => {
            reject(errorResponse(error))
          });
      });
    }
  },
};
const mutations = {

  setSearchTickets: (state, data) => state.searchTickets = data,
  setTrip: (state, data) => state.trip = data,
  setBlockedSeatsTicketsCounterman: (state, {tickets}) => {
    setTimeout(() => {
      const seatWiseTickets = []
      if (tickets !== undefined) {

        const routeSequence = state.routeSequence.map((item) => item._id);
        const filteredTrip = tickets.filter((ticket) => routeSequence.includes(ticket.fromStoppage._id) && routeSequence.includes(ticket.toStoppage._id));
        for (let ticket of filteredTrip) {
          for (let seat of ticket.seatNumbers) {
            if (seatWiseTickets[seat]) {
              if (ticket.fromStoppage._id === state.passengerDetails.fromStoppage && ticket.toStoppage._id === state.passengerDetails.toStoppage) {
                seatWiseTickets[seat] = ticket;
              }
            } else {
              seatWiseTickets[seat] = ticket;
            }
          }
        }
      }
      state.blockedSeatsTicketsCounterman = seatWiseTickets
    }, 100)

  },
  setTicketByTrip: (state, ticketByTrip) => (state.ticketByTrip = ticketByTrip),
  setSupervisors: (state, supervisors) => (state.filter.supervisors = supervisors),
  setDrivers: (state, drivers) => (state.filter.drivers = drivers),
  setStoppageListInFilter: (state, stoppageList) => (state.filter.stoppage = stoppageList),
  setBoardingPlaceFilter: (state, boardingPlace) => (state.filter.boardingPlace = boardingPlace),
  setDroppingPointFilter: (state, droppingPoint) => (state.filter.droppingPoint = droppingPoint),
  setDefaultBoardingPlaceFilter: (state, defaultBoardingPlace) => (state.filter.defaultBoardingPlace = defaultBoardingPlace),
  setDefaultDroppingPointFilter: (state, defaultDroppingPoint) => (state.filter.defaultDroppingPoint = defaultDroppingPoint),
  setShipListInFilter: (state, shipList) => (state.filter.ship = shipList),
  setRouteListInFilter: (state, routeList) => (state.filter.route = routeList),
  setCounterListInFilter: (state, counterList) => (state.filter.counter = counterList),
  setTicketsHistory: (state, tickets) => (state.tickets = tickets),
  setTrips: (state, trips) => (state.trips = trips),
  setRouteSequence: (state, routeSequence) => (state.routeSequence = routeSequence),
  setBoardingPlace: (state, boardingPlace) => (state.boardingPlace = boardingPlace),
  setDroppingPoint: (state, droppingPoint) => (state.droppingPoint = droppingPoint),

  updateTrip: (state, trip) => state.trips.docs.filter((element, index) => {
    if (element._id === trip._id) {
      state.trips.docs.splice(index, 1, trip)
    }
  }),
  setSelectedSeat: (state, payload) => {
    let selectedSeat = state.seatDetails.selectedSeat;
    let seat = payload.seat;
    let tripDetails = payload.tripDetails;
    let maxSeatPerTicket = payload.maxSeatPerTicket.value;
    let selectedSeatList = selectedSeat !== null ? selectedSeat : [];

    let findSeat = selectedSeatList.find(s => s.seat_no === seat.seat_no);

    if (selectedSeatList.length === 0 || !findSeat) {
      if (selectedSeatList.length > maxSeatPerTicket - 1) {
        warningToast(`You can sell ${maxSeatPerTicket} tickets at a time !!`);
        return;
      }
      selectedSeatList.push(seat);
      const {seatFare} = seatFareCalculate(tripDetails, seat.seat_no, seat.class_id);
      state.seatDetails.seatFare.push(...seatFare);
      //state.seatDetails.farePerSeat = farePerSeat;
    } else {
      selectedSeatList = selectedSeatList.filter(s => s.seat_no !== seat.seat_no)
      state.seatDetails.seatFare = state.seatDetails.seatFare.filter(s => s.seat_no !== seat.seat_no)
    }
    state.seatDetails.selectedSeat = selectedSeatList;
  },
  setSeatFare: (state, seatFareList) => {
    state.seatDetails.seatFare = seatFareList
  },
  setTicketDetails: (state, ticketDetails) => {
    state.seatDetails.ticketDetails = ticketDetails
  },
  resetTicketDetails: (state) => {
    state.seatDetails.selectedSeat = [];
    state.seatDetails.seatFare = [];
    state.seatDetails.ticketDetails = [];
    //state.passengerDetails = {}
  },
  setBookedSeatList: (state, payload) => {
    state.bookedSeatList = payload;
  },
  setPassengerDetails: (state, payload) => {
    state.passengerDetails = payload;
  },
  setPurchasedTicket: (state, payload) => {
    state.ticketPurchasedDetails.purchasedTicket = payload;
  },
  setPurchaseStatus: (state, payload) => {
    state.ticketPurchasedDetails.purchaseStatus = payload;
  },
  setSelectedSeatsBookedDatabase: (state, payload) => {
    state.selectedSeatsBookedDatabase = payload;
  },
  setResetStatus: (state, payload) => {
    state.resetStatus = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
