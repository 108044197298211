const API_URL = process.env.VUE_APP_API;

// Auth  start

const LOGIN = API_URL + "/api/auth/login";
const REFRESH = API_URL + "/api/auth/refresh-token";
const LOGOUT = API_URL + "/logout";

// Auth  end

// special

const GET_SINGLE_COMPANY_DATA = API_URL + "/api/admin/company/get-company";

//

// Admin  start

const COMPANY_LIST = API_URL + "/api/admin/company/all";
const GET_ALL_USERS = API_URL + "/api/admin/admins";
const ADD_USER = API_URL + "/api/admin/admins";
const UPDATE_USER = API_URL + "/api/admin/admins";
const DELETE_USER = API_URL + "/api/admin/admins"; // not required
const ADMIN_PRINT_COUNT_REPORT = API_URL + "/api/admin/report/print-count-report";
const GET_ALL_COMPANIES = API_URL + "/api/admin/company";
const ADD_COMPANY = API_URL + "/api/admin/company";
const UPDATE_COMPANY = API_URL + "/api/admin/company";
const GET_ALL_DEPOTS = API_URL + "/api/admin/company/depot";
const ADD_DEPOT = API_URL + "/api/admin/company/depot";
const UPDATE_DEPOT = API_URL + "/api/admin/company/depot";
const GET_ALL_DEPOTS_ALL = API_URL + "/api/admin/company/depot/all";
const GET_ALL_OWNERS = API_URL + "/api/admin/company/owner";
const ADD_OWNER = API_URL + "/api/admin/company/owner";
const UPDATE_OWNER = API_URL + "/api/admin/company/owner";
const GET_ALL_DT_OWNERS = API_URL + "/api/admin/company/dt-owner";
const ADD_DT_OWNER = API_URL + "/api/admin/company/dt-owner";
const UPDATE_DT_OWNER = API_URL + "/api/admin/company/dt-owner";
const GET_ALL_STOPPAGES = API_URL + "/api/admin/company/stoppage";
const ADD_STOPPAGE = API_URL + "/api/admin/company/stoppage";
const UPDATE_STOPPAGE = API_URL + "/api/admin/company/stoppage";
const GET_ALL_ROUTES = API_URL + "/api/admin/company/route";
const ADD_ROUTE = API_URL + "/api/admin/company/route";
const UPDATE_ROUTE = API_URL + "/api/admin/company/route";
const GET_ALL_STOPPAGE = API_URL + "/api/admin/company/stoppage/all";
const GET_ALL_BOARDING_PLACES =
  API_URL + "/api/admin/company/route/boarding-point";
const ADD_BOARDING_PLACE = API_URL + "/api/admin/company/route/boarding-point";
const UPDATE_BOARDING_PLACE =
  API_URL + "/api/admin/company/route/boarding-point";
const GET_ALL_DROPPING_PLACES =
  API_URL + "/api/admin/company/route/dropping-point";
const ADD_DROPPING_PLACE = API_URL + "/api/admin/company/route/dropping-point";
const UPDATE_DROPPING_PLACE =
  API_URL + "/api/admin/company/route/dropping-point";

const GET_ALL_BOARDING_PLACES_FOR_STOPPAGE =
  API_URL + "/api/admin/company/stoppage/boarding-point";
const ADD_BOARDING_PLACE_FOR_STOPPAGE =
  API_URL + "/api/admin/company/stoppage/boarding-point";
const UPDATE_BOARDING_PLACE_FOR_STOPPAGE =
  API_URL + "/api/admin/company/stoppage/boarding-point";
const DELETE_BOARDING_PLACE_FOR_STOPPAGE =
  API_URL + "/api/admin/company/stoppage/boarding-point";
const GET_ALL_DROPPING_PLACES_FOR_STOPPAGE =
  API_URL + "/api/admin/company/stoppage/dropping-point";
const ADD_DROPPING_PLACE_FOR_STOPPAGE =
  API_URL + "/api/admin/company/stoppage/dropping-point";
const UPDATE_DROPPING_PLACE_FOR_STOPPAGE =
  API_URL + "/api/admin/company/stoppage/dropping-point";
const DELETE_DROPPING_PLACE_FOR_STOPPAGE =
  API_URL + "/api/admin/company/stoppage/dropping-point";

const GET_ALL_COUNTERS = API_URL + "/api/admin/company/counter";
const GET_ALL_COUNTERS_ALL = API_URL + "/api/admin/company/counter/all/";
const ADD_COUNTER = API_URL + "/api/admin/company/counter";
const UPDATE_COUNTER = API_URL + "/api/admin/company/counter";
const SEAT_CLASS = API_URL + "/api/admin/company/seat-class";
const ADD_SEAT_CLASS = API_URL + "/api/admin/company/seat-class";
const UPDATE_SEAT_CLASS = API_URL + "/api/admin/company/seat-class";
const ADD_SEAT_PLAN = API_URL + "/api/admin/company/seat-plan";
const GET_SEAT_PLAN = API_URL + "/api/admin/company/seat-plan";
const UPDATE_SEAT_PLAN = API_URL + "/api/admin/company/seat-plan";
const OWNER_TRIP_WISE_REPORT = API_URL + "/api/dt-owner/report/trip-wise-report";
const COACH_WISE_REPORT = API_URL + "/api/admin/report/coach-wise-report";
const DIGITAL_TICKET_WISE_REPORT = API_URL + "/api/admin/report/digital-ticketing-report";
const DIGITAL_TICKET_WISE_REPORT_DETAILS = API_URL + "/api/admin/report/digital-ticketing-report-details";
const DIGITAL_TICKET_CANCEL = API_URL + "/api/admin/report/digital-ticket-cancel";
const COACH_WISE_SEAT_REPORT_DETAILS =
  API_URL + "/api/admin/report/coach-wise-report-details";
const COUNTERMAN_WISE_REPORT_DETAILS =
  API_URL + "/api/admin/report/counter-wise-report-details";
const ADD_SEAT_ROW = API_URL + "/api/admin/company/seat-plan/seat-row";
const UPDATE_SEAT_ROW = API_URL + "/api/admin/company/seat-plan/seat-row";
const GET_ALL_ROUTES_FILTER = API_URL + "/api/admin/company/route/all";
const ADD_SEAT_FARE = API_URL + "/api/admin/company/fare";
const GET_SEAT_FARE = API_URL + "/api/admin/company/fare";
const DELETE_FARE = API_URL + "/api/admin/company/fare";
const UPDATE_FARE = API_URL + "/api/admin/company/fare/update";
const SEQUENCE_ROUTE_FILTER =
  API_URL + "/api/admin/company/route/route-sequence";
const GET_ALL_SEAT_CLASS = API_URL + "/api/admin/company/seat-class/all";
const FARE_MODALITY_ADD = API_URL + "/api/admin/company/fare/fare-row";
const ROURE_FARE_DELETE = API_URL + "/api/admin/company/fare/fare-row";
const UPDATE_FARE_ROUTE = API_URL + "/api/admin/company/fare/fare-row";
const GET_FARE_ALL_BY_ROUTEID = API_URL + "/api/admin/company/fare/all";
const ADMIN_DASHBOARD = API_URL + "/api/admin/dashboard";
const ADD_COACH = API_URL + "/api/admin/company/coach";
const GET_ALL_LIST_COACH = API_URL + "/api/admin/company/coach/all";
const GET_ALL_COACH = API_URL + "/api/admin/company/coach";
const UPDATE_COACH = API_URL + "/api/admin/company/coach";
const GET_ALL_SEATPLAN = API_URL + "/api/admin/company/seat-plan/all";
const ADD_TRIP_DATA = API_URL + "/api/admin/company/trip";
const GET_TRIP_DATA = API_URL + "/api/admin/company/trip";
const UPDATE_TRIP_DATA = API_URL + "/api/admin/company/trip";
const CANCEL_TRIP = API_URL + "/api/admin/company/trip/cancel";
const GET_ALL_COUNTERMANS = API_URL + "/api/admin/company/counterman";
const ADD_COUNTERMAN = API_URL + "/api/admin/company/counterman";
const UPDATE_COUNTERMAN = API_URL + "/api/admin/company/counterman";
const GET_ALL_SUPERVISORS = API_URL + "/api/admin/company/supervisor";
const ADD_SUPERVISOR = API_URL + "/api/admin/company/supervisor";
const UPDATE_SUPERVISOR = API_URL + "/api/admin/company/supervisor";
const GET_ALL_DRIVERS = API_URL + "/api/admin/company/driver";
const ADD_DRIVER = API_URL + "/api/admin/company/driver";
const UPDATE_DRIVER = API_URL + "/api/admin/company/driver";
const GET_ALL_COACHWISE_TRIP_ADMIN =
  API_URL + "/api/admin/company/trip/coach-wise-trip";

// Admin  end

// Owner Start
const OWNER_PRINT_COUNT_REPORT =
  API_URL + "/api/dt-owner/report/print-count-report";
const COUNTERMAN_WISE_REPORT_DETAILS_OWNER =
  API_URL + "/api/dt-owner/report/counter-wise-report-details";
const GET_ALL_COUNTERS_IN_FILTER_OWNER_PANEL =
  API_URL + "/api/dt-owner/services/counter/all/";
const COUNTER_WISE_REPORT_OWNER_PANEL =
  API_URL + "/api/dt-owner/report/counter-wise-report";
const COACH_WISE_SEAT_REPORT_DETAILS_OWNER =
  API_URL + "/api/dt-owner/report/coach-wise-report-details";
const COACH_WISE_REPORT_OWNER = API_URL + "/api/dt-owner/report/coach-wise-report";
const DIGITAL_TICKET_CANCEL_OWNER = API_URL + "/api/dt-owner/report/digital-ticket-cancel";
const OWNER_DASHBOARD = API_URL + "/api/dt-owner/dashboard";
const CHALAN_TRIP_WISE_REPORT_OWNER =
  API_URL + "/api/dt-owner/report/trip-wise-report";
const DIGITAL_TICKET_WISE_REPORT_OWNER = API_URL + "/api/dt-owner/report/digital-ticketing-report";
const DIGITAL_TICKET_WISE_REPORT_DETAILS_OWNER = API_URL + "/api/dt-owner/report/digital-ticketing-report-details";
const GET_ALL_COACHWISE_TRIP_OWNER =
  API_URL + "/api/dt-owner/services/trip/coach-wise-trip";
const GET_ALL_OWNERS_PANEL = API_URL + "/api/dt-owner/owners/";
const ADD_OWNER_PANEL = API_URL + "/api/dt-owner/owners/";
const UPDATE_OWNER_PANEL = API_URL + "/api/dt-owner/owners/";
const GET_OWNER_SERVICES_STOPAGE = API_URL + "/api/dt-owner/services/stoppage";
const GET_OWNER_SERVICES_ROUTE = API_URL + "/api/dt-owner/services/route";
const GET_ALL_OWNER_SERVICES_STOPPAGE =
  API_URL + "/api/dt-owner/services/stoppage/all";
const GET_ALL_COUNTERS_OWNER = API_URL + "/api/dt-owner/services/counter";
const GET_ALL_COUNTER_LIST_OWNER = API_URL + "/api/dt-owner/services/counter/all";
const GET_ALL_STOPPAGES_OWNER = API_URL + "/api/dt-owner/services/stoppage/all";
const GET_ALL_DEPOTS_OWNER = API_URL + "/api/dt-owner/services/depot/all";
const ADD_COUNTER_OWNER = API_URL + "/api/dt-owner/services/counter";
const UPDATE_COUNTER_OWNER = API_URL + "/api/dt-owner/services/counter";
const GET_ALL_COUNTERMANS_OWNER = API_URL + "/api/dt-owner/services/counterman";
const ADD_COUNTERMAN_OWNER = API_URL + "/api/dt-owner/services/counterman";
const UPDATE_COUNTERMAN_OWNER = API_URL + "/api/dt-owner/services/counterman";
const GET_ALL_ROUTES_OWNER = API_URL + "/api/dt-owner/services/route/all";
const GET_ROUTES_OWNER = API_URL + "/api/dt-owner/services/route";
const GET_SEAT_CLASS_OWNER = API_URL + "/api/dt-owner/services/seat-class";
const ADD_SEAT_CLASS_OWNER = API_URL + "/api/dt-owner/services/seat-class";
const UPDATE_SEAT_CLASS_OWNER = API_URL + "/api/dt-owner/services/seat-class";
const ADD_SEAT_ROW_OWNER = API_URL + "/api/dt-owner/services/seat-plan/seat-row";
const UPDATE_SEAT_ROW_OWNER =
  API_URL + "/api/dt-owner/services/seat-plan/seat-row";
const DELETE_SEAT_ROW_OWNER =
  API_URL + "/api/dt-owner/services/seat-plan/seat-row";
const GET_SEAT_PLAN_OWNER = API_URL + "/api/dt-owner/services/seat-plan";
const ADD_SEAT_PLAN_OWNER = API_URL + "/api/dt-owner/services/seat-plan";
const UPDATE_SEAT_PLAN_OWNER = API_URL + "/api/dt-owner/services/seat-plan";
const GET_ALL_SEAT_PLAN_OWNER = API_URL + "/api/dt-owner/services/seat-plan/all";
const GET_SEAT_FARE_OWNER = API_URL + "/api/dt-owner/services/fare";
const DELETE_FARE_OWNER = API_URL + "/api/dt-owner/services/fare";
const UPDATE_FARE_OWNER = API_URL + "/api/dt-owner/services/fare/update";
const ADD_SEAT_FARE_OWNER = API_URL + "/api/dt-owner/services/fare";
const SEQUENCE_ROUTE_FILTER_OWNER =
  API_URL + "/api/dt-owner/services/route/route-sequence";
const ADD_FARE_MODALITY_OWNER = API_URL + "/api/dt-owner/services/fare/fare-row";
const UPDATE_FARE_MODALITY_OWNER =
  API_URL + "/api/dt-owner/services/fare/fare-row";
const DELETE_FARE_MODALITY_OWNER =
  API_URL + "/api/dt-owner/services/fare/fare-row";
const GET_ALL_COACH_OWNER = API_URL + "/api/dt-owner/services/coach";
const ADD_COACH_OWNER = API_URL + "/api/dt-owner/services/coach";
const UPDATE_COACH_OWNER = API_URL + "/api/dt-owner/services/coach";
const GET_ALL_ROUTES_NEW_OWNER = API_URL + "/api/dt-owner/services/route";
const GET_TRIP_DATA_OWNER = API_URL + "/api/dt-owner/services/trip";
const GET_ALL_STOPPAGES_OWNER_NEW = API_URL + "/api/dt-owner/services/stoppage";
const GET_ALL_ROUTES_FILTER_OWNER = API_URL + "/api/dt-owner/services/route/all";
const GET_ALL_STOPPAGE_OWNER = API_URL + "/api/dt-owner/services/stoppage/all";
const GET_CHALAN_REPORT = API_URL + "/api/dt-owner/report/chalan-report";
const GET_ALL_LIST_COACH_OWNER = API_URL + "/api/dt-owner/services/coach/all";

// Owner end

// Counterman  startGET_ALL_LIST_COACH
const GET_SINGLE_TRIP_LOG_HISTORY = API_URL + "/api/counterman/trip/all-trip-history"
const TICKET_BY_TRIP = API_URL + "/api/counterman/dashboard/tickets-by-trip";
const REFUND_RESERVED_TICKET_PAYMENT = API_URL + "/api/counterman/dashboard/refund-reserve-amount";
const COUNTERMAN_ALL_COACH = API_URL + "/api/counterman/coach";
const COUNTER_ALL_TRIPS = API_URL + "/api/counterman/trip";
const GET_ALL_TRIPS_FOR_TICKETING = API_URL + "/api/counterman/dashboard";
const TICKET_PURCHASE = API_URL + "/api/counterman/dashboard/ticket-purchase";
const TICKET_RESERVE = API_URL + "/api/counterman/dashboard/reserve-ticket";
const GET_ALL_COUNTERMAN_STOPPAGES = API_URL + "/api/counterman/stoppage/all";
const GET_ALL_COUNTERMAN_SHIPS = API_URL + "/api/counterman/coach/all";
const GET_ALL_TICKETS_HISTORY =
  API_URL + "/api/counterman/dashboard/ticket-history";
const TICKET_CANCEL_COUNTERMAN =
  API_URL + "/api/counterman/dashboard/ticket-cancel";
const RESERVATION_TICKET_CANCEL_COUNTERMAN =
  API_URL + "/api/counterman/dashboard/reserve-cancel";
const RESERVED_TICKET_PAYMENT = API_URL + "/api/counterman/dashboard/pay-reserve-ticket";
const EXTRA_TICKET_SEATS =
  API_URL + "/api/counterman/dashboard/extra-ticket-purchase";
const EXTRA_TICKET_GOODS =
  API_URL + "/api/counterman/dashboard/goods-ticket-purchase";
const EXTRA_TICKET_CANCEL =
  API_URL + "/api/counterman/dashboard/extra-ticket-cancel";
const GOODS_TICKET_CANCEL =
  API_URL + "/api/counterman/dashboard/goods-ticket-cancel";
const LAST_TICKET_PRINT_COUNTERMAN =
  API_URL + "/api/counterman/dashboard/last-ticket";
const TICKET_PRINT_COUNT = API_URL + "/api/counterman/dashboard/print-count";
const TRIP_PASS = API_URL + "/api/counterman/dashboard/trip-pass";
const PNR_TICKET = API_URL + "/api/counterman/dashboard/pnr-ticket";
const UPDATE_TRIP = API_URL + "/api/counterman/dashboard/update-trip";
const ALL_DRIVER = API_URL + "/api/counterman/driver/all";
const ALL_SUPERVISOR = API_URL + "/api/counterman/supervisor/all";
const GET_ALL_COUNTERMAN_REPORT =
  API_URL + "/api/counterman/report/counterman-report";
const GET_COUNTERMAN_TRIPWISE_REPORT =
  API_URL + "/api/counterman/report/trip-report";
const SEARCH_WITH_PNR_OR_PHONE = API_URL + "/api/counterman/ticket/global-search";
const COUNTERMAN_SEARCH_DETAILS_WITH_PASSENGER_PHONE = API_URL + "/api/counterman/dashboard/passenger-info-by-phone";
const GET_ADMIN_RESERVATION_HISTORY = API_URL + "/api/admin/report/trip-reserve-list";
const GET_ADMIN_RESERVATION_HISTORY_DETAILS = API_URL + "/api/admin/report/trip-reserve-details";
const GET_OWNER_RESERVATION_HISTORY = API_URL + "/api/dt-owner/report/trip-reserve-list";
const GET_OWNER_RESERVATION_HISTORY_DETAILS = API_URL + "/api/dt-owner/report/trip-reserve-details";
const GET_COUNTERMAN_RESERVATION_HISTORY = API_URL + "/api/counterman/report/trip-reserve-list";
const GET_COUNTERMAN_RESERVATION_HISTORY_DETAILS = API_URL + "/api/counterman/report/trip-reserve-details";
const VERIFY_TICKET_COUNTERMAN = API_URL + "/api/counterman/verify/digital-ticket-verification";

// Counterman  end

// Extra Start

const GET_ALL_COUNTERS_IN_FILTER = API_URL + "/api/admin/company/counter/all";
const GET_ALL_DECKMANS = API_URL + "/api/deckman";
const ADD_DECKMAN = API_URL + "/api/deckman";
const UPDATE_DECKMAN = API_URL + "/api/deckman";

const GET_ALL_SHIPS_BY_ROUTE = API_URL + "/api/ship/by-route";
const GET_ALL_SHIPS_BY_DECK = API_URL + "/api/ship/by-deck";
const ADD_SHIP = API_URL + "/api/ship";
const UPDATE_SHIP = API_URL + "/api/ship";
const GET_SHIP_INFO = API_URL + "/api/ship/info";

// const GET_ALL_SEAT_CLASSES = API_URL + "/api/seat-class"
// const ADD_SEAT_CLASS = API_URL + "/api/seat-plan/add-class"
// const UPDATE_SEAT_CLASS = API_URL + "/api/seat-plan/update-class"
// const DELETE_SEAT_CLASS = API_URL + "/api/seat-plan/delete-class"

const ADD_CUSTOM_SEAT_ROW = API_URL + "/api/seat-plan/add-custom-seat";
const EDIT_CUSTOM_SEAT_ROW = API_URL + "/api/seat-plan/edit-seat-row";
const DELETE_SEAT_ROW = API_URL + "/api/admin/company/seat-plan/seat-row";
const SEAT_BLOCK = API_URL + "/api/seat-plan/seat-blocking";

const PRINT_COUNT = API_URL + "/api/report/print-count-report";
const SHIP_WISE_REPORT = API_URL + "/api/report/ship-wise-report";
const COUNTER_WISE_REPORT = API_URL + "/api/admin/report/counter-wise-report";
const SHIP_WISE_DECK_REPORT_DETAILS =
  API_URL + "/api/report/ship-wise-report-details";

const SHIP_WISE_GOOD_REPORT_DETAILS =
  API_URL + "/api/report/ship-wise-report-details";
const COUNTER_WISE_REPORT_DETAILS =
  API_URL + "/api/report/counter-wise-report-details";

const GET_ALL_TRIPS = API_URL + "/api/trip";
const ADD_TRIP = API_URL + "/api/trip";
const TRIP_COMPLETED = API_URL + "/api/trip/complete";
const GET_ALL_DASHBOARD_TICKETS = API_URL + "/api/dashboard";

const GET_ALL_COUNTERMAN_ROUTES = API_URL + "/api/route";
const GET_ALL_COUNTERMAN_COUNTERS = API_URL + "/api/counter";
const COUNTER_ALL_SHIPS = API_URL + "/api/counterman/ship";
const TICKETS_HISTORY_COUNTER = API_URL + "/api/counterman/ticket/history";
const TICKET_CANCEL = API_URL + "/api/counterman/ticket/cancel";

const GET_LAST_TICKET = API_URL + "/api/counterman/ticket/print-last-one";
const REPORT_PRINT_COUNT =
  API_URL + "/api/counterman/report/report-print-count";
const REPORT_PRINT_COUNT_INCREASE =
  API_URL + "/api/counterman/report/report-print-count-increase";
const GET_ALL_DASHBOARD_TICKETS_OWNER = API_URL + "/api/owner-panel/dashboard";
const SHIP_WISE_REPORT_OWNER =
  API_URL + "/api/owner-panel/report/ship-wise-report";
const GET_ALL_SHIPS_OWNER = API_URL + "/api/owner-panel/ship";
const GET_ALL_OWNER_SHIPS_BY_DECK = API_URL + "/api/owner-panel/ship/by-deck";
const GET_ALL_COUNTER_OWNER = API_URL + "/api/owner-panel/counter";
const SHIP_WISE_DECK_REPORT_DETAILS_OWNER =
  API_URL + "/api/owner-panel/report/ship-wise-report-details";
const SHIP_WISE_GOOD_REPORT_DETAILS_OWNER =
  API_URL + "/api/owner-panel/report/ship-wise-report-details";
const SHIP_WISE_SEAT_REPORT_DETAILS_OWNER =
  API_URL + "/api/owner-panel/report/ship-wise-report-details";
const COUNTER_WISE_REPORT_OWNER =
  API_URL + "/api/owner-panel/report/counter-wise-report";
const COUNTER_WISE_DETAILS_REPORT_OWNER =
  API_URL + "/api/owner-panel/report/counter-wise-report-details";
const GET_OWNER_SERVICES_BOARDING_PLACE =
  API_URL + "/api/dt-owner/services/route/boarding-point";
const GET_OWNER_SERVICES_DROPPING_PLACE =
  API_URL + "/api/dt-owner/services/route/dropping-point";
const GET_OWNER_SERVICES_TRIPS = API_URL + "/api/owner-panel/trip";
const GET_ALL_OWNER_SERVICES_SHIPS_BY_ROUTE =
  API_URL + "/api/owner-panel/ship/by-route";
const GET_ALL_OWNER_SERVICES_SHIP_INFO = API_URL + "/api/owner-panel/ship/info";
const GET_ALL_DECKMANS_OWNER = API_URL + "/api/owner-panel/deckman";
const ADD_DECKMAN_OWNER = API_URL + "/api/owner-panel/deckman";
const UPDATE_DECKMAN_OWNER = API_URL + "/api/owner-panel/deckman";
const ADD_SHIP_OWNER = API_URL + "/api/owner-panel/ship";
const UPDATE_SHIP_OWNER = API_URL + "/api/owner-panel/ship";
const GET_ALL_SEAT_CLASS_OWNER = API_URL + "/api/dt-owner/services/seat-class/all";
const DELETE_SEAT_CLASS_OWNER =
  API_URL + "/api/owner-panel/seat-plan/delete-class";

const ADD_CUSTOM_SEAT_ROW_BY_OWNER =
  API_URL + "/api/owner-panel/seat-plan/add-custom-seat";
const EDIT_CUSTOM_SEAT_ROW_BY_OWNER =
  API_URL + "/api/owner-panel/seat-plan/edit-seat-row";
const SEAT_BLOCK_OWNER = API_URL + "/api/owner-panel/seat-plan/seat-blocking";

const GET_SUPERVISORS_OWNER = API_URL + "/api/dt-owner/services/supervisor";
const ADD_SUPERVISOR_OWNER = API_URL + "/api/dt-owner/services/supervisor";
const UPDATE_SUPERVISOR_OWNER = API_URL + "/api/dt-owner/services/supervisor";
const GET_DRIVERS_OWNER = API_URL + "/api/dt-owner/services/driver";
const ADD_DRIVER_OWNER = API_URL + "/api/dt-owner/services/driver";
const UPDATE_DRIVER_OWNER = API_URL + "/api/dt-owner/services/driver";
const GET_TRIPS_OWNER = API_URL + "/api/dt-owner/services/trip";
const CANCEL_TRIP_OWNER = API_URL + "/api/dt-owner/services/trip/cancel";
const GET_FARE_ALL_BY_ROUTED_OWNER = API_URL + "/api/dt-owner/services/fare";

const ADD_TRIP_OWNER = API_URL + "/api/dt-owner/services/trip";
const UPDATE_TRIP_OWNER = API_URL + "/api/dt-owner/services/trip";

const GET_FARE_ALL_BY_ROUTEID_OWNER = API_URL + "/api/dt-owner/services/fare/all";
const ADD_TRIP_DATA_OWNER = API_URL + "/api/dt-owner/services/trip";
const UPDATE_TRIP_DATA_OWNER = API_URL + "/api/dt-owner/services/trip";
const ROUTE_FARE_DELETE_OWNER = API_URL + "/api/dt-owner/services/fare/fare-row";

const GET_ALL_OWNER_SERVICES_BOARDING_PLACES_FOR_STOPPAGE =
  API_URL + "/api/dt-owner/services/stoppage/boarding-point";
const ADD_OWNER_SERVICES_BOARDING_PLACE_FOR_STOPPAGE =
  API_URL + "/api/dt-owner/services/stoppage/boarding-point";
const UPDATE_OWNER_SERVICES_BOARDING_PLACE_FOR_STOPPAGE =
  API_URL + "/api/dt-owner/services/stoppage/boarding-point";
const DELETE_OWNER_SERVICES_BOARDING_PLACE_FOR_STOPPAGE =
  API_URL + "/api/dt-owner/services/stoppage/boarding-point";
const GET_ALL_OWNER_SERVICES_DROPPING_PLACES_FOR_STOPPAGE =
  API_URL + "/api/dt-owner/services/stoppage/dropping-point";
const ADD_OWNER_SERVICES_DROPPING_PLACE_FOR_STOPPAGE =
  API_URL + "/api/dt-owner/services/stoppage/dropping-point";
const UPDATE_OWNER_SERVICES_DROPPING_PLACE_FOR_STOPPAGE =
  API_URL + "/api/dt-owner/services/stoppage/dropping-point";

const DELETE_OWNER_SERVICES_DROPPING_PLACE_FOR_STOPPAGE =
  API_URL + "/api/dt-owner/services/stoppage/dropping-point";

// Extra end

export default {
  LOGIN,
  LOGOUT,
  REFRESH,
  GET_ALL_USERS,
  ADD_USER,
  UPDATE_USER,
  DELETE_USER,
  GET_ALL_COMPANIES,
  ADD_COMPANY,
  UPDATE_COMPANY,
  GET_ALL_DEPOTS,
  ADD_DEPOT,
  UPDATE_DEPOT,
  GET_ALL_DEPOTS_ALL,
  GET_ALL_OWNERS,
  ADD_OWNER,
  UPDATE_OWNER,
  GET_ALL_DT_OWNERS,
  ADD_DT_OWNER,
  UPDATE_DT_OWNER,
  GET_ALL_STOPPAGES,
  GET_ALL_STOPPAGES_OWNER_NEW,
  ADD_STOPPAGE,
  UPDATE_STOPPAGE,
  GET_ALL_BOARDING_PLACES,
  ADD_BOARDING_PLACE,
  UPDATE_BOARDING_PLACE,
  GET_ALL_DROPPING_PLACES,
  ADD_DROPPING_PLACE,
  UPDATE_DROPPING_PLACE,
  GET_ALL_BOARDING_PLACES_FOR_STOPPAGE,
  ADD_BOARDING_PLACE_FOR_STOPPAGE,
  UPDATE_BOARDING_PLACE_FOR_STOPPAGE,
  DELETE_BOARDING_PLACE_FOR_STOPPAGE,
  GET_ALL_DROPPING_PLACES_FOR_STOPPAGE,
  ADD_DROPPING_PLACE_FOR_STOPPAGE,
  UPDATE_DROPPING_PLACE_FOR_STOPPAGE,
  DELETE_DROPPING_PLACE_FOR_STOPPAGE,
  GET_ALL_DEPOTS_OWNER,
  GET_ALL_ROUTES,
  ADD_ROUTE,
  UPDATE_ROUTE,
  GET_ALL_COUNTERS,
  ADD_COUNTER,
  UPDATE_COUNTER,
  GET_ALL_COUNTERMANS,
  GET_ALL_SUPERVISORS,
  ADD_SUPERVISOR,
  UPDATE_SUPERVISOR,
  GET_ALL_DRIVERS,
  ADD_DRIVER,
  UPDATE_DRIVER,
  ADD_COUNTERMAN,
  UPDATE_COUNTERMAN,
  GET_ALL_SHIPS_BY_ROUTE,
  GET_ALL_SHIPS_BY_DECK,
  ADD_SHIP,
  UPDATE_SHIP,
  ADD_SEAT_CLASS,
  UPDATE_SEAT_CLASS,
  ADD_SEAT_ROW,
  ADD_CUSTOM_SEAT_ROW,
  EDIT_CUSTOM_SEAT_ROW,
  DELETE_SEAT_ROW,
  GET_ALL_TRIPS,
  ADD_TRIP,
  GET_ALL_DECKMANS,
  ADD_DECKMAN,
  UPDATE_DECKMAN,
  TRIP_COMPLETED,
  GET_SHIP_INFO,
  COMPANY_LIST,
  SHIP_WISE_REPORT,
  COUNTER_WISE_REPORT,
  COACH_WISE_SEAT_REPORT_DETAILS,
  COUNTERMAN_WISE_REPORT_DETAILS,
  SHIP_WISE_GOOD_REPORT_DETAILS,
  GET_ALL_TRIPS_FOR_TICKETING,
  GET_ALL_DASHBOARD_TICKETS,
  COUNTER_WISE_REPORT_DETAILS,
  TICKET_PURCHASE,
  TICKET_RESERVE,
  TICKET_PRINT_COUNT,
  PRINT_COUNT,
  REPORT_PRINT_COUNT,
  REPORT_PRINT_COUNT_INCREASE,
  COUNTER_ALL_SHIPS,
  COUNTER_ALL_TRIPS,
  GET_SINGLE_TRIP_LOG_HISTORY,
  TICKETS_HISTORY_COUNTER,
  TICKET_CANCEL,
  VERIFY_TICKET_COUNTERMAN,
  GET_ALL_COUNTERMAN_REPORT,
  GET_COUNTERMAN_TRIPWISE_REPORT,
  GET_LAST_TICKET,
  SEAT_BLOCK,
  GET_ALL_DASHBOARD_TICKETS_OWNER,
  SHIP_WISE_REPORT_OWNER,
  GET_ALL_SHIPS_OWNER,
  SHIP_WISE_DECK_REPORT_DETAILS_OWNER,
  SHIP_WISE_GOOD_REPORT_DETAILS_OWNER,
  SHIP_WISE_SEAT_REPORT_DETAILS_OWNER,
  COUNTER_WISE_REPORT_OWNER,
  GET_ALL_OWNER_SHIPS_BY_DECK,
  GET_ALL_COUNTER_OWNER,
  COUNTER_WISE_DETAILS_REPORT_OWNER,
  GET_ALL_OWNERS_PANEL,
  ADD_OWNER_PANEL,
  UPDATE_OWNER_PANEL,
  GET_ALL_DECKMANS_OWNER,
  ADD_DECKMAN_OWNER,
  UPDATE_DECKMAN_OWNER,
  GET_ALL_COUNTERS_OWNER,
  GET_ALL_STOPPAGES_OWNER,
  ADD_COUNTER_OWNER,
  UPDATE_COUNTER_OWNER,
  GET_ALL_COUNTERMANS_OWNER,
  ADD_COUNTERMAN_OWNER,
  UPDATE_COUNTERMAN_OWNER,
  GET_ALL_ROUTES_OWNER,
  ADD_SHIP_OWNER,
  UPDATE_SHIP_OWNER,
  GET_SEAT_CLASS_OWNER,
  ADD_SEAT_CLASS_OWNER,
  ADD_SEAT_ROW_OWNER,
  UPDATE_SEAT_ROW_OWNER,
  ADD_CUSTOM_SEAT_ROW_BY_OWNER,
  EDIT_CUSTOM_SEAT_ROW_BY_OWNER,
  SEAT_BLOCK_OWNER,
  DELETE_SEAT_CLASS_OWNER,
  UPDATE_SEAT_CLASS_OWNER,
  GET_OWNER_SERVICES_STOPAGE,
  GET_OWNER_SERVICES_ROUTE,
  GET_OWNER_SERVICES_BOARDING_PLACE,
  GET_OWNER_SERVICES_DROPPING_PLACE,
  GET_OWNER_SERVICES_TRIPS,
  GET_ALL_OWNER_SERVICES_STOPPAGE,
  GET_ALL_OWNER_SERVICES_SHIPS_BY_ROUTE,
  GET_ALL_OWNER_SERVICES_SHIP_INFO,
  GET_ALL_COUNTERMAN_STOPPAGES,
  GET_ALL_COUNTERMAN_ROUTES,
  GET_ALL_COUNTERMAN_COUNTERS,
  GET_ALL_COUNTERMAN_SHIPS,
  DELETE_SEAT_ROW_OWNER,
  GET_ALL_STOPPAGE,
  GET_ALL_STOPPAGE_OWNER,
  SEAT_CLASS,
  ADD_SEAT_PLAN,
  GET_SEAT_PLAN,
  GET_SEAT_PLAN_OWNER,
  ADD_SEAT_PLAN_OWNER,
  UPDATE_SEAT_PLAN_OWNER,
  GET_ALL_SEAT_PLAN_OWNER,
  UPDATE_SEAT_PLAN,
  UPDATE_SEAT_ROW,
  GET_ALL_ROUTES_FILTER,
  ADD_SEAT_FARE,
  GET_SEAT_FARE,
  GET_SEAT_FARE_OWNER,
  ADD_SEAT_FARE_OWNER,
  DELETE_FARE_OWNER,
  UPDATE_FARE_OWNER,
  DELETE_FARE,
  UPDATE_FARE,
  SEQUENCE_ROUTE_FILTER,
  SEQUENCE_ROUTE_FILTER_OWNER,
  GET_ALL_SEAT_CLASS,
  GET_ALL_SEAT_CLASS_OWNER,
  FARE_MODALITY_ADD,
  ADD_FARE_MODALITY_OWNER,
  UPDATE_FARE_MODALITY_OWNER,
  DELETE_FARE_MODALITY_OWNER,
  ROURE_FARE_DELETE,
  UPDATE_FARE_ROUTE,
  ADD_COACH,
  ADD_COACH_OWNER,
  UPDATE_COACH_OWNER,
  GET_ALL_SEATPLAN,
  GET_ALL_COACH,
  GET_ALL_COACH_OWNER,
  UPDATE_COACH,
  GET_ALL_LIST_COACH,
  GET_FARE_ALL_BY_ROUTEID,
  ADD_TRIP_DATA,
  GET_TRIP_DATA,
  UPDATE_TRIP_DATA,
  CANCEL_TRIP,
  CANCEL_TRIP_OWNER,
  COUNTERMAN_ALL_COACH,
  GET_ALL_COUNTER_LIST_OWNER,
  GET_SUPERVISORS_OWNER,
  ADD_SUPERVISOR_OWNER,
  UPDATE_SUPERVISOR_OWNER,
  GET_DRIVERS_OWNER,
  ADD_DRIVER_OWNER,
  UPDATE_DRIVER_OWNER,
  GET_ALL_TICKETS_HISTORY,
  TICKET_CANCEL_COUNTERMAN,
  RESERVATION_TICKET_CANCEL_COUNTERMAN,
  RESERVED_TICKET_PAYMENT,
  GET_TRIPS_OWNER,
  GET_ALL_LIST_COACH_OWNER,
  GET_FARE_ALL_BY_ROUTED_OWNER,
  ADD_TRIP_OWNER,
  UPDATE_TRIP_OWNER,
  GET_ROUTES_OWNER,
  GET_ALL_ROUTES_NEW_OWNER,
  GET_TRIP_DATA_OWNER,
  GET_ALL_ROUTES_FILTER_OWNER,
  GET_FARE_ALL_BY_ROUTEID_OWNER,
  DIGITAL_TICKET_WISE_REPORT_OWNER,
  DIGITAL_TICKET_WISE_REPORT_DETAILS_OWNER,
  ADD_TRIP_DATA_OWNER,
  UPDATE_TRIP_DATA_OWNER,
  ROUTE_FARE_DELETE_OWNER,
  EXTRA_TICKET_SEATS,
  EXTRA_TICKET_GOODS,
  EXTRA_TICKET_CANCEL,
  GOODS_TICKET_CANCEL,
  LAST_TICKET_PRINT_COUNTERMAN,
  TRIP_PASS,
  PNR_TICKET,
  UPDATE_TRIP,
  ALL_DRIVER,
  ALL_SUPERVISOR,
  COACH_WISE_REPORT,
  DIGITAL_TICKET_WISE_REPORT,
  DIGITAL_TICKET_WISE_REPORT_DETAILS,
  ADMIN_DASHBOARD,
  ADMIN_PRINT_COUNT_REPORT,
  OWNER_TRIP_WISE_REPORT,
  SHIP_WISE_DECK_REPORT_DETAILS,
  GET_ALL_COUNTERS_IN_FILTER,
  GET_SINGLE_COMPANY_DATA,
  GET_ALL_COACHWISE_TRIP_ADMIN,
  GET_ALL_COACHWISE_TRIP_OWNER,
  CHALAN_TRIP_WISE_REPORT_OWNER,
  DIGITAL_TICKET_CANCEL,
  GET_CHALAN_REPORT,
  OWNER_DASHBOARD,
  TICKET_BY_TRIP,
  COACH_WISE_REPORT_OWNER,
  DIGITAL_TICKET_CANCEL_OWNER,
  COACH_WISE_SEAT_REPORT_DETAILS_OWNER,
  COUNTER_WISE_REPORT_OWNER_PANEL,
  GET_ALL_COUNTERS_IN_FILTER_OWNER_PANEL,
  COUNTERMAN_WISE_REPORT_DETAILS_OWNER,
  OWNER_PRINT_COUNT_REPORT,
  GET_ALL_COUNTERS_ALL,
  GET_ALL_OWNER_SERVICES_BOARDING_PLACES_FOR_STOPPAGE,
  ADD_OWNER_SERVICES_BOARDING_PLACE_FOR_STOPPAGE,
  UPDATE_OWNER_SERVICES_BOARDING_PLACE_FOR_STOPPAGE,
  DELETE_OWNER_SERVICES_BOARDING_PLACE_FOR_STOPPAGE,
  GET_ALL_OWNER_SERVICES_DROPPING_PLACES_FOR_STOPPAGE,
  ADD_OWNER_SERVICES_DROPPING_PLACE_FOR_STOPPAGE,
  UPDATE_OWNER_SERVICES_DROPPING_PLACE_FOR_STOPPAGE,
  DELETE_OWNER_SERVICES_DROPPING_PLACE_FOR_STOPPAGE,
  SEARCH_WITH_PNR_OR_PHONE,
  COUNTERMAN_SEARCH_DETAILS_WITH_PASSENGER_PHONE,
  GET_ADMIN_RESERVATION_HISTORY,
  GET_ADMIN_RESERVATION_HISTORY_DETAILS,
  GET_OWNER_RESERVATION_HISTORY,
  GET_OWNER_RESERVATION_HISTORY_DETAILS,
  GET_COUNTERMAN_RESERVATION_HISTORY,
  GET_COUNTERMAN_RESERVATION_HISTORY_DETAILS,
  REFUND_RESERVED_TICKET_PAYMENT
};
