import apiResponse from "@/modules/apiResponse";
import toast from "@/modules/Toast";
import tokenCookie from "@/modules/tokenCookie";
import validationMessage from "@/modules/ValidationMessage";
import Apis from "@/urls/apis";
import axios from "axios";

const { successToast } = toast();
const { hasValidToken, getToken } = tokenCookie();
const { errorResponse, successResponse } = apiResponse();
const { messageExtractor } = validationMessage();

const state = {
  filter: {
    stoppageOwner: {},
    routeOwner: {},
    route: {},
    depot: {},
    seatClass: {},
    sequenceRoutes: {},
    seatPlanList: {},
    counter: {},
    routes: {},
    stoppage: {},
    allCoachList: {},
  },
  deckMansOwner: [],
  countersOwner: [],
  counterMansOwner: [],

  ownerServicesStopage: [],
  ownerServicesRoute: [],
  ownerServicesBoardingPlaces: [],
  ownerServicesDroppingPlaces: [],

  ownerServicesTrips: [],
  seatClasses: [],
  seatPlans: [],
  fares: [],
  coachs: [],
  counterMans: [],
  supervisors: {},
  drivers: {},
  routesNew: [],
  trips: [],
  stoppages: [],
  boardingPlacesForStoppage: [],
  droppingPlacesForStoppage: [],
  defaultBoardingPlace: null,
	defaultDroppingPoint: null,
  singleTripHistoryOwner: [],
};
const getters = {
  getSingleTripHistoryOwner: (state) => state.singleTripHistoryOwner,
  getOwnerServicesBoardingPlacesForStoppage: (state) =>
    state.boardingPlacesForStoppage,
  getOwnerServicesDroppingPlacesForStoppage: (state) =>
    state.droppingPlacesForStoppage,
  getOwnerServicesDefaultBoardingPlace: (state) => state.defaultBoardingPlace,
  getOwnerServicesDefaultDroppingPoint: (state) => state.defaultDroppingPoint,
  getDepotListForFilterOwnerServices: (state) => state.filter.depot,
  getRouteListForFilterOwner: (state) => state.filter.routeOwner,
  getCounterMansOwner: (state) => state.counterMansOwner.docs,
  getTotalCounterManOwner: (state) => state.counterMansOwner.totalDocs,
  getCounterManOwner: (state) => (id) =>
    state.counterMansOwner.docs.filter(function (counterMan) {
      return counterMan._id === id;
    }),

  getOwnerServicesCounterMans: (state) => state.counterMans.docs,
  getOwnerServicesTotalCounterMan: (state) => state.counterMans.totalDocs,
  getOwnerServicesCounterMan: (state) => (id) =>
    state.counterMans.docs.filter(function (counterMan) {
      return counterMan._id === id;
    }),

  getStoppagesOwner: (state) => state.filter.stoppageOwner,
  getCountersOwner: (state) => state.countersOwner.docs,
  getTotalCounterOwner: (state) => state.countersOwner.totalDocs,
  getCounterOwner: (state) => (id) =>
    state.countersOwner.docs.filter(function (counter) {
      return counter._id === id;
    }),

  getDeckMansOwner: (state) => state.deckMansOwner.docs,
  getTotalDeckManOwner: (state) => state.deckMansOwner.totalDocs,
  getDeckManOwner: (state) => (id) =>
    state.deckMansOwner.docs.filter(function (deckMan) {
      return deckMan._id === id;
    }),

  getOwnerServicesStopageList: (state) => state.ownerServicesStopage.docs,
  getTotalStopageRecords: (state) => state.ownerServicesStopage.totalDocs,
  getOwnerServicesStopage: (state) => (id) =>
    state.ownerServicesStopage.docs.filter(function (stopage) {
      return stopage._id === id;
    }),

  getOwnerServicesRouteList: (state) => state.ownerServicesRoute.docs,
  getTotalRouteRecords: (state) => state.ownerServicesRoute.totalDocs,
  getOwnerServicesRoute: (state) => (id) =>
    state.ownerServicesRoute.docs.filter(function (route) {
      return route._id === id;
    }),
  getServicesRouteListForFilter: (state) => state.filter.route,
  getOwnerServicesBoardingPlaces: (state) => state.ownerServicesBoardingPlaces,
  getOwnerServicesDroppingPlaces: (state) => state.ownerServicesDroppingPlaces,
  getOwnerServicesTrips: (state) => state.ownerServicesTrips.docs,
  getTotalOwnerServicesTrips: (state) => state.ownerServicesTrips.totalDocs,
  getOwnerServicesTrip: (state) => (id) =>
    state.ownerServicesTrips.docs.filter(function (trip) {
      return trip._id === id;
    }),

  getOwnerServicesSeatClass: (state) => state.seatClasses.docs,
  getOwnerServicesTotalSeatClass: (state) => state.seatClasses.totalDocs,
  getOwnerServicesSeatCls: (state) => (id) =>
    state.seatClasses.docs.filter(function (seatClass) {
      return seatClass._id === id;
    }),
  getAllSeatClassOwner: (state) => state.filter.seatClass,
  getOwnerServicesSeatPlans: (state) => state.seatPlans.docs,
  getTotalOwnerServicesSeatPlans: (state) => state.seatPlans.totalDocs,
  getOwnerServicesSeatPlan: (state) => (id) => {
    return state.seatPlans.docs.filter((plan) => plan._id === id);
  },

  getOwnerServicesFares: (state) => state.fares.docs,
  getOwnerTotalServicesFares: (state) => state.fares.totalDocs,
  getOwnerServicesFare: (state) => (id) => {
    return state.fares.docs.filter((plan) => plan._id === id);
  },
  getOwnerServicesSequenceRouteListForFilter: (state) =>
    state.filter.sequenceRoutes,
  getOwnerServicesSeatPlanListForFilter: (state) => state.filter.seatPlanList,
  getOwnerServicesCoachs: (state) => state.coachs.docs,
  getOwnerServicesCoach: (state) => (id) =>
    state.coachs.docs.filter(function (coach) {
      return coach._id === id;
    }),
  getOwnerServicesTotalCoachs: (state) => state.coachs.totalDocs,
  getOwnerServicesCounterListForFilter: (state) => state.filter.counter,

  getOwnerServicesSupervisors: (state) => state.supervisors.docs,
  getOwnerServicesTotalSupervisor: (state) => state.supervisors.totalDocs,
  getOwnerServicesSupervisor: (state) => (id) =>
    state.supervisors.docs.filter(function (supervisor) {
      return supervisor._id === id;
    }),

  getOwnerServicesDrivers: (state) => state.drivers.docs,
  getOwnerServicesTotalDriver: (state) => state.drivers.totalDocs,
  getOwnerServicesDriver: (state) => (id) =>
    state.drivers.docs.filter(function (driver) {
      return driver._id === id;
    }),

  getOwnerServicesRoutesNew: (state) => state.routesNew.docs,

  getOwnerServicesTripsNew: (state) => state.trips.docs,
  getOwnerServicesTotalTrips: (state) => state.trips.totalDocs,
  getOwnerServicesTripNew: (state) => (id) =>
    state.trips.docs.filter(function (trip) {
      return trip._id === id;
    }),
  getOwnerServicesStoppagesNew: (state) => state.stoppages,
  getOwnerServicesRouteListForFilter: (state) => state.filter.routes,
  getOwnerServicesStoppageListForFilterNew: (state) => state.filter.stoppage,
  getOwnerServicesAllCoachListForFilter: (state) => state.filter.allCoachList,
};
const actions = {
	async singleTripHistoryActionOwner({ commit }, payload) {
		if (await hasValidToken()) {
			const header = {
				Authorization: "Bearer " + getToken(),
			};
			return new Promise((resolve, reject) => {
				axios
					.get(Apis.GET_SINGLE_TRIP_LOG_HISTORY_OWNER, {
						params: payload,
						headers: header,
					})
					.then(({ data, status }) => {
						if (status === 200) {
							commit("setSingleTripHistoryOwner", data.trips);
							resolve(data.trip);
						}
					})
					.catch((error) => {
						reject(errorResponse(error));
					});
			});
		}
	},
  async updateSeatPlanOwnerPanel({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .put(Apis.UPDATE_SEAT_PLAN_OWNER, payload, { headers: header })
          .then((response) => {
            commit("updateSeatPlnOwner", response.data.stoppage);
            resolve(successResponse(response));
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getDackmanByPageOwner({ commit }, payload) {
    if (await hasValidToken()) {
      commit("setDeckMans", []);
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_ALL_DECKMANS_OWNER, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              commit("setDeckMansOwner", data.deckMan);
              resolve({ success: "success" });
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async addDeckManOwner(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .post(Apis.ADD_DECKMAN_OWNER, payload, { headers: header })
          .then((response) => {
            successToast("Deckman Added Successfully");
            resolve(response);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async updateDeckManOwner(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .put(Apis.UPDATE_DECKMAN_OWNER, payload, { headers: header })
          .then((response) => {
            successToast("Deckman Updated Successfully");
            resolve(response);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getCountersByPageOwner({ commit }, payload) {
    if (await hasValidToken()) {
      commit("setCounters", []);
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_ALL_COUNTERS_OWNER, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              commit("setCountersOwner", data.counter);
              resolve({ success: "success" });
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getStoppageListOwner({ commit }, payload) {
    if (await hasValidToken()) {
      commit("setStoppageListInFilter", {});
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_ALL_STOPPAGES_OWNER, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              let stoppageList = {};
              data.stoppage.forEach((stoppage) => {
                stoppageList[stoppage._id] = stoppage.name;
              });
              commit("setStoppageListInFilterOwner", stoppageList);
              commit("setStoppages", data.stoppage);

              resolve(data.stoppage);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async addCounterOwner(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .post(Apis.ADD_COUNTER_OWNER, payload, { headers: header })
          .then((success) => {
            successToast("Counter Added Successfully");
            resolve(success);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async updateCounterOwner(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .put(Apis.UPDATE_COUNTER_OWNER, payload, { headers: header })
          .then((success) => {
            successToast("Counter Updated Successfully");
            resolve(success);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getCounterMansByPageOwner({ commit }, payload) {
    if (await hasValidToken()) {
      commit("setCounterMans", []);
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_ALL_COUNTERMANS_OWNER, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              commit("setCounterMansOwner", data.counterMan);
              resolve({ success: "success" });
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async addCounterManOwner(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .post(Apis.ADD_COUNTERMAN_OWNER, payload, { headers: header })
          .then((success) => {
            successToast("Counterman Added Successfully");
            resolve(success);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async updateCounterManOwner(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .put(Apis.UPDATE_COUNTERMAN_OWNER, payload, { headers: header })
          .then((success) => {
            successToast("Counterman Updated Successfully");
            resolve(success);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getShipsByPageOwner({ commit }, payload) {
    if (await hasValidToken()) {
      commit("setShips", []);
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_ALL_SHIPS_OWNER, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              commit("setShipsOwner", data.ship);
              resolve({ success: "success" });
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getRouteListOwner({ commit }, payload) {
    if (await hasValidToken()) {
      commit("setRouteListInFilter", {});
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_ALL_ROUTES_OWNER, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              let routeList = {};
              data.route.forEach((route) => {
                routeList[route._id] = route.name;
              });
              commit("setRouteListInFilterOwner", routeList);
              resolve(data.route);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async addSeatClassOwner({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .post(Apis.ADD_SEAT_CLASS_OWNER, payload, { headers: header })
          .then(({ data, status }) => {
            if (status === 200) {
              messageExtractor(data, status);
              commit("updateShipOwner", data.ship);
              resolve(data.message);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async addSeatRowOwner({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .post(Apis.ADD_SEAT_ROW_OWNER, payload, { headers: header })
          .then(({ data, status }) => {
            if (status === 200) {
              messageExtractor(data, status);
              commit("updateShipOwner", data.ship);
              resolve(data.message);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async addCustomSeatRowByOwner({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .post(Apis.ADD_CUSTOM_SEAT_ROW_BY_OWNER, payload, { headers: header })
          .then(({ data, status }) => {
            if (status === 200) {
              messageExtractor(data, status);
              commit("updateShipOwner", data.ship);
              resolve(data.message);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async blockSeatsOwner({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .post(Apis.SEAT_BLOCK_OWNER, payload, { headers: header })
          .then(({ data, status }) => {
            if (status === 200) {
              messageExtractor(data, status);
              commit("updateShipOwner", data.ship);
              resolve(data.message);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async deleteSeatClassOwner({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .delete(Apis.DELETE_SEAT_CLASS_OWNER, {
            data: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              messageExtractor(data, status);
              // commit("removeSeatClass", payload);
              commit("updateShipOwner", data.ship);
              resolve(data.message);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async updateSeatClassOwner({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .put(Apis.UPDATE_SEAT_CLASS_OWNER, payload, { headers: header })
          .then(({ data, status }) => {
            if (status === 200) {
              messageExtractor(data, status);
              commit("updateShipOwner", data.ship);
              resolve(data.message);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getOwnerServicesStopageByPage({ commit }, payload) {
    if (await hasValidToken()) {
      commit("setOwnersServicesStopage", []);
      const header = {
        Authorization: "Bearer " + getToken(),
      };
      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_OWNER_SERVICES_STOPAGE, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              commit("setOwnersServicesStopage", data.stoppage);
              resolve({ success: "success" });
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async addOwnerServicesStopage(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };
      return new Promise((resolve, reject) => {
        axios
          .post(Apis.GET_OWNER_SERVICES_STOPAGE, payload, { headers: header })
          .then((success) => {
            successToast(success.data.message);
            resolve(success);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async updateOwnerServicesStopage(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };
      return new Promise((resolve, reject) => {
        axios
          .put(Apis.GET_OWNER_SERVICES_STOPAGE, payload, { headers: header })
          .then((success) => {
            successToast(success.data.message);
            resolve(success);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getOwnerServicesStoppagesInFilter({ commit }, payload) {
    if (await hasValidToken()) {
      commit("setOwnersServicesRouteInFilter", {});
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_ALL_OWNER_SERVICES_STOPPAGE, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              let stoppageList = {};
              data.stoppage.forEach((stoppage) => {
                stoppageList[stoppage._id] = stoppage.name;
              });
              commit("setOwnersServicesRouteInFilter", stoppageList);
              resolve(data.stoppage);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getOwnerServicesRouteByPage({ commit }, payload) {
    if (await hasValidToken()) {
      commit("setOwnersServicesRoute", []);
      const header = {
        Authorization: "Bearer " + getToken(),
      };
      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_OWNER_SERVICES_ROUTE, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              commit("setOwnersServicesRoute", data.route);
              resolve({ success: "success" });
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getOwnerServicesRoutesByPageNew({ commit }, payload) {
    if (await hasValidToken()) {
      commit("setRoutesNew", []);
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_ALL_ROUTES_NEW_OWNER, {
            params: payload,
            headers: header,
          })
          .then((response) => {
            commit("setRoutesNew", response.data.route);
            resolve(response.data.route);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async addOwnerServicesRoute({ commit }, { routeData, pagination }) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };
      return new Promise((resolve, reject) => {
        axios
          .post(Apis.GET_OWNER_SERVICES_ROUTE, routeData, {
            headers: header,
            params: pagination,
          })
          .then((success) => {
            commit("setRoutes", success.data.route);
            resolve(successResponse(success));
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async updateOwnerServicesRoute({ commit }, { routeData, pagination }) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .put(Apis.GET_OWNER_SERVICES_ROUTE, routeData, {
            headers: header,
            params: pagination,
          })
          .then((success) => {
            commit("setRoute", success.data.result);
            resolve(successResponse(success));
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getOwnerServicesBoardingPlacesByPage({ commit }, payload) {
    if (await hasValidToken()) {
      commit("setOwnerServicesBoardingPlaces", []);
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_OWNER_SERVICES_BOARDING_PLACE, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              commit("setOwnerServicesBoardingPlaces", data.boardingPlace);
              resolve({ success: "success" });
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async addOwnerServicesBoardingPlace(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .post(Apis.GET_OWNER_SERVICES_BOARDING_PLACE, payload, {
            headers: header,
          })
          .then((success) => {
            successToast("BoardingPlace Added Succesfully");
            resolve(success);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async updateOwnerServicesBoardingPlace(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .put(Apis.GET_OWNER_SERVICES_BOARDING_PLACE, payload, {
            headers: header,
          })
          .then((success) => {
            successToast("Boarding Place Updated Succesfully");
            resolve(success);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getOwnerServicesDroppingPlacesByPage({ commit }, payload) {
    if (await hasValidToken()) {
      commit("setOwnerServicesDroppingPlaces", []);
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_OWNER_SERVICES_DROPPING_PLACE, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              commit("setOwnerServicesDroppingPlaces", data.droppingPoint);
              resolve({ success: "success" });
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async addOwnerServicesDroppingPlace(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .post(Apis.GET_OWNER_SERVICES_DROPPING_PLACE, payload, {
            headers: header,
          })
          .then((success) => {
            successToast("Dropping Place Added Succesfully");
            resolve(success);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async updateOwnerServicesDroppingPlace(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .put(Apis.GET_OWNER_SERVICES_DROPPING_PLACE, payload, {
            headers: header,
          })
          .then((success) => {
            successToast("Dropping Place Updated Succesfully");
            resolve(success);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getOwnerServicesBoardingPlacesForStoppageByPage({ commit }, payload) {
    if (await hasValidToken()) {
      commit("setBoardingPlacesForStoppage", []);
      commit("setDefaultBoardingPlaceForStoppage", null);
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_ALL_OWNER_SERVICES_BOARDING_PLACES_FOR_STOPPAGE, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              commit("setBoardingPlacesForStoppage", data.boardingPlace);
              commit(
                "setDefaultBoardingPlaceForStoppage",
                data.defaultBoardingPlace
              );
              resolve({ success: "success" });
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async addOwnerServicesBoardingPlaceForStoppage({ commit }, bPlace) {
    if (await hasValidToken()) {
      commit("setBoardingPlacesForStoppage", []);
      commit("setDefaultBoardingPlaceForStoppage", null);
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .post(Apis.ADD_OWNER_SERVICES_BOARDING_PLACE_FOR_STOPPAGE, bPlace, {
            headers: header,
          })
          .then((response) => {
            commit("setBoardingPlaceForStoppage", response.data.boardingPlace);
            commit(
              "setDefaultBoardingPlaceForStoppage",
              response.data.defaultBoardingPlace
            );
            resolve(successResponse(response));
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async updateOwnerServicesBoardingPlaceForStoppage({ commit }, bPlace) {
    if (await hasValidToken()) {
      commit("setBoardingPlacesForStoppage", []);
      commit("setDefaultBoardingPlaceForStoppage", null);
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .put(Apis.UPDATE_OWNER_SERVICES_BOARDING_PLACE_FOR_STOPPAGE, bPlace, {
            headers: header,
          })
          .then((response) => {
            commit("setBoardingPlaceForStoppage", response.data.boardingPlace);
            commit(
              "setDefaultBoardingPlaceForStoppage",
              response.data.defaultBoardingPlace
            );
            resolve(successResponse(response));
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async deleteOwnerServicesBoardingPlaceForStoppage(_, { bPlace }) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .delete(Apis.DELETE_OWNER_SERVICES_BOARDING_PLACE_FOR_STOPPAGE, {
            data: bPlace,
            headers: header,
          })
          .then((response) => {
            resolve(successResponse(response));
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getOwnerServicesDroppingPlacesForStoppageByPage({ commit }, payload) {
    if (await hasValidToken()) {
      commit("setDroppingPlacesForStoppage", []);
      commit("setDefaultDroppingPlaceForStoppage", null);

      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_ALL_OWNER_SERVICES_DROPPING_PLACES_FOR_STOPPAGE, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              commit("setDroppingPlacesForStoppage", data.droppingPoint);
              commit(
                "setDefaultDroppingPlaceForStoppage",
                data.defaultDroppingPoint
              );
              resolve({ success: "success" });
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async addOwnerServicesDroppingPlaceForStoppage({ commit }, payload) {
    if (await hasValidToken()) {
      commit("setDroppingPlacesForStoppage", []);
      commit("setDefaultDroppingPlaceForStoppage", null);
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .post(Apis.ADD_OWNER_SERVICES_DROPPING_PLACE_FOR_STOPPAGE, payload, {
            headers: header,
          })
          .then((response) => {
            commit("setDroppingPlacesForStoppage", response.data.droppingPoint);
            commit(
              "setDefaultDroppingPlaceForStoppage",
              response.data.defaultDroppingPoint
            );
            resolve(successResponse(response));
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async updateOwnerServicesDroppingPlaceForStoppage({ commit }, payload) {
    if (await hasValidToken()) {
      commit("setDroppingPlaceForStoppage", []);
      commit("setDefaultDroppingPlaceForStoppage", null);
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .put(
            Apis.UPDATE_OWNER_SERVICES_DROPPING_PLACE_FOR_STOPPAGE,
            payload,
            {
              headers: header,
            }
          )
          .then((response) => {
            commit("setDroppingPlaceForStoppage", response.data.droppingPoint);
            commit(
              "setDefaultDroppingPlaceForStoppage",
              response.data.defaultDroppingPoint
            );
            resolve(successResponse(response));
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async deleteOwnerServicesDroppingPlaceForStoppage(_, { dPlace }) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .delete(Apis.DELETE_OWNER_SERVICES_DROPPING_PLACE_FOR_STOPPAGE, {
            data: dPlace,
            headers: header,
          })
          .then((response) => {
            resolve(successResponse(response));
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getOwnerServicesShipsByRoute(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_ALL_OWNER_SERVICES_SHIPS_BY_ROUTE, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              resolve(data.ship);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },
  async getOwnerServicesShipsInfo(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_ALL_OWNER_SERVICES_SHIP_INFO, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              resolve(data.ship);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getOwnerServicesDepotListInFilter({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_ALL_DEPOTS_OWNER, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              let depotList = {};
              data.depot.forEach((depot) => {
                depotList[depot._id] = depot.name;
              });
              commit("setOwnerServicesDepotListInFilter", depotList);
              resolve(data.depot);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getOwnerServicesSeatClassByPage({ commit }, payload) {
    if (await hasValidToken()) {
      commit("setSeatClass", []);
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_SEAT_CLASS_OWNER, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              commit("setSeatClass", data.seatClass);
              resolve({ success: "success" });
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },
  async addOwnerServicesSeatClass({ commit }, { seatClassData, pagination }) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .post(Apis.ADD_SEAT_CLASS_OWNER, seatClassData, {
            headers: header,
            params: pagination,
          })
          .then((success) => {
            commit("setSeatClass", success.data.seatClass);
            resolve(successResponse(success));
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },
  async updateOwnerServicesSeatClass({ commit }, { seatClassData }) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .put(Apis.UPDATE_SEAT_CLASS_OWNER, seatClassData, { headers: header })
          .then((success) => {
            commit("setSeatCls", success.data.seatClass);
            resolve(successResponse(success));
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getOwnerServicesAllSeatClass({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_ALL_SEAT_CLASS_OWNER, {
            params: payload,
            headers: header,
          })
          .then(({ data }) => {
            let seatClass = {};
            data.seatClass.forEach((seat) => {
              seatClass[seat._id] = seat.name;
            });
            commit("setAllSeatClass", seatClass);
            resolve(seatClass);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getOwnerServicesNewSeatPlan({ commit }, pagination) {
    if (await hasValidToken()) {
      commit("addSeatPlan", []);

      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_SEAT_PLAN_OWNER, {
            headers: header,
            params: pagination,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              commit("addSeatPlan", data.seatPlan);
              resolve(data.message);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async addOwnerServicesNewSeatPlan({ commit }, { seatPlan, pagination }) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .post(Apis.ADD_SEAT_PLAN_OWNER, seatPlan, {
            headers: header,
            params: pagination,
          })
          .then((response) => {
            commit("addSeatPlan", response.data.route);
            resolve(successResponse(response));
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async addOwnerServicesSeatRow({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .post(Apis.ADD_SEAT_ROW_OWNER, payload, { headers: header })
          .then((response) => {
            // messageExtractor(response.data, response.status)
            commit("updateSeatPln", response.data.seatPlan);
            resolve(successResponse(response));
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },
  async deleteOwnerServicesSeatRow({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .delete(Apis.DELETE_SEAT_ROW_OWNER, {
            data: payload,
            headers: header,
          })
          .then((response) => {
            // messageExtractor(response.data, response.status)
            commit("updateSeatPln", response.data.seatPlans);
            resolve(successResponse(response));
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async editOwnerServicesCustomSeatRow({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .put(Apis.UPDATE_SEAT_ROW_OWNER, payload, { headers: header })
          .then((response) => {
            // messageExtractor(response.data, response.status)
            commit("updateSeatPln", response.data.updatedSeatPlan);
            resolve(successResponse(response));
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getOwnerServicesNewSeatFare({ commit }, payload) {
    if (await hasValidToken()) {
      commit("addSeatFare", []);

      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_SEAT_FARE_OWNER, { headers: header, params: payload })
          .then((response) => {
            commit("addSeatFare", response.data.fare);
            resolve(response);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async addOwnerServicesNewSeatFare({ commit }, { seatPlan, pagination }) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .post(Apis.ADD_SEAT_FARE_OWNER, seatPlan, {
            headers: header,
            params: pagination,
          })
          .then((response) => {
            commit("addSeatFare", response.data.fare);
            resolve(successResponse(response));
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async deleteOwnerServicesSeatFare({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .delete(Apis.DELETE_FARE_OWNER, { headers: header, params: payload })
          .then((response) => {
            commit("addSeatFare", response.data.fareList);
            resolve(successResponse(response));
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async updateOwnerServicesSeatFare(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .put(Apis.UPDATE_FARE_OWNER, payload, {
            headers: header,
            params: { fare_id: payload.fare_id },
          })
          .then((response) => {
            resolve(successResponse(response));
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getOwnerServicesSequenceRouteListFilter({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.SEQUENCE_ROUTE_FILTER_OWNER, {
            params: payload,
            headers: header,
          })
          .then((response) => {
            let routeList = {};
            response.data.route.routeSequence.forEach((route) => {
              routeList[route._id] = route.name;
            });
            commit("setAllSequenceRoutes", routeList);
            resolve(routeList);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async addOwnerServicesFareModality({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .post(Apis.ADD_FARE_MODALITY_OWNER, payload, { headers: header })
          .then((success) => {
            commit("addSeatFareNew", success.data.fare);
            resolve(successResponse(success));
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async updateOwnerServicesFareModality({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .put(Apis.UPDATE_FARE_MODALITY_OWNER, payload, { headers: header })
          .then((success) => {
            commit("addSeatFareNew", success.data.fare);
            resolve(successResponse(success));
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getOwnerServicesAllCoachByPage({ commit }, payload) {
    if (await hasValidToken()) {
      commit("addCoach", []);
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_ALL_COACH_OWNER, {
            params: payload,
            headers: header,
          })
          .then((response) => {
            commit("addCoach", response.data.coach);
            resolve(response);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getOwnerServicesAllSeatPlan({ commit }, payload) {
    if (await hasValidToken()) {
      commit("setSeatPlanAll", {});
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_ALL_SEAT_PLAN_OWNER, {
            params: payload,
            headers: header,
          })
          .then((response) => {
            let seatPlanList = {};
            response.data.seatPlan.forEach((seatPlan) => {
              seatPlanList[seatPlan._id] = seatPlan.name;
            });
            commit("setSeatPlanAll", seatPlanList);
            resolve(response.data.seatPlan);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async addOwnerServicesCoach({ commit }, { coachData, pagination }) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .post(Apis.ADD_COACH_OWNER, coachData, {
            headers: header,
            params: pagination,
          })
          .then((success) => {
            commit("addCoach", success.data.coach);
            resolve(successResponse(success));
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },
  async updateOwnerServicesCoach({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .put(Apis.UPDATE_COACH_OWNER, payload, { headers: header })
          .then((success) => {
            commit("updateCoach", success.data.coach);
            resolve(successResponse(success));
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getOwnerServicesCounterMansByPage({ commit }, payload) {
    if (await hasValidToken()) {
      commit("setCounterMans", []);
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_ALL_COUNTERMANS_OWNER, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              commit("setCounterMans", data.counterman);
              resolve({ success: "success" });
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async addOwnerServicesCounterMan(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .post(Apis.ADD_COUNTERMAN_OWNER, payload, { headers: header })
          .then((success) => {
            resolve(successResponse(success));
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async updateOwnerServicesCounterMan(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .put(Apis.UPDATE_COUNTERMAN_OWNER, payload, { headers: header })
          .then((success) => {
            resolve(successResponse(success));
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getOwnerServicesCounterListInFilter({ commit }, payload) {
    if (await hasValidToken()) {
      commit("setCounterListInFilter", {});
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_ALL_COUNTER_LIST_OWNER, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              let counterList = {};
              data.counter.forEach((counter) => {
                counterList[counter._id] = counter.name;
              });
              commit("setCounterListInFilter", counterList);
              resolve(data.counter);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getOwnerServicesSupervisorsByPage({ commit }, payload) {
    if (await hasValidToken()) {
      commit("setSupervisors", []);
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_SUPERVISORS_OWNER, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              commit("setSupervisors", data.supervisor);
              resolve({ success: "success" });
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async addOwnerServicesSupervisor(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .post(Apis.ADD_SUPERVISOR_OWNER, payload, { headers: header })
          .then((success) => {
            resolve(successResponse(success));
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async updateOwnerServicesSupervisor(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .put(Apis.UPDATE_SUPERVISOR_OWNER, payload, { headers: header })
          .then((success) => {
            resolve(successResponse(success));
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getOwnerServicesDriversByPage({ commit }, payload) {
    if (await hasValidToken()) {
      commit("setDrivers", []);
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_DRIVERS_OWNER, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              commit("setDrivers", data.driver);
              resolve({ success: "success" });
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async addOwnerServicesDriver(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .post(Apis.ADD_DRIVER_OWNER, payload, { headers: header })
          .then((success) => {
            resolve(successResponse(success));
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async updateOwnerServicesDriver(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .put(Apis.UPDATE_DRIVER_OWNER, payload, { headers: header })
          .then((success) => {
            resolve(successResponse(success));
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getOwnerServicesTripsByPage({ commit }, payload) {
    if (await hasValidToken()) {
      commit("setTrips", []);
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_TRIP_DATA_OWNER, {
            params: payload,
            headers: header,
          })
          .then((response) => {
            commit("setTrips", response.data.trip);
            resolve(response);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async addOwnerServicesTrip({ commit }, { tripData, pagination }) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .post(Apis.GET_TRIP_DATA_OWNER, tripData, {
            headers: header,
            params: pagination,
          })
          .then((success) => {
            commit("setTrips", success.data.trip);
            resolve(successResponse(success));
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },
  async updateOwnerServicesTrip(_, { tripData, pagination }) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .put(Apis.UPDATE_TRIP_DATA_OWNER, tripData, {
            headers: header,
            params: pagination,
          })
          .then((success) => {
            resolve(successResponse(success));
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async cancelOwnerServicesTrip(_, { payload }) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .put(Apis.CANCEL_TRIP_OWNER, payload, { headers: header })
          .then((success) => {
            resolve(successResponse(success));
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getOwnerServicesStoppagesByPageNew({ commit }, payload) {
    if (await hasValidToken()) {
      commit("setStoppages", []);
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_ALL_STOPPAGES_OWNER_NEW, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              commit("setStoppages", data.stoppage);
              resolve({ success: "success" });
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getOwnerServicesRouteListFilterNew({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_ALL_ROUTES_FILTER_OWNER, {
            params: payload,
            headers: header,
          })
          .then(({ data }) => {
            let routeList = {};
            data.route.forEach((route) => {
              routeList[route._id] = route.name;
            });
            commit("setAllRoutes", routeList);
            resolve(routeList);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async getOwnerServicesStoppageList({ commit }, payload) {
    if (await hasValidToken()) {
      commit("setOwnerServicesStoppageListInFilter", {});
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_ALL_STOPPAGE_OWNER, {
            params: payload,
            headers: header,
          })
          .then(({ data, status }) => {
            if (status === 200) {
              let stoppageList = {};
              data.stoppage.forEach((stoppage) => {
                stoppageList[stoppage._id] = stoppage.name;
              });
              commit("setOwnerServicesStoppageListInFilter", stoppageList);
              resolve(data.stoppage);
            }
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async allOwnerServicesTripFare(_, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_FARE_ALL_BY_ROUTEID_OWNER, {
            headers: header,
            params: payload,
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
  },

  async getOwnerServicesAllCoachList({ commit }, payload) {
    if (await hasValidToken()) {
      commit("setAllCoachList", {});
      const header = {
        Authorization: "Bearer " + getToken(),
      };

      return new Promise((resolve, reject) => {
        axios
          .get(Apis.GET_ALL_LIST_COACH_OWNER, {
            params: payload,
            headers: header,
          })
          .then((response) => {
            let allCoachList = {};
            response.data.coach.forEach((coachList) => {
              allCoachList[coachList._id] = coachList.name;
            });
            commit("setAllCoachList", allCoachList);
            resolve(response.data.coach);
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },

  async deleteOwnerServicesRouteFare({ commit }, payload) {
    if (await hasValidToken()) {
      const header = {
        Authorization: "Bearer " + getToken(),
      };
      return new Promise((resolve, reject) => {
        axios
          .delete(Apis.ROUTE_FARE_DELETE_OWNER, {
            data: payload,
            headers: header,
          })
          .then((response) => {
            commit("addSeatFareNew", response.data.fare);
            resolve(successResponse(response));
          })
          .catch((error) => {
            reject(errorResponse(error));
          });
      });
    }
  },
};
const mutations = {
  setSingleTripHistoryOwner: (state, trips) => (state.singleTripHistoryOwner = trips),
  setRouteListInFilterOwner: (state, routeList) =>
    (state.filter.routeOwner = routeList),
  setRoutes: (state, routes) => (state.ownerServicesRoute = routes),

  setRoute: (state, route) =>
    state.ownerServicesRoute.docs.filter((element, index) => {
      if (element._id === route._id) {
        state.ownerServicesRoute.docs.splice(index, 1, route);
      }
    }),
  setShipsOwner: (state, ships) => (state.shipsOwner = ships),
  setDeckMansOwner: (state, deckMans) => (state.deckMansOwner = deckMans),
  setCountersOwner: (state, counters) => (state.countersOwner = counters),
  setStoppageListInFilterOwner: (state, stoppageList) =>
    (state.filter.stoppageOwner = stoppageList),
  setCounterMansOwner: (state, counterMans) =>
    (state.counterMansOwner = counterMans),

  updateShipOwner: (state, updatedShip) => {
    const index = state.shipsOwner.docs.findIndex(
      (ship) => ship._id === updatedShip._id
    );
    if (index !== -1) {
      state.shipsOwner.docs.splice(index, 1, updatedShip);
    }
  },

  setOwnersServicesStopage: (state, stopage) =>
    (state.ownerServicesStopage = stopage),
  setOwnersServicesRoute: (state, route) => (state.ownerServicesRoute = route),
  setOwnersServicesRouteInFilter: (state, routeList) =>
    (state.filter.route = routeList),

  setOwnerServicesBoardingPlaces: (state, boardingPlaces) =>
    (state.ownerServicesBoardingPlaces = boardingPlaces),
  setOwnerServicesDroppingPlaces: (state, droppingPlaces) =>
    (state.ownerServicesDroppingPlaces = droppingPlaces),

  setOwnerServicesTrips: (state, trips) => (state.ownerServicesTrips = trips),
  setOwnerServicesDepotListInFilter: (state, depots) =>
    (state.filter.depot = depots),
  setSeatClass: (state, seatClass) => (state.seatClasses = seatClass),
  setSeatCls: (state, seatClass) =>
    state.seatClasses.docs.filter((element, index) => {
      if (element._id === seatClass._id) {
        state.seatClasses.docs.splice(index, 1, seatClass);
      }
    }),
  setAllSeatClass: (state, seatClass) => (state.filter.seatClass = seatClass),
  addSeatPlan: (state, seatPlan) => {
    state.seatPlans = seatPlan;
  },

  updateSeatPlnOwner: (state, seatPlan) =>
    state.seatPlans.docs.filter((element, index) => {
      if (element._id === seatPlan._id) {
        state.seatPlans.docs.splice(index, 1, seatPlan);
      }
    }),

  addSeatFare: (state, fare) => (state.fares = fare),

  setAllSequenceRoutes: (state, sequenceRouteList) => {
    state.filter.sequenceRoutes = sequenceRouteList;
  },

  addSeatFareNew: (state, neSeatFare) =>
    state.fares.docs.filter((element, index) => {
      if (element._id === neSeatFare._id) {
        state.fares.docs.splice(index, 1, neSeatFare);
      }
    }),

  addCoach: (state, coachs) => (state.coachs = coachs),
  setSeatPlanAll: (state, seatPlanList) =>
    (state.filter.seatPlanList = seatPlanList),
  updateCoach: (state, coach) =>
    state.coachs.docs.filter((element, index) => {
      if (element._id === coach._id) {
        state.coachs.docs.splice(index, 1, coach);
      }
    }),

  setCounterMans: (state, counterMans) => (state.counterMans = counterMans),
  setCounterListInFilter: (state, counterList) =>
    (state.filter.counter = counterList),
  setSupervisors: (state, supervisor) => (state.supervisors = supervisor),
  setDrivers: (state, driver) => (state.drivers = driver),

  setRoutesNew: (state, routes) => (state.routesNew = routes),
  setTrips: (state, trips) => (state.trips = trips),
  setStoppages: (state, stoppages) => (state.stoppages = stoppages),
  setAllRoutes: (state, routeList) => (state.filter.routes = routeList),
  setOwnerServicesStoppageListInFilter: (state, stoppageList) =>
    (state.filter.stoppage = stoppageList),
  setAllCoachList: (state, coachList) =>
    (state.filter.allCoachList = coachList),
  setBoardingPlacesForStoppage: (state, boardingPlaces) => {
    state.boardingPlacesForStoppage = boardingPlaces;
  },
  setDefaultBoardingPlaceForStoppage: (state, defaultBoarding) =>
    (state.defaultBoardingPlace = defaultBoarding),
  setBoardingPlaceForStoppage: (state, boardingPlace) =>
    (state.boardingPlacesForStoppage = boardingPlace),
  setDroppingPlacesForStoppage: (state, droppingPlaces) =>
    (state.droppingPlacesForStoppage = droppingPlaces),
  setDefaultDroppingPlaceForStoppage: (state, defaultDropping) =>
    (state.defaultDroppingPoint = defaultDropping),
  setDroppingPlaceForStoppage: (state, droppingPlace) =>
    (state.droppingPlacesForStoppage = droppingPlace),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
