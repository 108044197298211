import store from "@/store";

const getBookedSeatStatusClass = (seat_no) => {
    const seatWiseTicket = store.getters.getBlockedSeatsTicketsCounterman;
    if (seatWiseTicket[seat_no]?.ticketType === 'RESERVED') {
        if (seatWiseTicket[seat_no]?.issuedBy._id === store.getters.getUser._id) {
            return 'bg-secondary'
        } else {
            return 'bg-dark text-white'
        }
    } else if (seatWiseTicket[seat_no]?.ticketType === 'SEAT') {
        if (seatWiseTicket[seat_no]?.passengerGender === 'Male') {
            if (seatWiseTicket[seat_no]?.issuedBy?._id === store.getters.getUser._id) {
                return 'male'
            } else {
                return 'maleOtherCounter'
            }
        } else {
            if (seatWiseTicket[seat_no]?.issuedBy?._id === store.getters.getUser._id) {
                return 'female'
            } else {
                return 'femaleOtherCounter'
            }
        }
    }


}

const getSelectedSeatStatusClass = (selectedSeat, seat) => {
    const seatStatus = selectedSeat && selectedSeat.some(s => s.seat_no === seat.seat_no)
    if (seatStatus === true) {
        return 'bg-success'
    }
}

export {
    getBookedSeatStatusClass,
    getSelectedSeatStatusClass
}
