<template>
  <div v-if="hasPermission(Counterman.trips)">
    <!-- <div>
        <Filter>
          <template v-slot:body>
            <div class="col-sm-6 col-md-2">
              <DatePickerFilter
                v-bind:id="'datePickerDate'"
                v-bind:label="'Date'"
                v-model="pagination.date"
              />
            </div>
            <div class="col-sm-6 col-md-2">
              <DropdownFilter
                v-bind:id="'status'"
                v-bind:label="'Status'"
                v-bind:defaultOption="'--select status--'"
                v-model="pagination.status"
                v-bind:defaultValue="''"
                v-bind:dropdown="status"
              />
            </div>
          </template>
        </Filter> -->
    <TicketHistory
      v-if="ticketHistoryActive"
      v-model="ticketHistoryActive"
      :tripId="tripIdIndividual"
    />
		<LogHistory v-else-if="logHistory" v-model="logHistory" :tripId="tripIdIndividual"/>
    <div v-else>
      <div
        class="accordion d-md-none accordion-flush"
        id="accordionFlushExample"
      >
        <div class="accordion-item">
          <h2 class="accordion-header" id="flush-headingOne">
            <button
              class="accordion-button p-2 collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseOne"
              aria-expanded="false"
              aria-controls="flush-collapseOne"
            >
              {{ t("Filter By", {}, { locale: $store.getters.getLang }) }}
            </button>
          </h2>
          <div
            id="flush-collapseOne"
            class="accordion-collapse collapse"
            aria-labelledby="flush-headingOne"
            data-bs-parent="#accordionFlushExample"
          >
            <div class="accordion-body p-0">
              <FilterTab>
                <template v-slot:body>
                  <div class="col-sm-6 col-md-2">
                    <DatePickerFilter
                      v-bind:id="'datePickerDate'"
                      v-bind:label="'Date'"
                      v-model="pagination.date"
                    />
                  </div>
                  <div class="col-sm-6 col-md-2">
                    <DropdownFilter
                      v-bind:id="'status'"
                      v-bind:label="'Status'"
                      v-model="pagination.status"
                      v-bind:dropdown="status"
                    />
                  </div>
                </template>
              </FilterTab>
            </div>
          </div>
        </div>
      </div>
      <FilterTab class="d-none d-md-block">
        <template v-slot:body>
          <div class="col-sm-6 col-md-2">
            <DatePickerFilter
              v-bind:id="'datePickerDate'"
              v-bind:label="'Date'"
              v-model="pagination.date"
            />
          </div>
          <div class="col-sm-6 col-md-2">
            <DropdownFilter
              v-bind:id="'status'"
              v-bind:label="'Status'"
              v-model="pagination.status"
              v-bind:dropdown="status"
            />
          </div>
        </template>
      </FilterTab>
      <div class="p-2 position-relative d-md-none">
        <!-- Table Data Pannel -->
        <div>
          <div
            class="card w-100 my-2"
            v-for="trip in $store.getters.getCountermanTrips"
            :key="trip._id"
          >
            <div class="card-body position-relative">
              <div class="row">
                <div class="col-12 mb-2">
                  <!-- <span
                                      v-if="trip.status"
                                      class="badge bg-success position-absolute top-0 end-0 m-1"
                                      >{{ dropdownVariables.status[1] }}</span
                                    >
                                    <span
                                      v-else
                                      class="badge bg-danger position-absolute top-0 end-0 m-1"
                                      >{{ dropdownVariables.status[0] }}</span
                                    > -->
                </div>
                <div class="col-12">
                  <p>
                    <b
                      >{{
                        t(
                          "TRIP TIME & DATE",
                          {},
                          { locale: $store.getters.getLang }
                        )
                      }}:</b
                    >
                    <span>{{ dateFormat(trip.tripDateTime) }}</span>
                  </p>
                </div>
                <div class="col-6">
                  <p>
                    <b
                      >{{
                        t("TRIP CODE", {}, { locale: $store.getters.getLang })
                      }}:</b
                    >
                    {{ trip.tripCode }}
                  </p>
                </div>
                <div class="col-6">
                  <p>
                    <b
                      >{{
                        t("ROUTE NAME", {}, { locale: $store.getters.getLang })
                      }}:</b
                    >
                    {{ trip.route.name }}
                  </p>
                </div>
                <div class="col-6">
                  <p>
                    <b
                      >{{
                        t("COACH NAME", {}, { locale: $store.getters.getLang })
                      }}:</b
                    >
                    {{ trip.coach.name }}
                  </p>
                </div>
                <div class="col-6">
                  <p>
                    <b
                      >{{
                        t(
                          "FROM STOPPAGE",
                          {},
                          { locale: $store.getters.getLang }
                        )
                      }}:</b
                    >
                    {{ trip.fromStoppage.name }}
                  </p>
                </div>
                <div class="col-6">
                  <p>
                    <b
                      >{{
                        t(
                          "TO STOPPAGE",
                          {},
                          { locale: $store.getters.getLang }
                        )
                      }}:</b
                    >
                    {{ trip.toStoppage.name }}
                  </p>
                </div>
                <div class="col-6">
                  <p>
                    <b
                      >{{
                        t(
                          "SEATPLAN NAME",
                          {},
                          { locale: $store.getters.getLang }
                        )
                      }}:</b
                    >
                    {{ trip.seatPlan.name }}
                  </p>
                </div>
                <div class="col-6">
                  <p>
                    <b
                      >{{
                        t("CLASS NAME", {}, { locale: $store.getters.getLang })
                      }}:</b
                    >
                    {{ trip.seatPlan.seatClass }}
                  </p>
                </div>
                <div class="col-6">
                  <p>
                    <b
                      >{{
                        t("STATUS", {}, { locale: $store.getters.getLang })
                      }}:</b
                    >
                    <span v-if="trip.status" class="badge bg-success mx-2">{{
                      dropdownVariables.status[1]
                    }}</span>
                    <span v-else class="badge bg-danger mx-2">{{
                      dropdownVariables.status[0]
                    }}</span>
                  </p>
                </div>

                <div
                  v-if="hasPermission(Counterman.ticketCancel)"
                  class="col-12"
                >
                  <button
                    class="btn btn-warning btn-sm w-100"
                    @click="Ticket(trip._id)"
                  >
                    <i></i
                    ><span class="mx-1">{{
                      t(
                        "Ticket History",
                        {},
                        { locale: $store.getters.getLang }
                      )
                    }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Table class="d-none d-md-block" v-if="$store.getters.getCountermanTrips">
        <template v-slot:header>
          <tr>
            <td class="font_detail">
              {{ t("SL", {}, { locale: $store.getters.getLang }) }}
            </td>
            <td class="font_detail">
              {{
                t("TRIP TIME & DATE", {}, { locale: $store.getters.getLang })
              }}
            </td>
            <td class="font_detail">
              {{ t("TRIP CODE", {}, { locale: $store.getters.getLang }) }}
            </td>
            <td class="font_detail">
              {{ t("ROUTE NAME", {}, { locale: $store.getters.getLang }) }}
            </td>
            <td class="font_detail">
              {{ t("COACH NAME", {}, { locale: $store.getters.getLang }) }}
            </td>
            <td class="font_detail">
              {{ t("FROM STOPPAGE", {}, { locale: $store.getters.getLang }) }}
            </td>
            <td class="font_detail">
              {{ t("TO STOPPAGE", {}, { locale: $store.getters.getLang }) }}
            </td>
            <td class="font_detail">
              {{ t("SEATPLAN NAME", {}, { locale: $store.getters.getLang }) }}
            </td>
            <td class="font_detail">
              {{ t("CLASS NAME", {}, { locale: $store.getters.getLang }) }}
            </td>
            <td class="font_detail">
              {{ t("STATUS", {}, { locale: $store.getters.getLang }) }}
            </td>
            <td class="font_detail">
              {{ t("ACTION", {}, { locale: $store.getters.getLang }) }}
            </td>
          </tr>
        </template>
        <template v-slot:body>
          <tr
            v-for="(trip, index) in $store.getters.getCountermanTrips"
            :key="trip._id"
          >
            <td class="font_detail">{{ index + 1 }}</td>
            <td class="font_detail">
              <span>{{ dateFormat(trip.tripDateTime) }}</span>
            </td>
            <td class="font_detail">{{ trip.tripCode }}</td>
            <td class="font_detail">{{ trip.route.name }}</td>
            <td class="font_detail">{{ trip.coach.name }}</td>
            <td class="font_detail">{{ trip.fromStoppage.name }}</td>
            <td class="font_detail">{{ trip.toStoppage.name }}</td>
            <td class="font_detail">{{ trip.seatPlan.name }}</td>
            <td class="font_detail">{{ trip.seatPlan.seatClass }}</td>
            <td class="font_detail">
              <span
                class="badge"
                :class="{
                  'bg-success': trip.status === 'ACTIVE',
                  'bg-danger': trip.status === 'CANCELLED',
                  'bg-primary': trip.status === 'COMPLETED',
                }"
              >
                {{ trip.status }}</span
              >
            </td>
            <td class="font_detail bold">
              <button class="btn btn-warning btn-sm me-3" @click="Ticket(trip._id)">
                <i></i
                ><span class="mx-1">{{
                  t("Ticket History", {}, { locale: $store.getters.getLang })
                }}</span>
              </button>
							<button class="btn btn-warning btn-sm" @click="handleLogHistory(trip._id)">
                <i></i
                ><span class="mx-1">{{
                  t("Log History", {}, { locale: $store.getters.getLang })
                }}</span>
              </button>
            </td>
          </tr>
        </template>
        <template v-slot:pagination>
          <pagination
            v-model="pagination.page"
            :records="$store.getters.getCountermanTotalTrips"
            :per-page="pagination.limit"
            @paginate="getAllTrips"
            :options="options"
          />
        </template>
      </Table>
    </div>
    <!-- </div> -->
  </div>
</template>

<script>
import moment from "moment";
import { reactive } from "@vue/reactivity";
import { inject, onBeforeMount, watch, ref, computed } from "vue";
import store from "@/store";
import dropdownVariables from "@/modules/dropdownVariables";
import permission from "@/modules/permission";
import roles from "@/modules/roles";
import TicketHistory from "./TicketHistory.vue";
import router from "@/router";
import web from "@/urls/web";
import { useI18n } from "vue-i18n";
import LogHistory from "./LogHistory.vue"

const { hasPermission } = permission();
const { Counterman } = roles();

export default {
  name: "Trips",
  components: {
    TicketHistory,
		LogHistory,
  },
  setup() {
    let ticketHistoryActive = ref(false);
    let tripIdIndividual = ref("");
    // moment(new Date()).format("yyyy-MM-DD")
		let logHistory = ref(false);

    let pagination = reactive({
      page: 1,
      limit: 25,
      date: moment(new Date()).format("yyyy-MM-DD"),
      status: "ACTIVE",
    });

    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    const { t, locale } = useI18n();
    const options = {
      texts: {
        count: "",
      },
    };
    watch(
      () => store.getters.userRoles,
      (value) => {
        if (!value.includes(Counterman.trips)) {
          router
            .push(web.Default.LOGIN_URL.path)
            .catch((error) => console.log(error));
        }
      }
    );
    watch(
      () => pagination.status,
      () => {
        getAllTrips();
      }
    );
    watch(
      () => pagination.date,
      () => {
        getAllTrips();
      }
    );

    const status = computed(() => {
      let allTripStatus = {};
      for (let [key, value] of Object.entries(dropdownVariables.tripStatus)) {
        allTripStatus[key] = value.value;
      }
      return allTripStatus;
    });

    const dateFormat = (date) => {
      return moment(String(date))
        .utcOffset(0, false)
        .format("DD-MMM-yyyy hh:mm:ss A");
    };

    // const dateFormatBangla = (date) => {
    //   let newDate = new Date(String(date)).toLocaleString("bn-BD", {
    //     hour12: true,
    //     timeZone: "UTC",
    //     day: "2-digit",
    //     month: "short",
    //     year: "numeric",
    //     hour: "2-digit",
    //     minute: "2-digit",
    //     second: "2-digit",
    //   });
    //   return newDate.replace(" ", "-").replaceAll(", ", "-");
    // };
    const Ticket = (tripId) => {
      ticketHistoryActive.value = true;
      tripIdIndividual.value = tripId;
    };

		const handleLogHistory = (tripId) => {
			logHistory.value = true;
      tripIdIndividual.value = tripId;

		}

    const getAllTrips = () => {
      showLoader();
      store
        .dispatch("getAllTripsForCounterman", pagination)
        .then(() => {
          hideLoader();
        })
        .catch((error) => {
          hideLoader();
          if (error.status === 401) {
            getAllTrips();
          }
        });
    };

    onBeforeMount(() => {
      getAllTrips();
    });

    return {
      t,
      locale,
      pagination,
      dropdownVariables,
      close,
      dateFormat,
      // dateFormatBangla,
      status,
      options,
      getAllTrips,
      hasPermission,
      Counterman,
      Ticket,
      ticketHistoryActive,
      tripIdIndividual,
			logHistory,
			handleLogHistory,
    };
  },
};
</script>

<style scoped>
button.accordion-button {
  background-color: #242424;
  color: #fff;
  border: 0;
  padding-left: 10px;
  padding-right: 10px;
}

.accordion-button::after {
  filter: invert(1);
}

.card-body p {
  font-size: 12px;
  margin-bottom: 5px;
}

.bold {
  font-weight: bold;
}
</style>
