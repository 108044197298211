import {reactive} from "@vue/reactivity";
import useValidators from "./validators";

const errors = reactive({});
const {isEmpty, minLength, isEmail, isValidNumber, isPhone, hasRole} = useValidators();
export default function useInputValidation() {
    const requiredValidation = (fieldName, fieldValue) => {
        errors[fieldName] = !fieldValue || Object.keys(fieldValue).length === 0 ? isEmpty(fieldName, fieldValue) : "";
    };
    const compareRouteSequenceValidation = (name,fromStoppage,toStoppage,arrFirstElement,arrLastElement) => {
        if(fromStoppage===arrFirstElement && toStoppage===arrLastElement){
              errors[name] = ''
        }else{
            errors[name] = 'fromStoppage/toStoppage did not match with route sequence'
        }
      
    };
    const commission = (name,commssionAmount) => {
        if(commssionAmount>=0 && commssionAmount<=100){
              errors[name] = ''
        }else{
            errors[name] = 'Commission above 100% is not allowed'
        }
      
    };
    const validNumberValidation = (fieldName, fieldValue) => {
        errors[fieldName] = fieldValue !== "" ? isValidNumber(fieldName, fieldValue) : isEmpty(fieldName, fieldValue);
    };
    const passwordValidation = (fieldName, fieldValue) => {
        errors[fieldName] = !fieldValue ? isEmpty(fieldName, fieldValue) : minLength(fieldName, fieldValue, 8);
    };
    const emailValidation = (fieldName, fieldValue) => {
        errors[fieldName] = !fieldValue ? isEmpty(fieldName, fieldValue) : isEmail(fieldName, fieldValue);
    };
    const phoneValidation = (fieldName, fieldValue) => {
        errors[fieldName] = !fieldValue ? isEmpty(fieldName, fieldValue) : isPhone(fieldName, fieldValue);
    };
    const validPhoneValidation = (fieldName, fieldValue) => {
        errors[fieldName] = fieldValue ? isPhone(fieldName, fieldValue) : "";
    };
    const requiredRoleValidation = (fieldName, chosenRoles, requiredRole, role, roleObject) => {
        errors[fieldName] = chosenRoles.includes(role) && !chosenRoles.includes(requiredRole) ? hasRole(requiredRole, role, roleObject) : "";
    };
    const routeSequenceValidation = (name,obj, fromStoppageId, toStoppageId) => {
        for (const property of Object.keys(obj)){
            if(property===fromStoppageId && fromStoppageId!==toStoppageId){
                errors[name] = ''
                break
            }else{
                errors[name] = 'fromStoppage did not match with route sequence & fromStoppage and toStoppage will not be the same!! '
                break
            }
        }
    };
    return {
        errors,
        requiredValidation,
        passwordValidation,
        emailValidation,
        validNumberValidation,
        phoneValidation,
        validPhoneValidation,
        requiredRoleValidation,
        compareRouteSequenceValidation,
        routeSequenceValidation,
        commission
    };
}