<template>
  <div v-if="hasPermission(counterManRoles.ticketBooking)">
    <div class="accordion d-md-none">
      <div class="accordion-item">
        <h2 id="panelsStayOpen-headingOne" class="accordion-header">
          <button
              aria-controls="panelsStayOpen-collapseOne"
              aria-expanded="true"
              class="accordion-button collapsed p-2"
              data-bs-target="#panelsStayOpen-collapseOne"
              data-bs-toggle="collapse"
              type="button"
          >
            {{ t("Filter By", {}, {locale: selectedLanguage}) }}
          </button>
        </h2>
        <div
            id="panelsStayOpen-collapseOne"
            aria-labelledby="panelsStayOpen-headingOne"
            class="accordion-collapse collapse"
        >
          <div class="accordion-body p-0">
            <FilterTab>
              <template v-slot:body>
                <div class="col-sm-6 col-md-2">
                  <DropdownFilter
                      v-bind:id="'from'"
                      v-model="pagination.from"
                      v-bind:defaultOption="'--select stoppage--'"
                      v-bind:defaultValue="''"
                      v-bind:dropdown="getCountermanStoppageListForFilter"
                      v-bind:label="'From Stoppage'"
                  />
                </div>
                <div class="col-sm-6 col-md-2">
                  <DropdownFilter
                      v-bind:id="'to'"
                      v-model="pagination.to"
                      v-bind:defaultOption="'--select stoppage--'"
                      v-bind:defaultValue="''"
                      v-bind:dropdown="getCountermanStoppageListForFilter"
                      v-bind:label="'To Stoppage'"
                  />
                </div>
                <div class="col-sm-6 col-md-2">
                  <DropdownFilter
                      v-bind:id="'Coach'"
                      v-model="pagination.coach"
                      v-bind:defaultOption="'--select coach--'"
                      v-bind:defaultValue="''"
                      v-bind:dropdown="
                      $store.getters.getCountermanCoachListForFilter
                    "
                      v-bind:label="'Coach'"
                  />
                </div>
                <div class="col-sm-6 col-md-2">
                  <datePickerFilterCounterman
                      v-bind:id="'datePickerDate'"
                      v-model="pagination.date"
                      v-bind:label="'Date'"
                  />
                </div>
                <div class="col-sm-6 col-md-2">
                  <form class="input-group global-filter-input" @submit.prevent="searchWithPnrPhone">
                    <label></label>
                    <input v-model="searchQuery" class="input_field form-control rounded font_detail py-2 height-34"
                           placeholder="PNR or Phone Number"
                           type="text">
                    <div class="input-group-append">
                      <button class="btn btn-secondary font-12" type="submit">
                        <i class="fa fa-search"></i>
                      </button>
                    </div>
                  </form>
                </div>
              </template>
            </FilterTab>
          </div>
        </div>
      </div>
    </div>
    <div class="d-none d-md-block">
      <FilterTab>
        <template v-slot:body>
          <div class="col-sm-6 col-md-2">
            <DropdownFilter
                v-bind:id="'from'"
                v-model="pagination.from"
                v-bind:defaultOption="'--select stoppage--'"
                v-bind:defaultValue="''"
                v-bind:dropdown="getCountermanStoppageListForFilter"
                v-bind:label="'From Stoppage'"
            />
          </div>
          <div class="col-sm-6 col-md-2">
            <DropdownFilter
                v-bind:id="'to'"
                v-model="pagination.to"
                v-bind:defaultOption="'--select stoppage--'"
                v-bind:defaultValue="''"
                v-bind:dropdown="getCountermanStoppageListForFilter"
                v-bind:label="'To Stoppage'"
            />
          </div>
          <div class="col-sm-6 col-md-2">
            <DropdownFilter
                v-bind:id="'Coach'"
                v-model="pagination.coach"
                v-bind:defaultOption="'--select coach--'"
                v-bind:defaultValue="''"
                v-bind:dropdown="$store.getters.getCountermanCoachListForFilter"
                v-bind:label="'Coach'"
            />
          </div>
          <div class="col-sm-6 col-md-2">
            <datePickerFilterCounterman
                v-bind:id="'datePickerDate'"
                v-model="pagination.date"
                v-bind:label="'Date'"
            />
          </div>
          <!--                <div class="col-sm-6 col-md-2">-->
          <!--                    <DropdownFilter v-bind:id="'status'" v-bind:label="'Status'"-->
          <!--                                    v-bind:defaultOption="'&#45;&#45;select status&#45;&#45;'"-->
          <!--                                    v-model="pagination.status"-->
          <!--                                    v-bind:defaultValue="''"-->
          <!--                                    v-bind:dropdown="status"/>-->
          <!--                </div>-->
          <div class="col-sm-6 col-md-2">
            <form class="input-group global-filter-input" @submit.prevent="searchWithPnrPhone">
              <label></label>
              <input v-model="searchQuery" class="input_field form-control rounded font_detail py-2 height-34"
                     placeholder="PNR or Phone Number"
                     type="text">
              <div class="input-group-append">
                <button class="btn btn-secondary font-12" type="submit" >
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </form>
          </div>
        </template>
      </FilterTab>
    </div>
    <div v-if="dashboardActive">
      <div class="table-responsive">
        <table
            class="table table-hover table-striped text-center vehicle_table"
        >
          <thead class="font_detail bg_corporate bording_table">
          <tr>
            <th class="font_detail">
              {{ t("sl", {}, {locale: selectedLanguage}) }}
            </th>
            <th class="font_detail">
              {{
                t("TRIP TIME & DATE", {}, {locale: selectedLanguage})
              }}
            </th>
            <th class="font_detail">
              {{ t("TRIP CODE", {}, {locale: selectedLanguage}) }}
            </th>
            <th class="font_detail">
              {{ t("COACH", {}, {locale: selectedLanguage}) }}
            </th>
            <th class="font_detail">
              {{ t("ROUTE", {}, {locale: selectedLanguage}) }}
            </th>

            <th class="font_detail">
              {{ t("SEAT-PLAN", {}, {locale: selectedLanguage}) }}
            </th>
            <th class="font_detail">
              {{ t("SEAT-CLASS", {}, {locale: selectedLanguage}) }}
            </th>
            <th class="font_detail">
              {{ t("DIRECTION", {}, {locale: selectedLanguage}) }}
            </th>
            <th class="font_detail">
              {{ t("STATUS", {}, {locale: selectedLanguage}) }}
            </th>
            <th class="font_detail">
              {{ t("ACTION", {}, {locale: selectedLanguage}) }}
            </th>
          </tr>
          </thead>
          <tbody
              v-for="(trip, index) in getTripsForTicketing"
              :key="trip._id"
              :ref="setTripRef"
              :data-trip-time="trip.tripDateTime"
          >
          <tr v-if="!isMobile" :class="trip.tripDateTime === nearestTrip && 'nearest-trip-highlight'"
              class="bording_table">
            <td class="">
                <span class="">{{
                    index + 1 + (pagination.page - 1) * 10
                  }}</span>
            </td>
            <td style="min-width: 150px">
                <span>
                  <span>
                    <span class="p-1"><i class="far fa-calendar-alt"></i></span>
                    <span class="">{{ dateFormat(trip.tripDateTime) }}</span>
                  </span>
                  <br/>
                  <span class="">
                    <span class="p-1 fs-6 fw-bold text-primary">
                      <i class="far fa-clock"></i>
                    </span>
                    <span class="fs-6 fw-bold text-primary">{{
                        timeFormat(trip.tripDateTime)
                      }}</span>
                  </span>
                </span>
            </td>

            <td class="">
                <span class="text-white px-2 py-1 rounded-pill bg-success">{{
                    trip.tripCode
                  }}</span>
            </td>
            <td style="min-width: 100px">
              <span class="">{{ trip.coach.name }}</span>
            </td>
            <td style="min-width: 200px">
              <span class="">{{ trip.route.name }}</span>
            </td>
            <td class="">
              <span class="">{{ trip.seatPlan.name }}</span>
            </td>
            <td style="min-width: 200px">
                <span class="text-white px-2 py-1 rounded-pill bg-secondary text-uppercase">{{
                    trip.seatPlan.seatClass
                  }}</span>
            </td>

            <td class="">
                <span class="">
                  {{
                    t(
                        trip.route.direction,
                        {},
                        {locale: selectedLanguage}
                    )
                  }}</span
                >
            </td>
            <td>
                <span
                    :class="
                    'bg-' + dropdownVariables.tripStatus[trip.status].badge
                  "
                    class="text-white px-2 py-1 rounded-pill"
                >{{ dropdownVariables.tripStatus[trip.status].value }}</span
                >
            </td>
            <td>
              <button
                  v-if="activeTrip !== trip._id"
                  class="custom_button btn btn-primary px-4"
                  @click="viewTrip(trip._id, trip.route._id, trip.coach.name)"
              >
                  <span class="mx-1">{{
                      t("View", {}, {locale: selectedLanguage})
                    }}</span>
                <!-- <i class="ml-2 fas fa-chevron-down"></i> -->
              </button>
              <button
                  v-else
                  class="custom_button btn btn-primary px-4"
                  @click="viewTrip('')"
              >
                  <span class="mx-1">{{
                      t("Close", {}, {locale: selectedLanguage})
                    }}</span>
                <!-- <i class="ml-2 fas fa-chevron-up"></i> -->
              </button>
            </td>
          </tr>
          <tr v-else class="bording_table_phone" :class="trip.tripDateTime === nearestTrip && 'nearest-trip-highlight'">
            <td colspan="11">
              <!-- mobile view start -->
              <div class="table_row_phone custom-font">
                <div class="table_single_row">
                  <div class="p-3 line_height itemShadow shadow">
                    <p class="text_size text-start">
                      <span class="fw-bold"> {{ trip.coach.name }}</span> -
                      {{ trip.seatPlan.seatClass }}
                    </p>
                    <p class="text-start">
                      <strong class="text-success">{{
                          trip.route.name
                        }}</strong>
                    </p>
                    <div class="d-flex gap-3">
                      <p class="text_size">
                        <i class="far fa-clock"> </i>
                        <strong class="icon_margin">
                          {{ timeFormat(trip.tripDateTime) }}
                        </strong>
                      </p>

                      <p class="text_size ms-5">
                        <i class="far fa-calendar-alt"> </i>
                        <strong class="icon_margin">
                          {{ dateFormat(trip.tripDateTime) }}
                        </strong>
                      </p>
                    </div>
                    <button
                        v-if="activeTrip !== trip._id"
                        class="phnbtn mr-2 buttonText font_detail w-100 mx-auto common_btn corporate_btn"
                        @click="
                          viewTrip(trip._id, trip.route._id, trip.coach.name)
                        "
                    >
                        <span id="button_text2">{{
                            t("View Seat", {}, {locale: selectedLanguage})
                          }}</span>
                    </button>
                    <button
                        v-else
                        class="phnbtn mr-2 buttonText font_detail w-100 mx-auto common_btn corporate_btn"
                        @click="viewTrip('')"
                    >
                        <span id="button_text3">{{
                            t("Close", {}, {locale: selectedLanguage})
                          }}</span>
                    </button>
                  </div>
                </div>
              </div>
              <!-- mobile view end  -->
            </td>
          </tr>

          <tr v-if="activeTrip === trip._id && tripDashboardActive">
            <td class="p-0" colspan="11">
              <TicketBooking
                  :paginationData="pagination"
                  :shouldRefreshPageGoBack="shouldRefreshPageGoBack"
                  :tripDetails="tripDetails"
                  v-bind:tripId="activeTrip"
                  @openGlobalFilter="openGlobalFilter"
              />
            </td>
          </tr>
          </tbody>
        </table>
        <div v-if="$store.getters.getTotalTripsForTicketing" class="row mx-5">
          <nav aria-label="Page navigation example">
            <ul
                class="pagination justify-content-center justify-content-sm-end"
            >
              <pagination
                  v-model="pagination.page"
                  :records="$store.getters.getTotalTripsForTicketing"
                  :per-page="pagination.limit"
                  @paginate="getAllTripsForTicketing"
                  :options="options"
              />
            </ul>
          </nav>
        </div>
      </div>
    </div>
    <GlobalFilter
        v-else-if="globalFilterTab"
        @openGlobalFilter="openGlobalFilter"
    />
    <TicketsDetails
        v-else
        :paginationData="pagination"
        :tripIdPass="tripIdPass"
        @close="close"
    />
    <div>
      <button @click="getAllTripsForTicketing" class="counterman_floating_btn">
        <i class="fa fa-sync"></i>
      </button>
    </div>
  </div>
</template>

<script>
import {ref, watch, computed, onMounted, onBeforeUnmount,} from "vue";
import {useStore} from 'vuex'
import {useI18n} from "vue-i18n";
import permission from "@/modules/permission";
import roles from "@/modules/roles";
import router from "@/router";
import web from "@/urls/web";
import TicketBooking from "./TicketBooking/TicketBooking";
import dropdownVariables from "@/modules/dropdownVariables";
import TicketsDetails from "./TicketBooking/TicketsDetails.vue";
import datePickerFilterCounterman from "@/components/layouts/Filter/Inputs/DatePickerFilterCounterman.vue";
import {
  getViewTrip,
  getAllTrips,
  setNearestTripScroll,
  getSearchResultWithPnrOrPhone
} from "@/composables/counterman/trips"
import {counter, coach} from "@/composables/common"
import {dateFormat, timeFormat} from "@/helpers/dateTimeFormat";
import GlobalFilter from "@/views/Counterman/Dashboard/TicketBooking/GlobalFilter";

const {hasPermission} = permission();
const {Counterman: counterManRoles} = roles();

export default {
  name: "CountermanDashboard",
  components: {
    GlobalFilter,
    TicketBooking,
    TicketsDetails,
    datePickerFilterCounterman,
  },
  provide: {
    counterManRoles
  },
  setup() {
    const store = useStore();
    const {t, locale} = useI18n();
    const options = {
      texts: {
        count: "",
      },
    };

    let tripIdPass = ref("");
    const dashboardActive = ref(true);
    const globalFilterTab = ref(false)
    let searchQuery = ref('');
    const shouldRefreshPageGoBack = ref(false)

    //Mobile and Desktop list view handle start
    const isMobile = ref(false);
    onBeforeUnmount(() => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', onResize)
      }
    })

    onMounted(() => {
      onResize()
      window.addEventListener('resize', onResize)
    })

    const onResize = () => {
      isMobile.value = window.innerWidth < 768
    }
    //Mobile and Desktop list view handle end

    //composable variables
    const {viewTrip, tripDetails, activeTrip, tripDashboardActive} = getViewTrip(shouldRefreshPageGoBack);
    const {getAllTripsForTicketing, pagination} = getAllTrips();
    const {nearestTrip, setTripRef, nearestTripe, tripRef} = setNearestTripScroll();
    const {getAllStoppages} = counter();
    const {getAllCoach} = coach();
    const {SearchResultWithPnrOrPhone} = getSearchResultWithPnrOrPhone(dashboardActive, globalFilterTab);

    getAllTripsForTicketing();
    getAllStoppages();
    getAllCoach();

    const getTripsForTicketing = computed(() => store.getters.getTripsForTicketing)
    const selectedLanguage = computed(() => store.getters.getLang)
    const userRoles = computed(() => store.getters.userRoles)
    const getCountermanStoppageListForFilter = computed(() => store.getters.getCountermanStoppageListForFilter)
    const comingFrom = ref("")

    const Tickets = (tripId) => {
      tripIdPass.value = tripId;
      dashboardActive.value = false;
    };

    const close = () => {
      tripIdPass.value = "";
      dashboardActive.value = true;
    };
    const openGlobalFilter = (value) => {
      console.log("Emit from dashboard", value.isOpen)
      dashboardActive.value = !value.isOpen;
      globalFilterTab.value = value.isOpen;
      shouldRefreshPageGoBack.value = !value.isOpen
      if (value.from === 'globalFilter' && comingFrom.value === 'search') {
        searchWithPnrPhone()
      } else {
        comingFrom.value = ''
        console.log("", comingFrom.value)
      }
    }

    watch(
        () => userRoles,
        (value) => {
          if (!value?.includes(counterManRoles.ticketBooking)) {
            router
                .push(web.Default.LOGIN_URL.path)
                .catch((error) => console.log(error));
          }
        }
    );

    watch(() => pagination, () => {
      setTimeout(() => {
        nearestTripe();
      }, 500)
    }, {deep: true})

    //Nearest Trip set and scroll
    setTimeout(() => {
      nearestTripe();
    }, 1000)

    const searchWithPnrPhone = () => {
      comingFrom.value = 'search'
      SearchResultWithPnrOrPhone(searchQuery.value)
    }

    return {
      t,
      locale,
      activeTrip,
      dropdownVariables,
      tripIdPass,
      dashboardActive,
      hasPermission,
      counterManRoles,
      pagination,
      options,
      viewTrip,
      Tickets,
      close,
      getAllTripsForTicketing,
      dateFormat,
      timeFormat,
      tripDetails,
      tripDashboardActive,
      getTripsForTicketing,
      selectedLanguage,
      getCountermanStoppageListForFilter,
      setTripRef,
      nearestTrip,
      tripRef,
      globalFilterTab,
      searchWithPnrPhone,
      searchQuery,
      openGlobalFilter,
      shouldRefreshPageGoBack,
      isMobile
    };
  },
};
</script>

<style scoped>
button.accordion-button {
  background-color: #242424;
  color: #fff;
  border: 0;
  padding-left: 10px;
  padding-right: 10px;
}

.accordion-button::after {
  filter: invert(1);
}

/* responsive start  */
@media only screen and (min-width: 768px) {
  .bording_table_phone {
    display: none !important;
  }
}

@media only screen and (max-width: 767px) {
  .bording_table {
    display: none;
  }

  .bording_table_content {
    display: none;
  }
}

/* responsive end  */
.phnbtn {
  width: 80%;
  background-color: #f04935;
  color: #fff;
  border: #f04935;
  border-radius: 5px 5px 5px 5px;
  /* margin-left: 20px !important; */
  padding: 10px;
}

.line_height {
  line-height: 10px;
}

.itemShadow {
  border: 1px solid #ddd;
  border-radius: 6px;
}

.custom-font {
  font-family: "Lato", sans-serif;
}

.icon_margin {
  margin-left: 5px;
}

.bording_table_phone {
  box-shadow: #0c0b0b;
  /* border: 1 px solid black; */
  /* box-shadow: orangered; */
}

.color-blue {
  color: blue;
}

.droping_place {
  color: red;
  font-weight: 700;
  letter-spacing: 2px;
}

.bording_place {
  color: #12b83e;
  font-weight: 700;
  letter-spacing: 2px;
}

.number5 {
  font-size: 20px;
  font-weight: 700;
  color: #ff9c07;
}

.number6 {
  font-size: 20px;
  font-weight: 700;
  color: #ff9c07;
}

.number1 {
  font-size: 16px;
  font-weight: 700;
  color: red;
}

.number4 {
  font-weight: 700;
  color: #ff9c07;
  letter-spacing: 2px;
  /* font-size: 16px; */
}

.number {
  font-size: 20px;
  font-weight: 700;
  color: #12b83e;
}

.font1 {
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
}

.custom_button {
  margin: 1px 2px;
  border: 2px solid #0051ff;
  border-radius: 4px;
  background-color: #0051ff;
  padding: 2px 6px;
  color: #fff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.custom_button:hover,
.custom_button:focus {
  border: 2px solid #0051ff;
  background-color: #fff;
  color: #0051ff;
  transition: 0.4s;
  outline: 0;
}

.font-12 {
  font-size: 14px;
}

.global-filter-input {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 14px;
}

.height-34 {
  height: 34px;
}

.nearest-trip-highlight {
  background: #59db756b;
}

.counterman_floating_btn {
  position: fixed;
  width: 50px;
  height: 50px;
  bottom: 30px;
  right: 30px;
  background-color: #449104;
  color: #fff;
  border: 2px solid #449104;
  border-radius: 50px;
  z-index: 999;
  text-align: center;
  box-shadow: 3px 3px 4px 1px rgb(74 74 74 / 70%);
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}
</style>
