<template>
  <label :for="inputId" class="col-sm-3 col-form-label font_detail">{{ t(inputLabel, {}, { locale: $store.getters.getLang }) }}:</label>
  <div class="col-sm-9">
    <v-date-picker class="inline-block h-full" mode="time" v-model="time" :timezone="timezone" :model-config="modelConfig" is-dark
                   color="teal"
                   is-required>
      <template v-slot="{ inputValue, togglePopover }">
        <input
            class="form-control font_detail"
            :value="inputValue"
            @click="togglePopover()"
            readonly
        />
      </template>
    </v-date-picker>
    <slot name="error"></slot>
  </div>
</template>

<script>
import {ref, watch} from "vue";
import { useI18n } from "vue-i18n";
export default {
  name: "TimePicker",
  props: {
    modelValue: String,
    label: String,
    id: String
  },
  setup(props, {emit}) {
    let time = ref(props.modelValue);
    const inputLabel = ref(props.label);
    const inputId = ref(props.id);
    const { t, locale } = useI18n();
    const modelConfig = ref({
      type: 'string',
      mask: 'HH:mm:00'
    });
    let timezone = ref('');
    watch(() => time.value, () => {
      emit("update:modelValue", time.value);
    });
    watch(() => props.modelValue, (value) => {
      time.value = value;
    });
    return {
      t,locale,
      time, timezone, inputLabel, inputId, modelConfig
    };
  }
};
</script>

<style scoped>

</style>