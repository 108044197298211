<template>
  <div class="col-sm-2 col-md-2 col-lg-1 col-xl-1 order-md-1 order-xl-1">
    <span class="fw-bold">{{t("SEATS", {}, {locale: selectedLanguage}) }}</span>
    <div class="card card-body">
      <div class="row">
        <div class="col-4 col-sm-12 col-md-12 col-xl-12mt-md-12 mt-xl-0 mt-xxl-2">
          <p class="text-center font_detail m-0">
            {{ t("Sold", {}, {locale: selectedLanguage}) }}
          </p>
          <div>
            <div>
              <div class="rounded-end sold_seats common_btn span_font w-100 ">
                <span>{{ seatSummeryDetails.totalSoldTicket }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-4 col-sm-12 col-md-12 col-xl-12 col-xxl-6 mt-md-12 mt-xl-0 mt-xxl-2">
          <div>
            <p class="text-center font_detail m-0">
              {{ t("M", {}, {locale: selectedLanguage}) }}
            </p>
            <div>
              <div class="rounded-end sold_seats_m common_btn span_font w-100">
                <span>{{ seatSummeryDetails.maleTicket }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-4 col-sm-12 col-md-12 col-xl-12 col-xxl-6 mt-md-12 mt-xl-0 mt-xxl-2">
          <div>
            <p class="text-center font_detail m-0">
              {{ t("F", {}, {locale: selectedLanguage}) }}
            </p>
            <div>
              <div class="rounded-end sold_seats_f common_btn span_font w-100">
                <span>{{ seatSummeryDetails.femaleTicket }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, ref} from "vue";
import {useStore} from 'vuex'
import {useI18n} from "vue-i18n";

export default {
  name: 'SeatSummery',
  setup() {
    const store = useStore();
    const {t, locale} = useI18n();
    const selectedLanguage = computed(() => store.getters.getLang)

    const seatSummery = () => {
      let maleTicket = ref([]); let femaleTicket = ref([]); let bookedSeatsMaleFemale = ref({})
      bookedSeatsMaleFemale.value = Object.entries(store.getters.getBlockedSeatsTicketsCounterman);
      bookedSeatsMaleFemale.value.forEach((seat) => {
        if (seat[1].passengerGender === "Male") {
          maleTicket.value.push(seat[1].seatNumbers);
        } else if (seat[1].passengerGender === "Female") {
          femaleTicket.value.push(seat[1].seatNumbers);
        }
      });

      return {
        totalSoldTicket: maleTicket.value.length + femaleTicket.value.length,  //bookedSeatsMaleFemale.value.length,
        maleTicket: maleTicket.value.length,
        femaleTicket: femaleTicket.value.length
      }

    }
    const seatSummeryDetails = computed(seatSummery)
    return {
      t,
      locale,
      selectedLanguage,
      seatSummeryDetails
    }

  }
}
</script>

<style scoped>

</style>
