<template>
  <Modal @close="closeModal" v-bind:size="'xl'">
    <template v-slot:title>{{ title }}</template>
    <template v-slot:body>
      <form>
        <div class="auth_form py-3">
          <div class="row my-2">
            <div class="col-md-3">
              <Input
                  v-model="owner.name"
                  v-bind:placeholder="'Enter Owner Name'"
                  v-bind:label="'Name'"
                  v-bind:id="'name'"
                  v-bind:type="'text'"
              />
              <Error v-if="errors.name" v-bind:error="errors.name"/>
            </div>
            <div class="col-md-3">
              <Input
                  v-model="owner.phone"
                  v-bind:placeholder="'Enter Phone Number'"
                  v-bind:label="'Phone'"
                  v-bind:id="'phone'"
                  v-bind:type="'text'"
              />
              <Error v-if="errors.phone" v-bind:error="errors.phone"/>
            </div>
            <div class="col-md-3">
              <Input
                  v-model="owner.password"
                  v-bind:placeholder="'Enter Password'"
                  v-bind:label="'Password'"
                  v-bind:id="'password'"
                  v-bind:type="fieldTypes.password"
              />
              <Error v-if="errors.password" v-bind:error="errors.password"/>
            </div>
            <div class="col-md-3">
              <Input
                  v-model="owner.email"
                  v-bind:placeholder="'Email Address'"
                  v-bind:label="'Email Address'"
                  v-bind:id="'email'"
                  v-bind:type="fieldTypes.email"
              />
            </div>
            <div class="col-md-3">
              <Dropdown
                  v-if="status"
                  v-model="owner.status"
                  v-bind:id="'status'"
                  v-bind:label="'Status'"
                  v-bind:dropdown="status"
                  v-bind:hideLabel="'inactive'"
              />
            </div>
          </div>
        </div>

        <hr class="section_devider"/>
        <div class="role_saection">
          <div class="row">
            <div class="d-flex justify-content-between align-items-center">
              <h5><b>Roles</b></h5>
              <div class="form-check form-check-inline">
                <input
                    class="form-check-input all_permission_label"
                    type="checkbox"
                    id="select-all"
                    name="select-all"
                    @click="giveAllPermission"
                    v-model="allSelected"
                />
                <label
                    class="form-check-label all_permission_label"
                    for="select-all"
                >Give All Permission</label
                >
              </div>
            </div>
          </div>
          <span v-if="errors.roles" class="text-danger err_msg">{{
              errors.roles
            }}</span>
          <span
              v-else-if="errors[Owner.ownerCompanySetupWrite]"
              class="text-danger err_msg"
          >{{ errors[Owner.ownerCompanySetupWrite] }}</span
          >
          <span
              v-else-if="errors[Owner.ownerTripWrite]"
              class="text-danger err_msg"
          >{{ errors[Owner.ownerTripWrite] }}</span
          >
          <span
              v-else-if="errors[Owner.ownerWrite]"
              class="text-danger err_msg"
          >{{ errors[Owner.ownerWrite] }}</span
          >
          <div class="assigned_role">
            <div class="row">
              <div class="col-6 col-sm-4 col-md-3">
                <div class="role_select_area">
                  <h5>Dashboard</h5>
                  <Checkbox
                      v-model="checked"
                      v-bind:label="'Read'"
                      v-bind:role="Owner.ownerDashboard"
                      :key="allSelected"
                      @toggleAllPermission="toggleAllPermission"
                  />
                </div>
              </div>
              <div class="col-6 col-sm-4 col-md-3">
                <div class="role_select_area">
                  <h5>Report</h5>
                  <Checkbox
                      v-model="checked"
                      v-bind:label="'Read'"
                      v-bind:role="Owner.ownerReports"
                      :key="allSelected"
                      @toggleAllPermission="toggleAllPermission"
                  />
                </div>
              </div>
              <div class="col-6 col-sm-4 col-md-3">
                <div class="role_select_area">
                  <h5>Owners</h5>
                  <Checkbox
                      v-model="checked"
                      v-bind:label="'Read'"
                      v-bind:role="Owner.ownerRead"
                      :key="allSelected"
                      @toggleAllPermission="toggleAllPermission"
                  />
                  <Checkbox
                      v-model="checked"
                      v-bind:label="'Write'"
                      v-bind:role="Owner.ownerWrite"
                      :key="allSelected"
                      @toggleAllPermission="toggleAllPermission"
                  />
                </div>
              </div>
              <div class="col-6 col-sm-4 col-md-3">
                <div class="role_select_area">
                  <h5>Trips</h5>
                  <Checkbox
                      v-model="checked"
                      v-bind:label="'Read'"
                      v-bind:role="Owner.ownerTripRead"
                      :key="allSelected"
                      @toggleAllPermission="toggleAllPermission"
                  />
                  <Checkbox
                      v-model="checked"
                      v-bind:label="'Write'"
                      v-bind:role="Owner.ownerTripWrite"
                      :key="allSelected"
                      @toggleAllPermission="toggleAllPermission"
                  />
                </div>
              </div>
              <div class="col-6 col-sm-4 col-md-3">
                <div class="role_select_area">
                  <h5>Company Setup</h5>
                  <Checkbox
                      v-model="checked"
                      v-bind:label="'Read'"
                      v-bind:role="Owner.ownerCompanySetupRead"
                      :key="allSelected"
                      @toggleAllPermission="toggleAllPermission"
                  />
                  <Checkbox
                      v-model="checked"
                      v-bind:label="'Write'"
                      v-bind:role="Owner.ownerCompanySetupWrite"
                      :key="allSelected"
                      @toggleAllPermission="toggleAllPermission"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group row my-1">
          <label class="col-sm-3 col-form-label"></label>
          <div class="col-sm-9">
            <input
                v-if="!ownerIdForUpdate"
                type="submit"
                class="mt-3 py-2 submit_button font_detail float-end"
                @click.prevent="addOwner"
            />
            <input
                v-else
                type="submit"
                class="mt-3 py-2 submit_button font_detail float-end"
                @click.prevent="updateOwner"
            />
          </div>
        </div>
      </form>
    </template>
  </Modal>
</template>

<script>
import {reactive} from "@vue/reactivity";
import dropdownVariables from "@/modules/dropdownVariables";
import useInputValidation from "@/modules/useInputValidations";
import {useRoute} from "vue-router";
import {inject, onBeforeMount, ref, watch} from "vue";
import roles from "@/modules/roles";
import store from "@/store";

export default {
  name: "AddUpdateModal",
  props: {
    modalValue: Boolean,
    ownerId: String,
  },
  setup(props, {emit}) {
    let owner = reactive({
      id: "",
      companyId: "",
      name: "",
      phone: "",
      email: "",
      password: "",
      status: "1",
      seatPlanEditAccess: "1",
      roles: "",
    });
    let ownerIdForUpdate = ref(props.ownerId);
    let checked = ref([]);
    const editAccess = dropdownVariables.editAccess;
    let allSelected = ref(false);
    let {
      requiredValidation,
      passwordValidation,
      phoneValidation,
      requiredRoleValidation,
      errors,
    } = useInputValidation();
    const {Owner} = roles();
    const status = dropdownVariables.status;
    const title = ref("Add Owner");
    const route = useRoute();
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    let fieldTypes = reactive({
      email: "text",
      password: "text",
    });

    let pagination = reactive({
      page: 1,
      limit: 25,
    });

    watch(
        () => owner.name,
        () => {
          requiredValidation("name", owner.name);
        }
    );
    watch(
        () => owner.phone,
        () => {
          phoneValidation("phone", owner.phone);
        }
    );
    watch(
        () => owner.password,
        () => {
          passwordValidation("password", owner.password);
        }
    );
    watch(
        () => owner.password,
        () => {
          fieldTypes.password = "password";
        }
    );
    watch(
        () => owner.email,
        () => {
          fieldTypes.email = "email";
        }
    );

    const giveAllPermission = () => {
      checked.value = [];
      if (!allSelected.value) {
        checked.value = Object.values(Owner);
      }
    };
    const closeModal = () => {
      emit("update:modelValue", false);
    };
    const toggleAllPermission = () => {
      allSelected.value = checked.value.length === Object.values(Owner).length;
    };
    const validate = () => {
      Object.keys(errors).forEach(function (key) {
        errors[key] = "";
      });
      requiredValidation("name", owner.name);
      phoneValidation("phone", owner.phone);
      requiredValidation("roles", owner.roles);
      requiredRoleValidation(
          Owner.ownerCompanySetupWrite.toString(),
          checked.value,
          Owner.ownerCompanySetupRead,
          Owner.ownerCompanySetupWrite,
          Owner
      );
      requiredRoleValidation(
          Owner.ownerTripWrite.toString(),
          checked.value,
          Owner.ownerTripRead,
          Owner.ownerTripWrite,
          Owner
      );
      requiredRoleValidation(
          Owner.ownerWrite.toString(),
          checked.value,
          Owner.ownerRead,
          Owner.ownerWrite,
          Owner
      );
      if (ownerIdForUpdate.value === "") {
        passwordValidation("password", owner.password);
      } else {
        if (owner.password !== "") {
          passwordValidation("password", owner.password);
        }
      }
      return !(
          Object.values(errors).length <= 0 ||
          !Object.values(errors).every((x) => x === null || x === "")
      );
    };
    const addOwner = () => {
      owner.roles = checked.value.sort().join(",");
      if (validate()) {
        showLoader();
        store
            .dispatch("addOwner", {owner, pagination})
            .then(() => {
              hideLoader();
              closeModal();
            })
            .catch((error) => {
              hideLoader();
              if (error.status === 401) {
                addOwner();
              }
            });
      }
    };
    const updateOwner = () => {
      owner.roles = checked.value.sort().join(",");
      if (validate()) {
        showLoader();
        store
            .dispatch("updateOwner", {owner, pagination})
            .then(() => {
              hideLoader();
              closeModal();
            })
            .catch((error) => {
              hideLoader();
              if (error.status === 401) {
                updateOwner();
              }
            });
      }
    };

    onBeforeMount(() => {
      Object.keys(errors).forEach(function (key) {
        delete errors[key];
      });
      if (route.query.id) {
        owner.companyId = route.query.id;
      }
      if (ownerIdForUpdate.value !== "") {
        const ownerDetails = store.getters.getOwner(ownerIdForUpdate.value);
        title.value = "Update Owner";
        owner.id = ownerDetails[0]._id;
        owner.companyId = ownerDetails[0].companyId;
        owner.name = ownerDetails[0].name;
        owner.phone = ownerDetails[0].phone;
        owner.email = ownerDetails[0].email;
        owner.status = ownerDetails[0].status ? 1 : 0;
        ownerDetails[0].roles[0]
            .split(",")
            .forEach((element) => checked.value.push(parseInt(element)));
        Object.keys(Owner).length === checked.value.length
            ? (allSelected.value = true)
            : (allSelected.value = false);
      }
    });

    return {
      status,
      owner,
      errors,
      Owner,
      title,
      checked,
      allSelected,
      ownerIdForUpdate,
      giveAllPermission,
      toggleAllPermission,
      updateOwner,
      addOwner,
      closeModal,
      fieldTypes,
      editAccess,
    };
  },
};
</script>

<style scoped></style>
