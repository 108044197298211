<template>
  <div class="card h-100 mt-4">
    <div class="card-header text-center bg-white">
      <h5 class="mb-0 text-uppercase">{{ t("Goods_tickets", {}, { locale: $store.getters.getLang }) }}</h5>
    </div>
    <div class="card-body mt-0 py-0">
      <slot name="body"></slot>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
export default {
  name: "TableCard",

  setup() {
    const { t, locale } = useI18n();

    return {
      t,
      locale,
    };
  },
};
</script>

<style scoped>
h3,
h5 {
  font-size: 36px;
  color: #f04935;
}
h5 {
  font-size: 18px;
}
</style>
