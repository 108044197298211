export default {
  boolean: {
    1: "Yes",
    0: "No",
  },
  direction: {
    UP: "Up",
    DOWN: "Down",
  },
  editAccess: {
    1: "Yes",
    0: "No",
  },
  dtTypes: {
    intercity: "Intercity",
    sd_intercity: "Short Distance Intercity",
  },
  adminDirection: {
    UP: "Up",
    DOWN: "Down",
    BOTH: "Both",
  },
  seatTypes: {
    SEAT: "SEAT",
    EXTRA: "EXTRA",
    GOODS: "GOODS",
  },
  userType: {
    admin: "Admin",
    dtowner: "DtOwner",
    counterman: "Counterman",
  },
  tripStatusBaria: {
    true: "Confirmed",
    false: "Cancelled",
  },
  tripStatus: {
    ACTIVE: { value: "Active", badge: "success" },
    CANCELLED: { value: "Cancelled", badge: "danger" },
    COMPLETE: { value: "Completed", badge: "primary" },
  },
  status: {
    1: "Active",
    0: "Inactive",
  },
  statusTrip: {
    ACTIVE: "Active",
    INACTIVE: "Inactive",
  },
  commissionType: {
    PERCENTAGE: "Percentage",
    CASH: "Cash",
  },
  reportStatus: {
    1: "Confirmed",
    0: "Cancelled",
  },
  printingType: {
    Individual: "Individual",
    Group: "Group",
  },
  reportByIndividualSeat: {
    1: "Yes",
    0: "No",
  },
  gender: {
    Male: "Male",
    Female: "Female",
  },
  fareType: {
    predefined: "Predefined",
    trip_fare: "Trip Fare",
    instant_fare: "Instant Fare",
  },
  printerType: {
    // laser_printer: "Laser Printer",
    // desktop_pos: "Desktop POS",
    android_pos: "Android POS",
    no_print: "No Printing",
    // bluetooth_pos: "Bluetooth POS",
  },
  countermanPrinterType: {
    T2mini: "T2mini",
    Bluetooth: "Bluetooth",
    USB: "USB",
  },
  ticketCancelReason: {
    "প্রিন্টার সমস্যা": "প্রিন্টার সমস্যা",
    "আসন পরিবর্তন": "আসন পরিবর্তন",
    "ক্লাস পরিবর্তন": "ক্লাস পরিবর্তন",
    "টিকেট ফেরত": "টিকেট ফেরত",
    অন্যান্য: "অন্যান্য",
  },
  discount: {
    0: "No Discount",
    1: "With Discount",
  },
  reportType: {
    ticket: "Ticket",
    tripWiseReport: "Trip Wise Report",
    dailySummaryReport: "Daily Summary Report",
  },
  sequences: {},
};

// 'ACTIVE': {value:"Active",badge:"success"},
// 'CANCELLED': {value:"Cancelled",badge:"success"},
// 'COMPLETE': {value:"Completed",badge:"success"},
