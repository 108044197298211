import store from "@/store";
import {inject, ref, reactive, watch, onBeforeUpdate} from "vue";
import moment from "moment";
import {dateTimeFormat} from "@/helpers/dateTimeFormat";

const getViewTrip = (shouldRefreshPageGoBack) => {
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    let tripDetails = reactive({
        route: "",
        coach: "",
    });
    let activeTrip = ref("");
    let tripDashboardActive = ref(false);

    const viewTrip = (tripId, routeName, coachName) => {
        console.log("starting.....")
        shouldRefreshPageGoBack.value = false;
        if (tripId !== "") {
            showLoader();
            store
                .dispatch("getTicketByTrip", {tripId: tripId})
                .then(() => {
                    tripDetails.route = routeName;
                    tripDetails.coach = coachName;
                    activeTrip.value = tripId;
                    tripDashboardActive.value = true;
                    hideLoader();

                })
                .catch(() => {
                    hideLoader();
                });
        } else {
            tripDetails.route = routeName;
            tripDetails.coach = coachName;
            activeTrip.value = tripId;
            tripDashboardActive.value = true;
        }
    };

    return {
        viewTrip,
        tripDetails,
        activeTrip,
        tripDashboardActive
    }
}

const getAllTrips = () => {
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    let pagination = reactive({
        page: 1,
        limit: 15,
        from: "",
        to: "",
        coach: "",
        date: moment(new Date()).format("yyyy-MM-DD"),
        status: "ACTIVE",
    });
    const getAllTripsForTicketing = () => {
        showLoader();
        store
            .dispatch("getActiveTripsForTicketing", pagination)
            .then(() => {
                hideLoader();
            })
            .catch((error) => {
                hideLoader();
                if (error.status === 401) {
                    getAllTripsForTicketing();
                }
            });
    };

    watch(
        () => pagination,
        () => {
            getAllTripsForTicketing();
        },
        {deep: true}
    );

    return {getAllTripsForTicketing, pagination}
}

const getTripFromAndToStoppageGenerate = (singleTripDetails) => {
    let fromStoppage = ref("");
    let toStoppage = ref("");
    if (
        store.getters.getUserInfo?.counter?.preferredStoppage &&
        store.getters.getUserInfo?.counter?.preferredStoppage.length > 0
    ) {
        fromStoppage.value = "";
        for (let item of store.getters.getUserInfo?.counter
            ?.preferredStoppage) {
            let withoutLastStoppage = [
                ...singleTripDetails.route.routeSequence,
            ];
            withoutLastStoppage.pop();
            if (withoutLastStoppage.some((data) => data._id === item._id)) {
                fromStoppage.value = item._id;
                break;
            }
        }
        if (fromStoppage.value === "") {
            fromStoppage.value = singleTripDetails.route.fromStoppageId;
        }
    } else {
        fromStoppage.value = singleTripDetails.route.fromStoppageId;
    }
    toStoppage.value = singleTripDetails.route.toStoppageId;

    return {fromStoppage, toStoppage}
}

const setNearestTripScroll = () => {
    const nearestTrip = ref('');
    let tripRef = [];

    const nearestTripe = () => {
        let minDiff = null;
        const DateList = store.getters.getTripsForTicketing.map((trip) => trip.tripDateTime)
        let mostAccurateDate = DateList[0];

        DateList.map((item, index) => {
            const currentTime = dateTimeFormat(new Date(), 6);
            const tripTime = dateTimeFormat(item, 0);
            if (new Date(tripTime) > new Date(currentTime)) {
                const diff = (new Date(tripTime) - new Date(currentTime));
                if (!minDiff || diff < minDiff) {
                    minDiff = diff;
                    mostAccurateDate = DateList[index]
                }
            }
        });
        nearestTrip.value = mostAccurateDate;
        findTripAndScroll()
    }

    const findTripAndScroll = () => {
        tripRef.find(el => {
                if (el.dataset.tripTime === nearestTrip.value) {
                    el.scrollIntoView({behavior: 'smooth', block: 'center'})
                }
            }
        );
    }

    const setTripRef = el => {
        if (el) {
            tripRef.push(el)
        }
    }
    onBeforeUpdate(() => {
        tripRef = []
    })

    return {
        tripRef,
        nearestTrip,
        nearestTripe,
        setTripRef,
    }

}

const getSearchResultWithPnrOrPhone = (dashboardActive, globalFilterTab) => {
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    const SearchResultWithPnrOrPhone = (searchQuery) => {
        showLoader()
        store
            .dispatch("searchWithPnrOrPhone", {search: searchQuery})
            .then((res) => {
                console.log(res.data.tickets)
                dashboardActive.value = false;
                globalFilterTab.value = true;
                hideLoader();
            })
            .catch(() => {
                hideLoader();
            });
    };

    return {
        SearchResultWithPnrOrPhone
    }
}


export {
    getViewTrip,
    getAllTrips,
    getTripFromAndToStoppageGenerate,
    setNearestTripScroll,
    getSearchResultWithPnrOrPhone
};
