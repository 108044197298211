import {ref, computed, reactive} from "vue";
import store from "@/store";
import axios from "axios";
import Apis from "@/urls/apis";
import tokenCookie from "@/modules/tokenCookie";

const getFilterBoardingAndDroppingPlace = (singleTripDetails) => {
    const {getToken} = tokenCookie();
    const passenger = reactive({
        name: "",
        phoneNo: "",
        gender: "Male",
        nid: "",
        address: "",
        fromStoppage: "",
        toStoppage: "",
        boardingPlace: "",
        droppingPoint: "",
        trip: "",
        coach: "",
        ticketType: "SEAT",
    })
    let err = ref("");

    let boardingPlaceDropDownFilter = ref({});
    let droppingPointDropdownFilter = ref({});
    const userInfo = computed(() => store.getters.getUserInfo);

    const filterBoardingAndDroppingPlace = () => {
        boardingPlaceDropDownFilter.value = {};
        droppingPointDropdownFilter.value = {};
        let stoppageListObjectBoarding = Object.entries(store.getters.getBoardingPlaceBasedOnStoppage);
        let stoppageListObjectDropping = Object.entries(store.getters.getDroppingPointBasedOnStoppage);
        let userPreferredStoppage = userInfo.value?.counter?.preferredStoppage;

        if (userPreferredStoppage
          && userPreferredStoppage.length > 0
          && userPreferredStoppage.some((item) => item._id === passenger.fromStoppage)
        ) {
            userPreferredStoppage.map((preferredStoppage) => {
                stoppageListObjectBoarding.forEach(([key, value]) => {
                    if (preferredStoppage._id === key) {
                        if (value.length !== 0) {
                            value.forEach((item) => {
                                boardingPlaceDropDownFilter.value[item] = item;
                            });
                        }
                    }
                })
            })
            passenger.boardingPlace = Object.keys(boardingPlaceDropDownFilter.value)[store.getters.getUserInfo?.counter?.preferredBoardingPlace];
        } else {
            stoppageListObjectBoarding.forEach(([key, value]) => {
                if (value.length === 0) {
                    boardingPlaceDropDownFilter.value = {}
                }
                if (key === passenger.fromStoppage && value.length !== 0) {
                    value.forEach((item) => {
                        boardingPlaceDropDownFilter.value[item] = item;
                    });
                }
                let defaultBoardingPointIndex = store.getters.getDefaultBoardingPlaceBasedOnStoppage[passenger.fromStoppage];
                if (Number.isInteger(defaultBoardingPointIndex)) {
                    passenger.boardingPlace = Object.keys(boardingPlaceDropDownFilter.value)[defaultBoardingPointIndex];
                } else {
                    passenger.boardingPlace = "";
                }
            })
        }
        for (let [key, value] of stoppageListObjectDropping) {
            if (key === passenger.toStoppage) {
                if (value.length !== 0) {
                    value.forEach((item) => {
                        droppingPointDropdownFilter.value[item] = item;
                    });
                } else {
                    droppingPointDropdownFilter.value = {};
                }
            }

            let defaultDroppingPointIndex = store.getters.getDefaultDroppingPointBasedOnStoppage[passenger.toStoppage];
            if (Number.isInteger(defaultDroppingPointIndex)) {
                passenger.droppingPoint = Object.keys(droppingPointDropdownFilter.value)[defaultDroppingPointIndex];
            } else {
                passenger.droppingPoint = "";
            }
        }
    };


    const renderSeatListBasedOnStoppageSelect = () => {
        const tripDetails = computed(() => store.getters.getTripForTicketing)
        const selectedSeatsBookedDatabase = ref({});
        let routeSequence = singleTripDetails.route.routeSequence;
        let indexOfFromStoppage = arrayObjectIndexOf(routeSequence, passenger.fromStoppage, "_id");
        let indexOfToStoppage = arrayObjectIndexOf(routeSequence, passenger.toStoppage, "_id");
        if (indexOfToStoppage !== -1 && indexOfFromStoppage !== -1 && indexOfToStoppage > indexOfFromStoppage) {
            selectedSeatsBookedDatabase.value = [];
            const tripRouteSequence = routeSequence.map((item) => item._id);
            const indexOfFromStoppage = tripRouteSequence.indexOf(passenger.fromStoppage);
            const indexOfToStoppage = tripRouteSequence.indexOf(passenger.toStoppage);
            const fromToSequence = tripRouteSequence.slice(indexOfFromStoppage, indexOfToStoppage + 1);
            let bookedAndReservedSeats = {...tripDetails.value.bookedSeats, ...tripDetails.value.reservedSeats}
            for (let [key, value] of Object.entries(bookedAndReservedSeats)) {
                for (let v of Object.values(value)) {
                    const intersectValue = v.filter((s) => fromToSequence.includes(s)).length;
                    if (intersectValue > 1) {
                        if (!selectedSeatsBookedDatabase.value.includes(key)) {
                            selectedSeatsBookedDatabase.value.push(key);
                            break;
                        }
                    } else {
                        if (selectedSeatsBookedDatabase.value.includes(key)) {
                            selectedSeatsBookedDatabase.value.splice(
                              selectedSeatsBookedDatabase.value.indexOf(key),
                              1
                            );
                        }
                    }
                }
            }
            store.commit("setSelectedSeatsBookedDatabase", selectedSeatsBookedDatabase.value)
            err.value = "";
        } else {
            err.value = "fromStoppage to toStoppage route sequence did not match";
        }
    };

    const arrayObjectIndexOf = (myArray, searchTerm, property) => {
        for (var i = 0, len = myArray.length; i < len; i++) {
            if (myArray[i][property] === searchTerm) return i;
        }
        return -1;
    };

    const searchWithPassengerPhone = (e) => {
        e.preventDefault();
        const payload = {
            phone: '0'+passenger.phoneNo
        }
        const header = {
            'Authorization': 'Bearer ' + getToken()
        };
        axios.get(Apis.COUNTERMAN_SEARCH_DETAILS_WITH_PASSENGER_PHONE, {headers: header, params: payload})
          .then(res => {
              if(res.data.passenger[0]) {
                  passenger.name = res.data.passenger[0]?.name;
                  passenger.gender = res.data.passenger[0]?.gender;
                  passenger.nid = res.data.passenger[0]?.nid;
                  passenger.address = res.data.passenger[0]?.address;
              }
          })
          .catch(error => console.log(error))
    }

    return {
        passenger,
        boardingPlaceDropDownFilter,
        droppingPointDropdownFilter,
        filterBoardingAndDroppingPlace,
        renderSeatListBasedOnStoppageSelect,
        searchWithPassengerPhone,
        err
    }
}

export {getFilterBoardingAndDroppingPlace}
