import {createToast} from 'mosha-vue-toastify';
import store from "@/store";

export default function toast() {
    const infoToast = (info) => {
        createToast(
            {
                title: "Info",
                description: info
            },
            {
                type: 'info',
                timeout: 3000,
                position: store.getters.userType === 'Counterman' ? 'top-right' : 'bottom-left',
                showIcon: true,
                transition: 'bounce',
                hideProgressBar: false,
                showCloseButton: true,
                toastBackgroundColor: '#4285f4'
            }
        );
    };
    const errorToast = (error) => {
        createToast(
            {
                title: "Error",
                description: error
            },
            {
                type: 'danger',
                timeout: 3000,
                position: store.getters.userType === 'Counterman' ? 'top-right' : 'bottom-left',
                showIcon: true,
                transition: 'bounce',
                hideProgressBar: false,
                showCloseButton: true,
                toastBackgroundColor: '#ea4335'
            }
        );
    };
    const successToast = (success) => {
        createToast(
            {
                title: "Success",
                description: success
            },
            {
                type: 'success',
                timeout: 3000,
                position: store.getters.userType === 'Counterman' ? 'top-right' : 'bottom-left',
                showIcon: true,
                transition: 'bounce',
                hideProgressBar: false,
                showCloseButton: true,
                toastBackgroundColor: '#34a853'
            }
        );
    };
    const warningToast = (warning) => {
        createToast(
            {
                title: "Warning",
                description: warning
            },
            {
                type: 'warning',
                timeout: 3000,
                position: store.getters.userType === 'Counterman' ? 'top-right' : 'bottom-left',
                showIcon: true,
                transition: 'bounce',
                hideProgressBar: false,
                showCloseButton: true,
                toastBackgroundColor: '#fbbc05'
            }
        );
    };
    const genericToast = (generic) => {
        createToast(
            {
                title: "Default",
                description: generic
            },
            {
                type: 'default',
                timeout: 3000,
                position: store.getters.userType === 'Counterman' ? 'top-right' : 'bottom-left',
                showIcon: true,
                transition: 'bounce',
                hideProgressBar: false,
                showCloseButton: true,
                toastBackgroundColor: '#ffffff'
            }
        );
    };

    return {
        infoToast, errorToast, successToast, warningToast, genericToast
    };

}
