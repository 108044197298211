<template>
  <div>
    <div v-if="addUpdate">
      <CounterAddUpdateModal
        v-model="addUpdate"
        v-bind:counter="counterId"
        v-bind:company="pagination.companyId"
        @getAllCounters="getAllCounters"
      />
    </div>

    <div>
      <FilterTab>
        <!-- <template v-slot:title><h6>Counter</h6></template> -->
        <template v-slot:body>
          <div class="col-sm-6 col-md-2"></div>
        </template>
        <template v-if="hasPermission(Admin.companyDeployWrite)" v-slot:button1>
          <button class="edit_table_data font_detail" @click="toggle">
            <i class="fas fa-plus"></i><span class="mx-1 bold">Add New</span>
            <!--              <span v-if="!Counterman"><i class="fas fa-plus"></i><span class="mx-1 bold">Add New</span></span>-->
            <!--              <span v-else><i class="fa fa-arrow-left"></i><span class="mx-1 bold">Go Back</span></span>-->
          </button>
        </template>
      </FilterTab>
      <Table v-if="$store.getters.getCounters">
        <template v-slot:header>
          <tr>
            <td class="font_detail">SL</td>
            <td class="font_detail">SEQUENCE</td>

            <td class="font_detail">NAME</td>
            <td class="font_detail">STATUS</td>

            <td
              v-if="hasPermission(Admin.companyDeployWrite)"
              class="font_detail"
            >
              ACTION
            </td>
          </tr>
        </template>
        <template v-slot:body>
          <tr
            v-for="(counter, index) in $store.getters.getCounters"
            :key="counter._id"
          >
            <td class="font_detail">
              {{ index + 1 + (pagination.page - 1) * 25 }}
            </td>
            <td class="font_detail">#{{ counter?.sequence }}</td>

            <td class="font_detail">{{ counter.name }}</td>

            <td>
              <span v-if="counter.status" class="badge bg-success">{{
                dropdownVariables.status[1]
              }}</span>
              <span v-else class="badge bg-danger">{{
                dropdownVariables.status[0]
              }}</span>
            </td>

            <td v-if="hasPermission(Admin.companyDeployWrite)">
              <button
                class="edit_table_data font_detail edit_table_button"
                @click="updateCounters(counter._id)"
              >
                <i class="fas fa-pencil-alt"></i><span class="mx-1">Edit</span>
              </button>
            </td>
          </tr>
        </template>
        <template v-slot:pagination>
          <pagination
            v-model="pagination.page"
            :records="$store.getters.getTotalCounters"
            :per-page="pagination.limit"
            @paginate="getAllCounters"
            :options="options"
          />
        </template>
      </Table>
    </div>
  </div>
</template>

<script>
import { reactive } from "@vue/reactivity";
import { inject, onBeforeMount, ref } from "vue";
import store from "@/store";
import dropdownVariables from "@/modules/dropdownVariables";
import { useRoute } from "vue-router";
import CounterAddUpdateModal from "./CounterAddUpdateModal";
import permission from "@/modules/permission";
import roles from "@/modules/roles";

const { hasPermission } = permission();
const { Admin } = roles();

export default {
  name: "Counter",
  components: { CounterAddUpdateModal },
  setup() {
    let pagination = reactive({
      page: 1,
      limit: 25,
      companyId: "",
    });
    const addUpdate = ref(false);
    const counterman = ref(false);
    const counterId = ref("");
    const options = {
      texts: {
        count: "",
      },
    };
    const route = useRoute();
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");

    const getAllCounters = () => {
      showLoader();
      store
        .dispatch("getCountersByPage", pagination)
        .then(() => {
          getStoppageList();
        })
        .catch((error) => {
          hideLoader();
          if (error.status === 401) {
            getAllCounters();
          }
        });
    };
    const getStoppageList = () => {
      showLoader();
      store
        .dispatch("getStoppageList", { companyId: pagination.companyId })
        .then(() => {
          hideLoader();
        })
        .catch((error) => {
          hideLoader();
          if (error.status === 401) {
            getStoppageList();
          }
        });
    };
    const close = () => {
      addUpdate.value = false;
      counterman.value = false;
    };
    const toggle = () => {
      counterId.value = "";
      if (counterman.value) {
        counterman.value = false;
      } else {
        addUpdate.value = !addUpdate.value;
      }
    };
    const updateCounters = (id) => {
      counterId.value = id;
      addUpdate.value = !addUpdate.value;
    };
    const updateCounterman = (id) => {
      counterId.value = id;
      counterman.value = true;
    };

    onBeforeMount(() => {
      if (route.query.id) {
        pagination.companyId = route.query.id;
        getAllCounters();
      }
    });

    return {
      options,
      pagination,
      dropdownVariables,
      addUpdate,
      counterman,
      counterId,
      Admin,
      hasPermission,
      getAllCounters,
      toggle,
      updateCounters,
      close,
      updateCounterman,
    };
  },
};
</script>

<style scoped>
.bold {
  font-weight: bold;
}
</style>
