<template>
  <div class="table-responsive">
    <table class="table table-hover table-striped text-center vehicle_table">
      <thead class="font_detail bg_corporate">
      <slot name="header"></slot>
      </thead>
      <tbody>
      <slot name="body"></slot>
      </tbody>
    </table>
    <div class="row mx-5">
      <nav aria-label="Page navigation example">
        <ul class="pagination justify-content-center justify-content-sm-end">
          <slot name="pagination"></slot>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  name: "Table"
}
</script>

<style scoped>

</style>