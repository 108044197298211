<template>
  <Modal @close="closeModal" v-bind:size="'xl'">
    <template v-slot:title>{{
      t(title, {}, { locale: $store.getters.getLang })
    }}</template>
    <template v-slot:body>
      <form>
        <div class="auth_form py-3">
          <div class="row my-2">
            <div class="col-md-4">
              <Input
                v-model="driverData.name"
                v-bind:placeholder="'Enter Driver Name'"
                v-bind:label="'Name'"
                v-bind:id="'name'"
                v-bind:type="'text'"
              />
              <Error v-if="errors.name" v-bind:error="errors.name" />
            </div>
            <div class="col-md-4">
              <Input
                v-model="driverData.phone"
                v-bind:placeholder="'Enter Phone Number'"
                v-bind:label="'Phone'"
                v-bind:id="'phone'"
                v-bind:type="'text'"
              />
              <Error v-if="errors.phone" v-bind:error="errors.phone" />
            </div>

            <div class="col-md-4">
              <Input
                v-model="driverData.email"
                v-bind:placeholder="'Email Address'"
                autocomplete="off"
                v-bind:label="'Email'"
                v-bind:id="'email'"
                v-bind:type="fieldTypes.email"
              />
            </div>
          </div>
          <div class="row my-2">
            <div class="col-md-4">
              <label for="address" class="form-label all_permission_label">
                {{ t("Address", {}, { locale: $store.getters.getLang }) }}
              </label>
              <div>
                <textarea
                  class="form-control font_detail"
                  id="address"
                  v-model="driverData.address"
                ></textarea>
              </div>
            </div>
            <div class="col-md-4">
              <Input
                v-model="driverData.nid"
                v-bind:placeholder="'NID Number'"
                v-bind:label="'NID'"
                v-bind:id="'nid'"
                v-bind:type="'text'"
              />
            </div>
            <div class="col-md-4">
              <Dropdown
                v-if="status"
                v-model="driverData.status"
                v-bind:id="'status'"
                v-bind:label="'Status'"
                v-bind:dropdown="status"
                v-bind:hideLabel="'inactive'"
              />
            </div>
          </div>
        </div>

        <div class="form-group row my-1">
          <label class="col-sm-3 col-form-label"></label>
          <div class="col-sm-9">
            <!-- <input
              v-if="!driverIdForUpdate"
              type="submit"
              class="mt-3 py-2 submit_button font_detail float-end"
              @click.prevent="addDriver"
            />
            <input
              v-else
              type="submit"
              class="mt-3 py-2 submit_button font_detail float-end"
              @click.prevent="updateDriver"
            /> -->
            <button
              v-if="!driverIdForUpdate"
              class="mt-3 py-2 submit_button font_detail float-end"
              @click.prevent="addDriver"
            >
              {{ t("Submit", {}, { locale: $store.getters.getLang }) }}
            </button>
            <button
              v-else
              class="mt-3 py-2 submit_button font_detail float-end"
              @click.prevent="updateDriver"
            >
              {{ t("Submit", {}, { locale: $store.getters.getLang }) }}
            </button>
          </div>
        </div>
      </form>
    </template>
  </Modal>
</template>

<script>
import { reactive } from "@vue/reactivity";
import dropdownVariables from "@/modules/dropdownVariables";
import useInputValidation from "@/modules/useInputValidations";
import { inject, onBeforeMount, ref, watch } from "vue";
import store from "@/store";
import { useI18n } from "vue-i18n";
export default {
  name: "SupervisorAddUpdateModal",
  components: {},
  props: {
    modalValue: Boolean,
    company: String,
    driver: String,
  },
  setup(props, { emit }) {
    let driverData = reactive({
      id: "",
      name: "",
      designation: "",
      phone: "",
      email: "",
      nid: "",
      address: "",
      avatar: "",
      transactionType: "",
      balance: "",
      commission: "",
      userType: "counterman",
      companyId: "",
      commissionType: "",
      status: "1",
      api_token: "",
      device_token: "",
      printingType: "Individual",
      reset_token: "",
      printerType: "",
    });
    let driverIdForUpdate = ref(props.driver);
    let companyId = ref(props.company);
    let { requiredValidation, phoneValidation, errors } = useInputValidation();
    let printerType = dropdownVariables.countermanPrinterType; //["T2mini", "Bluetooth"];
    const status = dropdownVariables.status;
    const printingType = dropdownVariables.printingType;
    const title = ref("Add Driver");
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
const { t, locale } = useI18n();
    let fieldTypes = reactive({
      email: "text",
      password: "text",
    });

    watch(
      () => driverData.name,
      () => {
        requiredValidation("name", driverData.name);
      }
    );

    watch(
      () => driverData.phone,
      () => {
        phoneValidation("phone", driverData.phone);
      }
    );

    watch(
      () => driverData.printerType,
      () => {
        requiredValidation("printerType", driverData.printerType);
      }
    );

    watch(
      () => driverData.email,
      () => {
        fieldTypes.email = "email";
      }
    );

    const closeModal = () => {
      emit("update:modelValue", false);
    };

    const validate = () => {
      Object.keys(errors).forEach(function (key) {
        errors[key] = "";
      });
      requiredValidation("name", driverData.name);
      phoneValidation("phone", driverData.phone);

      return !(
        Object.values(errors).length <= 0 ||
        !Object.values(errors).every((x) => x === null || x === "")
      );
    };
    const addDriver = () => {
      if (validate()) {
        showLoader();
        store
          .dispatch("addOwnerServicesDriver", driverData)
          .then(() => {
            hideLoader();
            emit("getAllDrivers");
            closeModal();
          })
          .catch((error) => {
            hideLoader();
            if (error.status === 401) {
              addDriver();
            }
          });
      }
    };
    const updateDriver = () => {
      if (validate()) {
        showLoader();
        store
          .dispatch("updateOwnerServicesDriver", driverData)
          .then(() => {
            hideLoader();
            emit("getAllDrivers");
            closeModal();
          })
          .catch((error) => {
            hideLoader();
            if (error.status === 401) {
              updateDriver();
            }
          });
      }
    };

    onBeforeMount(() => {
      Object.keys(errors).forEach(function (key) {
        delete errors[key];
      });
      driverData.companyId = companyId.value;
      if (driverIdForUpdate.value !== "") {
        const driverDetails = store.getters.getOwnerServicesDriver(
          driverIdForUpdate.value
        );
        title.value = "Update Driver";
        driverData.id = driverDetails[0]._id;
        driverData.companyId = driverDetails[0].companyId;
        driverData.name = driverDetails[0].name;
        driverData.phone = driverDetails[0].phone;
        driverData.email = driverDetails[0].email;
        driverData.printerType = driverDetails[0].printerType;
        driverData.nid = driverDetails[0].nid;
        driverData.address = driverDetails[0].address;
        driverData.status = driverDetails[0].status ? 1 : 0;
        driverData.printingType = driverDetails[0].printingType;
      }
    });

    return {
      t,locale,
      status,
      driverData,
      errors,
      title,
      driverIdForUpdate,
      printingType,
      fieldTypes,

      updateDriver,
      addDriver,
      closeModal,
      printerType,
    };
  },
};
</script>

<style scoped></style>
