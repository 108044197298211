<template>
  <div>
    <div class="sticky-top">
      <FilterTab>
        <template v-slot:body>
          <div class="col-sm-6 col-md-2"></div>
        </template>
        <template v-slot:button2>
          <button class="edit_table_data font_detail" @click="goBack('back')">
            <i class="fas fa-arrow-left"> </i
            > <span class="mx-1 bold">{{ t("Go Back", {}, {locale: selectedLanguage}) }}</span>
          </button>
        </template>
      </FilterTab>
    </div>

    <div class=" overflow-hidden">
      <table
          class="table table-hover table-striped text-center vehicle_table"
      >
        <thead class="font_detail bg_corporate  display-small-none">
        <tr>
          <th class="font_detail">
            {{ t("sl", {}, {locale: selectedLanguage}) }}
          </th>
          <th class="font_detail">
            {{ t("PNR CODE", {}, {locale: selectedLanguage}) }}
          </th>
          <th class="font_detail">
            {{
              t("TRIP TIME & DATE", {}, {locale: selectedLanguage})
            }}
          </th>
          <th class="font_detail">
            {{ t("COACH", {}, {locale: selectedLanguage}) }}
          </th>
          <th class="font_detail">
            {{ t("ROUTE", {}, {locale: selectedLanguage}) }}
          </th>
          <th class="font_detail">
            {{ t("BoardingPlace", {}, {locale: selectedLanguage}) }}
          </th>
          <th class="font_detail">
            {{ t("DroppingPlace", {}, {locale: selectedLanguage}) }}
          </th>
          <th class="font_detail">
            {{ t("Seat-and-Fare", {}, {locale: selectedLanguage}) }}
          </th>
          <th class="font_detail">
            {{ t("STATUS", {}, {locale: selectedLanguage}) }}
          </th>
          <th class="font_detail">
            {{ t("ACTION", {}, {locale: selectedLanguage}) }}
          </th>
        </tr>
        </thead>
        <tbody v-for="(ticket, index) in tickets" :key="ticket._id">
        <tr class="display-small-none">
          <td>{{ index + 1 }}</td>
          <td>
        <span class="text-white px-2 py-1 rounded-pill bg-success">
           {{ ticket.pnrCode }}
        </span>
          </td>
          <td>
            {{ ticket.trip?.route.name }}
            <div>
              <p class="text_size d-block">
                <i class="far fa-clock mr-1"> </i>
                <strong class="icon_margin">
                  {{ dateFormat(ticket?.tripDateTime) }} {{ timeFormat(ticket?.tripDateTime) }}
                </strong>
              </p>
            </div>
          </td>
          <td>{{ ticket.coach.name }}</td>
          <td>{{ ticket.trip?.route.name }}</td>
          <td>{{ ticket.boardingPlace }}</td>
          <td>{{ ticket.droppingPoint }}</td>
          <td>
            <div v-for="(value, seat) in ticket.farePerSeat" :key="seat">
              {{ seat }} - {{ value }}
            </div>
          </td>
          <td>
            <span v-if="ticket.status" class="badge bg-success">
                Purchased
            </span>
            <span v-else class="badge bg-danger">
                Cancelled
            </span>
          </td>
          <td>
            <button v-if="ticket._id !== selectedTicket"
                    class="custom_button btn btn-primary px-4"
                    @click="viewTicket(ticket._id)"
            >
              {{ t("View", {}, {locale: selectedLanguage}) }}
            </button>
            <button v-else
                    class="custom_button btn btn-primary px-4"
                    @click="viewTicket('')"
            >
              {{ t("Close", {}, {locale: selectedLanguage}) }}
            </button>
          </td>
        </tr>

        <tr class="display-md-none">
          <div class="table_row_phone custom-font">
            <div class="table_single_row">
              <div class="p-3 line_height itemShadow shadow">

                <p class="text_size d-flex justify-content-between">

                  <span class="fw-bold"> {{ ticket.coach.name }}</span>
                  <span class="text-white px-2 py-2 rounded-pill bg-success">
                    {{ ticket.pnrCode }}
                  </span>
                </p>
                <p class="text-start">
                  <strong class="text-success">{{ ticket.trip?.route.name }}</strong>

                </p>
                <div class="d-flex gap-3">
                  <p class="text_size">
                    <i class="far fa-clock"> </i>
                    <strong class="icon_margin">
                      {{ dateFormat(ticket?.tripDateTime) }}
                    </strong>
                  </p>

                  <p class="text_size ms-5">
                    <i class="far fa-calendar-alt"> </i>
                    <strong class="icon_margin">
                      {{ timeFormat(ticket?.tripDateTime) }}
                    </strong>
                  </p>
                </div>
                <button
                    v-if="ticket._id !== selectedTicket"
                    class="phnbtn mr-2 buttonText font_detail w-100 mx-auto common_btn corporate_btn"
                    @click="viewTicket(ticket._id)"
                >
                        <span id="button_text2">{{
                            t("View Ticket", {}, {locale: selectedLanguage})
                          }}</span>
                </button>
                <button
                    v-else
                    class="phnbtn mr-2 buttonText font_detail w-100 mx-auto common_btn corporate_btn"
                    @click="viewTicket('')"
                >
                        <span id="button_text3">{{
                            t("Close", {}, {locale: selectedLanguage})
                          }}</span>
                </button>
              </div>
            </div>
          </div>
        </tr>

        <tr v-if="ticket._id === selectedTicket">
          <td class="p-2" colspan="9">
            <div class="row">
              <div class="col-md-12">
                <div class="my-3">
                  <h4>{{ ticket.company.name }}</h4>
                  <h5>{{ ticket?.trip?.route?.name }}</h5>
                </div>
              </div>
              <div class="col-md-3 col-12">
                <table class="table table-bordered table-striped">
                  <thead>
                  <tr>
                    <th colspan="2"><strong>{{t("User_Details", {}, {locale: selectedLanguage})}}</strong></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>{{t("Name", {}, {locale: selectedLanguage})}}</td>
                    <td>{{ ticket.passengerName }}</td>
                  </tr>
                  <tr>
                    <td>{{t("Phone", {}, {locale: selectedLanguage})}}</td>
                    <td>{{ ticket.passengerPhone }}</td>
                  </tr>
                  <tr>
                    <td>{{t("Gender", {}, {locale: selectedLanguage})}}</td>
                    <td>{{ ticket.passengerGender }}</td>
                  </tr>
                  <tr>
                    <td>{{t("NID", {}, {locale: selectedLanguage})}}</td>
                    <td>{{ ticket.passengerNID }}</td>
                  </tr>
                  <tr>
                    <td>{{t("Address", {}, {locale: selectedLanguage})}}</td>
                    <td>{{ ticket.passengerAddress }}</td>
                  </tr>
                  <tr>
                    <td>{{t("ISSUED ON", {}, {locale: selectedLanguage})}}</td>
                    <td>{{ dateTimeFormat(ticket.ticketDateTime, 6) }}</td>
                  </tr>
                  <tr>
                    <td>{{t("Issued By", {}, {locale: selectedLanguage})}}</td>
                    <td>{{ ticket.issuedBy.name }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div class="col-md-2 col-12">
                <table class="table table-bordered table-striped">
                  <thead>
                  <tr>
                    <th colspan="2"><strong>{{t("Booking Information", {}, {locale: selectedLanguage})}}</strong></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>{{t("From", {}, {locale: selectedLanguage})}}</td>
                    <td>{{ ticket.fromStoppage.name }}</td>
                  </tr>
                  <tr>
                    <td>{{t("To", {}, {locale: selectedLanguage})}}</td>
                    <td>{{ ticket.toStoppage.name }}</td>
                  </tr>
                  <tr>
                    <td>{{t("Boarding", {}, {locale: selectedLanguage})}}</td>
                    <td>{{ ticket.boardingPlace }}</td>
                  </tr>
                  <tr>
                    <td>{{t("Dropping", {}, {locale: selectedLanguage})}}</td>
                    <td>{{ ticket.droppingPoint }}</td>
                  </tr>
                  <tr>
                    <td>{{t("Couch", {}, {locale: selectedLanguage})}}</td>
                    <td>{{ ticket.coach.name }}</td>
                  </tr>
                  <tr>
                    <td>{{t("Departure Date", {}, {locale: selectedLanguage})}}</td>
                    <td>{{ dateFormat(ticket.tripDateTime) }}</td>
                  </tr>
                  <tr>
                    <td>{{t("Departure Time", {}, {locale: selectedLanguage})}}</td>
                    <td>{{ timeFormat(ticket.tripDateTime) }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div class="col-md-2 col-12">
                <table class="table table-bordered table-striped">
                  <thead>
                  <tr>
                    <th colspan="2"><strong>{{t("Seat Information", {}, {locale: selectedLanguage})}}</strong></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>{{t("SEATS", {}, {locale: selectedLanguage})}}</td>
                    <td>
                      [<span>{{ ticket.seatNumbers.join(", ") }}</span>]
                    </td>
                  </tr>
                  <tr>
                    <td>{{t("TOTAL AMOUNT", {}, {locale: selectedLanguage})}}</td>
                    <td>Tk. {{ ticket.totalAmount }}</td>
                  </tr>
                  <tr>
                    <td>{{t("DISCOUNT", {}, {locale: selectedLanguage})}}</td>
                    <td>Tk. {{ ticket.discount }}</td>
                  </tr>
                  <tr>
                    <td>{{t("Payable", {}, {locale: selectedLanguage})}}</td>
                    <td>Tk. {{ ticket.payable }}</td>
                  </tr>
                  <tr>
                    <td>{{t("Paid", {}, {locale: selectedLanguage})}}</td>
                    <td>Tk. {{ ticket.paid }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div class="col-md-5 col-12">
                <table class="table table-bordered table-striped">
                  <thead>
                  <tr>
                    <th colspan="2"><strong>{{t("Seat Cancellation", {}, {locale: selectedLanguage})}}</strong></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td style="width: 150px">
                      <div class="row">
                        <div v-for="seatNo in ticket.seatNumbers" :key="seatNo" class="col-md-6">
                          <input v-if="ticket.status === true" :id="seatNo"
                                 v-model="ticketCancelData.cancellableSeats"
                                 :value="seatNo"
                                 style="width: 15px; height: 15px; margin-right: 10px"
                                 type="checkbox">
                          <label :for="seatNo" style="font-size: 15px;">{{ seatNo }}</label>
                        </div>
                        <Error v-if="errors.CancellableSeats"
                               v-bind:error="errors.CancellableSeats"
                        />
                      </div>
                    </td>
                    <td>
                      <div v-if="ticket.cancelledBy && ticket.cancelledBy !== ''">
                        <span class="badge bg-danger">Cancelled</span>
                      </div>
                      <div v-else>
                        <div class="row form-group">

                          <ModalDropdown
                              v-bind:id="'status'"
                              v-model="ticketCancelData.cancellationCause"
                              v-bind:default-option="'--select reason--'"
                              v-bind:default-value="''"
                              v-bind:dropdown="ticketCancelReason"
                              v-bind:label="'Cancel reason'"
                          >
                            <template v-slot:error>
                              <ModalError
                                  v-if="errors.ReasonToCancelIssue"
                                  v-bind:error="errors.ReasonToCancelIssue"
                              />
                            </template>
                          </ModalDropdown>

                          <!--                      <Error v-if="errors.ReasonToCancelIssue"-->
                          <!--                             v-bind:error="errors.ReasonToCancelIssue"-->
                          <!--                      />-->
                        </div>
                        <div
                            v-if="ticketCancelData.cancellationCause == 'অন্যান্য'"
                            class="form-group row my-1"
                        >
                          <label class="col-sm-3 col-form-label font_detail"></label>
                          <div class="col-12 col-md-9">
                          <TextArea
                              v-bind:id="'Cancel'"
                              v-model="ticketCancelIssue"
                              v-bind:placeholder="'Why are you cancelling Ticket?'"
                              v-bind:type="'text'"
                          >
                          </TextArea>
                            <Error v-if="errors.TicketCancelIssue"
                                   v-bind:error="errors.TicketCancelIssue"
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12 col-md-3"></div>
                          <div class="col-12 col-md-9">
                            <button class="btn btn-danger d-block w-100 mt-2 font_detail" @click="cancelTickets">
                              {{t("Cancel Ticket", {}, {locale: selectedLanguage})}}
                            </button>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

  </div>

</template>

<script>
import {useI18n} from "vue-i18n";
import {computed, ref, reactive, inject} from "vue";
import store from "@/store";
import {dateFormat, timeFormat, dateTimeFormat} from "@/helpers/dateTimeFormat";
import dropdownVariables from "@/modules/dropdownVariables";
import useInputValidation from "@/modules/useInputValidations";
import permission from "@/modules/permission";
import roles from "@/modules/roles";

export default {
  name: "GlobalFilter",
  emits: ["openGlobalFilter"],

  setup(props, {emit}) {
    const {t, locale} = useI18n();
    const {hasPermission} = permission();
    const {Counterman} = roles();
    const selectedSeat = ref([]);
    const selectedTicket = ref('');
    const selectedLanguage = computed(() => store.getters.getLang)
    const tickets = computed(() => store.getters.getSearchTickets)
    const userInfo = computed(() => store.getters.getUser)
    const ticketCancelReason = dropdownVariables.ticketCancelReason;
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");
    const initialState = {
      ticketId: "",
      cancellableSeats: [],
      cancellationCause: "",
    }
    const payDataPyload = reactive({
      ticketId: "",
      payable: 0,
      partialPaid: 0,
      discount: 0,
      paid: 0,
      due: 0,
      refund: 0
    })
    const ticketCancelData = reactive({...initialState});
    let ticketCancelIssue = ref("");

    let {requiredValidation, errors} = useInputValidation();
    const singleTicket = computed(() => store.getters.getSearchSingleTicket(selectedTicket.value))
    const remainingPayable = computed(() => {
      return payDataPyload.payable - payDataPyload.discount - payDataPyload.partialPaid
    })

    const goBack = (fromValue) => {
      emit("openGlobalFilter", {isOpen: false, from: fromValue})
    }
    const viewTicket = (ticket_id) => {
      console.log(userInfo.value)
      Object.assign(ticketCancelData, initialState)
      ticketCancelIssue.value = "";
      if (ticket_id === "") {
        selectedTicket.value = "";
        return;
      }

      ticketCancelData.ticketId = ticket_id;
      selectedTicket.value = ticket_id;
    }

    const cancelTickets = () => {
      if (validate()) {
        showLoader()
        store.dispatch("cancelTicket", ticketCancelData)
            .then(res => {
              console.log(res)
              hideLoader()
              goBack("globalFilter")
            })
            .catch(err => {
              console.log(err)
              hideLoader()
            })
      }
    }

    const validate = () => {
      Object.keys(errors).forEach(function (key) {
        errors[key] = "";
      });
      requiredValidation("CancellableSeats", ticketCancelData.cancellableSeats);
      requiredValidation("ReasonToCancelIssue", ticketCancelData.cancellationCause);
      if (ticketCancelData.cancellationCause === 'অন্যান্য') {
        requiredValidation("TicketCancelIssue", ticketCancelIssue.value);
      }
      return !(
          Object.values(errors).length <= 0 ||
          !Object.values(errors).every((x) => x === null || x === "")
      );
    };

    return {
      t,
      locale,
      selectedLanguage,
      tickets,
      selectedSeat,
      selectedTicket,
      viewTicket,
      dateFormat,
      timeFormat,
      dateTimeFormat,
      singleTicket,
      goBack,
      ticketCancelReason,
      ticketCancelData,
      ticketCancelIssue,
      cancelTickets,
      errors,
      payDataPyload,
      remainingPayable,
      hasPermission,
      Counterman,
    };
  }
}
</script>
<style>
@media only screen and (min-width: 768px) {
  .display-md-none {
    display: none !important;
  }
}

@media only screen and (max-width: 767px) {
  .display-small-none {
    display: none;
  }

  .bording_table_content {
    display: none;
  }
}

button.accordion-button {
  background-color: #242424;
  color: #fff;
  border: 0;
  padding-left: 10px;
  padding-right: 10px;
}

.accordion-button::after {
  filter: invert(1);
}

/* responsive start  */
@media only screen and (min-width: 768px) {
  .bording_table_phone {
    display: none !important;
  }
}

@media only screen and (max-width: 767px) {
  .bording_table {
    display: none;
  }

  .bording_table_content {
    display: none;
  }
}

/* responsive end  */
.phnbtn {
  width: 80%;
  background-color: #f04935;
  color: #fff;
  border: #f04935;
  border-radius: 5px 5px 5px 5px;
  /* margin-left: 20px !important; */
  padding: 10px;
}

.line_height {
  line-height: 10px;
}

.itemShadow {
  border: 1px solid #ddd;
  border-radius: 6px;
}

.custom-font {
  font-family: "Lato", sans-serif;
}

.icon_margin {
  margin-left: 5px;
}

.bording_table_phone {
  box-shadow: #0c0b0b;
  /* border: 1 px solid black; */
  /* box-shadow: orangered; */
}

.color-blue {
  color: blue;
}

.droping_place {
  color: red;
  font-weight: 700;
  letter-spacing: 2px;
}

.bording_place {
  color: #12b83e;
  font-weight: 700;
  letter-spacing: 2px;
}

.number5 {
  font-size: 20px;
  font-weight: 700;
  color: #ff9c07;
}

.number6 {
  font-size: 20px;
  font-weight: 700;
  color: #ff9c07;
}

.number1 {
  font-size: 16px;
  font-weight: 700;
  color: red;
}

.number4 {
  font-weight: 700;
  color: #ff9c07;
  letter-spacing: 2px;
  /* font-size: 16px; */
}

.number {
  font-size: 20px;
  font-weight: 700;
  color: #12b83e;
}

.font1 {
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
}

.custom_button {
  margin: 1px 2px;
  border: 2px solid #0051ff;
  border-radius: 4px;
  background-color: #0051ff;
  padding: 2px 6px;
  color: #fff;
  position: relative;
  font-size: 14px;
  font-family: Helvetica Neue;
}

.custom_button:hover,
.custom_button:focus {
  border: 2px solid #0051ff;
  background-color: #fff;
  color: #0051ff;
  transition: 0.4s;
  outline: 0;
}

.font-12 {
  font-size: 14px;
}

.global-filter-input {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 14px;
}

.height-34 {
  height: 34px;
}

.nearest-trip-highlight {
  background: #59db756b;
}
</style>
