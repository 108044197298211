<template>
  <FilterTab>
    <template v-slot:title
      ><h6>
        {{ t("Ticket History", {}, { locale: $store.getters.getLang }) }}
      </h6></template
    >
    <template v-slot:body>
      <div class="col-sm-6 col-md-2">
        <DropdownFilter
          v-bind:id="'seatType'"
          v-bind:label="'Seat Type'"
          v-model="pagination.ticketType"
          v-bind:dropdown="dropdownVariables.seatTypes"
        />
      </div>
    </template>
    <template v-slot:button1>
      <button class="edit_table_data font_detail" @click="goBack">
        <i class="fas fa-arrow-left"></i
        ><span class="mx-1 bold">{{
          t("Go Back", {}, { locale: $store.getters.getLang })
        }}</span>
      </button>
    </template>
  </FilterTab>
  <div>
    <div class="p-2 position-relative d-md-none">
      <!-- Table Data Pannel -->
      <div>
        <div
          class="card w-100 my-2"
          v-for="ticket in $store.getters.getTicketsHistory"
          :key="ticket._id"
        >
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <p>
                  <b
                    >{{
                      t("COACH", {}, { locale: $store.getters.getLang })
                    }}:</b
                  >{{ ticket?.coach?.name }}
                </p>
              </div>
              <div class="col-6">
                <p>
                  <b
                    >{{
                      t("STOPPAGE", {}, { locale: $store.getters.getLang })
                    }}:</b
                  >
                  {{ ticket?.fromStoppage?.name }} -
                  {{ ticket?.toStoppage?.name }}
                </p>
              </div>
              <div class="col-6">
                <p>
                  <b
                    >{{
                      t("ISSUED BY", {}, { locale: $store.getters.getLang })
                    }}:</b
                  >
                  {{ ticket?.issuedBy?.name }}
                </p>
              </div>
              <div class="col-6">
                <p>
                  <b
                    >{{
                      t("COUNTER NAME", {}, { locale: $store.getters.getLang })
                    }}:</b
                  >
                  {{ ticket?.issuedBy?.counterId?.name }}
                </p>
              </div>
              <div class="col-6">
                <p>
                  <b
                    >{{
                      t("TICKET TYPE", {}, { locale: $store.getters.getLang })
                    }}:</b
                  >
                  {{ ticket?.ticketType }}
                </p>
              </div>
              <div v-if="pagination.ticketType !== 'GOODS'" class="col-6">
                <p>
                  <b
                    >{{
                      t("SEAT NUMBERS", {}, { locale: $store.getters.getLang })
                    }}:</b
                  >
                  {{ ticket?.seatNumbers.toString() }}
                </p>
              </div>
              <div v-if="pagination.ticketType !== 'GOODS'" class="col-6">
                <p>
                  <b>
                    {{
                      t("SEAT CLASS", {}, { locale: $store.getters.getLang })
                    }}:</b
                  >
                  <span v-if="ticket?.seatClass !== ''">
                    {{ ticket?.seatClass }}
                  </span>
                  <span v-else>
                    {{ t("Mixed", {}, { locale: $store.getters.getLang }) }}
                  </span>
                </p>
              </div>
              <div v-if="pagination.ticketType !== 'GOODS'" class="col-6">
                <p>
                  <b>
                    {{
                      t("PNR CODE", {}, { locale: $store.getters.getLang })
                    }}:</b
                  >
                  {{ ticket?.pnrCode }}
                </p>
              </div>
              <div v-if="pagination.ticketType === 'GOODS'" class="col-6">
                <p>
                  <b>
                    {{
                      t("Goods Id", {}, { locale: $store.getters.getLang })
                    }}:</b
                  >
                  {{ ticket?.goodsId }}
                </p>
              </div>
              <div v-if="hasPermission(Counterman.ticketCancel)" class="col-6">
                <button
                  class="btn btn-warning btn-sm p-1"
                  @click="ticketCancel(ticket, ticket?.ticketType)"
                >
                  <i></i
                  ><span class="mx-1">{{
                    t("Cancel", {}, { locale: $store.getters.getLang })
                  }}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Table class="d-none d-md-block" v-if="$store.getters.getTicketsHistory">
      <template v-slot:header>
        <tr>
          <td class="font_detail">
            {{ t("SL", {}, { locale: $store.getters.getLang }) }}
          </td>
          <td class="font_detail">
            {{ t("COACH", {}, { locale: $store.getters.getLang }) }}
          </td>
          <td class="font_detail">
            {{ t("STOPPAGE", {}, { locale: $store.getters.getLang }) }}
          </td>
          <td class="font_detail">
            {{ t("ISSUED BY", {}, { locale: $store.getters.getLang }) }}
          </td>
          <td class="font_detail">
            {{ t("COUNTER NAME", {}, { locale: $store.getters.getLang }) }}
          </td>
          <td class="font_detail">
            {{ t("TICKET TYPE", {}, { locale: $store.getters.getLang }) }}
          </td>
          <td v-if="pagination.ticketType !== 'GOODS'" class="font_detail">
            {{ t("SEAT NUMBERS", {}, { locale: $store.getters.getLang }) }}
          </td>
          <td class="font_detail" v-if="pagination.ticketType !== 'GOODS'">
            {{ t("SEAT CLASS", {}, { locale: $store.getters.getLang }) }}
          </td>
          <td class="font_detail" v-if="pagination.ticketType !== 'GOODS'">
            {{ t("PNR CODE", {}, { locale: $store.getters.getLang }) }}
          </td>
          <td class="font_detail" v-if="pagination.ticketType === 'GOODS'">
            {{ t("Goods Id", {}, { locale: $store.getters.getLang }) }}
          </td>
          <td class="font_detail">
            {{ t("TICKET", {}, { locale: $store.getters.getLang }) }}
          </td>
        </tr>
      </template>
      <template v-slot:body>
        <tr
          v-for="(ticket, index) in $store.getters.getTicketsHistory"
          :key="ticket._id"
        >
          <td class="font_detail">{{ index + 1 }}</td>
          <td class="font_detail">{{ ticket?.coach?.name }}</td>
          <td class="font_detail">
            {{ ticket?.fromStoppage?.name }} - {{ ticket?.toStoppage?.name }}
          </td>
          <td class="font_detail">{{ ticket?.issuedBy?.name }}</td>
          <td class="font_detail">{{ ticket?.issuedBy?.counterId?.name }}</td>
          <td class="font_detail">{{ ticket?.ticketType }}</td>
          <td v-if="pagination.ticketType !== 'GOODS'" class="font_detail">
            {{ ticket?.seatNumbers.toString() }}
          </td>
          <td class="font_detail" v-if="pagination.ticketType !== 'GOODS'">
            <span v-if="ticket?.seatClass !== ''">
              {{ ticket?.seatClass }}
            </span>
            <span v-else>
              {{ t("Mixed", {}, { locale: $store.getters.getLang }) }}
            </span>
          </td>
          <td class="font_detail" v-if="pagination.ticketType !== 'GOODS'">
            {{ ticket?.pnrCode }}
          </td>
          <td class="font_detail" v-if="pagination.ticketType === 'GOODS'">
            {{ ticket?.goodsId }}
          </td>
          <td class="font_detail bold">
            <button
              :disabled="
                ticket.issuedBy.name === $store.getters.getUser.name
                  ? hasPermission(Counterman.ticketCancel)
                    ? false
                    : true
                  : hasPermission(Counterman.cancelOthersTicket)
                  ? false
                  : true
              "
              class="btn btn-warning btn-sm"
              @click="ticketCancel(ticket, ticket?.ticketType)"
            >
              <i></i
              ><span class="mx-1">{{
                t("Cancel", {}, { locale: $store.getters.getLang })
              }}</span>
            </button>
          </td>
        </tr>
      </template>
      <template v-slot:pagination>
        <pagination
          v-model="pagination.page"
          :records="$store.getters.getTicketsHistoryTotalDocs"
          :per-page="pagination.limit"
          @paginate="getAllTickets"
          :options="options"
        />
      </template>
    </Table>
  </div>
  <TicketCancelModal
    @getAllTickets="getAllTickets"
    v-if="cancelTicketModal"
    v-model="cancelTicketModal"
    :ticketIdStore="ticketData"
    :ticketTotalSeats="cancellableSeats"
    :ticketTypeForProps="ticketTypeForProps"
  />
  <!-- </div> -->
</template>

<script>
import moment from "moment";
import { reactive } from "@vue/reactivity";
import { inject, onBeforeMount, watch, ref } from "vue";
import store from "@/store";
import dropdownVariables from "@/modules/dropdownVariables";
import permission from "@/modules/permission";
import roles from "@/modules/roles";
import TicketCancelModal from "./TicketCancelModal.vue";
import { useI18n } from "vue-i18n";
export default {
  name: "Trips",
  props: {
    tripId: String,
    modelValue: Boolean,
  },
  components: {
    TicketCancelModal,
  },
  setup(props, { emit }) {
    const { hasPermission } = permission();
    const { Counterman } = roles();
    let cancelTicketModal = ref(false);
    let ticketData = ref({});
    let cancellableSeats = ref([]);
    let ticketTypeForProps = ref("");
    const { t, locale } = useI18n();
    // moment(new Date()).format("yyyy-MM-DD")
    let pagination = reactive({
      tripId: props.tripId,
      page: 1,
      limit: 25,
      ticketType: "SEAT",
    });

    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");

    const options = {
      texts: {
        count: "",
      },
    };

    // const status = computed(() => {
    //   let allTripStatus = {};
    //   for (let [key, value] of Object.entries(dropdownVariables.tripStatus)) {
    //     allTripStatus[key] = value.value;
    //   }
    //   return allTripStatus;
    // });

    watch(
      () => pagination.ticketType,
      (value) => {
        pagination.ticketType = value;
        getAllTickets();
      }
    );

    const dateFormat = (date) => {
      return moment(String(date))
        .utcOffset(0, true)
        .format("DD-MMM-yyyy hh:mm:ss A");
    };

    const getAllTickets = () => {
      showLoader();
      store
        .dispatch("ticketHistory", pagination)
        .then(() => {
          hideLoader();
        })
        .catch((error) => {
          hideLoader();
          if (error.status === 401) {
            getAllTickets();
          }
        });
    };
    const goBack = () => {
      emit("update:modelValue", false);
    };

    const ticketCancel = (ticket, ticketType) => {
      ticketTypeForProps.value = ticketType;
      ticketData.value = ticket._id;
      cancellableSeats.value = ticket.seatNumbers;
      cancelTicketModal.value = true;
    };

    onBeforeMount(() => {
      getAllTickets();
    });
    return {
      t,
      locale,
      pagination,
      dropdownVariables,
      close,
      dateFormat,
      // status,
      options,
      hasPermission,
      Counterman,
      goBack,
      ticketCancel,
      getAllTickets,
      cancelTicketModal,
      cancellableSeats,
      ticketData,
      ticketTypeForProps,
    };
  },
};
</script>

<style scoped>
.card-body p {
  font-size: 12px;
  margin-bottom: 5px;
}
.bold {
  font-weight: bold;
}
.seat {
  background-color: yellow;
  margin: 3px;
  border: none;
  width: 60px;
  padding: 5px;
}
.seat:hover {
  background-color: red;
  color: white;
}
</style>
