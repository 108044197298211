<template>
  <div class="form-check form-check-inline">
    <input
      class="form-check-input options"
      :class="{ customSizeInput: isBigger }"
      type="checkbox"
      :id="checkRole"
      v-bind:checked="checked"
      :value="checkRole"
      @click="click(checkRole)"
    />
    <label
      class="form-check-label options"
      :class="{ customLabel: isBigger }"
      :for="checkRole"
      >{{ t(checkLabel, {}, { locale: $store.getters.getLang }) }}</label
    >
  </div>
</template>

<script>
import { onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
export default {
  name: "Checkbox",
  props: {
    modelValue: Array,
    role: [Number, String],
    label: String,
    bigger: Boolean,
  },
  setup(props, { emit }) {
    const checkLabel = ref(props.label);
    const checkRole = ref(props.role);
    const arr = ref(props.modelValue);
    const checked = ref(false);
    const isBigger = ref(props.bigger);
    const { t, locale } = useI18n();
    onMounted(() => {
      if (arr.value.includes(checkRole.value)) {
        checked.value = true;
      }
    });

    watch(
      () => [...props.modelValue],
      (value) => {
        arr.value = value;
        if (arr.value.includes(checkRole.value)) {
          checked.value = true;
        } else {
          checked.value = false;
        }
        emit("toggleAllPermission");
      }
    );

    const click = (role) => {
      if (arr.value.includes(role)) {
        arr.value.splice(arr.value.indexOf(role), 1);
      } else {
        arr.value.push(role);
      }
      emit("toggleAllPermission");
      emit("update:modelValue", arr.value);
    };
    return {
      t,
      locale,
      checkLabel,
      checkRole,
      click,
      checked,
      isBigger,
    };
  },
};
</script>

<style scoped>
.customSizeInput {
  /* Double-sized Checkboxes */
  -ms-transform: scale(1.7); /* IE */
  -moz-transform: scale(1.7); /* FF */
  -webkit-transform: scale(1.7); /* Safari and Chrome */
  -o-transform: scale(1.7); /* Opera */
  padding: 1px;
  margin-right: 10px;
}
.customLabel {
  font-size: 18px;
  line-height: 0;
}
</style>
