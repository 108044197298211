<template>
  <Modal @close="closeModal">
    <template v-slot:title>{{ title }}</template>
    <template v-slot:body>
      <form>
        <!-- {{ $store.getters.getAllStoppageList }} -->

        <div class="form-group row my-1">
          <ModalInput
              v-model="depotData.name"
              v-bind:placeholder="'Enter Name'"
              v-bind:label="'Name'"
              v-bind:id="'name'"
              v-bind:type="'text'"
          >
            <template v-slot:error>
              <ModalError v-if="errors.name" v-bind:error="errors.name"/>
            </template>
          </ModalInput>
        </div>
        <div class="form-group row my-1">
          <ModalInput
              v-model="depotData.complainNumber"
              v-bind:placeholder="'Enter Complain Number'"
              v-bind:label="'Complain Number'"
              v-bind:id="'complainNumber'"
              v-bind:type="'text'"
          >
            <template v-slot:error>
              <ModalError v-if="errors.complainNumber" v-bind:error="errors.complainNumber"/>
            </template>
          </ModalInput>
        </div>
        <div class="form-group row my-1">
          <ModalDropdown
              v-if="status"
              v-model="depotData.status"
              v-bind:id="'status'"
              v-bind:label="'Status'"
              v-bind:dropdown="status"
          ></ModalDropdown>
        </div>

        <div class="form-group row my-1">
          <label class="col-sm-3 col-form-label"></label>
          <div class="col-sm-9">
            <input
                v-if="!depotId"
                type="submit"
                class="mt-3 py-2 submit_button font_detail float-end"
                @click.prevent="addDepot"
            />
            <input
                v-else
                type="submit"
                class="mt-3 py-2 submit_button font_detail float-end"
                @click.prevent="updateDepot"
            />
          </div>
        </div>
      </form>
    </template>
  </Modal>
</template>

<script>
import dropdownVariables from "@/modules/dropdownVariables";
import useInputValidation from "@/modules/useInputValidations";
import {inject, onBeforeMount, ref, watch} from "vue";
import {reactive} from "@vue/reactivity";
import store from "@/store";
import {useRoute} from "vue-router";

export default {
  name: "DepotAddUpdateModal",
  props: {
    depot: String,
  },
  setup(props, {emit}) {
    const route = useRoute();
    let depotData = reactive({
      id: "",
      companyId: "",
      name: "",
      complainNumber: "",
      depot: "",
      status: 1,
    });
    let {requiredValidation, errors} =
        useInputValidation();
    const depotId = ref(props.depot);
    const status = dropdownVariables.status;

    const title = ref("Add Depot");
    const showLoader = inject("showLoader");
    const hideLoader = inject("hideLoader");

    let pagination = reactive({
      page: 1,
      limit: 25,
    });

    watch(
        () => depotData.name,
        () => {
          requiredValidation("name", depotData.name);
        }
    );

    const validate = () => {
      Object.keys(errors).forEach(function (key) {
        errors[key] = "";
      });
      requiredValidation("name", depotData.name);
      return !(
          Object.values(errors).length <= 0 ||
          !Object.values(errors).every((x) => x === null || x === "")
      );
    };
    const closeModal = () => {
      emit("update:modelValue", false);
    };
    const addDepot = () => {
      if (validate()) {
        showLoader();
        console.log(depotData)
        store
            .dispatch("addDepot", {depot: depotData, pagination})
            .then(() => {
              hideLoader();
              emit("getAllDepots");
              closeModal();
            })
            .catch((error) => {
              hideLoader();
              if (error.status === 401) {
                addDepot();
              }
            });
      }
    };
    const updateDepot = () => {
      if (validate()) {
        showLoader();
        store
            .dispatch("updateDepot", { depot: depotData, pagination})
            .then(() => {
              hideLoader();
              emit("getAllDepots");
              closeModal();
            })
            .catch((error) => {
              hideLoader();
              if (error.status === 401) {
                updateDepot();
              }
            });
      }
    };

    onBeforeMount(() => {
      Object.keys(errors).forEach(function (key) {
        delete errors[key];
      });
      depotData.companyId = route.query.id
      if (depotId.value !== "") {
        const depotDetails = store.getters.getDepot(depotId.value);
        title.value = "Update Depot";
        depotData.id = depotDetails[0]._id;
        depotData.companyId = depotDetails[0].companyId;
        depotData.name = depotDetails[0].name;
        depotData.complainNumber = depotDetails[0].complainNumber;
        depotData.depot = depotDetails[0].depot;
        depotData.status = depotDetails[0].status ? 1 : 0;
      }
    });

    return {
      depotId,
      depotData,
      errors,
      status,
      title,
      closeModal,
      addDepot,
      updateDepot,
    };
  },
};
</script>

<style scoped></style>
