import axios from "axios";
import Apis from "@/urls/apis";
import tokenCookie from "@/modules/tokenCookie";
import apiResponse from "@/modules/apiResponse";
// import toast from "@/modules/Toast";

// const {successToast} = toast();


const {hasValidToken,getToken} = tokenCookie();
const {successResponse,errorResponse} = apiResponse();

const state = {
	admins: [],
	
};
const getters = {
    getAdmins: (state) => state.admins.docs,
    getTotalRecords: (state) => state.admins.totalDocs,
    getAdmin: (state) => (id) => state.admins.docs.filter(function (user) {
        return user._id === id;
    })
};
const actions = {
    async getAdminsByPage({commit}, payload) {
        if (await hasValidToken()) {
            commit("setAdmins", []);
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };
            return new Promise((resolve, reject) => {
                axios.get(Apis.GET_ALL_USERS, {
                    params: payload,
                    headers: header
                })
                    .then(({data, status}) => {
                        if (status === 200) {
                            commit("setAdmins", data.admin)
                            resolve({'success': 'success'});
                        }
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },

    async addAdmin({commit},{admin,pagination}) {

        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };
            return new Promise((resolve, reject) => {
                axios.post(Apis.ADD_USER, admin, {headers: header,params:pagination})
                    .then((success) => {
                        commit('setAdmins',success.data.admin)
                        // successToast(success.data.message)
                        resolve(successResponse(success))
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },

    async updateAdmin({commit}, {admin,pagination}) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };
            return new Promise((resolve, reject) => {
                axios.put(Apis.UPDATE_USER, admin, {headers: header,params:pagination})
                    .then((success) => {
                        commit('setAdmin',success.data.admin)
                        resolve(successResponse(success))
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    },

    async deleteAdmin({commit}, payload) {
        if (await hasValidToken()) {
            const header = {
                'Authorization': 'Bearer ' + getToken()
            };
            return new Promise((resolve, reject) => {
                axios.delete(Apis.DELETE_USER, {data: payload, headers: header})
                    .then((success) => {
                        if (success.status === 200) {
                            commit('deleteAdmin', payload);
                            resolve(successResponse(success))
                        }
                    })
                    .catch(error => {
                        reject(errorResponse(error))
                    });
            });
        }
    }


};
const mutations = {
    setAdmins: (state, admins) => (state.admins = admins),
    setAdmin: (state,admin) => state.admins.docs.filter((element,index) => {
        if(element._id===admin._id){
            state.admins.docs.splice(index,1,admin)
        }
    }),
    deleteAdmin: (state, payload) => (state.admins.docs = state.admins.docs.filter((user) => user._id !== payload.id))
};

export default {
    state,
    getters,
    actions,
    mutations
};